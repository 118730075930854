import React, { useEffect, useRef, useState } from 'react'
import { LearningDocumentPoint, LearningDocumentPointType } from 'src/dtos/Training/LearningDocument.dto'
import { FormikProps } from 'formik';
import { LearningDocumentPointResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus } from 'src/dtos/Training/LearningDocumentResponse.dto';
import CommonPoint from './Components/CommonPoint';
import { useMutation } from '@tanstack/react-query';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import { SaveStatusIcons } from 'src/config/constants';
import TallyCounter from './Components/TallyCounter';
import TroubleShootingPoint from './Components/TroubleShootingPoint';


type BaseProps = {
    point: LearningDocumentPoint
    index: number
    previousPointType?: LearningDocumentPointType
    allIconlessPoints?: boolean
    pointResponse?: LearningDocumentPointResponse;
    demoMode?: boolean;
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    pointResponse: LearningDocumentPointResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    pointClick: (changedPointStatus: LearningDocumentResponseStatus, changedPointId: number) => void;
    pointLocked: boolean;
}

type Props = DisplayProps | ActiveProps;

export function Point(props: Props) {
    const {point, index, previousPointType, allIconlessPoints} = props;

    

    const [saveStatusIcon, setSaveStatusIcon] = useState<React.ReactNode>(SaveStatusIcons.idle)


    //Handle Update point
    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: LearningDocumentPointResponse }) => {
            return LearningDocumentResponseService.UpdatePoint(id, values)
        },
        onSuccess: () => {
            setSaveStatusIcon(SaveStatusIcons.success)
        },
        onError: () => {
            setSaveStatusIcon(SaveStatusIcons.failed)
        }
    })
    
    useEffect(() => {
        if(updateMutation.isPending && saveStatusIcon !== SaveStatusIcons.loading){
            setSaveStatusIcon(SaveStatusIcons.loading)
        }
    }, [saveStatusIcon, updateMutation.isPending])

    const hasMounted = useRef(false);
    useEffect(() => {
        function updatePoint(){
            if (hasMounted.current && props.demoMode === false) {
                //Point has changed
                if(props.pointResponse && !updateMutation.isPending){
                    updateMutation.mutateAsync({id: props.pointResponse.id, values: props.pointResponse})
                }
            } else {
                hasMounted.current = true;
            }
        }
        updatePoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pointResponse?.checked, props.pointResponse?.tallyValue])

    if (props.type === "DisplayOnly"){
        if(point.type === LearningDocumentPointType.tallyCounter) {
            return (
                <TallyCounter 
                    key={index}
                    type="DisplayOnly"
                    point={point}
                    index={index}
                />
            )
        } else if (point.type === LearningDocumentPointType.troubleshooting) {
            return (
                <TroubleShootingPoint
                    key={index} 
                    type="DisplayOnly"
                    point={point}
                    showHeader={previousPointType !== LearningDocumentPointType.troubleshooting}
                    allIconlessPoints={allIconlessPoints}
                />
            )
        } else {
            return (
                <CommonPoint 
                    key={index}
                    type="DisplayOnly"
                    point={point}
                    index={index}
                    allIconlessPoints={allIconlessPoints}
                />
            )
        }
    }
    else {
        const {pointResponse, formik, formikString, pointClick, pointLocked} = props
        if(point.type === LearningDocumentPointType.tallyCounter) {
            return (
                <TallyCounter 
                    key={index}
                    type="Active"
                    point={point}
                    index={index}
                    pointResponse={pointResponse}
                    formik={formik}
                    formikString={formikString}
                    pointClick={pointClick}
                    pointLocked={pointLocked}
                    SaveStatusIcon={saveStatusIcon}
                    allIconlessPoints={allIconlessPoints}
                />
            )
        } else if (point.type === LearningDocumentPointType.troubleshooting) {
            return (
                <TroubleShootingPoint
                    key={index} 
                    type='Active'
                    point={point}
                    showHeader={previousPointType !== LearningDocumentPointType.troubleshooting}
                    pointResponse={pointResponse}
                    formik={formik}
                    formikString={formikString}
                    pointClick={pointClick}
                    pointLocked={pointLocked}
                    SaveStatusIcon={saveStatusIcon}
                    allIconlessPoints={allIconlessPoints}
                />
            )
        } else {
            return (
                <CommonPoint 
                    key={index}
                    type="Active"
                    point={point}
                    index={index}
                    pointResponse={pointResponse}
                    formik={formik}
                    formikString={formikString}
                    pointClick={pointClick}
                    pointLocked={pointLocked}
                    SaveStatusIcon={saveStatusIcon}
                    allIconlessPoints={allIconlessPoints}
                    demoMode={props.demoMode}
                /> 
            )
        }
    }
}
