import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService';
import DepartmentService from 'src/Services/Resources/DepartmentService';
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService';
import SearchableTransferList from 'src/components/SearchableTransferList/SearchableTransferList';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { NameDto } from 'src/dtos/NameDto.dto';

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    handleBack: () => void
    handleNext: () => void
    selectedTeamMembers: readonly NameDto[]
    setSelectedTeamMembers: React.Dispatch<React.SetStateAction<readonly NameDto[]>>
}
function SelectTeamMembers({setMessageItem, handleBack, handleNext, selectedTeamMembers, setSelectedTeamMembers}:Props) {
    const [, setLeft] = useState<readonly NameDto[]>([]);

    const [departments, setDepartments] = useState<NameDto[]>([])
    const [trainingGroupParents, setTrainingGroupParents] = useState<NameDto[]>([])
    //#region queries
    const departmentNamesQuery = useQuery({
        queryKey: ["departmentNames"],
        queryFn: async () => {
            return await DepartmentService.GetNames();
        },
        
    })

    const trainingGroupParentNamesQuery = useQuery({
        queryKey: ["trainingGroupParentNames"],
        queryFn: async () => {
            return await TrainingGroupParentService.GetNames()
        }
    })

    const teamMemberNamesQuery = useQuery({
        queryKey: ["teamMemberNames", departments, trainingGroupParents],
        queryFn: async () => {
            return await TeamMemberService.GetNames(departments, trainingGroupParents)
        },
        select: (data) => {
            //remove already selected team members
            const newData = data.filter(tm => selectedTeamMembers.findIndex(stm => stm.id === tm.id) === -1)
            return newData
        }
    })


    //Handle Query errors
    useEffect(() => {
        if(departmentNamesQuery.isError){
            setMessageItem({error: departmentNamesQuery.error})
        }
        if(trainingGroupParentNamesQuery.isError){
            setMessageItem({error: trainingGroupParentNamesQuery.error})
        }
        if(teamMemberNamesQuery.isError){
            setMessageItem({error: teamMemberNamesQuery.error})
        }
    }, [departmentNamesQuery.error, departmentNamesQuery.isError, setMessageItem, teamMemberNamesQuery.error, teamMemberNamesQuery.isError, trainingGroupParentNamesQuery.error, trainingGroupParentNamesQuery.isError])

    //#endregion

    const stepCompleted = selectedTeamMembers.length > 0
    const handleBackClick = () => {
        handleBack()
    }

    const handleNextClick = () => {
        if(stepCompleted){
            handleNext()
        }
    }
    return (
        <>
            <Stack spacing={1} direction={"row"} sx={{paddingBottom:"0.5rem"}}>
                <Autocomplete
                    id="departments"
                    options={departmentNamesQuery.data ?? []}
                    multiple
                    value={departments}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label ? option.label: ""}
                    onChange={(e, value) => {
                        setDepartments(value) 
                    }}
                    size="small"
                    sx={{ width: "20rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            label="Department"
                        />
                    }
                />
                <Autocomplete
                    id="trainingGroupParents"
                    options={trainingGroupParentNamesQuery.data ?? []}
                    multiple
                    value={trainingGroupParents}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label ? option.label: ""}
                    onChange={(e, value) => {
                        setTrainingGroupParents(value) 
                    }}
                    size="small"
                    sx={{ width: "20rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            label="Training Groups"
                        />
                    }
                />
            </Stack>
        
            <SearchableTransferList
                choicesTitle='Team Members'
                left={teamMemberNamesQuery.data ?? []}
                setLeft={setLeft}
                right={selectedTeamMembers}
                setRight={setSelectedTeamMembers}
            />
            <span style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"1rem"}}>
                <Button onClick={() => handleBackClick()}>
                    Back
                </Button>
                <Button onClick={() => handleNextClick()} disabled={!stepCompleted}>
                    Next
                </Button>
            </span>
        </>
    )
}

export default SelectTeamMembers