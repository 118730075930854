export enum LocationType {
    Attraction = "Attraction",
    Outlet = "Outlet",
    Administrative = "Administrative",
    Facility = "Facility",
    Area = "Area",
    Grounds = "Grounds"
}

export const LocationTypeIndexToString: {[key: number]: LocationType} = {
    0: LocationType.Attraction,
    1: LocationType.Outlet,
    2: LocationType.Administrative,
    3: LocationType.Facility,
    4: LocationType.Area,
    5: LocationType.Grounds,
}

export const LocationStringToIndex: {[key: string]: number} = {
    "Attraction": 0,
    "Outlet": 1,
    "Administrative": 2,
    "Facility": 3,
    "Area": 4,
    "Grounds": 5,
}

export const locationTypesList: string[] = [
    LocationType.Attraction,
    LocationType.Outlet,
    LocationType.Administrative,
    LocationType.Facility,
    LocationType.Area,
    LocationType.Grounds
]

export interface LocationDto {
    id: string;
    label: string;
    parentLocationId?: string;
    parentLocation?: {id: string, label: string} | null
    description?: string;
    type: LocationType | undefined;
    sortOrder: number;
    enabled: boolean;
    createdBy: string;
    createdOn: Date;
    modifiedBy?: string;
    modifiedOn?: Date;
}

export interface CreateLocationDto {
    id?: string;
    label: string;
    description?: string;
    type: number;
    sortOrder: number;
    parentLocationId?: number;
    enabled?: boolean;
}

export interface LocationNameDto {
    id: string;
    label: string;
}
