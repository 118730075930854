import { TableRow, TableCell, TextField, ListItemButton, Checkbox, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import CustomSignaturePad from 'src/components/SignaturePad/SignaturePad'
import { dateTimeOptions } from 'src/config/constants'
import { AttendanceSheetAttendee, AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { LearningDocumentResponseDto } from 'src/dtos/Training/LearningDocumentResponse.dto'

type Props = {
    formik: FormikProps<LearningDocumentResponseDto>
    trainer: AttendanceSheetAttendee
}
function TrainerRow({formik, trainer}:Props) {
    const [TrainerNotes, setTrainerNotes] = useState(trainer.notes ?? "")
    const trainerIndex = formik.values.attendanceSheetResponse.attendees.findIndex(attendee => attendee.teamMemberId === trainer.teamMemberId && attendee.type === AttendeeType.Trainer)
    const updateAssessorSignature = (signature: string | undefined) => {
        
        formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].signature`, signature)
        if(signature){
            formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].timeSigned`, new Date())
        } else {
            formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].timeSigned`, null)
        }
        
    }

    const markAttended = () => {
        console.log("test")
        formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].attended`, !trainer.attended)
    }

    return (
        <TableRow
            key={trainer.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                <ListItemButton
                    onClick={markAttended}
                >
                    <Checkbox 
                        edge="start"
                        checked={trainer.attended} 
                        disableRipple
                    />
                    <Typography>{`${trainer.teamMember.prefferedFirstName} ${trainer.teamMember.lastName}`}</Typography>
                </ListItemButton>
            </TableCell>
            <TableCell>
                <TextField 
                    id={`attendanceSheetResponse.attendees[${trainerIndex}].notes`}
                    name={`attendanceSheetResponse.attendees[${trainerIndex}].notes`}
                    value={TrainerNotes}
                    onChange={(e) => setTrainerNotes(e.target.value)}
                    onBlur={() => formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].notes`, TrainerNotes)}
                    multiline
                    maxRows={5}
                    size='small'
                    sx={{width:"20rem"}}
                />
            </TableCell>
            <TableCell>
                <CustomSignaturePad 
                    person={{name: `${trainer.teamMember.prefferedFirstName} ${trainer.teamMember.lastName}`, signature: trainer.signature}}
                    setSignature={(signature: string | undefined) => updateAssessorSignature(signature)}
                />
            </TableCell>
            <TableCell>
                {trainer.timeSigned && new Date(trainer.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
            </TableCell>
        </TableRow>
    )
}

export default TrainerRow