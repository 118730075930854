import { ExpandMore } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, TextField, Typography, Autocomplete } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import DepartmentService from 'src/Services/Resources/DepartmentService';
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService';
import { guidRegex } from 'src/Utils/helperFunc';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { dateOptions } from 'src/config/constants';
import { TrainingGroupParent } from 'src/dtos/Training/TrainingGroupParent.dto';
import * as yup from 'yup'

const validationSchema = yup.object({
    label: yup.string().required("Name is a required field"),
    departments: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                label: yup.string()
            })
        ).min(1, "At least one department is required")
})

type Props = {
    open: boolean;
    handleClose: () => void;
    setMessageItem: (value: React.SetStateAction<MessageItem>) => void;
}

function TrainingGroupParentForm({open, handleClose,setMessageItem}: Props) {

    const [searchParams, setSearchParams] = useSearchParams()
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const id = searchParams.get("parentId")

    function ActivateAlertDialog() {
        const handleClick = () => {
          formik.setFieldValue("enabled", !formik.values.enabled);
          saveToggleEnable();
          setOpenAlertDialog(false);
        }
        return (
          <Dialog
            open={openAlertDialog}
            onClose={()=> setOpenAlertDialog(false)}
          >
            <DialogTitle>
            {formik.values.enabled ? 'Deactivate' : 'Activate'} Area Name?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to {formik.values.enabled ? 'deactivate' : 'activate'} {formik.values.label}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAlertDialog(false)}>Cancel</Button>
              <Button onClick={handleClick}>{formik.values.enabled ? 'Deactivate' : 'Activate'}</Button>
            </DialogActions>
          </Dialog>
        )
    }
    
        //#region queries

        const trainingGroupParentQuery = useQuery({
            queryKey: ["trainingGroupParent", id],
            queryFn: async () => {
                if(id){
                    return await TrainingGroupParentService.Get(id)
                }
                return null;
            },
        })

        const departmentNamesQuery = useQuery({
            queryKey: ["departmentNames"],
            queryFn: async () => {
                return await DepartmentService.GetNames();
            }
        })
    
        //Handle query errors
        useEffect(() => {
            if(trainingGroupParentQuery.isError){
                setMessageItem({error: trainingGroupParentQuery.error})
            }
            if(departmentNamesQuery.isError){
                setMessageItem({error: departmentNamesQuery.error})
            }
        }, [trainingGroupParentQuery.error, trainingGroupParentQuery.isError, setMessageItem, departmentNamesQuery.isError, departmentNamesQuery.error])
    
        const createMutation = useMutation({
            mutationFn: (values:TrainingGroupParent) => {
                return TrainingGroupParentService.Create(values)
            },
            onSuccess: (data) => {
                setMessageItem({successMessage: "Training Group Name created successfully!"})
    
                //update id param
                searchParams.set("parentId", data.data.id)
                setSearchParams(searchParams)
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })
    
        const updateMutation = useMutation({
            mutationFn: ({id, values} : {id: string, values:TrainingGroupParent}) => {
                return TrainingGroupParentService.Update(id, values)
            },
            onSuccess: (data) => {
                setMessageItem({successMessage: "Training Group Name updated successfully!"})
                formik.setValues(data.data)
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })
    
        const enableMutation = useMutation({
            mutationFn: (id: string) => {
                return TrainingGroupParentService.Enable(id);
            },
            onSuccess: () => {
                setMessageItem({successMessage: "Training Group Name Activated Succesfully!"})
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })
    
        const disableMutation = useMutation({
            mutationFn: (id: string) => {
                return TrainingGroupParentService.Disable(id);
            },
            onSuccess: () => {
                setMessageItem({successMessage: "Training Group Name Disabled Succesfully!"})
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })
    
    //#endregion
    
    const save = async (values: TrainingGroupParent) => {
        if(id && guidRegex.test(id)){
            updateMutation.mutate({id, values})
        } else {
            createMutation.mutate(values)
        }
    }

    const saveToggleEnable = async () => {
        if(id){
            if(!formik.values.enabled){
                //enable
                enableMutation.mutate(id)
            } else {
                //disable
                disableMutation.mutate(id)
            }
        }
    }

    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit()
        } else {
            formik.validateForm();
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: trainingGroupParentQuery.data ?? TrainingGroupParentService.GetDefaultValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save(values);
        }
    })
    
    return (
        <>
            <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>{id ? "Edit Training Group Name" : "New Training Group Name"}</DialogTitle>
                    <DialogContent>
                        <QuestionTitle title="Name"/>
                        <TextField
                            id="label"
                            name="label"
                            value={formik.values.label}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.label && Boolean(formik.errors.label)}
                            helperText={formik.touched.label && formik.errors.label}
                            variant="outlined"
                            size="small"
                            sx={{width:"20rem"}}
                        />
                        <QuestionTitle title="Departments"/>
                        <Autocomplete
                            id="departments"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => (option.label ? option.label : "")}
                            options={departmentNamesQuery.data ?? []}
                            multiple
                            disableClearable
                            value={formik.values.departments}
                            onChange={(_, value) => {
                                formik.setFieldValue("departments", value)
                            }}
                            onBlur={formik.handleBlur}
                            size="small"
                            sx={{width:"20rem"}}
                            renderInput={(params) => 
                                <TextField {...params}
                                    error={formik.touched.departments && Boolean(formik.errors.departments)}
                                    helperText={(formik.touched.departments && typeof formik.errors.departments === "string" && formik.errors.departments) ?? ""}
                                />
                            }
                        />
                        {id && (
                        <>
                            <QuestionTitle title="Training Group Name Status"/>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.enabled}
                                        onChange={() => setOpenAlertDialog(true)}
                                    />
                                }
                                label={formik.values.enabled ? "Active" : "Inactive"}   
                            />

                            <Accordion sx={{marginTop:"1rem", width:"20rem"}}>
                                <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                id="additional-info"
                                >
                                <Typography>Modification Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                {formik.values.createdBy && (
                                    <Typography>
                                    Created By: {formik.values.createdBy}
                                    </Typography>
                                )}
                                {formik.values.createdOn && (
                                    <Typography>
                                    Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                                    </Typography>
                                )}
                                {formik.values.modifiedBy && (
                                    <Typography>
                                    Modified By: {formik.values.modifiedBy}
                                    </Typography>
                                )}
                                {formik.values.modifiedOn && (
                                    <Typography>
                                    Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                                    </Typography>
                                )}
                                </AccordionDetails>
                                
                            </Accordion>
                        </>
                    )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>Close</Button>
                        <Button variant='contained' onClick={() => handleSaveClick()}>Save</Button>
                    </DialogActions>
            </Dialog>
            <ActivateAlertDialog/>
        </>
    )
}

export default TrainingGroupParentForm