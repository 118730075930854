import { Box, Button, FormControlLabel, IconButton, Switch, TableCell, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TeamMemberService from "src/Services/HumanResources/TeamMemberService";
import { ListParameters } from "src/Services/ListParameters";
import { TableHeader } from "src/components/Datatable/TableHeader";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";
import colorConfigs from "src/config/colorConfigs";
import { TeamMember } from "src/dtos/HumanResources/TeamMember.dto";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import EditIcon from '@mui/icons-material/Edit'
import Datatable from "src/components/Datatable/Datatable";
import ClickableTableRow from "src/components/Datatable/ClickableTableRow";


function TeamMembers() {
    const tableHeaders: TableHeader[] = [
        { label: "", field: "statusIndicator", width: "10px", sortable: false },
        { label: "Name", field: "prefferedFirstName", width: "25rem", sortable: true },
        { label: "Department", field: "department", width: "25rem", sortable: false },
        { label: "", field: "edit", width: "3rem", sortable: false },
    ];

    type filterOptionsType = {
        includeDisabled?: boolean;
    };

    const [messageItem, setMessageItem] = useState<MessageItem>({});
    const [searchParams, setSearchParams] = useSearchParams()
    const deserializedFilterOptions = searchParams.get("filterOptions") ? JSON.parse(decodeURIComponent(searchParams.get("filterOptions") as string)) : null
    const defaultOptions = {includeDisabled: false}
    const [filterOptions, setFilterOptions] = useState<filterOptionsType>(deserializedFilterOptions ?? defaultOptions);
    const navigate = useNavigate();

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30,
        sortBy: "prefferedFirstName"
    };

    const fetchTeamMembers = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await TeamMemberService.GetList(listParams, searchTerm, filterOptions.includeDisabled);
        return data;
    };

    const renderTableRow = ({data, index}: {data: TeamMember, index: number}) => {
        return (
            <>
                <ClickableTableRow
                    hover
                    href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
                    key={`UsersRow_${index}`}
                    sx={{cursor: "pointer", textDecoration: "none"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{`${data.prefferedFirstName} ${data.lastName}`}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.department?.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
                </ClickableTableRow>
            </>
        )
    };

    const handleFormOpen = () => {
        navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
    }

    function FilterMenu(props:{handleClose: () => void}){
        const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);

        const handleIncludeDisabledChange = () => {
            setIncludeDisabled((prevVal) => !prevVal)
        }

        const handleOkClick = () => {
            props.handleClose()

            const newFilterOptions = {
                includeDisabled: includeDisabled
            }
            setFilterOptions(newFilterOptions)

            //update search params
            if(JSON.stringify(newFilterOptions) === JSON.stringify(defaultOptions)){
                searchParams.delete("filterOptions")
            } else {
                const serializedFilterOptions = encodeURIComponent(JSON.stringify(newFilterOptions))
                searchParams.set("filterOptions", serializedFilterOptions)
            }
            setSearchParams(searchParams)
        }

        return (
            <Box style={{padding:"1rem", display:"block"}}>
                <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
                <FormControlLabel 
                    control={
                        <Switch 
                        checked={includeDisabled}
                        onChange={handleIncludeDisabledChange}  
                        />
                    }
                    label={"Include Inactive?"}
                    sx={{minWidth: '100%'}}
                />
                <Button onClick={handleOkClick}>Filter</Button>
            </Box>
        )

    }


    return (
        <>
            <Datatable
                tableHeaders={tableHeaders}
                initialListParams={initialListParams}
                tableTitle='Team Members'
                buttonLabel='Create New Team Member'
                buttonOnClick={handleFormOpen}
                showSearch={true}
                renderRow={renderTableRow}
                callService={fetchTeamMembers}
                setMessageItem={setMessageItem}
                FilterMenu={FilterMenu}
            />
            <ErrorHandlingSnackbar messageItem={messageItem} />
        </>
    )
}

export default TeamMembers;
