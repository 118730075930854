import { Box, Button, Card, CardActions, CardContent, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';

function TodaysTrainingSessions() {

    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [searchTerm, setSearchTerm] = useState<string>("")
    const navigate = useNavigate()
    
    const todaysTrainingSessionsQuery = useQuery({
        queryKey: ["TodaysTrainingSessions"],
        queryFn: async () => {
            return await TrainingSessionService.GetTodaysSessions();
        }
    });

    useEffect(() => {
        if(todaysTrainingSessionsQuery.isError){
            setMessageItem({error: todaysTrainingSessionsQuery.error})
        }
    }, [todaysTrainingSessionsQuery.error, todaysTrainingSessionsQuery.isError])

    const handleOpenSession = (id: string) => {
        navigate({pathname: window.location.pathname + `/${id}`})
    }
    
    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <Stack spacing={3} sx={{alignItems: "center", maxWidth:"80%", marginTop:"2rem"}}>
                <Typography variant='h5'>Training Sessions</Typography>
                <TextField
                    label="Search"
                    size='small'
                    sx={{width:"25rem"}}
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }}                        
                />
                <Box sx={{display:"flex", flexWrap:"wrap", gap:"1rem", justifyContent:"center"}}>
                    {todaysTrainingSessionsQuery.isLoading ? (
                        <Skeleton
                            variant='rounded'
                            width={"20rem"}
                            height={"7rem"}
                        />
                    ) : (
                        <>
                            {todaysTrainingSessionsQuery.data && todaysTrainingSessionsQuery.data
                                .filter(ts => ts.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                                .map((session, index) => (
                                    <Card key={index} sx={{width: "20rem"}}>
                                        <CardContent>
                                            <Typography variant='h6'>{session.label}</Typography>
                                        </CardContent>
                                        <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                            <Button onClick={() => handleOpenSession(session.id)}>
                                                Open Session
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))

                            }
                        </>
                    )}
                    
                </Box>
            </Stack>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </div>
    )
}

export default TodaysTrainingSessions