import { LoadingButton } from '@mui/lab'
import { Button, Chip, Stack, Typography } from '@mui/material'
import { UseQueryResult, useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TrainingSessionService from 'src/Services/Training/TrainingSessionService'
import { guidRegex } from 'src/Utils/helperFunc'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { ChipColor } from 'src/config/constants'
import { LearningDocumentResponseStatusString } from 'src/dtos/Training/LearningDocumentResponse.dto'
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto'

type Props = {
    trainingSessionQuery: UseQueryResult<TrainingSession | null, Error>
}
function TrainingSessionDocuments({trainingSessionQuery}: Props) {
    const {id} = useParams();
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const navigate = useNavigate()
    
    const startSessionMutation = useMutation({
        mutationFn: (id: string) => {
            return TrainingSessionService.StartSession(id)
        },
        onSuccess: () => {
            trainingSessionQuery.refetch()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleStartSession = () => {
        if(id && guidRegex.test(id)){
            startSessionMutation.mutate(id)
        }
    }

    const handleOpen = (responseId?: string) => {
        navigate({pathname: window.location.pathname +`/${responseId}`})
    }

    const handleAttendanceSheetOpen = (responseId?: string) => {
        navigate({pathname: window.location.pathname +`/Attendance/${responseId}`})
    }

    const chipColour: ChipColor[] = [
        "success",
        "primary",
        "default",
        "success",
        "success",
        "secondary",
        "secondary"
    ]

    return (
    <>
        {trainingSessionQuery.data && trainingSessionQuery.data.sessionResponses.length === 0 ? (
            <>
                <Typography>Session hasn't been started!</Typography>
                <LoadingButton variant='contained' loading={startSessionMutation.isPending || trainingSessionQuery.isFetching} onClick={handleStartSession}>
                    Start Session
                </LoadingButton>
            </>
        ) : (
            <>
                {trainingSessionQuery.data && trainingSessionQuery.data.sessionResponses.map((sessionResponse, index) => (
                    <React.Fragment key={index}>
                        {sessionResponse.learningDocumentResponseId && (
                             <Stack direction="row" spacing={2} sx={{display:"flex", justifyContent:"Left", alignItems:"center"}}>
                                <Typography>{sessionResponse.learningDocumentLabel}</Typography>
                                <Chip
                                    label={LearningDocumentResponseStatusString[sessionResponse.status]}
                                    color={chipColour[sessionResponse.status]}
                                />
                                <Button onClick={() => handleOpen(sessionResponse.learningDocumentResponseId)}>Open</Button>
                            </Stack>
                        )}
                        {sessionResponse.learningItemResponseId && (
                            <Stack direction="row" spacing={2} sx={{display:"flex", justifyContent:"Left", alignItems:"center"}}>
                                <Typography>{sessionResponse.learningItemLabel}</Typography>
                                <Chip
                                    label={LearningDocumentResponseStatusString[sessionResponse.status]}
                                    color={chipColour[sessionResponse.status]}
                                />
                                <Button onClick={() => handleAttendanceSheetOpen(sessionResponse.learningItemResponseId)}>Open</Button>
                            </Stack>
                        )}
                    </React.Fragment>
                ))}
            </>
        )}
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
  )
}

export default TrainingSessionDocuments