import { Stack, Autocomplete, TextField, IconButton, Tooltip } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import { LearningMaterialItem, LearningMaterialType, LearningMaterialTypeString, TrainingGroup } from 'src/dtos/Training/TrainingGroup.dto'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { NameDto } from 'src/dtos/NameDto.dto';

type Props = {
    formikLearningContent: LearningMaterialItem,
    formik: FormikProps<TrainingGroup>;
    formikString: string;
    handleDelete: () => void;
    learningDocuments: NameDto[];
    learningItems: NameDto[]
}
function MaterialItem({formik, formikLearningContent, formikString, handleDelete, learningDocuments, learningItems}: Props) {
    const itemTypeNames: LearningMaterialType[] = [
        LearningMaterialType.learningDocument,
        LearningMaterialType.learningItem
    ]
    const learningDocumentIds: string[] = learningDocuments.map((learningDoc) => {return learningDoc.id})
    const learningItemIds: string[] = learningItems.map((learningItem) => {return learningItem.id})
    return (
        <Stack direction="row" spacing={1} sx={{width:"100%"}}>
            <Autocomplete
                id={`${formikString}.type`}
                options={itemTypeNames}
                size='small'
                sx={{width: "14rem"}}
                value={formikLearningContent.type ?? null}
                disableClearable
                getOptionLabel={(option) => option !== undefined ? LearningMaterialTypeString[option] : ""}
                onChange={(e, value) => {
                    formik.setFieldValue(`${formikString}.type`, value)
                    if(value === LearningMaterialType.learningDocument){
                        formik.setFieldValue(`${formikString}.learningDocumentId`, null)
                    } else {
                        formik.setFieldValue(`${formikString}.learningItemId`, null)
                    }
                }}
                renderInput={(params) => 
                    <TextField {...params} label="Type"/>
                }
            />
            {formikLearningContent.type === LearningMaterialType.learningDocument && ( 
                 <Autocomplete
                    id={`${formikString}.learningDocumentId`}
                    options={learningDocumentIds}
                    getOptionLabel={(option) => option ? learningDocuments.find(learningDoc => learningDoc.id === option)?.label ?? "" : ""}
                    value={formikLearningContent.learningDocumentId ?? null}
                    onChange={(e, value) => {
                        if(value){
                            formik.setFieldValue(`${formikString}.learningDocumentId`, value)
                        } else {
                            formik.setFieldValue(`${formikString}.learningDocumentId`, null)
                        }
                    }}
                    size='small'
                    sx={{width:"50rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            label="Learning Document"
                        />
                    }
                />
            )}
            {formikLearningContent.type === LearningMaterialType.learningItem && (
                <Autocomplete
                    id={`${formikString}.learningItemId`}
                    options={learningItemIds}
                    getOptionLabel={(option) => option ? learningItems.find(learningItem => learningItem.id === option)?.label ?? "" : ""}
                    value={formikLearningContent.learningItemId ?? null}
                    onChange={(e, value) => {
                        if(value){
                            formik.setFieldValue(`${formikString}.learningItemId`, value)
                        } else {
                            formik.setFieldValue(`${formikString}.learningItemId`, null)
                        }
                    }}
                    size='small'
                    sx={{width:"50rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            label="Learning Item"
                        />
                    }
                />
            )}

        <Tooltip title="Delete Item">
          <IconButton onClick={() => handleDelete()}>
            <DeleteOutlineOutlinedIcon sx={{color: "crimson"}} />
          </IconButton>
        </Tooltip>
           

        </Stack>
    )
}

export default MaterialItem