import { DeleteOutline } from '@mui/icons-material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TrainingSessionService from 'src/Services/Training/TrainingSessionService'
import { EmailToNameString, guidRegex } from 'src/Utils/helperFunc'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateTimeOptions } from 'src/config/constants'
import { NameDto } from 'src/dtos/NameDto.dto'
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { SessionAttendee, TrainingSession } from 'src/dtos/Training/TrainingSession.dto'


type Props = {
  formik: FormikProps<TrainingSession>
  setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
  sessionLocked: boolean
}
function TeamMembers({formik, setMessageItem, sessionLocked}: Props) {

  const {id} = useParams();
  const [selectedTrainee, setSelectedTrainee] = useState<NameDto | null>(null)
  const [selectedTrainer, setSelectedTrainer] = useState<NameDto | null>(null)

  const TeamMemberTable = ({attendees}: {attendees: SessionAttendee[]}) => {
    const DietaryRequirementChips = ({dietaryRequirements}: {dietaryRequirements?: NameDto[]}) => {
      
      return (
        <div style={{display:"flex", flexWrap: "wrap", gap:"0.5rem"}}>
          {dietaryRequirements && dietaryRequirements.map((requirement) => (
            <Chip 
              key={requirement.id}
              label={requirement.label}
              size='small'
            />
          ))}
        </div>
      )
    }
    return (
      <TableContainer component={Paper} sx={{minWidth:"45rem"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Dietary Requirements</TableCell>
              <TableCell sx={{maxWidth:"2rem"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendees && attendees.map((attendee) => (
              <TableRow
                key={attendee.id}
              >
                <TableCell component={"th"}>
                  {`${attendee.teamMember?.prefferedFirstName} ${attendee.teamMember?.lastName}`}
                </TableCell>
                <TableCell>
                  <DietaryRequirementChips dietaryRequirements={attendee.teamMember?.dietaryRequirements}/>
                </TableCell>
                <TableCell sx={{maxWidth:"2rem"}}>
                  <Stack direction="row" sx={{display:"flex", alignItems:"center", justifyContent:"center"}} spacing={1}>
                    <LoadingButton 
                      color='error'
                      variant='contained'
                      onClick={() => handleRemoveAttendee(attendee.id)}
                      loading={removeAttendeeMutation.isPending}
                      disabled={sessionLocked}
                    >
                      <DeleteOutline/>
                    </LoadingButton>
                    <Tooltip 
                      title={
                        <span style={{whiteSpace: "pre-line"}}>
                          {`Added By: ${attendee.createdBy ? EmailToNameString(attendee.createdBy): "Unknown"}\nAdded On: ${attendee.createdOn ? new Date(attendee.createdOn).toLocaleTimeString(undefined, dateTimeOptions) : "Unknown"}`}
                        </span>
                      }
                      sx={{cursor:"pointer"}}
                    >
                      <InfoOutlined/>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const assignableTraineesQuery = useQuery({
    queryKey: ["assignableTrainees", id],
    queryFn: async () => {
      if(id && guidRegex.test(id)){
        return await TrainingSessionService.GetAssignableTrainees(id)
      }
    },
    select: (data) => {
      if(data){
        //remove any attendees that have already been selected from the options
        const attendeesIds = formik.values.sessionAttendees ? formik.values.sessionAttendees.map(attendee => attendee.teamMemberId): []
        return data.filter(value => !attendeesIds.includes(value.id))
      }
    }
  })

  const assignableTrainersQuery = useQuery({
    queryKey: ["assignableTrainers", id],
    queryFn: async () => {
      if(id && guidRegex.test(id)){
        return await TrainingSessionService.GetAssignableTrainers(id)
      }
    },
    select: (data) => {
      if(data){
        //remove any attendees that have already been selected from the options
        const attendeesIds = formik.values.sessionAttendees ? formik.values.sessionAttendees.map(attendee => attendee.teamMemberId): []
        return data.filter(value => !attendeesIds.includes(value.id))
      }
    }
  })

  //handle query errors
  useEffect(() => {
    if(assignableTraineesQuery.isError){
        setMessageItem({error: assignableTraineesQuery.error})
    }
  }, [setMessageItem, assignableTraineesQuery.isError, assignableTraineesQuery.error])

  const addTrainerMutation = useMutation({
    mutationFn: ({id, teamMemberId}: {id: string, teamMemberId: string}) => {
      const newSessionAttendee: SessionAttendee = {
        id: 0,
        teamMemberId: teamMemberId,
        type: AttendeeType.Trainer,
      }
      return TrainingSessionService.AddAttendee(id, newSessionAttendee)
    },
    onSuccess: (data) => {
      setMessageItem({successMessage: "Added Trainer Successfully!"})
      formik.setFieldValue("sessionAttendees", data.data)
      setSelectedTrainer(null)
    },
    onError: (error) => {
      setMessageItem({error: error})
  }
  })

  const addTraineeMutation = useMutation({
    mutationFn: ({id, teamMemberId}: {id: string, teamMemberId: string}) => {
      const newSessionAttendee: SessionAttendee = {
        id: 0,
        teamMemberId: teamMemberId,
        type: AttendeeType.Trainee,
      }
      return TrainingSessionService.AddAttendee(id, newSessionAttendee)
    },
    onSuccess: (data) => {
      setMessageItem({successMessage: "Added Trainee Successfully!"})
      console.log(data.data)
      formik.setFieldValue("sessionAttendees", data.data)
      setSelectedTrainee(null)
    },
    onError: (error) => {
      setMessageItem({error: error})
    }
  })

  const removeAttendeeMutation = useMutation({
    mutationFn: ({trainingSessionId, id}:{trainingSessionId: string, id: number,}) => {
      return TrainingSessionService.RemoveAttendee(trainingSessionId, id)
    },
    onSuccess: (data) => {
      setMessageItem({successMessage: "Removed Team Member Successfully!"})
      formik.setFieldValue("sessionAttendees", data.data)
      setSelectedTrainee(null)
    },
    onError: (error) => {
      setMessageItem({error: error})
    }
  })

  const handleAddTrainee = () => {
    if(sessionLocked){
      setMessageItem({errorMessage: "Session is locked!"})
      return
    }
    if(id && guidRegex.test(id) && selectedTrainee){
      addTraineeMutation.mutate({id: id, teamMemberId: selectedTrainee.id})
    }
  }

  const handleAddTrainer = () => {
    if(id && guidRegex.test(id) && selectedTrainer){
      addTrainerMutation.mutate({id: id, teamMemberId: selectedTrainer.id})
    }
  }

  const handleRemoveAttendee = (attendeeId: number) => {
    if(sessionLocked){
      setMessageItem({errorMessage: "Session is locked!"})
      return
    }
    if(id && guidRegex.test(id)){
      removeAttendeeMutation.mutate({trainingSessionId: id, id: attendeeId})
    }
  }
 
  const trainers: SessionAttendee[] = formik.values.sessionAttendees && formik.values.sessionAttendees
    .filter(sa => sa.type === AttendeeType.Trainer)

  const trainees: SessionAttendee[] = formik.values.sessionAttendees && formik.values.sessionAttendees
    .filter(sa => sa.type === AttendeeType.Trainee)
  
  return (
    <>
      <div style={{marginBottom:"1rem"}}>
        <Typography variant='h5' sx={{marginBottom:"0.5rem"}}>Trainers</Typography>
        <Stack direction="row" spacing={2} sx={{paddingBottom:"1rem"}}>
          <Autocomplete
            id="AddTrainee"
            autoHighlight
            autoSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            options={assignableTrainersQuery.data ?? []}
            value={selectedTrainer ?? null}
            onChange={(_, value) => {
              setSelectedTrainer(value)
            }}
            onKeyDown={(e) => {
              if(e.key === "Enter" && selectedTrainer){
                handleAddTrainer();
              }
            }}
            size={"small"}
            sx={{width:"20rem"}}
            renderInput={(params) => 
              <TextField {...params} label="Add Trainer"/>
            }
          />
          <LoadingButton variant='contained' loading={addTrainerMutation.isPending} onClick={handleAddTrainer}>
            Add
          </LoadingButton>      
        </Stack>
        <TeamMemberTable attendees={trainers} />
        
      </div>
      <div>
        <Typography variant='h5' sx={{marginBottom:"0.5rem"}}>Trainees</Typography>
        <Stack direction="row" spacing={2} sx={{paddingBottom:"1rem"}}>
          <Autocomplete
            id="AddTrainee"
            autoHighlight
            autoSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (option.label ? option.label : "")}
            options={assignableTraineesQuery.data ?? []}
            value={selectedTrainee ?? null}
            onChange={(_, value) => {
              setSelectedTrainee(value)
            }}
            onKeyDown={(e) => {
              if(e.key === "Enter" && selectedTrainee){
                handleAddTrainee();
              }
            }}
            size={"small"}
            sx={{width:"20rem"}}
            renderInput={(params) => 
              <TextField {...params} label="Add Trainee"/>
            }
          />
          <LoadingButton 
            variant='contained'
            loading={addTraineeMutation.isPending}
            onClick={handleAddTrainee}
            disabled={sessionLocked}
          >
            Add
          </LoadingButton>
        </Stack>


        <TeamMemberTable attendees={trainees} />
      </div>
    </>
  )
}

export default TeamMembers