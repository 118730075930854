/* eslint-disable @typescript-eslint/no-unused-vars */

import { ConstructionOutlined } from "@mui/icons-material";
import { Autocomplete, Paper, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InspectionChecklistResponseService from "src/Services/Checklists/Inspections/ChecklistResponseService";
import InspectionChecklistService from "src/Services/Checklists/Inspections/InspectionChecklistService";
import InspectionChecklistVersionService from "src/Services/Checklists/Inspections/InspectionChecklistVersionService";
import { guidRegex } from "src/Utils/helperFunc";
import FinalSignOffSection from "src/pages/User Site/Inspections/Inspection/InspectionComponents/FormSections/FinalSignOffSection/FinalSignOffSection";
import FormSection from "src/pages/User Site/Inspections/Inspection/InspectionComponents/FormSections/FormSection/FormSection";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import MaintenanceBottomNavigation from "src/components/common/MaintenanceBottomNavigation";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";
import {
    ChildEntity,
    InspectionChecklist,
    QuestionType,
    InspectionChecklistVersion,
    InspectionChecklistSection,
} from "src/dtos/AwTypes";
import { InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from "src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto";
import { NameNumberDto } from "src/dtos/NameDto.dto";
import { Status } from "src/dtos/Statuses";

function DemoQuestions() {

    
    const [checklistAnswers, setChecklistAnswers] = useState<InspectionChecklistResponseDto>(InspectionChecklistResponseService.GetDefaultValues());
    const [checklistVersion, setChecklistVersion] = useState<InspectionChecklistVersion>(InspectionChecklistVersionService.GetDefaultVersionValues());
    const [checklist, setChecklist] = useState<InspectionChecklist>(InspectionChecklistService.GetDefaultValues())
    const [sectionVisibility, setSectionVisibility] = useState<boolean[]>(() => checklistVersion.sections.map(section => sectionVisible(section)))

    const [taggedOutComponents, setTaggedOutComponents] = useState<ChildEntity[]>([]);
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined});

    
    const {id} = useParams();      

    const save = (values: any) => {

    }

    useEffect(() => {
        const fetchInspection = async () => {
            try {
                if(id !== undefined && guidRegex.test(id)){
                    const data = await InspectionChecklistVersionService.GetInspection(id);
                    const inspectionChecklistId = data.inspectionChecklistId;
                    const checklistData = await InspectionChecklistService.Get(inspectionChecklistId)
                    setChecklist(checklistData)
                    setChecklistVersion(data)
                }
                
            } catch (error: any) {
                console.log(error)
                //setMessageItem({error: error})
            }
        }
        const fetchAnswers = async () => {
            try {
                if(id !== undefined && guidRegex.test(id)){
                    const data = await InspectionChecklistResponseService.GetNew(id);
                    setChecklistAnswers(data)
                }
            } catch (error: any) {
                console.log(error)
                //setMessageItem({error: error})
            }
        }

        fetchInspection();
        fetchAnswers();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: checklistAnswers,
        onSubmit: (values) => {
            save(values);
        }
    });

    

    const handleSectionStatusChange = (sectionIndex: number, sectionValues: InspectionChecklistSectionResponseDto) => {
        const updatedSectionAnswers = [...formik.values.sectionResponses];
        updatedSectionAnswers[sectionIndex] = sectionValues;
        formik.setValues({...formik.values, sectionResponses: updatedSectionAnswers})
    }

    const handleUserSelectedCompChange = (value: any) => {
        const newUserSelectedComp = value;
        formik.setFieldValue("selectedComponents", newUserSelectedComp)
    }

    const sectionVisible = (section: InspectionChecklistSection) : boolean => {
        
        if(section.userSelectedComponents === true && section.repeatingComponentSet){
            return (formik.values.selectedComponents??[]).some(e => e.id === section.repeatingComponentSet?.id);
        } else {
            return true;
        }
    }


    useEffect(() => {
        const newSectionVisiblity = checklistVersion.sections.map(section => sectionVisible(section));
        setSectionVisibility(newSectionVisiblity)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.selectedComponents, checklistVersion.sections])

    const userSelectedOptions = (userSelectedComponent: ChildEntity) : NameNumberDto[] => {
        const options: NameNumberDto[] = userSelectedComponent.children.map((child) => {
            const comp: NameNumberDto = {
                id: child.id??0,
                label: child.label
            }
            return comp
        })

        return options;
    }

    return (
        <>
            <div style={{ paddingBottom: "5rem" }}>
                <form onSubmit={formik.handleSubmit}>
                <Typography variant='h5' sx={{display: "flex", justifyContent: "center", padding: "1rem"}}>{checklist.label}</Typography>
                {checklistVersion.userSelectedComponent && (
                    <div style={{padding:"1rem"}}>
                
                    <Paper sx={{padding:"1rem"}}>
                        <QuestionTitle title={`Which ${checklistVersion.userSelectedComponent.label} are you inspecting during this inspection?`} required/>
                        <Autocomplete
                            id={`userSelecteditems_${checklistVersion.userSelectedComponent.label}`}
                            multiple
                            options={userSelectedOptions(checklistVersion.userSelectedComponent)??[]}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    size="small"
                                    label={checklistVersion.userSelectedComponent?.label}
                                />
                            )}
                            sx={{width: "20rem"}}
                            onChange={(_, value) => handleUserSelectedCompChange(value)}
                            value={formik.values.selectedComponents}
                        />
                    </Paper>
                </div>  
                )}
                {checklistVersion.sections.map((section, sectionIndex) => (
                    <Fragment key={sectionIndex}>
                    {sectionVisibility[sectionIndex] && (
                        <FormSection
                            key={sectionIndex}
                            formik={formik}
                            formikString={`sectionResponses[${sectionIndex}]`}
                            sectionContent={section}
                            sectionResponse={formik.values.sectionResponses[sectionIndex]}
                            handleSectionStatusChange={(sectionValues: InspectionChecklistSectionResponseDto) => handleSectionStatusChange(sectionIndex, sectionValues)}
                            questionsEditable={() => true} //Any questions can be answered in demo mode
                            taggedOutComponents={taggedOutComponents}
                            setTaggedOutComponents={setTaggedOutComponents}
                            demoMode={true}
                            setMessageItem={setMessageItem}
                        />
                    )}
                    </Fragment>
                    
                    
                ))}
                
                <FinalSignOffSection
                    checklistName={checklist.label}
                    checklistStatus={Status.Passed}
                />
                
                </form>
            </div>
            
            <ErrorHandlingSnackbar messageItem={messageItem}/>
            <MaintenanceBottomNavigation />
        </>
    );
}

export default DemoQuestions;
