import { Button, Card, CardHeader, Chip, List, ListItem, ListItemText, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { NameDto } from 'src/dtos/NameDto.dto'
import { Action, ActionType } from '../BulkActions'
import { useMutation } from '@tanstack/react-query'
import { EnrolTeamMembersRequestDto } from 'src/dtos/Training/TalentLMS.dto'
import TalentLMSService from 'src/Services/Training/TalentLMSService'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { LoadingButton } from '@mui/lab'
import { TeamMemberActionResultDto } from 'src/dtos/HumanResources/TeamMember.dto'

type Props = {
    handleBack: () => void
    handleReset: () => void
    selectedTeamMembers: readonly NameDto[]
    selectedAction: Action
}

function Confirmation({handleBack, handleReset, selectedAction, selectedTeamMembers}: Props) {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [actionCompleted, setActionCompleted] = useState(false)
    const [result, setResult] = useState<TeamMemberActionResultDto[]>([])

    const enrolTeamMembersMutation = useMutation({
        mutationFn: (request: EnrolTeamMembersRequestDto) => {
            return TalentLMSService.EnrolTeamMembers(request)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Action Completed!"})
            setActionCompleted(true)
            setResult(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const loading = enrolTeamMembersMutation.isPending
    
    const handleCompleteClick = () => {
        switch(selectedAction.type) {
            case ActionType.OnlineLearning:
                if(selectedAction.onlineLearningCourse){
                    const enrolRequest: EnrolTeamMembersRequestDto = {
                        learningItems: [selectedAction.onlineLearningCourse],
                        teamMembers: selectedTeamMembers
                    }
                    enrolTeamMembersMutation.mutate(enrolRequest)
                }
                return
        }
    }
        
    const handleBackClick = () => {
        handleBack()
    }

    const handleResetClick = () => {
        setActionCompleted(false)
        handleReset()
    }

    return (
    <>
       
        <Stack spacing={3} direction={"row"}>
            <Card sx={{width:"max-content", minWidth:"15rem"}}>
                <CardHeader
                    title="Team Members"
                    subheader={`Total: ${selectedTeamMembers.length}`}
                />
                <List
                    sx={{
                        width: 300,
                        height: 230,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    {selectedTeamMembers.map((teamMember) => (
                        <ListItem key={teamMember.id}>
                            <ListItemText id={teamMember.id} primary={teamMember.label}/>
                        </ListItem>
                    ))

                    }
                </List>
            </Card>

            <div>
                {selectedAction.type === ActionType.OnlineLearning && selectedAction.onlineLearningCourse && (
                    <Typography>{`The ${selectedTeamMembers.length} listed Team Member/s will be enrolled into the course "${selectedAction.onlineLearningCourse.label}"`}</Typography>
                )}
            </div>
        </Stack>
        
        {actionCompleted ? (
            <span style={{display:"flex", justifyContent:"right", alignItems:"center", gap:"1rem"}}>
                <Typography>Action Completed!</Typography>
                <Button onClick={handleResetClick}>
                    Reset
                </Button>
            </span>
        ) : (
            <span style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"1rem"}}>
                <Button onClick={handleBackClick}>
                    Back
                </Button>
                <LoadingButton onClick={handleCompleteClick} loading={loading}>
                    Complete Action
                </LoadingButton>
            </span> 
        )}

        {result.length > 0 && (
            <>
                <Typography variant='h6'>Result</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Team Member</TableCell>
                            <TableCell>Result</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map((result, index) => (
                            <TableRow key={index}>
                                <TableCell>{result.teamMember.label}</TableCell>
                                <TableCell>
                                    {result.succeeded ? (
                                        <Chip
                                            label="Successful"
                                            color='success'
                                        />
                                    ) : (
                                        <Chip
                                            label="Unsuccessful"
                                            color='error'
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {result.message}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        )}
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
  )
}

export default Confirmation