import {Autocomplete, Button, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Select, Stack, Switch, TextField, Tooltip, Typography, darken, lighten, styled, } from "@mui/material";
import { FormikProps } from "formik";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ChildEntity, InspectionChecklistVersion, QuestionDto, QuestionType } from "src/dtos/AwTypes";
import CheckIcon from "@mui/icons-material/Check";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { MessageItem } from "src/components/errorHandlingSnackbar";
import { Status } from "src/dtos/Statuses";
import ShowDifferences from "src/components/ShowDifferences/ShowDifferences";
import RepeatIcon from '@mui/icons-material/Repeat';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

type Props = {
    formik: FormikProps<InspectionChecklistVersion>;
    activeQuestion: Boolean;
    sectionIndex: number;
    questionIndex: number;
    showChanges: Boolean;
    previousQuestion?: QuestionDto;
    setMovingQuestion: Dispatch<SetStateAction<{sectionIndex: number, questionIndex: number} | null>>;
    setGhostLocation: Dispatch<SetStateAction<string>>;
    addQuestion: () => void;
    removeQuestion: () => void;
    components?: ChildEntity[];
    setMessageItem: Dispatch<SetStateAction<MessageItem>>;
    UpdatedQuestionNums: (sectionQuestions: QuestionDto[]) => QuestionDto[];
};

function NewQuestionForm({
    formik,
    sectionIndex,
    questionIndex,
    activeQuestion,
    showChanges,
    previousQuestion,
    setMovingQuestion,
    setGhostLocation,
    addQuestion,
    removeQuestion,
    components,
    setMessageItem,
    UpdatedQuestionNums,
    }: Props) {

    const formikQuestion = formik.values.sections[sectionIndex].questions[questionIndex];
    const formikQuestionString = `sections[${sectionIndex}].questions[${questionIndex}]`;

    const [additionalInfoOn, setAdditionalInfoOn] = useState(Boolean(formik.values.sections[sectionIndex].questions[questionIndex].additionalInformation));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const optionsMenuOpen = Boolean(anchorEl);
    const [questionLabel, setQuestionLabel] = useState(formikQuestion.label);
    const [additionalInfo, setAdditionalInfo] = useState(formikQuestion.additionalInformation);

    const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOptionsClose = () => {
        setAnchorEl(null);
    };
    
    const handleLabelSet = () => {
        formik.setFieldValue(`${formikQuestionString}.label`, questionLabel)
    }

    const handleAdditionalInfoSet = () => {
        formik.setFieldValue(`${formikQuestionString}.additionalInformation`, additionalInfo)
    }

    const handleTypeChange = (e: any) => {
        const val: QuestionType = e.target.value
        const oldQuestion = formik.values.sections[sectionIndex].questions[questionIndex];
        
        const sectionQuestions = formik.values.sections[sectionIndex].questions;
        sectionQuestions[questionIndex] = {...oldQuestion, type: val, number: undefined, }

        //Update question type and any values that arent compatable with the question type
        switch (val) {
            case(QuestionType.Alert):
                sectionQuestions[questionIndex] = {...oldQuestion, type: val, required: false, relatedComponent: null, relatedComponentId: undefined};
                break;
            case(QuestionType.YesOrNo):
                sectionQuestions[questionIndex] = {...oldQuestion, type: val, required: true }
                break;
            default:
                sectionQuestions[questionIndex] = {...oldQuestion, type: val }
                break;

        }

        //retrieve updated question numbers
        const updatedSectionQuestions = UpdatedQuestionNums(sectionQuestions);
        //set new values
        formik.setFieldValue(`sections[${sectionIndex}].questions`, updatedSectionQuestions)

    }

    const sectionRepeatingComponent = formik.values.sections[sectionIndex].repeatingComponentSet;
    

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        backgroundColor:
          theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
      }));
      
      const GroupItems = styled('ul')({
        padding: 0,
      });

      const inputRef = useRef<HTMLInputElement>(null);
      return (
        <>
        {activeQuestion ? (
            <Stack>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    padding: "1rem",
                    display: "flex",
                    alignItems: "flex-start",
                }}
            >
                <TextField

                    id={`${formikQuestionString}.label`}
                    name={`${formikQuestionString}.label`}
                    tabIndex={1}
                    ref={inputRef}
                    label="Question"
                    value={questionLabel}
                    onChange={(event: any) => setQuestionLabel(event.target.value)}
                    onKeyDown={event => {
                        if((event.key === "S" || event.key === "s") && event.ctrlKey){
                            //when checklist saves
                            event.preventDefault(); //stop page from downloading
                            handleLabelSet();
                        }
                        if(event.key === "Tab") {
                            alert("tab")
                            //event.preventDefault();
                            inputRef.current !== null && inputRef.current.focus()
                        }
                    }}
                    onBlur={handleLabelSet}
                    variant="standard"
                    size="small"
                    sx={{ width: "80%" }}
                    multiline
                    minRows={1}
                    maxRows={3}
                />
                <Select
                    id={`${formikQuestionString}.type`}
                    name={`${formikQuestionString}.type`}
                    value={formikQuestion.type}
                    size="small"
                    onChange={(e) => {handleTypeChange(e); }}
                    autoWidth
                >
                    <MenuItem value={QuestionType.YesOrNo}>Yes/No</MenuItem>
                    <MenuItem value={QuestionType.Alert}>Alert</MenuItem>
                </Select>
            </Stack>
            {sectionRepeatingComponent === null && formikQuestion.type !== QuestionType.Alert &&(
                <Stack direction="row">
                    <Autocomplete
                        disablePortal
                        id="relatedComponent"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        options={components ?? []}
                        groupBy={(option) => (option.parentLabel ?? option.label)}
                        size="small"
                        sx={{ width: "20rem", paddingLeft: "1rem", paddingBottom: "1rem"}}
                        value={formik.values.sections[sectionIndex].questions[questionIndex].relatedComponent ?? null}
                        onChange={(_, value: any) => {
                            formik.setFieldValue(`${formikQuestionString}.relatedComponent`, value ? value : null)
                            formik.setFieldValue(`${formikQuestionString}.relatedComponentId`, value ? value.id : null)
                            if(!(value && value.children && value.children.length > 0)){
                                //if the chosen option does not have children it cannot be repeated
                                formik.setFieldValue(`${formikQuestionString}.repeatOnComponentSetUserSelected`, null)
                                formik.setFieldValue( `${formikQuestionString}.repeatOnComponentSet`, null)
                            }
                        }}
                        //onBlur={formik.handleBlur}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            name="relatedComponent"
                            size="small"
                            variant="standard"
                            label="Ride Component"
                            InputLabelProps={{shrink: Boolean(formikQuestion.relatedComponent)}}
                            />
                        )}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                    />
                </Stack>
            )}
            
            
           
            
            {additionalInfoOn && (
                <div style={{ padding: "1rem", paddingTop: "0" }}>
                    <TextField
                        id={`${formikQuestionString}.additionalInformation`}
                        name={`${formikQuestionString}.additionalInformation`}
                        label="Additional Information"
                        value={additionalInfo}
                        onChange={(event: any) => setAdditionalInfo(event.target.value)}
                        onBlur={handleAdditionalInfoSet}
                        variant="standard"
                        size="small"
                        sx={{ width: "100%" }}
                    />
                </div>
            )}
            
            <Divider variant="middle" />
            <Grid container>
                <Grid item xs={4} sx={{display:"flex", justifyContent: "center"}}>
                    <FormControlLabel
                        control={
                            <Switch
                                name={`${formikQuestionString}.required`}
                                checked={
                                    formikQuestion.required
                                }
                                onChange={() =>
                                    formik.setFieldValue(
                                        `${formikQuestionString}.required`,
                                        !formikQuestion.required
                                    )
                                }
                            />
                        }
                        label="Required"
                    />
                </Grid>
                <Grid item xs={4} sx={{display: "flex", justifyContent: "center"}}>
                    <Button tabIndex={2} onClick={(e) => {e.stopPropagation(); addQuestion();}}>
                        <AddBoxOutlinedIcon />
                        <Typography>Add Question</Typography>
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                            paddingRight: "1rem",
                        }}
                    >   
                        <Tooltip title="Delete Question">
                            <IconButton onClick={(e) => {e.stopPropagation(); removeQuestion();}} sx={{color: "crimson"}}>
                                <DeleteOutlineOutlinedIcon />
                            </IconButton> 
                        </Tooltip>
                        
                        {formikQuestion.type !== QuestionType.Alert && (
                            <>
                                
                                <IconButton onClick={handleOptionsClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="QuestionOptions"
                                    anchorEl={anchorEl}
                                    open={optionsMenuOpen}
                                    onClose={handleOptionsClose}
                                >
                                    {sectionRepeatingComponent === null && (
                                        <MenuItem
                                        selected={formikQuestion.repeatOnComponentSet}
                                        onClick={() => {
                                            if(formikQuestion.relatedComponent && formikQuestion.relatedComponent.children && formikQuestion.relatedComponent.children.length > 0){
                                                formik.setFieldValue(`${formikQuestionString}.repeatOnComponentSet`,!formikQuestion.repeatOnComponentSet)
                                                formik.setFieldValue(`${formikQuestionString}.repeatOnComponentSetUserSelected`, null)
                                            }else {
                                                setMessageItem({errorMessage:"Ride component must be a component set"})
                                            }
                                        }
                                        }
                                        >
                                            {formikQuestion.repeatOnComponentSet && (
                                                <CheckIcon />
                                            )}
                                            Repeat on Component Set
                                        </MenuItem>
                                    )}
                                    {sectionRepeatingComponent === null && (
                                        <MenuItem
                                        selected={formikQuestion.repeatOnComponentSetUserSelected}
                                        onClick={() => {
                                            if(formikQuestion.relatedComponent && formikQuestion.relatedComponent.children && formikQuestion.relatedComponent.children.length > 0){
                                                formik.setFieldValue(`${formikQuestionString}.repeatOnComponentSetUserSelected`,!formikQuestion.repeatOnComponentSetUserSelected)
                                                formik.setFieldValue( `${formikQuestionString}.repeatOnComponentSet`, null)
                                            }else {
                                                setMessageItem({errorMessage:"Ride component must be a component set"})
                                            }
                                        }
                                        }
                                        >
                                            {formikQuestion.repeatOnComponentSetUserSelected && (
                                                <CheckIcon />
                                            )}
                                            Repeat on Component Set (User-Selected)
                                        </MenuItem>
                                    )}
                                    
                                    <MenuItem
                                        selected={formikQuestion.allowPhoto}
                                        onClick={() =>
                                            formik.setFieldValue(
                                                `${formikQuestionString}.allowPhoto`,
                                                !formikQuestion.allowPhoto
                                            )
                                        }
                                    >
                                        {formikQuestion.allowPhoto && (
                                            <CheckIcon />
                                        )}
                                        Allow Photos
                                    </MenuItem>
                                    <MenuItem
                                        selected={
                                            formikQuestion.allowComment
                                        }
                                        onClick={() =>
                                            formik.setFieldValue(
                                                `${formikQuestionString}.allowComment`,
                                                !formikQuestion.allowComment
                                            )
                                        }
                                    >
                                        {formikQuestion.allowComment && (
                                            <CheckIcon />
                                        )}
                                        Allow Commentary
                                    </MenuItem>
                                    <MenuItem
                                        selected={additionalInfoOn}
                                        onClick={() =>{
                                            setAdditionalInfoOn((prevVal) => !prevVal);
                                            if(additionalInfoOn){
                                                formik.setFieldValue(`${formikQuestionString}.additionalInformation`, "");
                                                setAdditionalInfo("");
                                            }
                                             
                                        }}
                                    >
                                        {additionalInfoOn && <CheckIcon />}
                                        Additional Information
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Divider variant="middle" />
        </Stack>
        ): (
        <span style={{ display: 'flex', justifyContent: "space-between" }}>
             {sectionRepeatingComponent === null && formikQuestion.type !== QuestionType.Alert && (
                <div style={{ display: "flex", minWidth: "8rem", maxWidth: "8rem", borderRight: "0.1rem solid", borderColor: "lightgrey", justifyContent: "center", alignItems: "center"}}>
                    {showChanges ? (
                        <ShowDifferences
                            originalContent={(previousQuestion && previousQuestion.relatedComponent && previousQuestion.relatedComponent.label) ?? ""}
                            newContent={(formikQuestion.relatedComponent && formikQuestion.relatedComponent.label) ?? ""}
                        />
                    ) : (
                        <>
                            {(formikQuestion.repeatOnComponentSet || formikQuestion.repeatOnComponentSetUserSelected) && (
                                <RepeatIcon sx={{paddingRight:"0.5rem"}}/>
                            )}
                            <Typography>{formikQuestion.relatedComponent?.label}</Typography>
                        </>
                        
                    )}
                </div>
             )}
            {formikQuestion.type === QuestionType.Alert && (
                <div style={{paddingLeft: "1rem", display:"flex", alignItems:"center", color: "blue"}}>
                    <WarningAmberOutlinedIcon fontSize="large"/>
                </div>
            )}
            <Stack direction="row" sx={{display: "flex", justifyContent: "space-between", width:"100%"}}>
                
                {showChanges ? (
                    <Stack direction="row" spacing={2} sx={{ padding: "1rem" }}>
                        <ShowDifferences
                            originalContent={previousQuestion && previousQuestion.number !== undefined && previousQuestion.number !== null ? previousQuestion.number + ".": ""}
                            newContent={formikQuestion.number !== undefined && formikQuestion.number !== null ? formikQuestion.number + "." : ""}
                        />
                        <ShowDifferences
                            originalContent={previousQuestion ? previousQuestion.label : ""}
                            newContent={formikQuestion.label}
                        />
                    </Stack>

                ) : (
                    <>
                        <Stack direction="row" spacing={2} sx={{ paddingLeft: "1rem", display:"inline-flex", alignItems:"center"}}>
                            <Typography>{`${formikQuestion.number !== undefined && formikQuestion.number !== null ? formikQuestion.number + "." : "" }`}</Typography>
                            <Typography>{formikQuestion.label.length > 0 ? formikQuestion.label : "New Question"}</Typography>
                        </Stack>
                    </>
                    
                )}
                {formik.values.status === Status.Draft && (
                    <div style={{display: "flex", alignItems: "center", padding: "0.5rem"}}>
                        <IconButton tabIndex={-1} onClick={(e) => {e.stopPropagation(); setMovingQuestion({sectionIndex: sectionIndex, questionIndex: questionIndex}); setGhostLocation(`${sectionIndex}.${questionIndex}`)}}>
                            <SwapVertIcon/>
                        </IconButton>
                    </div>
                )}                    
               
            </Stack>
        </span>
        )}
        </>
        
      );
    
}

export default NewQuestionForm;
