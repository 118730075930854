import { Card, Paper, InputBase, CardHeader, Checkbox, Divider } from "@mui/material"
import React, { useState } from "react"
import { NameDto } from "src/dtos/NameDto.dto"
import ItemsList from "./ItemsList"
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    title: React.ReactNode
    items: readonly NameDto[]
    handleToggleAll: (items: readonly NameDto[]) => () => void
    handleToggle: (value: NameDto) => () => void
    checked: readonly NameDto[]
    numberOfChecked: (items: readonly NameDto[]) => number
}

export default function CustomList ({title, items, handleToggle, handleToggleAll, checked, numberOfChecked}:Props) {
    const [searchTerm, setSearchTerm] = useState("")

    return  (
        <Card>
            <div style={{display:"flex", padding:"0.5rem", paddingBottom:"0"}}>
                <Paper
                    component="form"
                    sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width:"100%" }}
                >
                    <SearchIcon color="disabled"/>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
    
                </Paper>
            </div>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={numberOfChecked(items) === items.length && items.length !== 0}
                    indeterminate={
                    numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0}
                    inputProps={{
                    'aria-label': 'all items selected',
                    }}
                />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <ItemsList 
                searchTerm={searchTerm} 
                items={items}
                handleToggle={handleToggle}
                checked={checked}
            />
        </Card>
    );
  }