import React from 'react'

type Props = {}

const ResourcesIndex = (props: Props) => {
  return (
    <div>ResourcesIndex</div>
  )
}

export default ResourcesIndex