import { Chip, List, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { LearningDocumentPointType, LearningDocumentSubtopic } from 'src/dtos/Training/LearningDocument.dto'
import { Point } from '../Point/Point';
import { LearningDocumentResponseDto, LearningDocumentResponseStatus, LearningDocumentResponseStatusString, LearningDocumentSubtopicResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { FormikProps } from 'formik';
import { ChipColor } from 'src/config/constants';

type BaseProps = {
    index: number;
    subtopic: LearningDocumentSubtopic;
    demoMode?: boolean;
}

//Creator subtopic display only
type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

//demo & active response page. can actually answer points
type ActiveProps = BaseProps & {
    type: "Active"
    subtopicResponse: LearningDocumentSubtopicResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    pointsLocked: boolean;
    subtopicStatusChange: (changedSubtopicStatus: LearningDocumentResponseStatus, changedSubtopicId: number) => void
}

type Props = DisplayProps | ActiveProps

function Subtopic(props: Props) {
    const {subtopic, index} = props
    const iconlessPoints = [LearningDocumentPointType.standard, LearningDocumentPointType.tallyCounter, LearningDocumentPointType.troubleshooting]
    const numStandardPoints = subtopic.points.filter(point => iconlessPoints.findIndex(type => type === point.type) >= 0).length;

    //Used to handle changing subtopic status when a point is clicked.
    const handlePointClick = (changedPointStatus: LearningDocumentResponseStatus, changedPointId: number) => {
        if(props.type === "Active"){
            const {subtopicResponse, formikString, formik, subtopicStatusChange} = props

            const pointsCount = subtopicResponse.pointResponses.length;
            let completedPoints = subtopicResponse.pointResponses.filter(pr => (pr.status === LearningDocumentResponseStatus.Completed && pr.id !== changedPointId) ||
                (pr.id === changedPointId && changedPointStatus === LearningDocumentResponseStatus.Completed)).length;
            
            let unansweredPoints = subtopicResponse.pointResponses.filter(pr => (pr.status === LearningDocumentResponseStatus.Unanswered && pr.id !== changedPointId) ||
                (pr.id === changedPointId && changedPointStatus === LearningDocumentResponseStatus.Unanswered)).length;

            let newSubtopicStatus = LearningDocumentResponseStatus.InProgress;
            if(unansweredPoints === pointsCount){
                newSubtopicStatus = LearningDocumentResponseStatus.Unanswered
            } else if (unansweredPoints < pointsCount && completedPoints !== pointsCount) {
                newSubtopicStatus = LearningDocumentResponseStatus.InProgress
            } else if(completedPoints === pointsCount){
                newSubtopicStatus = LearningDocumentResponseStatus.Completed
            }
            formik.setFieldValue(`${formikString}.status`, newSubtopicStatus)
            subtopicStatusChange(newSubtopicStatus, subtopicResponse.id)
        }
    }

    if(props.type === "DisplayOnly"){
        return (
            <Paper className='avoidBreak'>
                <Typography variant='h6' style={{backgroundColor: "#b4c6e7", padding:"0.5rem", paddingLeft:"1rem", borderTopLeftRadius:"6px", borderTopRightRadius: "6px"}}>{`${index +1}. ${subtopic.label.length > 0 ? subtopic.label : "New Subtopic"}`}</Typography>
                <Stack sx={{padding: "1rem"}} spacing={2}>
                    <List component={"nav"}>
                        {subtopic.points.map((point, pointIndex) => (
                            <Point
                                key={pointIndex}
                                index={pointIndex}
                                type="DisplayOnly"
                                point={point}
                                previousPointType={pointIndex-1 >= 0 ? subtopic.points[pointIndex-1].type : undefined}
                                allIconlessPoints={numStandardPoints === subtopic.points.length}
                            />
                        ))}   
                    </List>
                </Stack>
            </Paper>
        )
    } else {
        const {formik, formikString, subtopicResponse, pointsLocked} = props

        
        let statusColour: ChipColor = "default"
        if(subtopicResponse.status === LearningDocumentResponseStatus.Completed)
            statusColour = "success"
        else if (subtopicResponse.status === LearningDocumentResponseStatus.InProgress)
            statusColour = "primary"
        else if (subtopicResponse.status === LearningDocumentResponseStatus.Unanswered)
            statusColour = "primary"
        return (
            <Paper className='avoidBreak'>
                <Typography variant='h6' style={{backgroundColor: "#b4c6e7", padding:"0.5rem", paddingLeft:"1rem", borderTopLeftRadius:"6px", borderTopRightRadius: "6px"}}>
                    <Stack direction={"row"} sx={{display:"flex", justifyContent:"space-between", marginRight:"1rem"}}>
                        {`${index +1}. ${subtopic.label}`}
                        <Chip
                            label={LearningDocumentResponseStatusString[subtopicResponse.status]}
                            color={statusColour}
                        />
                    </Stack>
                </Typography>
                <Stack sx={{padding: "1rem"}} spacing={2}>
                    <List component={"nav"}>
                        {subtopic.points.map((point, pointIndex) => (
                            <Point
                                key={pointIndex}
                                index={pointIndex}
                                type="Active"
                                point={point}
                                formik={formik}
                                formikString={`${formikString}.pointResponses[${pointIndex}]`}
                                pointResponse={subtopicResponse.pointResponses[pointIndex]}
                                previousPointType={pointIndex-1 >= 0 ? subtopic.points[pointIndex-1].type : undefined}
                                allIconlessPoints={numStandardPoints === subtopic.points.length}
                                pointClick={handlePointClick}
                                pointLocked={pointsLocked}
                                demoMode={props.demoMode}
                            />
                        ))}   
                    </List>
                </Stack>
            </Paper>
        )
    }
}

export default Subtopic