import { TabContext, TabList } from '@mui/lab';
import { Box, Pagination, Tab } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { JournalEntryQueryOptions, JournalEntryStatus } from 'src/dtos/Checklists/AttractionInspections/Journal.dto';
import JournalEntryService from 'src/Services/Checklists/Inspections/AttractionJournalEntryService';
import { ListParameters } from 'src/Services/ListParameters';
import EntriesTable from './EntriesTable';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';


const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30
}

function JournalEntries() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [openTab, setOpenTab] = React.useState(searchParams.get("openTab") ?? 'AllEntries');
    const [listParams, setListParams] = useState<ListParameters>(initialListParams);
    const{attractionId} = useParams() 
    const initialQueryOptions: JournalEntryQueryOptions = {
        attractionId: attractionId??"",
        statuses: searchParams.get("openTab") === "OutstandingEntries" ? [JournalEntryStatus.outstanding] : [],
        types: []
    }
    const [queryOptions, setQueryOptions] = useState<JournalEntryQueryOptions>(initialQueryOptions)
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    //Update attractionId in queryOptions when attractionId param changes
    useEffect(() => {
        if(queryOptions.attractionId !== attractionId && attractionId){
            setQueryOptions({...queryOptions, attractionId: attractionId})
        }
    }, [attractionId, queryOptions])

    //#region queries
    const entriesQuery = useQuery({
        queryKey: ["journalEntries", listParams, queryOptions],
        queryFn: async () => {
            return await JournalEntryService.GetList(queryOptions, listParams)
        }
    })

    useEffect(() => {
        if(entriesQuery.isError){
            setMessageItem({error: entriesQuery.error})
        }
    }, [entriesQuery.error, entriesQuery.isError])
    //#endregion

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        searchParams.set("openTab", tabValue)
        setSearchParams(searchParams)

        const newStatuses = tabValue === "OutstandingEntries" ? [JournalEntryStatus.outstanding] : []
        setQueryOptions({...queryOptions, statuses: newStatuses})
        setListParams({...listParams, page: 1})
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setListParams({...listParams, page:value}); 
    };

    const totalPages = Math.ceil((entriesQuery.data?.totalRecords??0) / listParams.pageSize)
    return (
        <>
            <TabContext value={openTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="All Entries" value="AllEntries"/>
                        <Tab label="Outstanding Entries" value="OutstandingEntries"/>
                    </TabList>
                </Box>
               
                <EntriesTable 
                    entriesQuery={entriesQuery} 
                    pageSize={listParams.pageSize}
                />
                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={listParams.page}
                        onChange={handlePageChange}
                        sx={{marginTop:"0.5rem"}}
                    />
                )}
                    
                
            </TabContext>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
  )
}

export default JournalEntries