import { Stack, Skeleton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Fab } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto';
import LearningItemResponseService from 'src/Services/Training/LearningItemResponseService';
import LearningItemService from 'src/Services/Training/LearningItemService';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { guidRegex } from 'src/Utils/helperFunc';
import CompletedTraineeRow from './CompletedTraineeRow';
import assets from 'src/assets';
import CompletedTrainerRow from './CompletedTrainerRow';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { dateOptions } from 'src/config/constants';

function CompletedIndividualAttendanceSheet() {
    const {trainingSessionId, responseId} = useParams(); //id is trainingSessionId
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const componentRef = useRef<HTMLDivElement>(null);

    //#region queries
    const itemResponseQuery = useQuery({
        queryKey: ["itemResponse", responseId],
        queryFn: async () => {
            if(responseId && guidRegex.test(responseId)){
                return await LearningItemResponseService.Get(responseId)
            }
            return null
        }
    })

    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", trainingSessionId],
        queryFn: async () => {
            if(trainingSessionId && guidRegex.test(trainingSessionId)){
                return await TrainingSessionService.Get(trainingSessionId)
            }
            return null
        }
    })

    const learningItemQuery = useQuery({
        queryKey: ["learningItem", itemResponseQuery.data?.learningItemId],
        queryFn: async () => {
            if(itemResponseQuery.data){
                return await LearningItemService.Get(itemResponseQuery.data.learningItemId)
            }
            return null
        }
    })

    useEffect(() => {
        if(itemResponseQuery.isError){
            setMessageItem({error: itemResponseQuery.error})
        }
        if(trainingSessionQuery.isError){
            setMessageItem({error: trainingSessionQuery.error})
        }
        if(learningItemQuery.isError){
            setMessageItem({error: learningItemQuery.error})
        }
    }, [itemResponseQuery.error, itemResponseQuery.isError, learningItemQuery.error, learningItemQuery.isError, trainingSessionQuery.error, trainingSessionQuery.isError])
    //#endregion


    const handlePrint = useReactToPrint({
        contentRef: componentRef, 
        documentTitle: `${learningItemQuery.data?.label} ${trainingSessionQuery.data?.date && new Date(trainingSessionQuery.data.date).toLocaleDateString(undefined, dateOptions)}`,
    });


    const initialLoading = trainingSessionQuery.isLoading || itemResponseQuery.isLoading || learningItemQuery.isLoading

    if(initialLoading) {
        return (
            <Stack>
                {/* For title */}
                <div style={{width:"100%", display:"flex", justifyContent:"center", height:"5rem"}}>
                    <Skeleton animation="wave" variant="text" sx={{width:"80%", display:"flex"}}/>

                </div>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </Stack>
        )
    }
    
    
    const trainees = itemResponseQuery.data?.attendanceSheetResponse.attendees.filter(a => a.type === AttendeeType.Trainee) ?? []
    const trainers = itemResponseQuery.data?.attendanceSheetResponse.attendees.filter(a => a.type === AttendeeType.Trainer) ?? []
    return (
        <>
            <div style={{padding: "0.5rem", margin:"2rem"}} ref={componentRef}>
                <Stack spacing={2} sx={{marginBottom:"4rem"}}>
                    
                    <Stack direction="row">
                        <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                        <Typography variant='h5' sx={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>{`${learningItemQuery.data?.label}`}</Typography>
                        <Typography sx={{display: "flex", justifyContent: "center", alignItems:"center", width:"8rem"}}>{trainingSessionQuery.data?.date && new Date(trainingSessionQuery.data.date).toLocaleDateString(undefined, dateOptions)}</Typography>
                    </Stack>
        
                    <>
                        <Typography variant='h6'>Trainees</Typography>
                        <Typography>
                            {`By signing this document, I confirm that I attended the ${learningItemQuery.data?.label}. `}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Signature</TableCell>
                                        <TableCell>Time Signed</TableCell>
                                        <TableCell>Notes/Feedback</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trainees.map((trainee, index) => (
                                        <CompletedTraineeRow 
                                            key={index}
                                            trainee={trainee}
                                            readyForAssesmentColumnDisabled
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
        
                        <Typography variant='h6' sx={{marginTop: "1rem"}}>Trainers</Typography>
                        <Typography>As the Trainer, I sign this to acknowledge the attendance of all the above marked Trainee's. </Typography>
                        <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Signature</TableCell>
                                        <TableCell>Time Signed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trainers.map((trainer, index) => (
                                        <CompletedTrainerRow 
                                            key={index}
                                            trainer={trainer}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                                
                </Stack>
                <ErrorHandlingSnackbar messageItem={messageItem}/>
            </div>
            <Fab className="invisiblePrint" onClick={() => handlePrint()} sx={{position:"fixed", bottom: 16, right: 16}}>
                <PrintIcon/>
            </Fab>
        </>
    )
}

export default CompletedIndividualAttendanceSheet