import { ExpandMore } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Divider, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React, { useEffect } from 'react'
import DietaryRequirementService from 'src/Services/HumanResources/DietaryRequirementService'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import DepartmentService from 'src/Services/Resources/DepartmentService'
import TalentLMSService from 'src/Services/Training/TalentLMSService'
import { guidRegex } from 'src/Utils/helperFunc'
import { showDialog } from 'src/components/AlertDialog/AlertDialog'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateOptions } from 'src/config/constants'
import { TeamMember } from 'src/dtos/HumanResources/TeamMember.dto'

type Props = {
    id?: string
    formik: FormikProps<TeamMember>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    loading: boolean;
}
function TmGeneral({id, formik, setMessageItem, loading}: Props) {

    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit();
          } else {
            formik.validateForm();
          }
    }

    //#region queries
    const dietaryRequirementNamessQuery = useQuery({
        queryKey: ["dietaryRequirementNames"],
        queryFn: async () => {
            return await DietaryRequirementService.GetNames()
        }
    })

    const talentLMSUsersQuery = useQuery({
        queryKey: ["talentLMSUsers"],
        queryFn: async () => {
            return await TalentLMSService.GetUserNames();
        }
    })

    const departmentNamesQuery = useQuery({
        queryKey: ["departmentNames"],
        queryFn: async () => {
            return await DepartmentService.GetNames();
        }
    })

    const microsoftUsersQuery = useQuery({
        queryKey: ["microsoftUsers"],
        queryFn: async () => {
            return await TeamMemberService.GetMicrosoftUsers();
        }
    })

    useEffect(() => {
        if(dietaryRequirementNamessQuery.isError){
            setMessageItem({error: dietaryRequirementNamessQuery.error})
        }
        if(talentLMSUsersQuery.isError) {
            setMessageItem({error: talentLMSUsersQuery.error})
        }
        if(departmentNamesQuery.isError){
            setMessageItem({error: departmentNamesQuery.error})
        }
        if(microsoftUsersQuery.isError) {
            setMessageItem({error: microsoftUsersQuery.error})
        }
    }, [setMessageItem, dietaryRequirementNamessQuery.isError, dietaryRequirementNamessQuery.error, talentLMSUsersQuery.error, talentLMSUsersQuery.isError, departmentNamesQuery.error, departmentNamesQuery.isError, microsoftUsersQuery.error, microsoftUsersQuery.isError])


    const disableMutation = useMutation({
        mutationFn: (id: string) => {
            return TeamMemberService.Disable(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Team Member disabled successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const enableMutation = useMutation({
        mutationFn: (id: string) => {
            return TeamMemberService.Enable(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Team Member enabled successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    //#endregion

    //Used to handle reset on completion
    const handleStatusChange = async () => {

        const active = formik.values.enabled

        const changeStatus = await showDialog({
            title: `${active ? "Deactivate" : "Activate"} Team Member`,
            contentText: `Are you sure you want to ${active ? "deactivate" : "activate"} ${formik.values.prefferedFirstName} ${formik.values.lastName}?`,
            acceptText: `${active ? "Deactivate" : "Activate"}`,
            declineText: "Cancel"
        })

        if(!changeStatus){
            return
        }

        if(active){
            if(id && guidRegex.test(id)){
                disableMutation.mutate(id)
            }
        } else {
            if(id && guidRegex.test(id)){
                enableMutation.mutate(id)
            }
        }
    };
    return (
        <>
        <Stack spacing={1}>
            <Typography variant='h6'>General</Typography>
            <div>
                <QuestionTitle title="Preferred  First Name" required/>
                <TextField
                    id="prefferedFirstName"
                    name="prefferedFirstName"
                    value={formik.values.prefferedFirstName ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.prefferedFirstName && Boolean(formik.errors.prefferedFirstName)}
                    helperText={formik.touched.prefferedFirstName && formik.errors.prefferedFirstName}
                />
            </div>
            <div>
                <QuestionTitle title="First Name" required/>
                <TextField
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />
            </div>
            <div>
                <QuestionTitle title="Last Name" required/>
                <TextField
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />
            </div>
            <div>
                <QuestionTitle title="Date of Birth" required/>
                <TextField
                    id="dob"
                    name="dob"
                    type='date'
                    value={formik.values.dob ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                />
            </div>
            <div>
                <QuestionTitle title="Mobile" required/>
                <TextField
                    id="mobile"
                    name="mobile"
                    type="tel"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                />
            </div>
            <div>
                <QuestionTitle title="Email" required/>
                <TextField
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
            </div>
            <div>
                <QuestionTitle title="Department" required/>
                <Autocomplete
                    id="departmentId"
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    options={departmentNamesQuery.data ?? []}
                    value={formik.values.department
                        ? {
                            id: formik.values.department.id,
                            label: formik.values.department.label,
                          }
                        : null
                    }
                    onChange={(_, value) => {
                        formik.setFieldValue("department", value ? { id: value.id, label: value.label } : null)
                        formik.setFieldValue("departmentId", value && value.id)
                    }}
                    size={"small"}
                    sx={{width:"20rem"}}
                    renderInput={(params) => 
                        <TextField {...params}/>
                    }
                />
            </div>
            <div>
                <QuestionTitle title="Dietary Requirements" infoText='These should only be requirements'/>
                <Autocomplete
                    id="dietaryRequirements"
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    options={dietaryRequirementNamessQuery.data ?? []}
                    multiple
                    value={formik.values.dietaryRequirements}
                    onChange={(_, value) => {
                        formik.setFieldValue("dietaryRequirements", value)
                    }}
                    size={"small"}
                    sx={{width:"20rem"}}
                    renderInput={(params) => 
                        <TextField {...params}/>
                    }
                />
            </div>
             <Divider/>       
            <Typography variant='h6'>Accounts</Typography>
            <div>
                <QuestionTitle title="Talent LMS"/>
                <Autocomplete
                    id="talentLMSAccountId"
                    autoHighlight
                    value={(talentLMSUsersQuery.data ?? []).find(option => option.id === formik.values.talentLMSAccountId) ?? null} 
                    options={talentLMSUsersQuery.data ?? []}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option ? option.name ?? "" : ""}
                    onChange={(_, value) => {
                        formik.setFieldValue("talentLMSAccountId", value ? value.id : null)
                    }}
                    size={"small"}
                    sx={{width:"20rem"}}
                    renderInput={(params) => 
                        <TextField {...params}/>
                    }
                />
                {formik.values.talentLMSAccountId && (
                    <Typography>{`Associated Email: ${talentLMSUsersQuery.data && talentLMSUsersQuery.data.find(user => user.id === formik.values.talentLMSAccountId)?.email}`}</Typography>
                )}
            </div>
            <div>
                <QuestionTitle title="Microsoft Account"/>
                <Autocomplete
                    id="microsoftAccountId"
                    autoHighlight
                    options={microsoftUsersQuery.data ?? []}
                    value={(microsoftUsersQuery.data ?? []).find(option => option.id === formik.values.microsoftAccountId) ?? null}
                    getOptionLabel={(option) => option ? option.displayName: ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionKey={(option) => option.id}
                    onChange={(_, value) => {
                        formik.setFieldValue("microsoftAccountId", value ? value.id : null);
                    }}
                    size={"small"}
                    sx={{width:"20rem"}}
                    renderInput={(params) => 
                        <TextField {...params}/>
                    }
                />
                {formik.values.microsoftAccountId && (
                    <Typography>{` ${microsoftUsersQuery.data && microsoftUsersQuery.data.find(user => user.id === formik.values.microsoftAccountId)?.userPrincipalName}`}</Typography>
                )}
            </div>
            <div>
                <QuestionTitle title="Galaxy Account Id" infoText='This refers to the staff membership account Id NOT their user login ID'/>
                <TextField
                    id="galaxyAccountId"
                    name="galaxyAccountId"
                    value={formik.values.galaxyAccountId ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    sx={{width:"20rem"}}
                    error={formik.touched.galaxyAccountId && Boolean(formik.errors.galaxyAccountId)}
                    helperText={formik.touched.galaxyAccountId && formik.errors.galaxyAccountId}
                />
            </div>
            {id !== "new" && (
                <>
                    <Divider/>  
                    <div>
                        <QuestionTitle title="Status"/>
                        <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.enabled}
                                        onChange={handleStatusChange}
                                    />
                                }
                                label={formik.values.enabled ? "Active" : "Inactive"}   
                            />
                    </div>
                </>
            )}
        </Stack>
        <span style={{display:"flex", justifyContent:"space-between"}}>
            {id !== "new" && (
                <Accordion sx={{width: "30rem"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="additional-info"
                    >
                    <Typography>Modification Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {formik.values.createdBy && (
                        <Typography>
                            Created By: {formik.values.createdBy}
                        </Typography>
                        )}
                        {formik.values.createdOn && (
                        <Typography>
                            Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                        {formik.values.modifiedBy && (
                        <Typography>
                            Modified By: {formik.values.modifiedBy}
                        </Typography>
                        )}
                        {formik.values.modifiedOn && (
                        <Typography>
                            Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                    </AccordionDetails>              
                </Accordion>
            )}
            <span style={{flexGrow: 1, textAlign:"right"}}>
                <LoadingButton
                    variant='contained'
                    loading={loading}
                    onClick={handleSaveClick}
                >
                    Save
                </LoadingButton>
            </span>
        </span>
        </>
    )
}

export default TmGeneral