import { TableRow, TableCell, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import CustomSignaturePad from 'src/components/SignaturePad/SignaturePad'
import { dateTimeOptions } from 'src/config/constants'
import { AttendanceSheetAttendee, AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { LearningDocumentResponseDto } from 'src/dtos/Training/LearningDocumentResponse.dto'

type Props = {
    formik: FormikProps<LearningDocumentResponseDto>
    assessor: AttendanceSheetAttendee
}
function AssessorRow({formik, assessor}:Props) {
    const [assessorNotes, setAssessorNotes] = useState(assessor.notes ?? "")
    const trainerIndex = formik.values.attendanceSheetResponse.attendees.findIndex(attendee => attendee.teamMemberId === assessor.teamMemberId && attendee.type === AttendeeType.Trainer)
    const updateAssessorSignature = (signature: string | undefined) => {
        
        formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].signature`, signature)
        if(signature){
            formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].timeSigned`, new Date())
        } else {
            formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].timeSigned`, null)
        }
        
    }
    return (
        <TableRow
            key={assessor.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {`${assessor.teamMember.prefferedFirstName} ${assessor.teamMember.lastName}`}
            </TableCell>
            <TableCell>
                <TextField 
                    id={`attendanceSheetResponse.attendees[${trainerIndex}].notes`}
                    name={`attendanceSheetResponse.attendees[${trainerIndex}].notes`}
                    value={assessorNotes}
                    onChange={(e) => setAssessorNotes(e.target.value)}
                    onBlur={() => formik.setFieldValue(`attendanceSheetResponse.attendees[${trainerIndex}].notes`, assessorNotes)}
                    multiline
                    maxRows={5}
                    size='small'
                    sx={{width:"20rem"}}
                    helperText={`(handover notes for next Assessor if necessary)`}
                />
            </TableCell>
            <TableCell>
                <CustomSignaturePad 
                    person={{name: `${assessor.teamMember.prefferedFirstName} ${assessor.teamMember.lastName}`, signature: assessor.signature}}
                    setSignature={(signature: string | undefined) => updateAssessorSignature(signature)}
                />
            </TableCell>
            <TableCell>
                {assessor.timeSigned && new Date(assessor.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
            </TableCell>
        </TableRow>
    )
}

export default AssessorRow