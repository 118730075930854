import { Close } from "@mui/icons-material"
import { Button, Modal, Box, Typography, IconButton, ListItem, Tooltip, ListItemIcon, ListItemButton, Checkbox, Stack, ListItemText, List } from "@mui/material"
import React, { useState } from "react"
import { EmailToNameString, sanitizeHtmlToIFrame } from "src/Utils/helperFunc"
import { LearningDocumentPoint, LearningDocumentPointType } from "src/dtos/Training/LearningDocument.dto"
import Subpoint from "../../Subpoint/Subpoint"
import { FormikProps } from "formik"
import { LearningDocumentPointResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PointIcon } from "./PointIcon"
import { createUnorderedListMarkup } from "./CreateUnorderedListMarkup"
import { GetCurrentUser } from "src/Utils/authUtils"
import { dateTimeOptions } from "src/config/constants"

type BaseProps = {
    point: LearningDocumentPoint
    index: number
    allIconlessPoints?: boolean
    SaveStatusIcon?: React.ReactNode;
    demoMode?: boolean;
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    pointResponse: LearningDocumentPointResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    pointClick: (changedPointStatus: LearningDocumentResponseStatus, changedPointId: number) => void
    pointLocked: boolean;
    SaveStatusIcon: React.ReactNode;
}

type Props = DisplayProps | ActiveProps;

function CommonPoint(props: Props) {
    const {point, index, allIconlessPoints} = props;
    const [examplesOn, setExamplesOn] = useState(true)

    const handleExamplesClick = () => {
        setExamplesOn((prevVal) => !prevVal)
    }

    const createEmbeddedContentMarkup = () => {
        if(point.embeddedIFrame) {
            return {
                __html : sanitizeHtmlToIFrame(point.embeddedIFrame)
            }
        } else {
            return {
                __html: "<p>Video Not Found</p>"
            }
        }
    }

    const EmbeddedContentModal = ({contentType}: {contentType: string}) => {
        const [open, setOpen] = useState(false)
        const style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "max-content",
            minWidth: "50%",
            height: "auto",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        const handleOpen = () => {
            setOpen(true)
        }
        const handleClose = () => {
            setOpen(false)
        }

        return (
            <div>
                <Button onClick={handleOpen}>{`Open ${contentType}`}</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <div style={{display:"flex", justifyContent:"space-between", marginBottom:"0.5rem"}}>
                            <Typography variant='h5'>{contentType}</Typography>
                            <IconButton onClick={handleClose}>
                                <Close/>
                            </IconButton>
                        </div>
                        <div dangerouslySetInnerHTML={createEmbeddedContentMarkup()} />
                    </Box>

                </Modal>
            </div>
        )
    }
    
    const pointDisabled = props.type === "Active" && props.pointResponse.answeredById ? !(props.pointResponse.answeredById === GetCurrentUser().id) && props.pointResponse.checked === true : false

    const handlePointClick = () => {
        if(props.type === "Active"){
            const {pointResponse, formikString, formik, pointClick, pointLocked} = props

            if (pointLocked || pointDisabled){
                return
            }
            formik.setFieldValue(`${formikString}.checked`, !pointResponse.checked);
            formik.setFieldValue(`${formikString}.timeAnswered`, new Date());
            const subpointsInComplete = pointResponse.subpointResponses.filter(spr => spr.checked === false).length;
            let newPointStatus = LearningDocumentResponseStatus.InProgress
            if(subpointsInComplete === 0 && pointResponse.checked !== true){
                newPointStatus = LearningDocumentResponseStatus.Completed
            } else if (subpointsInComplete === pointResponse.subpointResponses.length) {
                newPointStatus = LearningDocumentResponseStatus.Unanswered
            } 
            
            formik.setFieldValue(`${formikString}.status`, newPointStatus)
            pointClick(newPointStatus, pointResponse.id)

        }    
    }

    //Used to handle changing point status when a subpoint is clicked.
    const handleSubpointClick = (changedSubpointAnswer: boolean, changedSubpointId: number) => {
        if(props.type === "Active"){
            const {pointResponse, formikString, formik, pointClick} = props
            const subpointsCount = pointResponse.subpointResponses.length
            const completeSubpoints = pointResponse.subpointResponses.filter(spr => (spr.checked === true && spr.id !== changedSubpointId) || 
                (spr.id === changedSubpointId && changedSubpointAnswer === true)).length
            
            let newPointStatus = LearningDocumentResponseStatus.InProgress;
            if(completeSubpoints === subpointsCount){
                newPointStatus = LearningDocumentResponseStatus.Completed
            } else if (completeSubpoints === 0) {
                newPointStatus = LearningDocumentResponseStatus.Unanswered
            }
            formik.setFieldValue(`${formikString}.status`, newPointStatus)
            pointClick(newPointStatus, pointResponse.id)       
        }
    }

    const {SaveStatusIcon} = props
    return (
        <>
        <ListItem
            secondaryAction={
                <Stack spacing={1} direction="row" sx={{alignItems:"center"}}>
                    {pointDisabled && (
                        <Stack spacing={1} sx={{textAlign:"center"}}>
                            <Typography variant="caption">{props.type === "Active" && props.pointResponse.answeredBy && EmailToNameString(props.pointResponse.answeredBy)}</Typography>
                            <Typography variant="caption">{props.type === "Active" && props.pointResponse.timeAnswered && new Date(props.pointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}</Typography>
                        </Stack>
                    )}
                    {point.unorderedList && (
                        <Tooltip title={"Examples"}>
                            <IconButton edge="end" aria-label='examples' onClick={handleExamplesClick}>
                                <InfoOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {SaveStatusIcon}

                </Stack>
            }
            disablePadding
            >
                
                {allIconlessPoints !== true && (
                    <ListItemIcon>
                        <PointIcon pointType={point.type}/>
                    </ListItemIcon>
                )}
            <ListItemButton onClick={handlePointClick}>
                <ListItemIcon>
                    <Checkbox
                        disabled={pointDisabled}
                        edge="start"
                        checked={props.type === "Active" ? props.pointResponse.checked : false}
                        tabIndex={-1}
                        disableRipple
                        />
                </ListItemIcon>
                <Stack>
                    <ListItemText id={`${index}`} primary={`${index+1}. ${point.label}`}/>
                    {examplesOn && (
                        <div dangerouslySetInnerHTML={createUnorderedListMarkup(point)}/>
                        )}
                </Stack>
            </ListItemButton>

            
            {point.type === LearningDocumentPointType.video && (
                <EmbeddedContentModal contentType='Video'/>
            )}
            {point.type === LearningDocumentPointType.image && (
                <EmbeddedContentModal contentType='Image'/>
            )}
        </ListItem>
        {point.subpoints.length > 0 && (
            <List component="div" sx={{marginLeft:"6rem"}} dense disablePadding>
                {point.subpoints.map((subpoint, subpointIndex) => {
                    if(props.type === "Active"){
                        const {pointResponse, formikString, formik} = props 
                        return (
                            <Subpoint
                                key={subpointIndex}
                                type="Active"
                                label={`${index+1}.${subpointIndex+1}. ${subpoint.label}`}
                                subpointResponse={pointResponse.subpointResponses[subpointIndex]}
                                formikString={`${formikString}.subpointResponses[${subpointIndex}]`}
                                formik={formik}
                                subpointClick={handleSubpointClick}
                                demoMode={props.demoMode}
                            />
                        )
                    } 
                    return (
                        <Subpoint
                            key={subpointIndex}
                            type="DisplayOnly"
                            label={`${index+1}.${subpointIndex+1}. ${subpoint.label}`}
                        />
                    )
                })}
         </List>
        )}
        </>
    )
}

export default CommonPoint