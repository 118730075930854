import { Checkbox, ListItemButton, TableCell, TableRow, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { FormikProps } from 'formik/dist/types'
import React, { useEffect, useRef } from 'react'
import TrainingSessionService from 'src/Services/Training/TrainingSessionService'
import CustomSignaturePad from 'src/components/SignaturePad/SignaturePad'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateTimeOptions } from 'src/config/constants'
import { AttendanceSheetAttendee, AttendanceSheetResponse } from 'src/dtos/Training/AttendanceSheetResponse.dto'


type Props = {
    trainer: AttendanceSheetAttendee,
    formik: FormikProps<AttendanceSheetResponse>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    editingLocked: boolean;
}

function TrainerRow({trainer, formik, setMessageItem, editingLocked}: Props) {

    const trainerIndex = formik.values.attendees.findIndex(attendee => attendee === trainer);


    const markAttended = () => {
        if(editingLocked){
            return
        }
        formik.setFieldValue(`attendees[${trainerIndex}].attended`, !trainer.attended)
    }

    const updateSignature = (signature: string | undefined) => {
        if(editingLocked){
            return
        }
        formik.setFieldValue(`attendees[${trainerIndex}].signature`, signature)
        if(signature){
            formik.setFieldValue(`attendees[${trainerIndex}].timeSigned`, new Date())
        } else {
            formik.setFieldValue(`attendees[${trainerIndex}].timeSigned`, null)
        }
    }

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: AttendanceSheetAttendee}) => {
            return TrainingSessionService.UpdateAttendanceSheetAttendee(id, values)
        },
        onSuccess: (data) => {
            formik.setFieldValue(`attendees[${trainerIndex}]`, data.data)
            setMessageItem({successMessage: "Trainer Updated Successfully!"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const hasMounted = useRef(false)
    useEffect(() => {
        if(hasMounted.current === true && !editingLocked) {
            updateMutation.mutate({id: trainer.id, values: trainer})
        } else {
            hasMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainer.attended, trainer.signature])


    return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
            <ListItemButton
                    onClick={markAttended}
                >
                    <Checkbox 
                        edge="start"
                        checked={trainer.attended} 
                        disableRipple
                    />
                    <Typography>{`${trainer.teamMember.prefferedFirstName} ${trainer.teamMember.lastName}`}</Typography>
                </ListItemButton>
        </TableCell>
        <TableCell>
                <CustomSignaturePad 
                    person={{name: `${trainer.teamMember.prefferedFirstName} ${trainer.teamMember.lastName}`, signature: trainer.signature}}
                    setSignature={(signature: string | undefined) => updateSignature(signature)}
                    locked={editingLocked}
                />
            </TableCell>
            <TableCell>
                <Typography variant='body2' sx={{minWidth:"4rem"}}>
                    {trainer.timeSigned && new Date(trainer.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
                </Typography>
            </TableCell>
    </TableRow>
    )
}

export default TrainerRow