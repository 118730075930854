import { Accordion, AccordionDetails, AccordionSummary, Box, Fab, FormControlLabel, IconButton, Paper, Stack, Switch, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FieldArray, FieldArrayRenderProps, FormikProps, FormikProvider } from 'formik';
import { AttractionDto } from 'src/dtos/AwTypes';
import '../styles.scss'


type Props = {
    formikProps: FormikProps<AttractionDto>;
}

function ChildEntityForm({formikProps}: Props) {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleOpenChange = (panel:number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    event.stopPropagation();
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
        <FormikProvider value={formikProps}>
          <FieldArray 
            name="childEntities"
            render={(arrayHelpers: FieldArrayRenderProps) => (
              <div style={{overflowY: "scroll", maxHeight: "32rem"}}>
                
                {formikProps.values.childEntities && formikProps.values.childEntities.length > 0 && (
                  formikProps.values.childEntities.map((_, index) => (

                    <Accordion key={index} expanded={expanded === index} onChange={handleOpenChange(index)} >
                      <AccordionSummary  style={{width:"auto"}} expandIcon={<ExpandMoreIcon fontSize='large'/>}>
                        <Stack>
                          <div className='input-row' style={{width:"auto"}}>
                            <QuestionTitle title='Name'/>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                              <TextField
                                id={`childEntities.${index}.label`}
                                name={`childEntities.${index}.label`}
                                value={formikProps.values.childEntities && formikProps.values.childEntities[index].label}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                onClick={(e) => e.stopPropagation()}
                                variant="outlined" 
                                size="small" 
                                sx={{width: "10rem"}}
                              />
                              <IconButton onClick={(e) => {e.stopPropagation(); arrayHelpers.remove(index)}}><DeleteOutlineOutlinedIcon/></IconButton>
                            </div>
                          </div>
                          <Stack direction="row" spacing={-0.5}>
                            <FormControlLabel
                              control={
                                  <Switch
                                      name={`childEntities.${index}.requiredToPass`}
                                      checked={
                                          formikProps.values.childEntities && formikProps.values.childEntities[index].requiredToPass
                                      }
                                      onChange={(event) =>
                                        formikProps.setFieldValue(
                                          `childEntities.${index}.requiredToPass`,
                                              !(formikProps.values.childEntities && formikProps.values.childEntities[index].requiredToPass)
                                          )
                                      }
                                  />
                              }
                              label="Required To Operate"
                            />
                            <QuestionTitle title="" infoText='Toggled ON: If this component fails in an inspection, the attraction cannot operate and the entire inspection would fail. Toggled OFF: If this component fails in an inspection the attraction could still operate and the inspection could still pass e.g. a singular seat.' />
                          </Stack>
                          
                          <Stack direction="row" spacing={-0.5}>
                            <FormControlLabel
                              control={
                                  <Switch
                                      name={`childEntities.${index}.requiresTagging`}
                                      checked={
                                          formikProps.values.childEntities && formikProps.values.childEntities[index].requiresTagging
                                      }
                                      onChange={(event) =>{
                                        formikProps.setFieldValue(
                                          `childEntities.${index}.requiresTagging`,
                                              !(formikProps.values.childEntities && formikProps.values.childEntities[index].requiresTagging)
                                          );
                                        event.stopPropagation()
                                      }
                                        
                                      }
                                  />
                              }
                              label="Requires Tagging"
                            />
                            <QuestionTitle title="" infoText='Toggled ON: If this component fails in an inspection, it must be individually tagged out.'/>
                          </Stack>
                          
                        </Stack>
                        
                        
                      </AccordionSummary>
                      <AccordionDetails>
                      <FieldArray
                        name="children"
                        render={(subArrayHelpers: FieldArrayRenderProps) => (
                          <div>
                            {formikProps.values.childEntities && formikProps.values.childEntities[index].children && (
                            formikProps.values.childEntities[index].children?.map((subChild, subIndex) => (
                                <Paper key={subIndex} sx={{padding:"1rem"}}>
                                  <Stack>
                                    <div style={{width:"auto"}}>
                                      <QuestionTitle title='Name'/>
                                      <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <TextField
                                          id={`childEntities.${index}.children.${subIndex}.label`}
                                          name={`childEntities.${index}.children.${subIndex}.label`}
                                          value={formikProps.values.childEntities && formikProps.values.childEntities[index].children[subIndex].label}
                                          onChange={formikProps.handleChange}
                                          onBlur={formikProps.handleBlur}
                                          variant="outlined" 
                                          size="small" 
                                          sx={{width: "10rem"}}
                                        />
                                        <IconButton onClick={() => {
                                          const childEntities = formikProps.values.childEntities;
                                          if(childEntities && childEntities[index].children){
                                            const newChildrenArray = childEntities[index].children.filter((_, childIndex) => childIndex !== subIndex);
                                            arrayHelpers.replace(index, {...childEntities[index], children:newChildrenArray})
                                          }
                                        }}>
                                        <DeleteOutlineOutlinedIcon/></IconButton>
                                      </div>
                                    </div>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name={`childEntities.${index}.children.${subIndex}.requiredToPass`}
                                                checked={
                                                    formikProps.values.childEntities && formikProps.values.childEntities[index].children[subIndex].requiredToPass
                                                }
                                                onChange={(event) =>
                                                  formikProps.setFieldValue(
                                                      `childEntities.${index}.children.${subIndex}.requiredToPass`,
                                                        !(formikProps.values.childEntities && formikProps.values.childEntities[index].children[subIndex].requiredToPass)
                                                    )
                                                }
                                            />
                                        }
                                        label="Required To Operate"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name={`childEntities.${index}.children.${subIndex}.requiresTagging`}
                                                checked={
                                                    formikProps.values.childEntities && formikProps.values.childEntities[index].children[subIndex].requiresTagging
                                                }
                                                onChange={(event) =>
                                                  formikProps.setFieldValue(
                                                      `childEntities.${index}.children.${subIndex}.requiresTagging`,
                                                        !(formikProps.values.childEntities && formikProps.values.childEntities[index].children[subIndex].requiresTagging)
                                                    )
                                                }
                                            />
                                        }
                                        label="Requires Tagging"
                                    />
                                  </Stack>
                                  
                                                        
                                </Paper>
                              )))
                            }
                            <span style={{display: "flex", justifyContent:"center", paddingTop:"1rem"}}>
                              <Tooltip title="Add New Child Component">
                                <Fab sx={{maxBlockSize: "2rem", minBlockSize: "2rem", width: "2rem", padding: 0}} size= "small" color="primary" onClick={() => {
                                  const childEntities = formikProps.values.childEntities;
                                  if(childEntities && childEntities[index].children){
                                    arrayHelpers.replace(index, {...childEntities[index], children:[...childEntities[index].children, {label: "", children: [], requiredToPass: false, requiresTagging: false}]})
                                  }
                                }}>
                                <AddIcon fontSize='small'/></Fab>
                              </Tooltip>
                            </span>

                          </div>
                        )}
                      /> 
                      </AccordionDetails>
                    </Accordion>  
                  ))
                  
                ) }
                <span style={{display: "flex", justifyContent:"center", padding: "1rem"}}>
                  <Tooltip title="Add New Component">
                    <Fab size= "small" color="primary" onClick={() => {arrayHelpers.push({label: "", children: []})
                  }}><AddIcon/></Fab>
                  </Tooltip>
                </span>
              </div>
            )}/>
        
        </FormikProvider>
    </Box>
    
  )
}

export default ChildEntityForm