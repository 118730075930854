import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto'
type Props = {
    trainingSessionQuery: UseQueryResult<TrainingSession | null, Error>
}
function TrainingSessionTeamMembers({trainingSessionQuery}: Props) {
    const trainees = trainingSessionQuery.data?.sessionAttendees.filter(attendee => attendee.type === AttendeeType.Trainee) ?? []
    const trainers = trainingSessionQuery.data?.sessionAttendees.filter(attendee => attendee.type === AttendeeType.Trainer) ?? []

    return (
    <Stack spacing={2}> 
        <TableContainer component={Paper} sx={{width:"30rem"}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Trainers</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {trainers.length > 0 ? trainers.map((trainer, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {`${trainer.teamMember?.prefferedFirstName} ${trainer.teamMember?.lastName}`}
                            </TableCell>
                        </TableRow>
                    )) : (
                        <TableRow>
                            <TableCell>
                                No Trainers Assigned!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </TableContainer>

        <TableContainer component={Paper} sx={{width:"30rem"}}>
            <Table size="small">
                <TableHead>
                    <TableCell>Trainers</TableCell>
                </TableHead>
                <TableBody>
                    {trainees.length > 0 ? trainees.map((trainee, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {`${trainee.teamMember?.prefferedFirstName} ${trainee.teamMember?.lastName}`}
                            </TableCell>
                        </TableRow>
                    )) : (
                        <TableRow>
                            <TableCell>
                                No Trainees Assigned!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </TableContainer>
    </Stack>
  )
}

export default TrainingSessionTeamMembers