import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import "./CompletedLayoutStyles.css"

const CompletedDocumentLayout = () => {
    return (
        <main className="App">
            <Box component="main">
                <Outlet/>
            </Box>
        </main>
    )
}

export default CompletedDocumentLayout;