import { Button, IconButton, ImageList, ImageListItem, Modal, Stack } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import React, { useState } from 'react'
import './styles.scss'

type Props = {
    photosData: string[];
    photoGalleryOpen: boolean;
    setPhotoGalleryOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCameraOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deletePhoto: (image: string) => boolean; //returns true if photos remain
}

function PhotoGalleryWithCam({photosData, photoGalleryOpen, setPhotoGalleryOpen, setCameraOpen, deletePhoto}: Props) {
    const [openImage, setOpenImage] = useState<string | null>(null);

    const handlePhotoGalleryClose = () => {
        setPhotoGalleryOpen(false)
        //TODO: save iamges
    }

    const handleAddPhoto = () => {
        setCameraOpen(true);
        setPhotoGalleryOpen(true);
    }

    const handleOpenImageClose = () => {
        setOpenImage(null);
    }

    const handleDeleteImage = () => {
        if(openImage){
            const imagesRemaining: boolean = deletePhoto(openImage);
            setOpenImage(null);
            if(imagesRemaining){
                setPhotoGalleryOpen(true)
            } else {
                setPhotoGalleryOpen(false)
            }
        }   
    }

    return (
    <>
        <Modal
            open={photoGalleryOpen}
            onClose={handlePhotoGalleryClose}
        >
            <div className='modalBox'>
                <ImageList cols={3}>
                {photosData.map((photo, index) => (
                    <ImageListItem key={photo} style={{cursor:"pointer"}}>
                        <img
                            alt={`Capture ${index}`}
                            src={photo}
                            onClick={() => setOpenImage(photo)}
                        />
                        <div>
                            {/* <FullscreenOutlinedIcon/> */}
                        </div>
                    </ImageListItem>
                ))}
                <ImageListItem>
                    <div onClick={handleAddPhoto} style={{display: 'flex', justifyContent: "center", alignItems: "center", height: "100%", }}>
                        <IconButton onClick={handleAddPhoto}>
                            <AddCircleOutlineOutlinedIcon fontSize="large" color='primary'/>
                        </IconButton>
                    </div>
                </ImageListItem>

                </ImageList>
            </div>
        </Modal>
        <Modal
            open={Boolean(openImage)}
            onClose={handleOpenImageClose}
        >
            <div className='modalBox'>
                <Stack>
                    {openImage && 
                    <img
                        alt="Open Capture"
                        src={openImage}
                        className='capturedImage'
                    />
                    }
                    <Stack direction="row" style={{display: "flex", justifyContent: "center", padding: "0.5rem"}}>
                        <Button onClick={handleDeleteImage} color='error' variant='contained'> <DeleteForeverOutlinedIcon/> Delete</Button>
                        <Button onClick={handleOpenImageClose}>Close</Button>
                    </Stack>
                </Stack> 
            </div>               
        </Modal>
        
    </>
  )
}

export default PhotoGalleryWithCam