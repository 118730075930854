/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabContext, TabPanel } from '@mui/lab';
import { Button, Paper, Step, StepButton, StepLabel, Stepper, Typography } from '@mui/material'
import React, { useState } from 'react'
import SearchableTransferList from 'src/components/SearchableTransferList/SearchableTransferList';
import { NameDto } from 'src/dtos/NameDto.dto';
import SelectTeamMembers from './Components/SelectTeamMembers';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import SelectAction from './Components/SelectAction';
import Confirmation from './Components/Confirmation';
import { showDialog } from 'src/components/AlertDialog/AlertDialog';



export enum ActionType {
    OnlineLearning
}
export const ActionTypeStrings: string[] = [
    "Assign Online Learning"
]

export interface Action {
    type: ActionType;
    onlineLearningCourse?: NameDto | null;
}
function BulkActions() {
    const [activeStep, setActiveStep] = useState(0);
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const [selectedTeamMembers, setSelectedTeamMembers] = useState<readonly NameDto[]>([])
    const [selectedAction, setSelectedAction] = useState<Action>({type: ActionType.OnlineLearning})


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //Used to handle reset on completion
    const handleReset = async () => {
        setActiveStep(0);
        setSelectedAction({type: ActionType.OnlineLearning})

        const reusetSelectedTeamMembers = await showDialog({
            title: "Reset Selected Team Members?",
            contentText: "Would you like to reuse the same selected team members for your next action?",
            acceptText: "Yes",
            declineText: "No"
        })

        if(!reusetSelectedTeamMembers){
            setSelectedTeamMembers([])
        }
    };

    return (
        <>
            <Paper sx={{padding:"1.5rem"}}>
                <Stepper activeStep={activeStep}>
                    <Step>
                        <StepLabel>Set Action</StepLabel>                       
                    </Step>
                    <Step>
                        <StepLabel>Select Team Members</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Confirmation</StepLabel>
                    </Step>
                </Stepper>
                <TabContext value={activeStep.toString()}>
                    <TabPanel value="0">
                        <SelectAction
                            handleNext={handleNext}
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                        />
                    </TabPanel>
                    <TabPanel value="1">
                        <SelectTeamMembers
                            setMessageItem={setMessageItem}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            selectedTeamMembers={selectedTeamMembers}
                            setSelectedTeamMembers={setSelectedTeamMembers}
                        />
                    </TabPanel>
                    <TabPanel value="2">
                        <Confirmation
                            handleBack={handleBack}
                            handleReset={handleReset}
                            selectedTeamMembers={selectedTeamMembers}
                            selectedAction={selectedAction}
                        />
                    </TabPanel>
                </TabContext>
            </Paper>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default BulkActions