import { Stack, Button, TextField } from "@mui/material";
import { FormikProps } from "formik";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import { LearningDocumentTopic } from "src/dtos/Training/LearningDocument.dto";
import { LearningDocumentTopicResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto";
import Subtopic from "../../Subtopic/Subtopic";
import React, { useState } from "react";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";

type Props = {
    topic: LearningDocumentTopic;
    topicResponse: LearningDocumentTopicResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    demoMode?: boolean;
}

function CompetencyDocumentTopic({topic,topicResponse,formikString, formik, demoMode}: Props) {
    const [messageItem, setMessageItem] = useState<MessageItem>({});
    const [notes, setNotes] = useState(topicResponse.commentary)

    const incompleteSubtopics = topicResponse.subtopicResponses.filter(str => str.status !== LearningDocumentResponseStatus.Completed).length

    const handleTopicStatusChange = (changedTopicStatus: LearningDocumentResponseStatus, changedTopicId: number) => {
        const topicsCount = formik.values.topicResponses.length;
        const completedStatuses = [LearningDocumentResponseStatus.Competent, LearningDocumentResponseStatus.NotYetCompetent]
        let completedTopics = formik.values.topicResponses.filter(tr => (completedStatuses.includes(tr.status) && tr.id !== changedTopicId) || 
            (tr.id === changedTopicId && completedStatuses.includes(changedTopicStatus))).length
        
        let unansweredTopics = formik.values.topicResponses.filter(tr => (tr.status === LearningDocumentResponseStatus.Unanswered && tr.id !== changedTopicId) ||
            (tr.id === changedTopicId && changedTopicStatus === LearningDocumentResponseStatus.Unanswered)).length
        
        let newLearningDocumentStatus = LearningDocumentResponseStatus.InProgress;
        if(unansweredTopics === topicsCount){
            newLearningDocumentStatus = LearningDocumentResponseStatus.Unanswered
        } else if (unansweredTopics < topicsCount && completedTopics !== topicsCount){
            newLearningDocumentStatus = LearningDocumentResponseStatus.InProgress
        } else if (completedTopics === topicsCount){
            newLearningDocumentStatus = LearningDocumentResponseStatus.AwaitingSubmission
        }
        formik.setFieldValue("status", newLearningDocumentStatus)
    }

    const calculateStatus = (competency: boolean | null) => {
        let newStatus = LearningDocumentResponseStatus.InProgress
        if(incompleteSubtopics === 0) {
            if(competency === true)
                newStatus = LearningDocumentResponseStatus.Competent
            if(competency === false)
                newStatus = LearningDocumentResponseStatus.NotYetCompetent
        } else if (incompleteSubtopics === topicResponse.subtopicResponses.length){
            newStatus = LearningDocumentResponseStatus.Unanswered
        }
        formik.setFieldValue(`${formikString}.status`, newStatus);
        handleTopicStatusChange(newStatus, topicResponse.id)
    }

    const handleSubtopicStatusChange = (changedSubtopicStatus: LearningDocumentResponseStatus, changedSubtopicId: number) => {
        const subtopicsCount = topicResponse.subtopicResponses.length; 
        let unansweredSubtopics = topicResponse.subtopicResponses.filter(str => (str.status === LearningDocumentResponseStatus.Unanswered && str.id !== changedSubtopicId) ||
          (str.id === changedSubtopicId && changedSubtopicStatus === LearningDocumentResponseStatus.Unanswered)).length;
  
        let newTopicStatus = LearningDocumentResponseStatus.InProgress;
        if(unansweredSubtopics === subtopicsCount){
            newTopicStatus = LearningDocumentResponseStatus.Unanswered;
        }
        formik.setFieldValue(`${formikString}.status`, newTopicStatus)
    }
  
    

    const ResultOfAssesment = () => {

        const handleYesClick = () => {
            let competency = null;
            if(incompleteSubtopics > 0){
                setMessageItem({errorMessage: "Cannot set result while there are incomplete subtopics"})
                return
            }
            if (topicResponse.competent !== true) {
                competency = true
                formik.setFieldValue(`${formikString}.completedTime`, new Date());
            }
            formik.setFieldValue(`${formikString}.competent`, competency);
            calculateStatus(competency)
        };

        const handleNoClick = () => {
            let competency = null;
            if(incompleteSubtopics > 0){
                setMessageItem({errorMessage: "Cannot set result while there are incomplete subtopics"})
                return
            }
            if (topicResponse.competent !== false) {
                competency = false
                formik.setFieldValue(`${formikString}.completedTime`,new Date());
            }
            formik.setFieldValue(`${formikString}.competent`, competency);
            calculateStatus(competency)
        };

        

        return (
            <Stack direction="row" spacing={2}>
                <Button
                    size="large"
                    variant={
                        topicResponse.competent === true
                            ? "contained"
                            : "outlined"
                    }
                    color="success"
                    onClick={handleYesClick}
                >
                    Competent
                </Button>
                <Button
                    size="large"
                    variant={
                        topicResponse.competent === false
                            ? "contained"
                            : "outlined"
                    }
                    color="warning"
                    onClick={handleNoClick}
                >
                    Not Yet Competent
                </Button>
            </Stack>
        );
    };
    return (
        <Stack spacing={2}>
            {topic.subtopics.map((subtopic, index) => (
                <Subtopic
                    key={index}
                    type="Active"
                    index={index}
                    formik={formik}
                    formikString={`${formikString}.subtopicResponses[${index}]`}
                    subtopicResponse={topicResponse.subtopicResponses[index]}
                    subtopic={subtopic}
                    pointsLocked={topicResponse.status === LearningDocumentResponseStatus.NotYetCompetent || topicResponse.status === LearningDocumentResponseStatus.Competent}
                    subtopicStatusChange={handleSubtopicStatusChange}
                    demoMode={demoMode}
                />
            ))}
            <div></div>
            <div className="avoidBreak">
                <QuestionTitle title="Notes" />
                <TextField
                    id={`${formikString}.commentary`}
                    name={`${formikString}.commentary`}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    onBlur={() => formik.setFieldValue(`${formikString}.commentary`, notes)}
                    sx={{ width: "100%" }}
                    multiline
                />
            </div>
            <div>
                <QuestionTitle title="Result of Assessment" required />
                <ResultOfAssesment />
            </div>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </Stack>
    );
}

export default CompetencyDocumentTopic;