import { Button, Stack } from '@mui/material'
import React from 'react'
import MaterialItem from './MaterialItem'
import { FormikProps } from 'formik'
import { LearningMaterialItem, TrainingGroup } from 'src/dtos/Training/TrainingGroup.dto'
import TrainingGroupService from 'src/Services/Training/TrainingGroupService'
import { NameDto } from 'src/dtos/NameDto.dto'

type Props = {
  formik: FormikProps<TrainingGroup>;
  formikLearningMaterialItems: LearningMaterialItem[];
  formikString: string;
  learningDocuments: NameDto[];
  learningItems: NameDto[];
}
function MaterialCreator({formik, formikLearningMaterialItems, formikString, learningDocuments, learningItems}: Props) {

  const handleAddItem = () => {
    formik.setFieldValue(formikString, [...formikLearningMaterialItems, TrainingGroupService.GetDefaultLearningContent()])
  }

  const handleDeleteItem = (index: number) => {
    const newItems = [...formikLearningMaterialItems]
    newItems.splice(index, 1)
    formik.setFieldValue(formikString, newItems)

    if(newItems.length === 0){
      formik.setFieldValue(formikString, [])
    }
  }

  return (
    <Stack spacing={2}>
        {formikLearningMaterialItems.map((_, index) => (
          <MaterialItem
            key={index}
            formik={formik}
            formikString={`${formikString}[${index}]`}
            formikLearningContent={formikLearningMaterialItems[index]}
            handleDelete={() => handleDeleteItem(index)}
            learningDocuments={learningDocuments}
            learningItems={learningItems}
          />
        ))}
        <div style={{display:"flex", justifyContent:"center"}}>
          <Button onClick={handleAddItem}>
            Add Item
          </Button>
        </div>
        
    </Stack>
  )
}

export default MaterialCreator