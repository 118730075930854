import { Autocomplete, Box, Button, FormControlLabel, IconButton, Switch, TableCell, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ListParameters } from 'src/Services/ListParameters'
import LearningItemService from 'src/Services/Training/LearningItemService'
import { StyledTableRow } from 'src/components/Datatable/StyledTableRow'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { LearningItem, LearningItemType, LearningItemTypeString } from 'src/dtos/Training/LearningItem.dto'
import EditIcon from '@mui/icons-material/Edit'
import colorConfigs from 'src/config/colorConfigs'
import Datatable from 'src/components/Datatable/Datatable'
import LearningItemForm from '../LearningItemForm/LearningItemForm'
import { useSearchParams } from 'react-router-dom'

function LearningItems() {
    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "label", width: "15rem", sortable: true},
        {label: "Type", field: "type", width: "15rem", sortable: true},
        {label: '', field: "edit", width: "3rem", sortable: false},
    ]

    type filterOptionsType = {
        type?: LearningItemType;
        includeDisabled?: boolean;
    } 

    const [formOpen, setFormOpen] = useState(false);
    //const [learningItemId, setLearningItemId] = useState<string | null>(null)
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [filterOptions, setFilterOptions] = useState<filterOptionsType>({type: undefined, includeDisabled: false})
    const [toggleRefresh, setToggleRefresh] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30    
    }

    const fetchLearningItems = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await LearningItemService.GetList(listParams, searchTerm, filterOptions.type, filterOptions.includeDisabled);
        return data;
    }

    const setLearningItemIdParam = (id: string | null) => {
        if(id){
            searchParams.set("id", id);
            setSearchParams(searchParams)
        } else {
            searchParams.delete("id")
            setSearchParams(searchParams)
        }        
    }
    
    const renderTableRow = ({data, index}: {data: LearningItem, index: number}) => {
        const handleEdit = (e: any, id: string) => {
            e.stopPropagation();
            
            setLearningItemIdParam(id);
            setFormOpen(true);
        }

        return (
            <>
                <StyledTableRow
                    hover
                    onClick={(e) => handleEdit(e, data.id)}
                    key={`UsersRow_${index}`}
                    sx={{cursor: "pointer"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{LearningItemTypeString[data.type]}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`, paddingLeft: "0rem"}}><IconButton onClick={(e)=> handleEdit(e, data.id)}><EditIcon fontSize='small'/></IconButton></TableCell>
                </StyledTableRow>
            </>
        )
    };

    const handleFormOpen = () => {
        setFormOpen(true)
        
    }  

    const handleFormClose = () => {
        setFormOpen(false);
        setLearningItemIdParam(null);
        setToggleRefresh((prevState) => !prevState)
    }

    function FilterMenu(props:{handleClose: () => void}){
        const [type, setType] = useState(filterOptions.type);
        const typeNames: LearningItemType[] = LearningItemTypeString.map((_, index) => {return index})
        const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);

        const handleIncludeDisabledChange = () => {
            setIncludeDisabled((prevVal) => !prevVal)
        }

        const handleOkClick = () => {
            props.handleClose()
            setFilterOptions({type: type, includeDisabled: includeDisabled})
        }
        

        return (

            <Box style={{padding:"1rem", display:"block"}}>
                <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
                <Autocomplete
                    disablePortal={false}
                    id="type"
                    options={typeNames}
                    size="small"
                    sx={{width: "15rem", paddingBottom: "0.5rem"}}
                    value={type ?? null}
                    getOptionLabel={(option) => option !== undefined ? LearningItemTypeString[option] : ""}
                    onChange={(e, value) => {
                        if(value !== undefined && value !== null){
                            setType(value)
                        } else {
                            setType(undefined)
                        }
                    }}
                    renderInput={(params) => 
                        <TextField {...params} label="Type" />
                    }
                />
                <FormControlLabel 
                    control={
                        <Switch 
                            checked={includeDisabled}
                            onChange={handleIncludeDisabledChange}  
                        />
                    }
                    label={"Include Inactive?"}
                    sx={{minWidth: '100%'}}
                />
                <Button onClick={handleOkClick}>Filter</Button>
            </Box>
        )

    }

    
    
    return (
        <>
            <Datatable
                tableHeaders={tableHeaders}
                initialListParams={initialListParams}
                tableTitle='Learning Items'
                buttonLabel='Create New Learning Item'
                buttonOnClick={handleFormOpen}
                showSearch={true}
                renderRow={renderTableRow}
                callService={fetchLearningItems}
                setMessageItem={setMessageItem}
                FilterMenu={FilterMenu}
                toggleRefreshFlag={toggleRefresh}
            />
            <LearningItemForm
                formOpen={formOpen}
                handleFormClose={handleFormClose}
                setMessageItem={setMessageItem}
            />
            <ErrorHandlingSnackbar messageItem={messageItem} />
        </>
    )
}

export default LearningItems