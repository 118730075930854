import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'
import ReactDOM from 'react-dom';

type Props = {
    title: string;
    contentText: string;
    acceptText: string;
    declineText?: string;
    open: boolean;
    onClose: (result: boolean | null) => void
}
function AlertDialog({title, contentText, acceptText, declineText, open, onClose}: Props) {
    const handleClose = () => {
        onClose(false)
    }

    const handleAccept = () => {
        onClose(true)
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {declineText ? declineText : "Cancel"}
                    </Button>
                    <Button variant="contained" onClick={handleAccept}>
                        {acceptText}
                    </Button>
                </DialogActions>
            </Dialog>
        
        </>
    )
}

export default AlertDialog

type ShowDialogProps = {
    title: string;
    contentText: string;
    acceptText: string;
    declineText?: string;
}

export const showDialog = ({title, contentText, acceptText, declineText} : ShowDialogProps) => {
    return new Promise<boolean | null>((resolve) => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        const handleClose = (result: boolean | null) => {
            ReactDOM.unmountComponentAtNode(div);
            div.remove();
            resolve(result);
        };

        ReactDOM.render(
            <AlertDialog
                title={title}
                contentText={contentText}
                acceptText={acceptText}
                declineText={declineText}
                open={true}
                onClose={handleClose}
            />,
            div
        );
    });
}