import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, CircularProgressProps, Divider, Stack, Typography } from '@mui/material'
import { FormikProps } from 'formik';
import React, { ReactNode, useRef, useState } from 'react'
import { InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';

type Props = {
    children: ReactNode;
    sectionTitle: string;
    sectionSubtitle?: string;
    numberOfQuestions?: number; //used for calculating progress
    checkPrevSectionPassed: () => boolean;
    checkPassedQuestions?: () => number;
    formik?: FormikProps<InspectionChecklistResponseDto>;
    sectionIndex?: number
}

function Section({formik, sectionIndex, children, sectionTitle, sectionSubtitle, numberOfQuestions, checkPrevSectionPassed, checkPassedQuestions}: Props) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [passedQuestions] = useState<number>(checkPassedQuestions ? checkPassedQuestions(): 0)
  //TODO: determin if above passedQuestions is required (removed setPassedQuestions)
  const accordionRef = useRef<HTMLDivElement | null>(null);
 
  
  //TODO:Remove this if it is not needed
  // const updatePassedQuestions = () => {
  //   checkPassedQuestions && setPassedQuestions(checkPassedQuestions())
  // }
  
  const handleChange =
    (section: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if(true){
        setExpanded(isExpanded ? section : false);
      } else {
        alert("Previous section is incomplete")
      }
      
      if(section !== expanded){
        accordionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
      }
     
    };

  function QuestionProgress(
    props: CircularProgressProps & { value: number},
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${passedQuestions}/${numberOfQuestions}`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Accordion  ref={accordionRef} expanded={expanded === sectionTitle} onChange={handleChange(sectionTitle)}>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls={`${sectionTitle}-content`}
          id={`${sectionTitle}-header`}
        >
          <Stack direction="row" sx={{ width: "95%", display: "flex", justifyContent: "space-between"}}>
            <Typography variant='h5'>{sectionTitle}</Typography>
            {numberOfQuestions && passedQuestions &&
              <QuestionProgress 
                value={(passedQuestions/numberOfQuestions)*100}  
              />
            }          
          </Stack>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails>
          <Typography variant='body1' sx={{paddingLeft:"1rem"}}>{sectionSubtitle}</Typography>
          <Stack spacing={2}>
            {children}

          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Section