import { Box, Button, IconButton, Stack, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ListParameters, SortDirection } from 'src/Services/ListParameters';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import colorConfigs from 'src/config/colorConfigs';
import EditIcon from '@mui/icons-material/Edit'
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto';
import { dateOptions } from 'src/config/constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Datatable from 'src/components/Datatable/Datatable';
import dayjs from 'dayjs';
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto';
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow';

function TrainingSessions() {
    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "label", width: "25rem", sortable: true},
        {label: "Date", field: "date", width: "20rem", sortable: true},
        {label: "Capacity", field: "trainees/Maxtrainees", width:"20rem", sortable: false},
        {label: '', field: "edit", width: "3rem", sortable: false},
    ]
    
    type filterOptionsType = {
        startDate?: Date;
        endDate?: Date;
        includeDisabled?: boolean;
    }
    
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [searchParams, setSearchParams] = useSearchParams()
    const deserializedFilterOptions = searchParams.get("filterOptions") ? JSON.parse(decodeURIComponent(searchParams.get("filterOptions") as string)) : null
    const defaultOptions = { startDate: (dayjs(new Date())).startOf('day').toDate(), includeDisabled: false}
    const [filterOptions, setFilterOptions] = useState<filterOptionsType>(deserializedFilterOptions ?? defaultOptions)
    const navigate = useNavigate();

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30,
        sortBy: "date",
        sortDirection: SortDirection.Ascending
    }

    const fetchTrainingSessions = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await TrainingSessionService.GetList(listParams, searchTerm, filterOptions.startDate, filterOptions.endDate, filterOptions.includeDisabled);
        return data;
    }

    const renderTableRow = ({data, index}: {data: TrainingSession, index: number}) => {
        return (
            <>
                <ClickableTableRow
                    hover
                    href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
                    key={`UsersRow_${index}`}
                    sx={{cursor: "pointer", textDecoration: "none"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.date && new Date(data.date).toLocaleDateString(undefined, dateOptions)}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.maxTrainees ? `${data.sessionAttendees ? data.sessionAttendees.filter(sa => sa.type === AttendeeType.Trainee).length : "0"}/${data.maxTrainees}` : "No Capacity"}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
                </ClickableTableRow>
            </>
        )
    }

    const handleFormOpen = () => {
        navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
    }

    function FilterMenu(props:{handleClose: () => void}){
        const [includeDisabled] = useState(filterOptions.includeDisabled);
        const [startDate, setStartDate] = useState(filterOptions.startDate);
        const [endDate, setEndDate] = useState(filterOptions.endDate)

        // const handleIncludeDisabledChange = () => {
        //     setIncludeDisabled((prevVal) => !prevVal)
        // }

        const handleOkClick = () => {
            props.handleClose()
            const newFilterOptions = {
                startDate: startDate,
                endDate: endDate,
                includeDisabled
            }
            setFilterOptions(newFilterOptions)

            //update search params
            if(JSON.stringify(newFilterOptions) === JSON.stringify(defaultOptions)){
                searchParams.delete("filterOptions")
            } else {
                const serializedFilterOptions = encodeURIComponent(JSON.stringify(newFilterOptions))
                searchParams.set("filterOptions", serializedFilterOptions)
            }
            setSearchParams(searchParams)
        }

        return (
            <Box style={{padding:"1rem", display:"block"}}>
                <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
                <Stack spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            name="start Date"
                            format='DD/MM/YYYY'
                            value={startDate ? dayjs(startDate) : null}
                            onChange={(value) => {
                                if (value) {
                                    // Set time to midnight
                                    const startOfDay = value.startOf('day').toDate();
                                    setStartDate(startOfDay);
                                  } else {
                                    setStartDate(undefined);
                                  }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    label: "Start Date"
                                }
                            }}
                            sx={{width:"10rem"}}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            name="end Date"
                            format='DD/MM/YYYY'
                            value={endDate ? dayjs(endDate) : null}
                            onChange={(value) => {
                                if (value) {
                                    // Set time to 11:59 PM
                                    const endOfDay = value.endOf('day').toDate();
                                    setEndDate(endOfDay);
                                  } else {
                                    setEndDate(undefined);
                                  }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    label: "End Date"
                                }
                            }}
                            sx={{width:"10rem"}}
                        />
                    </LocalizationProvider>
                </Stack>
                {/* <FormControlLabel 
                    control={
                        <Switch 
                        checked={includeDisabled}
                        onChange={handleIncludeDisabledChange}  
                        />
                    }
                    label={"Include Inactive?"}
                    sx={{minWidth: '100%'}}
                /> */}
                <Button onClick={handleOkClick}>Filter</Button>
            </Box>
        )

    }

    return (
        <>
            <Datatable
                tableHeaders={tableHeaders}
                initialListParams={initialListParams}
                tableTitle='Training Session'
                buttonLabel='Create new Session'
                buttonOnClick={handleFormOpen}
                showSearch={true}
                renderRow={renderTableRow}
                callService={fetchTrainingSessions}
                setMessageItem={setMessageItem}
                FilterMenu={FilterMenu}
            />
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingSessions