import { Modal, Box, Stack, Typography, IconButton, Autocomplete, TextField, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from "@tanstack/react-query";
import TrainingGroupService from "src/Services/Training/TrainingGroupService";
import { MessageItem } from "src/components/errorHandlingSnackbar";
import LearningDocumentService from "src/Services/Training/LearningDocuments/LearningDocumentService";
import { LearningDocument, LearningDocumentType } from "src/dtos/Training/LearningDocument.dto";
import { NameDto } from "src/dtos/NameDto.dto";
import { LoadingButton } from "@mui/lab";

type Props = {
    open: boolean,
    OnClose: () => void,
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    handleAddDocuments: (learningDocs: LearningDocument[]) => void;
}

function AddDocumentForm({open, OnClose, setMessageItem, handleAddDocuments}: Props) {
    const enum AddType {
        trainingGroup,
        trainingDocument
    }
    const [addType, setAddType] = useState<AddType | string>(AddType.trainingGroup)
    const [trainingGroup, setTrainingGroup] = useState<NameDto | null>(null)
    const [trainingDocument, setTrainingDocument] = useState<NameDto | null>(null)
    
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'max-content',
        bgcolor: 'background.paper',
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        paddingBottom: 2,
        paddingTop: 2,
    };

    //#region queries

    const trainingGroupsQuery = useQuery({
        queryKey: ["trainingGroupNames"],
        queryFn: async () => {
            return await TrainingGroupService.GetNames();
        }
    })

    const trainingDocumentsQuery = useQuery({
        queryKey: ["trainingDocumentNames"],
        queryFn: async () => {
            const documentTypes = [LearningDocumentType.trainingDocumentNTM, LearningDocumentType.trainingDocumentRTM, LearningDocumentType.orientation]
            return await LearningDocumentService.GetNames(documentTypes)
        }
    })

    const trainingGroupTrainingDocsQuery = useQuery({
        queryKey: ["trainingGroupTrainingDocuments", trainingGroup?.id],
        queryFn: async () => {
            if(trainingGroup){
                return await TrainingGroupService.GetTrainingDocuments(trainingGroup.id)
            }
            return null
        }
    })

    const trainingDocumentQuery = useQuery({
        queryKey: ["learningDocument", trainingDocument?.id],
        queryFn: async () => {
            if(trainingDocument){
                return await LearningDocumentService.Get(trainingDocument.id)
            }
            return null
        }
    })

    //Handle query errors
    useEffect(() => {
        if(trainingGroupsQuery.isError){
            setMessageItem({error: trainingGroupsQuery.error})
        }
        if(trainingDocumentsQuery.isError){
            setMessageItem({error: trainingDocumentsQuery.error})
        }
        if(trainingGroupTrainingDocsQuery.isError){
            setMessageItem({error: trainingGroupTrainingDocsQuery.error})
        }
        if(trainingDocumentQuery.isError){
            setMessageItem({error: trainingDocumentQuery.error})
        }
    }, [setMessageItem, trainingDocumentQuery.error, trainingDocumentQuery.isError, trainingDocumentsQuery.error, trainingDocumentsQuery.isError, trainingGroupTrainingDocsQuery.error, trainingGroupTrainingDocsQuery.isError, trainingGroupsQuery.error, trainingGroupsQuery.isError])

    //#endregion

    const handleAddClick = () => {
        if(trainingDocument){
            handleAddDocuments(trainingDocumentQuery.data ? [trainingDocumentQuery.data] : [])
        }
        if(trainingGroup) {
            handleAddDocuments(trainingGroupTrainingDocsQuery.data ?? [])
        }
    }

    const handleClose = () => {
        OnClose()
        //clear form
        setAddType(AddType.trainingGroup)
        setTrainingDocument(null)
        setTrainingGroup(null)
    }
    const loading = trainingGroupTrainingDocsQuery.isFetching || trainingGroupTrainingDocsQuery.isLoading || 
                    trainingGroupsQuery.isFetching || trainingGroupsQuery.isLoading ||
                    trainingDocumentsQuery.isFetching || trainingDocumentsQuery.isLoading ||
                    trainingDocumentQuery.isFetching || trainingDocumentQuery.isLoading

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Stack direction={"row"} sx={{display:"flex", justifyContent:"space-between"}}>
                    <Typography variant='h5'>Add Training Documents</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Stack>
                <Stack spacing={1}>
                    <div>
                        <QuestionTitle title="Add From?" infoText='Training Group - Adds all Training Documents from the selected Training Group. Individual - Choose an individual Training Document to Add'/>
                        <Select
                            id="addType"
                            name="addType"
                            value={addType}
                            onChange={(e) => {
                                setAddType(e.target.value)
                                setTrainingGroup(null)
                                setTrainingDocument(null)
                            }}
                            size="small"
                            sx={{width:"25rem"}}
                        >
                            <MenuItem value={AddType.trainingGroup}>Training Group</MenuItem>
                            <MenuItem value={AddType.trainingDocument}>Individual Training Document</MenuItem>
                        </Select>
                    </div>
                    {addType === AddType.trainingGroup && (
                        <div>
                            <QuestionTitle title="Training Group" infoText='Add all Training Documents from the selected training group. This includes the documents of type "Training Document NTM", "Training Document RTM" and "Orientation"'/>
                            <Autocomplete
                                disablePortal
                                id="trainingGroup"
                                options={trainingGroupsQuery.data ?? []}
                                value={trainingGroup}
                                onChange={(e, value) => {
                                    setTrainingGroup(value)
                                    trainingGroupTrainingDocsQuery.refetch()
                                }}
                                size={"small"}
                                sx={{width:"25rem"}}
                                renderInput={(params) => 
                                    <TextField {...params}/>
                                }
                            />
                        </div>
                    )}
                    {addType === AddType.trainingDocument && (
                        <div>
                            <QuestionTitle title="Training Document" infoText="Add an individual Training Document"/>
                            <Autocomplete
                                disablePortal
                                id="trainingDocument"
                                options={trainingDocumentsQuery.data ?? []}
                                value={trainingDocument}
                                onChange={(e, value) => {
                                    setTrainingDocument(value)
                                }}
                                size={"small"}
                                sx={{width:"25rem"}}
                                renderInput={(params) => 
                                    <TextField {...params}/>
                                }
                            />
                        </div>
                    )}
                    <span style={{display:"flex", justifyContent:"end"}}>
                        <LoadingButton loading={loading} variant="contained" onClick={handleAddClick}>
                            Add
                        </LoadingButton>
                    </span>
                </Stack>

            </Box>
        </Modal>
    )
}

export default AddDocumentForm