import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom';
import { StyledTableRow } from './StyledTableRow';
import { TableRowProps } from '@mui/material';

interface StyledTableRowProps extends TableRowProps {
    'href'?: string;
    children?: ReactNode;
  }
const ClickableTableRow: React.FC<StyledTableRowProps> = ({ href, children, ...props }) => {
    const navigate = useNavigate();
  
    const handleRowClick = (event: React.MouseEvent) => {
      if (!href) return;
  
      // Check for middle-click (event.button === 1) or Ctrl/Cmd + Click (event.ctrlKey || event.metaKey)
      if (event.button === 1 || event.ctrlKey || event.metaKey) {
        window.open(href, '_blank'); // Open in a new tab
      } else {
        navigate(href); // Standard left-click navigation
      }
    };
  
    return (
      <StyledTableRow
        onClick={handleRowClick}
        onMouseDown={(e) => {
          if (e.button === 1){
            window.open(href, '_blank');
          } 
        }}
        {...props}
      >
        {children}
      </StyledTableRow>
    );
  };
    
export default ClickableTableRow;