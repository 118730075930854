import { List } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react'
import { Droppable } from 'react-beautiful-dnd';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import { FocusElement } from 'src/Utils/helperFunc';
import { LearningDocumentSubpoint, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto';
import SubpointListItem from './SubpointListItem';


type Props = {
    formik: FormikProps<LearningDocumentVersion>;
    formikString: string;
    formikSubpointsValue: LearningDocumentSubpoint[];
    pointSortOrder: number;
    DeleteSubPoint: (subpointIndex: number) => void;
    HandleAddPointBelow: () => void; //used to add point below all subpoints
}

function SubpointsCreator({formik, formikString, formikSubpointsValue, pointSortOrder, DeleteSubPoint, HandleAddPointBelow}: Props) {
    
    const handleAddSubpointBelow = (index: number) => {
        const insertIndex = index + 1;
        let newSubpoints = [
          ...formikSubpointsValue.slice(0, insertIndex),
          LearningDocumentVersionService.GetDefaultSubpointValue(),
          ...formikSubpointsValue.slice(insertIndex)
        ];
        newSubpoints = newSubpoints.map((newSubpoint, subpointIndex) => {
          return {...newSubpoint, sortOrder: subpointIndex}
        })
        formik.setFieldValue(`${formikString}`, newSubpoints);
        
        //Move focus to new subpoints label
        setTimeout(() => {
          FocusElement(1);
        }, 100); 

    }


      
  
    return (
        <Droppable droppableId={`point_${pointSortOrder}`} type={`droppableSubpoint`}>
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
                {formikSubpointsValue.map((subpoint, index) => (
                  <SubpointListItem 
                    key={index}
                    formik={formik}
                    formikString={`${formikString}[${index}]`}
                    formikSubpointValue={formikSubpointsValue[index]}
                    pointSortOrder={pointSortOrder}
                    DeleteSubPoint={DeleteSubPoint}
                    handleAddSubpointBelow={() => handleAddSubpointBelow(index)}
                    HandleAddPointBelow={HandleAddPointBelow}
                    index={index}

                  />
                ))}
                {provided.placeholder}
            </List>
          )
  
        }
  
      </Droppable>
      )
}

export default SubpointsCreator