import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { Stack, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, Typography } from '@mui/material'
import { renderTimeViewClock } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import React from 'react'
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService'
import { Expiry, ExpiryType } from 'src/dtos/Checklists/Expiry.dto'
import { LearningDocument } from 'src/dtos/Training/LearningDocument.dto'
import { InspectionChecklist } from 'src/dtos/Checklists/MaintenanceChecklist.dto'
import { LearningItem } from 'src/dtos/Training/LearningItem.dto'

type Props = {
    formik: FormikProps<InspectionChecklist> | FormikProps<LearningDocument> | FormikProps<LearningItem>;
    width?: string;
}

function ExpirySelector({formik, width}: Props) {

    const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      const monthsOfYear = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const handleClearExpiryVals = (typeChange?: Boolean, everyUnit?: string) => {
        //Type changes reset all value other than type
        //Every Unit day then clear everyMonth, everyDay
        //Every Unit week then clear everyMonth
        //Every Unit fortnight then clear everyMonth
        //Every Unit month then clear everyMonth
        //Every Unit year no clear
        const expiryDefaultVals:Expiry = InspectionChecklistService.GetDefaultValues().expiry; 
        if(typeChange === true){
            //reset all values other than type
            formik.setFieldValue("expiry.afterUnits", expiryDefaultVals.afterUnits)
            formik.setFieldValue("expiry.afterValue", expiryDefaultVals.afterValue)
            formik.setFieldValue("expiry.everyMonthValue", expiryDefaultVals.everyMonthValue)
            formik.setFieldValue("expiry.everyTimeValue", expiryDefaultVals.everyTimeValue)
            formik.setFieldValue("expiry.everyUnit", expiryDefaultVals.everyUnit)
            formik.setFieldValue("expiry.everyWeekdayValue", expiryDefaultVals.everyWeekdayValue)
            console.log(formik.values.expiry)
        } else {
            if(everyUnit === "Day"){
                formik.setFieldValue("expiry.everyMonthValue", expiryDefaultVals.everyMonthValue)
                formik.setFieldValue("expiry.everyWeekdayValue", expiryDefaultVals.everyWeekdayValue)
            } else if(everyUnit === "Week" || everyUnit === "Fortnight" || everyUnit === "Month"){
                formik.setFieldValue("expiry.everyMonthValue", expiryDefaultVals.everyMonthValue)
            }
        }
    }

    const handleExpiryFieldChange = (event: any, typeChange?: Boolean, everyUnit?: string) => {
        handleClearExpiryVals(typeChange, everyUnit)
        formik.handleChange(event)
    }

    
    return (
        <div style={{marginTop:"8px", width: width ?? "50rem", display:"flex", gap:"0.5rem", flexDirection:"row", flexWrap: "wrap"}}>
            <Stack>
                <FormControl size="small">
                    <InputLabel>Type</InputLabel>
                    <Select
                        id="expiry.type"
                        name="expiry.type"
                        value={formik.values.expiry.type}
                        label="Type"
                        onChange={(event) => handleExpiryFieldChange(event, true)}
                        onBlur={formik.handleBlur}
                        autoWidth
                        sx={{minWidth:"5rem"}}
                        error={formik.touched.expiry?.type && Boolean(formik.errors.expiry?.type)}
                    >
                    <MenuItem value={ExpiryType.After}>After</MenuItem>
                    <MenuItem value={ExpiryType.Every}>Every</MenuItem>
                    <MenuItem value={ExpiryType.Never}>Never</MenuItem>

                    </Select>
                    <FormHelperText sx={{marginLeft:"14px"}} error ={formik.touched.expiry?.type && Boolean(formik.errors.expiry?.type)}>
                        {formik.touched.expiry?.type && formik.errors.expiry?.type}
                    </FormHelperText>
                </FormControl>
                
            </Stack>
            
            {formik.values.expiry.type === ExpiryType.After ? (
                <>
                <TextField
                    id="expiry.afterValue"
                    name="expiry.afterValue"
                    value={formik.values.expiry.afterValue ?? ""}
                    size="small"
                    type="number"
                    label="Qty"
                    sx={{ width: "5rem" }}
                    error={
                    formik.touched.expiry?.afterValue &&
                    Boolean(formik.errors.expiry?.afterValue)
                    }
                    helperText={formik.touched.expiry?.afterValue && formik.errors.expiry?.afterValue
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    
                />
                <FormControl size="small">
                    <InputLabel>Units</InputLabel>
                    <Select
                        id="expiry.afterUnits"
                        name="expiry.afterUnits"
                        label="Units"
                        value={formik.values.expiry.afterUnits}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoWidth
                        sx={{minWidth:"5rem"}}
                        error={
                            formik.touched.expiry?.afterUnits &&
                            Boolean(formik.errors.expiry?.afterUnits)
                        }
                        
                        >
                        <MenuItem value={"Days"}>Days</MenuItem>
                        <MenuItem value={"Weeks"}>Weeks</MenuItem>
                        <MenuItem value={"Months"}>Months</MenuItem>
                        <MenuItem value={"Years"}>Years</MenuItem>
                    </Select>
                </FormControl>
                </>
            ) : formik.values.expiry.type === ExpiryType.Every && (
                <>
                <FormControl size="small">
                <InputLabel>Unit</InputLabel>
                <Select
                    id="expiry.everyUnit"
                    name="expiry.everyUnit"
                    label="Unit"
                    value={formik.values.expiry.everyUnit}
                    onChange={(event) => handleExpiryFieldChange(event, false, event.target.value)}
                    onBlur={formik.handleBlur}
                    autoWidth
                    sx={{minWidth:"5rem"}}
                    error={
                    formik.touched.expiry?.everyUnit &&
                    Boolean(formik.errors.expiry?.everyUnit)
                    }
                    
                >
                    <MenuItem value={"Day"}>Day</MenuItem>
                    <MenuItem value={"Week"}>Week</MenuItem>
                    <MenuItem value={"Fortnight"}>Fortnight</MenuItem>
                    <MenuItem value={"Month"}>Month</MenuItem>
                    <MenuItem value={"Year"}>Year</MenuItem>
                </Select>
                </FormControl>
                
                {formik.values.expiry.everyUnit === "Year" && (
                    <>
                    <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        In
                    </Typography>
                    <FormControl size="small">
                        <InputLabel>Month</InputLabel>
                        <Select
                            id="expiry.everyMonthValue"
                            name="expiry.everyMonthValue"
                            label="Month"
                            value={formik.values.expiry.everyMonthValue}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoWidth
                            sx={{minWidth:"5.5rem"}}
                            error={
                                formik.touched.expiry?.everyMonthValue &&
                                Boolean(formik.errors.expiry?.everyMonthValue)
                            }
                            
                            >
                            {monthsOfYear.map((month) => (
                                <MenuItem key={month} value={month}>
                                {month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    
                    </>
                )}
                {formik.values.expiry.everyUnit !== "Day" && formik.values.expiry.everyUnit !== "" && (
                    <>
                    <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                    >{`${
                        formik.values.expiry.everyUnit !== "Week"
                        ? "On the first"
                        : "On"
                    }`}</Typography>
                    <FormControl size="small">
                        <InputLabel>Day</InputLabel>
                        <Select
                        id="expiry.everyWeekdayValue"
                        name="expiry.everyWeekdayValue"
                        label="Day"
                        value={formik.values.expiry.everyWeekdayValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoWidth
                        sx={{minWidth:"5rem"}}
                        error={
                            formik.touched.expiry?.everyWeekdayValue &&
                            Boolean(formik.errors.expiry?.everyWeekdayValue)
                        }
                        >
                        {daysOfWeek.map((day) => (
                            <MenuItem key={day} value={day}>
                            {day}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    
                    </>
                )}
                {formik.values.expiry.everyUnit !== "" && (
                    <>
                        <Typography sx={{ display: "flex", alignItems: "center" }}>
                        At
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Time"
                            value={
                            dayjs(
                                formik.values.expiry.everyTimeValue,
                                "HH:mm"
                            ) ?? null
                            }
                            onChange={(val: any) =>
                            formik.setFieldValue(
                                "expiry.everyTimeValue",
                                val?.format("HH:mm")
                            )
                            }
                            viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                            }}
                            closeOnSelect={false}
                            sx={{ width: "9rem" }}
                            slotProps={{ textField: { size: "small" } }}
                        />
                        </LocalizationProvider>
                    </>
                )}
                </>
            )}
        </div>
    )
}

export default ExpirySelector