import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react'

type Props = {
    infoText: string;
}

function InfoButton({infoText}: Props) {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(true);
    }
    const toggleClose = () => {
        setOpen(false);
    }

  return (
    <ClickAwayListener onClickAway={toggleClose}>
        <Tooltip open={open} title={<h3>{infoText}</h3>}>
            <IconButton onClick={toggleOpen} onMouseEnter={toggleOpen} onMouseLeave={toggleClose} size='small' sx={{padding: '0', marginLeft: '5px', marginBottom: '5px'}}>
            <InfoOutlinedIcon sx={{color: 'blue'}} fontSize='inherit'/>
            </IconButton>                      
        </Tooltip>
    </ClickAwayListener>
  )
}

export default InfoButton