import React, { useEffect, useState } from 'react'
import TrainingGroupsMatrix from './Components/TrainingGroupsMatrix'
import { Autocomplete, Paper, Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import DepartmentService from 'src/Services/Resources/DepartmentService'
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { NameDto } from 'src/dtos/NameDto.dto'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'

function TrainingGroupsTrainer() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const [trainingGroupParents, setTrainingGroupParents] = useState<NameDto[]>([])
    const [teamMembers, setTeamMembers] = useState<NameDto[]>([])
    const [tgDepartments, setTgDepartments] = useState<NameDto[]>([])
    const [tmDepartments, setTmDepartments] = useState<NameDto[]>([])

    //#region queries
    const departmentNamesQuery = useQuery({
        queryKey: ["departmentNames"],
        queryFn: async () => {
            return await DepartmentService.GetNames();
        }
    })

    const trainingGroupParentNamesQuery = useQuery({
        queryKey: ["trainingGroupParentNames", tgDepartments],
        queryFn: async () => {
            return await TrainingGroupParentService.GetNames(tgDepartments)
        }
    })

    const teamMemberNamesQuery = useQuery({
        queryKey: ["teamMemberNames", tmDepartments],
        queryFn: async () => {
            return await TeamMemberService.GetNames(tmDepartments, [], true)
        }
    })

    //Handle Query errors
    useEffect(() => {
        if(departmentNamesQuery.isError){
            setMessageItem({error: departmentNamesQuery.error})
        }
        if(trainingGroupParentNamesQuery.isError){
            setMessageItem({error: trainingGroupParentNamesQuery.error})
        }
        if(teamMemberNamesQuery.isError){
            setMessageItem({error: teamMemberNamesQuery.error})
        }
    }, [departmentNamesQuery.error, departmentNamesQuery.isError, teamMemberNamesQuery.error, teamMemberNamesQuery.isError, trainingGroupParentNamesQuery.error, trainingGroupParentNamesQuery.isError])

    //#endregion
    return (
    <>
        <Paper sx={{padding:"1rem"}}>
            <Typography variant='h5'>Trainer & Assessor Authorised Training Groups</Typography>
            <Typography>Team members assigned to these Training Groups will be able to conduct Competency Assessments and train Training Sessions for the respective Training Group.</Typography>
            <Stack direction={"row"} spacing={2} sx={{paddingBottom:"1rem", paddingTop:"1rem"}}>
                <Stack spacing={2}>
                    <Autocomplete
                        id="tmDepartments"
                        options={departmentNamesQuery.data ?? []}
                        multiple
                        value={tmDepartments}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.label ? option.label: ""}
                        onChange={(e, value) => {
                            setTmDepartments(value) 
                        }}
                        size="small"
                        sx={{ width: "20rem"}}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                label="Team Member Department"
                            />
                        }
                    />
                    <Autocomplete
                        id="teamMembers"
                        options={teamMemberNamesQuery.data ?? []}
                        multiple
                        value={teamMembers}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.label ? option.label: ""}
                        onChange={(e, value) => {
                            setTeamMembers(value) 
                        }}
                        size="small"
                        sx={{width:"20rem"}}
                        renderInput={(params) => 
                            <TextField
                                {...params}
                                label="Team Members"
                            />
                        }   
                    />
                </Stack>
                <Stack spacing={2}>
                    <Autocomplete
                        id="tgDepartments"
                        options={departmentNamesQuery.data ?? []}
                        multiple
                        value={tgDepartments}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.label ? option.label: ""}
                        onChange={(e, value) => {
                            setTgDepartments(value) 
                        }}
                        size="small"
                        sx={{ width: "20rem"}}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                label="Training Group Department"
                            />
                        }
                    />
                    <Autocomplete
                        id="trainingGroup"
                        options={trainingGroupParentNamesQuery.data ?? []}
                        multiple
                        value={trainingGroupParents}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.label ? option.label: ""}
                        onChange={(e, value) => {
                            setTrainingGroupParents(value) 
                        }}
                        size="small"
                        sx={{width:"20rem"}}
                        renderInput={(params) => 
                            <TextField
                                {...params}
                                label="Training Group"
                            />
                        }   
                    />
                </Stack>
            </Stack>
            <TrainingGroupsMatrix
                teamMembersParam={teamMembers}
                parentTrainingGroupsParam={trainingGroupParents}
                tgDepartmentsParam={tgDepartments}
                tmDepartmentsParam={tmDepartments}
                setMessageItem={setMessageItem}
            />

        </Paper>
        <ErrorHandlingSnackbar messageItem={messageItem} />

    </>
  )
}

export default TrainingGroupsTrainer