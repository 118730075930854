import React from "react"
import { Typography, TypographyVariant } from "@mui/material";
import colorConfigs from "src/config/colorConfigs";


function ShowDifferences({originalContent, newContent, variant = "body1"}:{originalContent: string, newContent: string, variant?: TypographyVariant}){
    const originalContentWords: string[] = originalContent.split(" ");
    const newContentWords: string[] = newContent.split(" ");
    const trackedChanges: JSX.Element[] = [];

    function countOccurances(arr: string[], target: string): number {
        return arr.reduce((count, current) => count + (current === target ? 1 : 0), 0);
    }

    let originalWordsIndex = 0;
    let currentWordsIndex = 0;
    while (originalWordsIndex < originalContentWords.length && currentWordsIndex < newContentWords.length){

        if(originalContentWords[originalWordsIndex] === newContentWords[currentWordsIndex]){
            //Word remains the same
            trackedChanges.push(<span key={trackedChanges.length}>{newContentWords[currentWordsIndex] + " "}</span>);
            originalWordsIndex++;
            currentWordsIndex++;
        } else{
            //Word is different
            const originalWordOccurances = countOccurances(originalContentWords, originalContentWords[originalWordsIndex]);
            const currentWordOccurances = countOccurances(newContentWords, newContentWords[currentWordsIndex])
            if(currentWordOccurances > originalWordOccurances){
                //word added  but could be a second occurance
                trackedChanges.push(<span key={trackedChanges.length} style={{color: colorConfigs.trackChanges.added}}>{newContentWords[currentWordsIndex] + " "}</span>);
                currentWordsIndex++;
            } else {
                //word has been removed
                trackedChanges.push(<span key={trackedChanges.length} style={{color: colorConfigs.trackChanges.removed, textDecoration: "line-through"}}>{originalContentWords[originalWordsIndex] + " "}</span>);
                originalWordsIndex++;                        
            }
        }

        //reached the end of original sentence
        if(originalWordsIndex === originalContentWords.length){
            while(currentWordsIndex < newContentWords.length){
                trackedChanges.push(<span key={trackedChanges.length} style={{color: colorConfigs.trackChanges.added}}>{newContentWords[currentWordsIndex] + " "}</span>);
                currentWordsIndex++;
            }
        }

        //reached the end of the current sentence
        if(currentWordsIndex === newContentWords.length){
            while(originalWordsIndex < originalContentWords.length){
                trackedChanges.push(<span key={trackedChanges.length} style={{color: colorConfigs.trackChanges.removed, textDecoration: "line-through"}}>{originalContentWords[originalWordsIndex] + " "}</span>);
                originalWordsIndex++;
            }
        }
    }
    return <Typography variant={variant}>{trackedChanges}</Typography>
}


export default ShowDifferences;