import { LoadingButton } from '@mui/lab'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import TrainingSessionService from 'src/Services/Training/TrainingSessionService'
import { guidRegex } from 'src/Utils/helperFunc'
import { showDialog } from 'src/components/AlertDialog/AlertDialog'
import { MessageItem } from 'src/components/errorHandlingSnackbar'

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    id?: string;
    trainingSessionName: string;
}
function DeleteSessionButton({setMessageItem, id, trainingSessionName}: Props) {
    const navigate = useNavigate();

    const deleteMutation = useMutation({
        mutationFn: (sessionId: string) => {
            return TrainingSessionService.DeleteSession(sessionId)
        },
        onSuccess: () => {
            setMessageItem({successMessage:"Training Session Deleted"})
            console.log(`Training Session with ID: '${id}' deleted`)
            
            navigate({ pathname: window.location.pathname.replace(new RegExp(`/${id}$`), '') })
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleDeletePress = async () => {
        if(id && !guidRegex.test(id)){
            setMessageItem({errorMessage: "Session Id in URL must be a guid"})
            return
        }
        const deleteResponse = await showDialog({
            title: `Delete Training Session`,
            contentText: `Are you sure you want to delete the Training Session '${trainingSessionName}', this action is irreversible.`,
            acceptText: "Delete Permanently",
            declineText: "Cancel"
        })

        if(deleteResponse && id){
            deleteMutation.mutate(id)
        }
    }
    
    return (
        <>
            <LoadingButton 
                variant="contained" 
                color="error" 
                loading={deleteMutation.isPending}
                onClick={handleDeletePress}
            >Delete Session</LoadingButton>
        </>
    )
}

export default DeleteSessionButton