import { Autocomplete, Box, Button, IconButton, TableCell, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { InspectionChecklistVersion } from 'src/dtos/Checklists/MaintenanceChecklist.dto';
import { TableHeader } from 'src/components/Datatable/TableHeader';
import { Status, StatusString } from 'src/dtos/Statuses';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { ListParameters, SortDirection } from 'src/Services/ListParameters';
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import InspectionChecklistVersionService from 'src/Services/Checklists/Inspections/InspectionChecklistVersionService';
import colorConfigs from 'src/config/colorConfigs';
import EditIcon from '@mui/icons-material/Edit'
import Datatable from 'src/components/Datatable/Datatable';
import { dateOptions } from 'src/config/constants';
import { useNavigate } from 'react-router-dom';
import { EmailToNameString } from 'src/Utils/helperFunc';
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow';

type Props = {
  inspectionChecklistId: string;
}
 


function ChecklistVersions({inspectionChecklistId}: Props) {
  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
  const [filterOptions, setFilterOptions] = useState<filterOptionsType>({status: undefined})
  const navigate = useNavigate();

  const tableHeaders: TableHeader[] = [
    {label: '', field: "statusIndicator", width: "10px", sortable: false},
    {label: "Name", field: "versionLabel", width: "8rem", sortable: true},
    {label: "Description", field: "versionDescription", width: "10rem", sortable: false},
    {label: "Status", field: "status", width: "8rem", sortable: true},
    {label: "Created On", field: "createdOn", width: "10rem", sortable: true},
    {label: "Modified On", field: "modifiedOn", width: "10rem", sortable: true},
    {label: "Date Published", field: "datePublished", width: "10rem", sortable: true},
    {label: "Published By", field: "publishedBy", width: "10rem", sortable: true},
    {label: '', field: "edit", width: "3rem", sortable: false}
  ]
    
  type filterOptionsType = {
    status?: Status | undefined,
  }

    
  const initialListParams: ListParameters = {
    page: 1,
    pageSize: 25,
    sortBy: "createdOn",
    sortDirection: SortDirection.Descending
  }

  const fetchChecklistVersions = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
    const data = await InspectionChecklistVersionService.GetList(inspectionChecklistId, listParams, searchTerm, filterOptions.status);
    return data;
  }

  const renderTableRow = ({data, index}: {data: InspectionChecklistVersion, index: number}) => {
    const createdOnString = data.createdOn && new Date(data.createdOn).toLocaleDateString(undefined, dateOptions);
    const modifiedOnString = data.modifiedOn && new Date(data.modifiedOn).toLocaleDateString(undefined, dateOptions)
    const publishedOnString = data.datePublished && new Date(data.datePublished).toLocaleDateString(undefined, dateOptions);
    const publishedByString = data.publishedBy && EmailToNameString(data.publishedBy);
    const statusColour = data.status === Status.Outdated ? colorConfigs.statuses.outdated : 
                        data.status === Status.Draft ? colorConfigs.statuses.draft :
                        data.status === Status.Published ? colorConfigs.statuses.published : "white";
    return (
      <>
        <ClickableTableRow
          hover
          href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
          key={`InspectionChecklistRow_${index}`}
          sx={{cursor: "pointer", textDecoration: "none"}}
        >
          <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor:statusColour}}></TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.versionLabel}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.versionDescription}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{StatusString[data.status]}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{createdOnString}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[5].width} !important`, maxWidth: `${tableHeaders[5].width} !important`}}>{modifiedOnString}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[6].width} !important`, maxWidth: `${tableHeaders[6].width} !important`}}>{publishedOnString}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[7].width} !important`, maxWidth: `${tableHeaders[7].width} !important`}}>{publishedByString}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[8].width} !important`, maxWidth: `${tableHeaders[8].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
        </ClickableTableRow>
      </>
    )
  };

    function FilterMenu(props: {handleClose: () => void}){
      const [status, setStatus] = useState(filterOptions.status);
      const statusNames: Status[] = [
        Status.Published,
        Status.Draft,
        Status.Outdated
      ]
        
      const handleOkClick = () => {
          props.handleClose()
          setFilterOptions({status: status})
      }

      return (
        <Box style={{padding: "1rem", display: "block"}}>
          <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
          <Autocomplete
            disablePortal={false}
            id="status"
            options={statusNames}
            size="small"
            sx={{ width: "15rem", paddingBottom: "0.5rem" }}
            value={status || null}
            getOptionLabel={(option) => option ? StatusString[option] : ""}
            onChange={(e, value) => {
              if(value){
                setStatus(value)
              } else {
                setStatus(undefined)
              }
            }}
            renderInput={(params) => 
            <TextField {...params} label="Status"
            />}
          />

          <Button onClick={handleOkClick}>Filter</Button>                
        </Box>
      )
    }

    const handleFormOpen = () => {
        navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
    }

    return (
    <>
      <Datatable
        tableHeaders={tableHeaders}
        initialListParams={initialListParams}
        tableTitle='Versions'
        buttonLabel='Create New Version'
        buttonOnClick={handleFormOpen}
        showSearch={true}
        renderRow={renderTableRow}
        callService={fetchChecklistVersions}
        setMessageItem={setMessageItem}
        FilterMenu={FilterMenu}
      />
      <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
   
    
  )
}

export default ChecklistVersions