import { ExpandMore } from '@mui/icons-material'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControlLabel, Switch, DialogContentText, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LearningAreaNameService from 'src/Services/Training/LearningAreaNameService'
import { guidRegex } from 'src/Utils/helperFunc'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateOptions } from 'src/config/constants'
import { LearningAreaName } from 'src/dtos/Training/TrainingGroup.dto'
import * as yup from 'yup'

const validationSchema = yup.object({
    label: yup.string().required("Name is a required field")
})

type Props = {
    open: boolean;
    handleClose: () => void;
    setMessageItem: (value: React.SetStateAction<MessageItem>) => void;
}
function AreaNamesForm({open, handleClose,setMessageItem}: Props) {

    const [searchParams, setSearchParams] = useSearchParams()
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const id = searchParams.get("areaNameId")

    function ActivateAlertDialog() {
        const handleClick = () => {
          formik.setFieldValue("enabled", !formik.values.enabled);
          saveToggleEnable();
          setOpenAlertDialog(false);
        }
        return (
          <Dialog
            open={openAlertDialog}
            onClose={()=> setOpenAlertDialog(false)}
          >
            <DialogTitle>
            {formik.values.enabled ? 'Deactivate' : 'Activate'} Area Name?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to {formik.values.enabled ? 'deactivate' : 'activate'} {formik.values.label}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAlertDialog(false)}>Cancel</Button>
              <Button onClick={handleClick}>{formik.values.enabled ? 'Deactivate' : 'Activate'}</Button>
            </DialogActions>
          </Dialog>
        )
    }   
    
    

    //#region queries

    const areaNameQuery = useQuery({
        queryKey: ["areaName", id],
        queryFn: async () => {
            if(id){
                return await LearningAreaNameService.Get(id)
            }
            return null;
        },
    })

    //Handle query errors
    useEffect(() => {
        if(areaNameQuery.isError){
            setMessageItem({error: areaNameQuery.error})
        }
    }, [areaNameQuery.error, areaNameQuery.isError, setMessageItem])

    const createMutation = useMutation({
        mutationFn: (values:LearningAreaName) => {
            return LearningAreaNameService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Area Name created successfully!"})

            //update id param
            searchParams.set("areaNameId", data.data.id)
            setSearchParams(searchParams)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values} : {id: string, values:LearningAreaName}) => {
            return LearningAreaNameService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Area Name updated successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const enableMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningAreaNameService.Enable(id);
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Area Name Activated Succesfully!"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const disableMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningAreaNameService.Disable(id);
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Area Name Disabled Succesfully!"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    //#endregion

    const save = async (values: LearningAreaName) => {
        if(id && guidRegex.test(id)){
            updateMutation.mutate({id, values})
        } else {
            createMutation.mutate(values)
        }
    }

    const saveToggleEnable = async () => {
        if(id){
            if(!formik.values.enabled){
                //enable
                enableMutation.mutate(id)
            } else {
                //disable
                disableMutation.mutate(id)
            }
        }
    }

    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit()
        } else {
            formik.validateForm();
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: areaNameQuery.data ?? LearningAreaNameService.GetDefaultValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save(values);
        }
    })

    return (
        <>
            <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>{id ? "Edit Area Name" : "New Area Name"}</DialogTitle>
                    <DialogContent>
                        <QuestionTitle title="Name"/>
                        <TextField
                            id="label"
                            name="label"
                            value={formik.values.label}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.label && Boolean(formik.errors.label)}
                            helperText={formik.touched.label && formik.errors.label}
                            variant="outlined"
                            size="small"
                            sx={{width:"20rem"}}
                        />
                        {id && (
                        <>
                            <QuestionTitle title="Area Name Status"/>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.enabled}
                                        onChange={() => setOpenAlertDialog(true)}
                                    />
                                }
                                label={formik.values.enabled ? "Active" : "Inactive"}   
                            />

                            <Accordion sx={{marginTop:"1rem", width:"20rem"}}>
                                <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                id="additional-info"
                                >
                                <Typography>Modification Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                {formik.values.createdBy && (
                                    <Typography>
                                    Created By: {formik.values.createdBy}
                                    </Typography>
                                )}
                                {formik.values.createdOn && (
                                    <Typography>
                                    Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                                    </Typography>
                                )}
                                {formik.values.modifiedBy && (
                                    <Typography>
                                    Modified By: {formik.values.modifiedBy}
                                    </Typography>
                                )}
                                {formik.values.modifiedOn && (
                                    <Typography>
                                    Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                                    </Typography>
                                )}
                                </AccordionDetails>
                                
                            </Accordion>
                        </>
                    )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>Close</Button>
                        <Button variant='contained' onClick={() => handleSaveClick()}>Save</Button>
                    </DialogActions>
            </Dialog>
            <ActivateAlertDialog/>
        </>
    )
}

export default AreaNamesForm