import { FormikProps } from 'formik'
import React, { useState } from 'react'
import { LearningItem } from 'src/dtos/Training/LearningItem.dto'
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto'
import AddAttendanceSheetForm from './AddAttendanceSheetForm'
import { Paper, Stack, Typography, Button, List, IconButton, ListItem, ListItemText, Tooltip, ListItemIcon } from '@mui/material'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import CircleIcon from '@mui/icons-material/Circle';


type Props = {
    formik: FormikProps<TrainingSession>
    formikString: string
    formikAttendanceSheets: LearningItem[]
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    editable: boolean
}

function AttendanceSheetsSelector({formik, formikString, formikAttendanceSheets, setMessageItem, editable}:Props) {
    const [formOpen, setFormOpen] = useState(false)

    function AttendanceSheetListItem({index, attendanceSheet}:{index: number, attendanceSheet: LearningItem}) {
        return (
            <ListItem
                sx={{width:"100%"}}
                secondaryAction={
                    <>
                        {editable && (
                            <Tooltip title="Remove Document">
                                <IconButton edge="end" aria-label='delete' onClick={() => handleRemoveSheet(index)}>
                                    <DeleteOutlineOutlinedIcon color='error'/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                }
            >
                <ListItemIcon sx={{marginRight:"-20px"}}>
                    <CircleIcon fontSize="inherit" sx={{fontSize: "0.6rem", color:"black"}}/>
                </ListItemIcon>
                <ListItemText
                    sx={{textWrap:"wrap"}}
                    primary={attendanceSheet.label}
                />
            </ListItem>
        )
    }

    const handleFormClose = () => {
        setFormOpen(false)
    }

    const handleAddAttendanceSheets = (attendanceSheets: LearningItem[]) => {
        const nonExistingSheets = attendanceSheets.filter(attendanceSheet => formikAttendanceSheets.findIndex(sheet => sheet.id === attendanceSheet.id) === -1)

        formik.setFieldValue(formikString, [...formikAttendanceSheets, ...nonExistingSheets])
    }

    const handleRemoveSheet = (index: number) => {
        const newSheets = formikAttendanceSheets;
        newSheets.splice(index, 1)
        formik.setFieldValue(formikString, newSheets)
    }

    return (
        <>
            <Paper sx={{padding:"1rem", width:"fit-Content"}}>
                <Stack direction="row">
                    <Typography variant='h5'>Attendance Sheets</Typography>
                    <QuestionTitle title="" infoText='Learning Items of type Attendance sheet. Have no included Training Document to complete'/>
                    {editable && (
                        <Button
                            variant='contained'
                            onClick={() => setFormOpen(true)}
                            sx={{marginLeft:"auto"}}
                        >
                            Add Attendance Sheet
                        </Button>
                    )}
                </Stack>
                <List sx={{minWidth:"30rem", width:"40rem"}}>
                    {formikAttendanceSheets.map((attendanceSheet, index) => (
                        <AttendanceSheetListItem
                            key={index}
                            index={index}
                            attendanceSheet={attendanceSheet}
                        />
                    ))}
                </List>
            </Paper>
            <AddAttendanceSheetForm
                open={formOpen}
                OnClose={handleFormClose}
                handleAddSheets={handleAddAttendanceSheets}
                setMessageItem={setMessageItem}
            />
        </>
    )
}

export default AttendanceSheetsSelector