import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { TeamMemberActionResultDto } from "src/dtos/HumanResources/TeamMember.dto";
import { CourseNameDto, EnrolTeamMembersRequestDto, UserNameDto } from "src/dtos/Training/TalentLMS.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TalentLMS";
const debug = process.env.REACT_APP_DEBUG;


export default class TalentLMSService {

    static GetDefaultUserValues() {
        const userName: UserNameDto = {
            id: "0",
            name: "",
            email: ""
        }
        return userName
    }

    static async GetCourseNames() : Promise<CourseNameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<CourseNameDto[]> = await axios.get<CourseNameDto[]>(`${apiBaseUrl}/GetCourseNames`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetUserNames() : Promise<UserNameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<UserNameDto[]> = await axios.get<UserNameDto[]>(`${apiBaseUrl}/GetUserNames`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async EnrolTeamMembers(request: EnrolTeamMembersRequestDto) : Promise<AxiosResponse<TeamMemberActionResultDto[]>> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post(`${apiBaseUrl}/EnrolTeamMembers`, request, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}