import { Paper, Stack, Typography, Button, List, ListItem, ListItemText, Tooltip, IconButton, ListItemIcon } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { LearningItem } from 'src/dtos/Training/LearningItem.dto'
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOnlineLearningForm from './AddOnlineLearningForm'
import CircleIcon from '@mui/icons-material/Circle';

type Props = {
    formik: FormikProps<TrainingSession>
    formikString: string
    formikOnlineLearnings: LearningItem[]
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    editable: boolean
}

function OnlineLearningSelector({formik, formikString, formikOnlineLearnings, setMessageItem, editable}:Props) {
    const [formOpen, setFormOpen] = useState(false)

    function OnlineLearningListItem({index, onlineLearning}: {index: number, onlineLearning: LearningItem}) {
        return (
            <ListItem
                sx={{width:"100%"}}
                secondaryAction={
                    <>
                        {editable && (
                        <Tooltip title="Remove Online Learning">
                            <IconButton edge="end" aria-label='delete' onClick={() => handleRemoveLearning(index)}>
                                <DeleteOutlineOutlinedIcon color='error'/>
                            </IconButton>
                        </Tooltip>
                        )}
                    </>
                }
            >
                <ListItemIcon sx={{marginRight:"-20px"}}>
                    <CircleIcon fontSize="inherit" sx={{fontSize: "0.6rem", color:"black"}}/>
                </ListItemIcon>
                <ListItemText
                    sx={{textWrap:"wrap"}}
                    primary={onlineLearning.label}
                />
            </ListItem>
        )
    }

    const handleFormClose = () => {
        setFormOpen(false)
    }

    const handleAddOnlineLearnings = (onlineLearnings: LearningItem[]) => {
        const nonExistingOnlineLearnings = onlineLearnings.filter(onlineLearning => formikOnlineLearnings.findIndex(learning => learning.id === onlineLearning.id) === -1)

        formik.setFieldValue(formikString, [...formikOnlineLearnings, ...nonExistingOnlineLearnings])
    }

    const handleRemoveLearning = (index: number) => {
        const newLearnings = formikOnlineLearnings;
        newLearnings.splice(index, 1)
        formik.setFieldValue(formikString, newLearnings)
    }

    return (
        <>
            <Paper sx={{padding:"1rem", width:"fit-Content"}}>
                <Stack direction="row">
                    <Typography variant='h5'>Online Learning</Typography>
                    <QuestionTitle title="" infoText='Online Learning to be sent to team members who attended the session at the end of the session'/>
                    {editable && (
                        <Button
                            variant='contained'
                            onClick={() => setFormOpen(true)}
                            sx={{marginLeft:"auto"}}
                        >
                            Add Online Learning
                        </Button>
                    )}
                </Stack>
                <List sx={{width:"40rem", minWidth:"30rem"}}>
                    {formikOnlineLearnings.map((onlinelearning, index) => (
                        <OnlineLearningListItem
                            key={index}
                            index={index}
                            onlineLearning={onlinelearning}
                        />
                    ))}
                </List>
            </Paper>
            <AddOnlineLearningForm
                open={formOpen}
                OnClose={handleFormClose}
                handleAddLearning={handleAddOnlineLearnings}
                setMessageItem={setMessageItem}
            />
        </>
  )
}

export default OnlineLearningSelector