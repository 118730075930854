import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { useQuery } from '@tanstack/react-query'
import AttractionJournalService from 'src/Services/Checklists/Inspections/AttractionJournalService'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'

function Journals() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const journalsQuery = useQuery({
        queryKey: ["journals"],
        queryFn: async () => {
            return await AttractionJournalService.GetJournals()
        }
    })

    useEffect(() => {
        if(journalsQuery.isError){
            setMessageItem({error: journalsQuery.error})
        }
    }, [journalsQuery.error, journalsQuery.isError])


    return (
    <>
        <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
            
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Attraction</TableCell>
                        <TableCell>Outstanding Entries</TableCell>
                        <TableCell>Unread Entries</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(journalsQuery.isLoading || journalsQuery.isPending) ? (
                        <TableRow>
                            Loading...
                        </TableRow>
                    ) : journalsQuery.data && journalsQuery.data.map((journal) => (
                        <ClickableTableRow
                            key={journal.attractionId}
                            hover
                            href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${journal.attractionId}`}
                            sx={{cursor: "pointer", textDecoration: "none"}}
                        >
                            <TableCell>{journal.attraction.label}</TableCell>
                            <TableCell>{journal.outstandingEntriesQty}</TableCell>
                            <TableCell>{journal.unreadEntriesQty}</TableCell>
                        </ClickableTableRow>
                    ))}

                </TableBody>
            </Table>
            
        </div>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>

  )
}

export default Journals