import { Stack, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { JournalEntryDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'

type Props = {
    formik: FormikProps<JournalEntryDto>
}
function IssueOrFaultRecordEntry({formik}: Props) {
    return (
        <>
            <Stack>
                <Typography variant='h6'>Description</Typography>
                <EditableLabel
                    value={formik.values.description ?? ""}
                    setValue={(newValue: string) => formik.setFieldValue("description", newValue)}
                    placeholder='Describe any additional useful information regarding the issue/fault. Include a photo where possible'
                    onBlur={() => formik.setFieldTouched('description', true, false)}
                    helperText={formik.touched.description && formik.errors.description}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                />    
            </Stack>
        </>
      )
}

export default IssueOrFaultRecordEntry