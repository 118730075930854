import { Typography, Stack, TextField } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import colorConfigs from "src/config/colorConfigs";
import { LearningDocumentTopic } from "src/dtos/Training/LearningDocument.dto";
import { LearningDocumentTopicResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto";
import Subtopic from "../../Subtopic/Subtopic";

type Props = {
    topic: LearningDocumentTopic;
    topicResponse: LearningDocumentTopicResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    demoMode?: boolean;
}

function TrainingDocumentTopic({topic, topicResponse, formikString, formik, demoMode}: Props) {
    const [notes, setNotes] = useState(topicResponse.commentary)

    //Used to handle changing topic status when subtopic status changes.
    const handleSubtopicStatusChange = (changedSubtopicStatus: LearningDocumentResponseStatus, changedSubtopicId: number) => {
      const subtopicsCount = topicResponse.subtopicResponses.length; 
      let completedSubtopics = topicResponse.subtopicResponses.filter(str => (str.status === LearningDocumentResponseStatus.Completed && str.id !== changedSubtopicId) || 
        (str.id === changedSubtopicId && changedSubtopicStatus === LearningDocumentResponseStatus.Completed)).length;

      let unansweredSubtopics = topicResponse.subtopicResponses.filter(str => (str.status === LearningDocumentResponseStatus.Unanswered && str.id !== changedSubtopicId) ||
        (str.id === changedSubtopicId && changedSubtopicStatus === LearningDocumentResponseStatus.Unanswered)).length;

      let newTopicStatus = LearningDocumentResponseStatus.InProgress;
      if(unansweredSubtopics === subtopicsCount){
          newTopicStatus = LearningDocumentResponseStatus.Unanswered;
      } else if (unansweredSubtopics < subtopicsCount && completedSubtopics !== subtopicsCount){
          newTopicStatus = LearningDocumentResponseStatus.InProgress
      }else if(completedSubtopics === subtopicsCount){
          newTopicStatus = LearningDocumentResponseStatus.Completed;
      }
      formik.setFieldValue(`${formikString}.status`, newTopicStatus)
      handleTopicStatusChange(newTopicStatus, topicResponse.id)
    }

    const handleTopicStatusChange = (changedTopicStatus: LearningDocumentResponseStatus, changedTopicId: number) => {
        const topicsCount = formik.values.topicResponses.length;
        let completedTopics = formik.values.topicResponses.filter(tr => (LearningDocumentResponseStatus.Completed === tr.status && tr.id !== changedTopicId) || 
            (tr.id === changedTopicId && LearningDocumentResponseStatus.Completed ===changedTopicStatus)).length
        
        let unansweredTopics = formik.values.topicResponses.filter(tr => (tr.status === LearningDocumentResponseStatus.Unanswered && tr.id !== changedTopicId) ||
            (tr.id === changedTopicId && changedTopicStatus === LearningDocumentResponseStatus.Unanswered)).length
        
        let newLearningDocumentStatus = LearningDocumentResponseStatus.InProgress;
        if(unansweredTopics === topicsCount){
            newLearningDocumentStatus = LearningDocumentResponseStatus.Unanswered
        } else if (unansweredTopics < topicsCount && completedTopics !== topicsCount){
            newLearningDocumentStatus = LearningDocumentResponseStatus.InProgress
        } else if (completedTopics === topicsCount){
            newLearningDocumentStatus = LearningDocumentResponseStatus.AwaitingSubmission
        }
        formik.setFieldValue("status", newLearningDocumentStatus)
    }

    return (
      <>
        <div style={{color: "white", backgroundColor: colorConfigs.AW.blue, borderRadius: "10px"}}>
          <Typography variant='h5' sx={{textAlign:"center"}}>What to Train</Typography>
        </div>
        <ol>
          {topic.subtopics.map((subtopic, index) => (
            <Typography key={index}><li>{subtopic.label}</li></Typography>
          ))}
        </ol>
        
        <Stack spacing={2}>
        <div style={{color:"white", backgroundColor: colorConfigs.AW.blue, borderRadius: "10px"}}>
          <Typography variant='h5' sx={{textAlign:"center"}}>How to Train it</Typography>
        </div>
          {topic.subtopics.map((subtopic, index) => (
            <Subtopic 
              key={index}
              index={index}
              type="Active"
              formik={formik}
              formikString={`${formikString}.subtopicResponses[${index}]`}
              subtopicResponse={topicResponse.subtopicResponses[index]}
              subtopic={subtopic}
              pointsLocked={topicResponse.status === LearningDocumentResponseStatus.Completed}
              subtopicStatusChange={handleSubtopicStatusChange}
              demoMode={demoMode}
            />
          ))}
          <div className='avoidBreak'>
            <QuestionTitle title="Notes"/>
            <TextField 
              id={`${formikString}.commentary`}
              name={`${formikString}.commentary`}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              onBlur={() => formik.setFieldValue(`${formikString}.commentary`, notes)}
              sx={{width:"100%"}}
              multiline  
            />    
          </div>
        </Stack>
      </>
    )
}

export default TrainingDocumentTopic