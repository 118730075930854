import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, List, Paper, Stack, Typography } from "@mui/material";
import { Point } from '../Point/Point';
import { sanitizeHtml } from 'src/Utils/helperFunc';
import { LearningDocumentSubtopic } from 'src/dtos/Training/LearningDocument.dto';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LearningDocumentResponseDto, LearningDocumentSubtopicResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { FormikProps } from 'formik';

const createMarkup = (content: string) => {        
    return {
        __html: sanitizeHtml(content)
    }
}

export const GeneratePreparationSectionContent = ({content, checklist, preparationChecklistResponse, formik, demoMode}: {content: string, checklist?: LearningDocumentSubtopic, preparationChecklistResponse?: LearningDocumentSubtopicResponse, formik?: FormikProps<LearningDocumentResponseDto>, demoMode?:boolean}) => {

    const checklistRegex = /<preparationchecklist\b[^>]*>[\s\S]*?<\/preparationchecklist>/gi;
    
    if(checklistRegex.test(content))
    {
        const contentItems = content.split(checklistRegex);
        return (
            <>
                {contentItems.map((contentItem, index) => (
                    <React.Fragment key={index}>
                        <div dangerouslySetInnerHTML={createMarkup(contentItem)}/>
                        {index !== contentItems.length-1 && checklist && (
                            <Paper sx={{margin:"0.5rem"}}>
                                <Stack sx={{padding: "1rem"}} spacing={2}>
                                    <Typography variant='h6'>{`${checklist.label ? checklist.label : "New Checklist"}`}</Typography>
                                    <List component={"nav"}>
                                        {checklist.points.map((point, index) => {
                                            if (preparationChecklistResponse && formik){
                                                return (
                                                    <Point
                                                        type="Active"
                                                        key={index}
                                                        index={index}
                                                        point={point}
                                                        formik={formik}
                                                        pointResponse={preparationChecklistResponse.pointResponses[index]}
                                                        formikString={`preparationChecklistResponse.pointResponses[${index}]`}
                                                        pointClick={() => {}}
                                                        pointLocked={false}
                                                        demoMode={demoMode}
                                                    />
                                                )
                                            } 
                                            return (
                                                <Point
                                                    type="DisplayOnly"
                                                    key={index}
                                                    index={index}
                                                    point={point}
                                                />
                                            )
                                        })}
                                    </List>
                                </Stack>
                            </Paper>
                        )}

                    </React.Fragment>
                ))}
            </>
        )
    }
    return (
        <div dangerouslySetInnerHTML={createMarkup(content)}/>
    )
}

type Props = {
    initialSectionContent: string,
    preparationChecklist?: LearningDocumentSubtopic
    preparationChecklistResponse?: LearningDocumentSubtopicResponse
    formik?: FormikProps<LearningDocumentResponseDto>
    demoMode?: boolean
}

function PreparationSection({initialSectionContent, preparationChecklist, preparationChecklistResponse, formik, demoMode}: Props) {
  return (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon className="invsiblePrint"/>}
            aria-controls="preparation"
            id={`preparation_header`}
        >
            <Typography variant='h5'>Preparation</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <GeneratePreparationSectionContent 
                content={initialSectionContent}
                checklist={preparationChecklist}
                formik={formik}
                preparationChecklistResponse={preparationChecklistResponse}
                demoMode={demoMode}
            />
        </AccordionDetails>
    </Accordion>
  )
}

export default PreparationSection