import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { CircularProgress } from '@mui/material';
import React from 'react';

export const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric"};
export const shortDateOptions: Intl.DateTimeFormatOptions = {year: '2-digit', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric"};

export const dateTimeOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric"}
export type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'; //MUI chip colours

export const SaveStatusIcons: Record<string, React.ReactNode> =  {
    success: <CloudDoneOutlinedIcon fontSize='small' color="success"/>,
    failed: <CloudOffOutlinedIcon fontSize='small' color="error"/>,
    loading: <CircularProgress size="1rem" color="info"/>,
    idle: <CloudQueueIcon fontSize='small' sx={{color: "#cfcfcf"}} />
}