import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { LearningItemResponseDto } from "src/dtos/Training/LearningItemResponse.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/LearningItemResponse";
const debug = process.env.REACT_APP_DEBUG;

export default class LearningItemResponseService {
    static async Get(id: string) {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningItemResponseDto> = await axios.get<LearningItemResponseDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        }
        catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async SubmitAttendanceSheet(id: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningItemResponseDto> = await axios.put<LearningItemResponseDto>(`${apiBaseUrl}/SubmitAttendanceSheet/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DeleteResponse(id: string) {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeleteResponse/${id}`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    id: id
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}
