import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Modal, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import LearningItemService from 'src/Services/Training/LearningItemService';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import * as yup from 'yup'
import CloseIcon from '@mui/icons-material/Close';
import { LearningItem, LearningItemType, LearningItemTypeString } from 'src/dtos/Training/LearningItem.dto';
import { useFormik } from 'formik';
import { CourseNameDto } from 'src/dtos/Training/TalentLMS.dto';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { ExpandMore } from '@mui/icons-material';
import { dateOptions } from 'src/config/constants';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import TalentLMSService from 'src/Services/Training/TalentLMSService';
import ExpirySelector from 'src/pages/Checklists/CommonComponents/ExpirySelector';


const validationSchema = yup.object({
    label: yup.string().required("Name is a required field"),
    type: yup.number().required("Type is a required field")

})

type Props = {
    formOpen: boolean;
    handleFormClose: () => void;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function LearningItemForm ({formOpen, handleFormClose, setMessageItem}: Props) {
    const [formValues, setFormValues] = useState(LearningItemService.GetDefaultValues())
    const typeNames: LearningItemType[] = LearningItemTypeString.map((_, index) => {return index})
    const [courseNames, setCourseNames] = useState<CourseNameDto[]>([])
    const [courseIds, setCourseIds] = useState<string[]>([])
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get("id")

    const style = {
        borderRadius: '10px',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const save = async (values: LearningItem) => {
        try {
            setLoading(true)
            if(id){
                //update
                const response = await LearningItemService.Update(id, values)
                if(response.status >= 200 && response.status <= 299){
                    setMessageItem({successMessage: "Learning Item Updated Successfully!"})
                    formik.setValues(response.data)
                }
            } else {
                //create
                const response = await LearningItemService.Create(values);
                if(response.status >= 200 && response.status <= 299){
                    setMessageItem({successMessage: "Learning Item Created Successfully!"})
                    formik.setValues(response.data)

                    //update ID param
                    searchParams.set("id", response.data.id);
                    setSearchParams(searchParams)
                }
            }
        } catch (error: any){
            setMessageItem({error: error})
        } finally {
            setLoading(false)
        }
    }
    

    const saveToggleEnable = async () => {
        try {
          if(id){
            if(!formik.values.enabled){
                //enable
                const response = await LearningItemService.Enable(id);
                if(response.status >= 200 && response.status <= 299){
                    setMessageItem({successMessage: "Learning Item Activated Succesfully!"})
                }
                
              } else {
                //disable
                const response = await LearningItemService.Disable(id);
                if(response.status >= 200 && response.status <= 299){
                    setMessageItem({successMessage: "Learning Item Deactivated Succesfully!"})
                }
              }
          }          
        } catch (error: any){
            setMessageItem({error: error});
        }
    }

    function ActivateAlertDialog() {
        const handleClick = () => {
          formik.setFieldValue("enabled", !formik.values.enabled);
          saveToggleEnable();
          setOpenAlertDialog(false);
        }
        return (
          <Dialog
            open={openAlertDialog}
            onClose={()=> setOpenAlertDialog(false)}
          >
            <DialogTitle>
            {formik.values.enabled ? 'Deactivate' : 'Activate'} Learning Item?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to {formik.values.enabled ? 'deactivate' : 'activate'} {formik.values.label}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAlertDialog(false)}>Cancel</Button>
              <Button onClick={handleClick}>{formik.values.enabled ? 'Deactivate' : 'Activate'}</Button>
            </DialogActions>
          </Dialog>
        )
    }   

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save(values);
        }

    })

    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit()
        } else {
            formik.validateForm();
        }
    }

    //#region get data queries
    const {data, isError, error} = useQuery({
        queryKey: ["learningItem", id],
        queryFn: async () => {
            if(id){
                const response = await LearningItemService.Get(id);
                return response;
            } 
            return LearningItemService.GetDefaultValues()
        },
        placeholderData: LearningItemService.GetDefaultValues(),
    })
    useEffect(() => {
        if(data){
            setFormValues(data)
        }
    }, [data])
    useEffect(() => {
        if(isError){
            setMessageItem({errorMessage: error.message})
        }

    }, [error, isError, setMessageItem])


    const coursesQuery = useQuery({
        queryKey: ["talentLMSCourseNames"],
        queryFn: async () => {
            const response = await TalentLMSService.GetCourseNames()
            return response;
        }
    })
    useEffect(() => {
        if(coursesQuery.data){
            setCourseNames(coursesQuery.data)
            setCourseIds(coursesQuery.data.map((course) => {return course.id}))
        }
        if(coursesQuery.isError){
            setMessageItem({errorMessage: coursesQuery.error.message})
        }
    }, [coursesQuery.data, coursesQuery.isError, coursesQuery.error, setMessageItem])

    //#endregion

    return (

        <Modal
            open={formOpen}
            onClose={handleFormClose}
        >
            <Box sx={style}>
                <Stack direction={"row"} sx={{display:"flex", justifyContent:"space-between", alignItems: "Center"}}>
                    <Typography variant='h5'>{`${id ? "Edit" : "New"} Learning Item`}</Typography>
                    <IconButton onClick={handleFormClose}><CloseIcon fontSize='large'/></IconButton>
                </Stack>
                <div style={{padding:'1rem'}}>
                    <QuestionTitle title="Name" required/>
                    <TextField
                        id="label"
                        name="label"
                        value={formik.values.label}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.label && Boolean(formik.errors.label)}
                        helperText={formik.touched.label && formik.errors.label}
                        variant="outlined" 
                        size="small" 
                        sx={{width: "24rem"}}
                    />
                    <QuestionTitle title="Type" required marginTop='1rem'/>
                    <Autocomplete
                        disablePortal={false}
                        id="type"
                        options={typeNames}
                        size="small"
                        sx={{width: "24rem", paddingBottom: "0.5rem"}}
                        value={formik.values.type ?? null}
                        getOptionLabel={(option) => option !== undefined ? LearningItemTypeString[option] : ""}
                        onChange={(e, value) => {
                            if(value !== undefined && value !== null){
                                formik.setFieldValue("type", value);
                            } else {
                                formik.setFieldValue("type", undefined);
                            }
                        }}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                error={formik.touched.type && Boolean(formik.errors.type)}
                                helperText={formik.touched.type && formik.errors.type}
                            />
                        }
                    />
                    {formik.values.type === LearningItemType.talentLMS && (
                        <>
                            <QuestionTitle title="Course" required/>
                            <Autocomplete
                                disablePortal={false}
                                id="type"
                                options={courseIds}
                                size="small"
                                sx={{width: "24rem", paddingBottom: "0.5rem"}}
                                value={formik.values.talentLMSCourseId ?? null}
                                getOptionLabel={(option) => option ? courseNames.find((course) => course.id === option)?.name ?? "" : ""}
                                onChange={(e, value) => {
                                    if(value !== undefined && value !== null){
                                        formik.setFieldValue("talentLMSCourseId", value);
                                    } else {
                                        formik.setFieldValue("talentLMSCourseId", undefined);
                                    }
                                }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        error={formik.touched.talentLMSCourseId && Boolean(formik.errors.talentLMSCourseId)}
                                        helperText={formik.touched.talentLMSCourseId && formik.errors.talentLMSCourseId}
                                    />
                                }

                            />
                        </>
                    )}
                    {id && (
                        <>
                            <QuestionTitle title="Learning Item Status"/>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.enabled}
                                        onChange={() => setOpenAlertDialog(true)}
                                    />
                                }
                                label={formik.values.enabled ? "Active" : "Inactive"}   
                            />
                        </>
                    )}
                    
                    <QuestionTitle title="Expiry" required/>
                    <ExpirySelector
                        formik={formik}
                        width='24rem'
                    />
                </div>
                <div style={{display:"flex", justifyContent:"right"}}>
                    <LoadingButton onClick={handleSaveClick} variant='contained' color='primary' size="large" loading={loading}>Save</LoadingButton>
                </div>
                {id && (
                    <Accordion sx={{marginTop:"1rem", width:"22rem"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMore/>}
                      id="additional-info"
                    >
                      <Typography>Modification Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formik.values.createdBy && (
                        <Typography>
                          Created By: {formik.values.createdBy}
                        </Typography>
                      )}
                      {formik.values.createdOn && (
                        <Typography>
                          Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                      )}
                      {formik.values.modifiedBy && (
                        <Typography>
                          Modified By: {formik.values.modifiedBy}
                        </Typography>
                      )}
                      {formik.values.modifiedOn && (
                        <Typography>
                          Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                      )}
                    </AccordionDetails>
                    
                  </Accordion>
                )}
                <ActivateAlertDialog/>
            </Box>
        </Modal>
    )
}

export default LearningItemForm