import { LearningItem, LearningItemType } from "src/dtos/Training/LearningItem.dto";
import { ListParameters, SortDirection } from "../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { GetBearer } from "src/Utils/authUtils";
import axios, { AxiosResponse } from "axios";
import { NameDto } from "src/dtos/NameDto.dto";
import dayjs from "dayjs";
import qs from "qs";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/learningItem";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}


export default class LearningItemService {
    static GetDefaultValues(){
        const defaultVals: LearningItem = {
            id: "00000000-0000-0000-0000-000000000000",
            label:"",
            type: LearningItemType.attendanceSheet,
            expiry: {
                type: "",
                afterValue: null,
                afterUnits: "",
                everyUnit: "",
                everyWeekdayValue: "",
                everyMonthValue: "",

                everyTimeValue: dayjs().startOf("day").format("HH:mm"),
            },
            enabled:true
        }
        return defaultVals;
    }

    static async Get(id: string): Promise<LearningItem> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningItem> = await axios.get<LearningItem>(`${apiBaseUrl}/get/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, type?: LearningItemType, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    type: type,
                    includeDisabled: includeDisabled  
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetNames(itemTypes?: LearningItemType[]): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    itemTypes: itemTypes
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: LearningItem): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, data: LearningItem): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}