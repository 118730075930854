import React, { useState } from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { Paper, Button, Stack, Grid, IconButton, TextField, Autocomplete, Divider, Typography, CircularProgress, Chip } from '@mui/material'
import './styles.scss'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import PhotoGalleryWithCam from 'src/components/PhotoGalleryWithCam/PhotoGalleryWithCam';
import { ChildEntity, QuestionDto } from 'src/dtos/AwTypes';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { Failure, InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import { Status } from 'src/dtos/Statuses';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhotoCameraBackOutlinedIcon from '@mui/icons-material/PhotoCameraBackOutlined';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import WebcamCapture from 'src/components/WebcamCapture/WebcamCapture';

type Props = {
    demoMode: Boolean;
    formik: FormikProps<InspectionChecklistResponseDto>;
    formikErrors?: FormikErrors<InspectionChecklistQuestionResponseDto>
    formikTouched?: FormikTouched<InspectionChecklistQuestionResponseDto>
    formikString: string;
    questionContent: QuestionDto;
    questionResponse: InspectionChecklistQuestionResponseDto;
    updateSectionStatus: (newQuestionResponse: InspectionChecklistQuestionResponseDto) => void;
    questionEditable: () => true | string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;

    //TODO: replace with the inspected components from formik.inspectedComponents
    taggedOutComponents?: ChildEntity[]; 
    setTaggedOutComponents?: React.Dispatch<React.SetStateAction<ChildEntity[]>>
}

// function areEqual(prevProps: Props, nextProps: Props) {
//     return (
//         prevProps === nextProps
//     )
// }

function YesOrNoQuestionUnmemoized({formik, formikErrors, formikTouched, formikString, questionContent, questionResponse, taggedOutComponents, setTaggedOutComponents, updateSectionStatus: updateSection, demoMode, questionEditable, setMessageItem}: Props) {
    
    const [commentOpen, setCommentOpen] = useState(questionResponse?.comment ?? false);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [photoGalleryOpen, setPhotoGalleryOpen] = useState(false);

    const initialNo = Boolean(questionResponse.answer === "No");
    const initialYes = Boolean(questionResponse.answer === "Yes");
    const [comment, setComment] = useState<string>(questionResponse.comment ?? "");

    const photoGalleryImages: string[] = questionResponse.images ?? [];

    const queryClient = useQueryClient()
    const {responseId} = useParams(); 

    const [taggedOutImg, setTaggedOutImg] = useState("");

    const setAnswer = (answer: "Yes" | "No" | null) => {
        let newStatus = Status.Unanswered;
        if(answer === "Yes"){
            newStatus = Status.Passed
        }
        handleUpdateQuestion({...questionResponse, answer: answer, status: newStatus})

        if(newStatus === Status.Unanswered){
            //if the question changes to unanswered then untag the component and clear other answers
            untagComponent();

        }
    }
    
    const setPhotoGalleryImages = (images: string[]) => {
        formik.setFieldValue(`${formikString}.images`, images);
    }

    //#region queries
    const updateQuestionMutation = useMutation({
        mutationKey: ["UpdateQuestion", questionResponse.id],
        mutationFn: async (answer: InspectionChecklistQuestionResponseDto) => {
            queryClient.cancelQueries({queryKey: ["checklistResponse", responseId], exact: true})
            return await InspectionChecklistResponseService.UpdateQuestion(questionResponse.id, answer)
        },
        onSuccess: () => {
            if(responseId){
                queryClient.invalidateQueries({queryKey: ["checklistResponse", responseId], exact: true});
            }
        },
    })

    //#endregion

    const handleUpdateQuestion = (newValues: InspectionChecklistQuestionResponseDto) => {
        //Update timeAnswered
        if((newValues.timeAnswered === undefined || newValues.timeAnswered === null) && (newValues.status === Status.Failed || newValues.status === Status.Passed)){
            newValues.timeAnswered = new Date()
        } else {
            //if the question is unanswered clear time answered
            newValues.timeAnswered = undefined;
        }

        formik.setFieldValue(formikString, newValues)
        
        if(!demoMode){
            updateQuestionMutation.mutateAsync(newValues)
        }

        setTimeout(() => {
            updateSection(newValues)
        }, 100);

    }

    const handleInitialYesClick = () => {
        const questionEditableResponse = questionEditable()
        if(questionEditableResponse !== true){
            setMessageItem({errorMessage: questionEditableResponse})
            return
        }
        initialYes ? setAnswer(null) : setAnswer("Yes");        
    }

    const handleInitialNoClick = () => {
        const questionEditableResponse = questionEditable()
        if(questionEditableResponse !== true){
            setMessageItem({errorMessage: questionEditableResponse})
            return
        }
        initialNo ? setAnswer(null) : setAnswer("No");
    }

    const handlePhotoClick = () => {
        if(photoGalleryImages.length === 0){
            setCameraOpen(true);
        } else {
            setPhotoGalleryOpen(true);
        }
    }

    const handleCommentClick = () => {
        setCommentOpen((prevState) => !prevState);
    }

    const handleRejectImage = (image: string) => {
        handlePhotoClick();
    }

    const handleApproveImage = (image: string, setCapturedImage: React.Dispatch<React.SetStateAction<string | null>>) => {
        setPhotoGalleryImages([...photoGalleryImages, image])
        setCameraOpen(false);
        setPhotoGalleryOpen(true);
        setCapturedImage(null);
    };

    //returns true if images remain
    const deleteImage = (image: string) => {
        const newImages = photoGalleryImages.filter((img: string) => img !== image);
        setPhotoGalleryImages(newImages);
        return Boolean(newImages.length > 0);
    }

    const untagComponent = () => {
        const component = questionContent.relatedComponent;
        if(component === null || component === undefined){
            return
        }
        if(taggedOutComponents && setTaggedOutComponents){
            const index = taggedOutComponents.findIndex(tc => tc.id === component.id);
            const newComponents = [...taggedOutComponents]
            newComponents.splice(index, 1)
            setTaggedOutComponents(newComponents);
            
        }
    }

    function IssueResolvedQuestion({componentLabel}: {componentLabel: string}){
        const [failureCause, setFailureCause] = useState<string>(questionResponse.failureCause ?? "");
        const [remidialAction, setRemidialAction] = useState<string>(questionResponse.remidialAction ?? "");
        const [actionRequired, setActionRequired] = useState<string>(questionResponse.actionRequired ?? "")
        
        const setIssueResolvedAnswer = (answer: boolean | null) => {
            handleUpdateQuestion({...questionResponse, issueResolved: answer})
            //setAndValidateFailureCause();
        }
        

        const failureCauseError = formikErrors?.failureCause;
        const failureCauseHasValidAnswer = failureCause.length >= 10;
        
        const remidialActionError = formikErrors?.remidialAction;
        const remidialActionHasValidAnswer = remidialAction.length >= 10;

        const actionRequiredError = formikErrors?.actionRequired;
        const actionRequiredHasValidAnswer = actionRequired.length >= 10;

        const setAndValidateFailureCause = () => {
            handleUpdateQuestion({...questionResponse, failureCause: failureCause})
            //wait until field is set before validating
            setTimeout(() => {
                formik.validateField(`${formikString}.failureCause`)
                formik.setFieldTouched(`${formikString}.failureCause`)
            }, 200)
        }

        const handleResolvedYesClick = () => {
            console.log(failureCauseHasValidAnswer)
            if(failureCauseHasValidAnswer){
                questionResponse.issueResolved === true ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(true);
                //if it equals true that means that this click is actually unanswering the question
            } else {
                formik.setFieldTouched(`${formikString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${formikString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }
        }

        const handleResolvedNoClick = () => {
            
            if(failureCauseHasValidAnswer){ 
                questionResponse.issueResolved === false ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(false);

            } else {
                formik.setFieldTouched(`${formikString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${formikString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }   
        }
         
        const failureCauseHandleBlur = () => {
            setAndValidateFailureCause();
            if(failureCauseError){
                setIssueResolvedAnswer(null)
            }
        }

        const remidialActionHandleBlur = () => {
            const newQuestionResponse = questionResponse

            newQuestionResponse.remidialAction = remidialAction

            
            if(remidialActionHasValidAnswer){
                newQuestionResponse.status = Status.Passed

                setTimeout(() => {
                    formik.validateField(`${formikString}.remidialAction`);
                }, 100)
                
            } else {
                newQuestionResponse.status = Status.Unanswered

                setTimeout(() => {
                    formik.setFieldError(`${formikString}.remidialAction`, "Remedial action is required and must be at least 10 characters")
                }, 100)
            }

            handleUpdateQuestion(newQuestionResponse)
        }   
        
        const actionRequiredHandleBlur = () => {
            const newQuestionResponse = questionResponse
            newQuestionResponse.actionRequired = actionRequired
            
            if(actionRequiredHasValidAnswer){
                newQuestionResponse.status = Status.Failed

                setTimeout(() => {
                    formik.validateField(`${formikString}.actionRequired`);
                }, 100)
                
            } else {
                newQuestionResponse.status = Status.Unanswered

                setTimeout(() => {
                    formik.setFieldError(`${formikString}.actionRequired`, "Action required is required and must be at least 10 characters")
                }, 100)
            }

            handleUpdateQuestion(newQuestionResponse)
        }  

        const yesVariant = questionResponse.issueResolved === true ? "contained" : "outlined";
        const noVariant = questionResponse.issueResolved === false ? "contained" : "outlined";
        return (
            <>
            <QuestionTitle
                title={`What caused ${componentLabel} to fail?`}
                required
            />
            <TextField
                id={`${formikString}.failureCause`}
                name={`${formikString}.failureCause`}
                size="small"
                sx={{width: "100%"}}
                multiline
                maxRows={3}
                value={failureCause}
                onChange={(e) => {setFailureCause(e.target.value)}}
                onBlur={failureCauseHandleBlur}
                error={ formikTouched && Boolean(failureCauseError)}
                helperText={formikTouched && failureCauseError}
            />
            <QuestionTitle
                title="Has this issue now been resolved?"
                required
            />

            <Stack direction="row">
                <Button variant={yesVariant} color='success' onMouseDown={() => handleResolvedYesClick()}>Yes</Button>
                <Button variant={noVariant} color='error' onMouseDown={() => handleResolvedNoClick()}>No</Button>
            </Stack>

            {questionResponse.issueResolved === true ? (
                <>
                    <QuestionTitle
                        title="What remedial action was taken to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={remidialAction}
                        onChange={(e) => {setRemidialAction(e.target.value)}}
                        onBlur={remidialActionHandleBlur}
                        error={formikTouched && Boolean(remidialActionError)}
                        helperText={Boolean(remidialActionError) && remidialActionError}
                    />
                </>     
            ) : questionResponse.issueResolved === false && (
                <>
                    <QuestionTitle
                        title="What action is required to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={actionRequired}
                        onChange={(e) => {setActionRequired(e.target.value)}}
                        onBlur={actionRequiredHandleBlur}
                        error={formikTouched && Boolean(actionRequiredError)}
                        helperText={Boolean(actionRequiredError) && actionRequiredError}

                    />
                </>
            )}
            
            </>
            
        )
    }

    function GroupedQuestion(){
        
        const handleFailedItemsChange = (value: ChildEntity[]) => {
            formik.setFieldValue(`${formikString}.failedItems`,value);

            if(questionResponse.groupedQuestions && value.length > questionResponse.groupedQuestions.length){
                //Value added
                const newGroupedQuestions = questionResponse.groupedQuestions ? [...questionResponse.groupedQuestions] : [];
                newGroupedQuestions.push({component: value[value.length-1], failureCause: ""})
                formik.setFieldValue(`${formikString}.groupedQuestions`, newGroupedQuestions)
                //TODO: Temporary fix to allow progression when groupedQuestion used.
                formik.setFieldValue(`${formikString}.status`, Status.Passed)

                
            } else {
                //Value/s removed
                const originalValue = questionResponse.failedItems ?? [];
                const itemsRemoved = originalValue.filter(v => !value.includes(v))
                if(questionResponse.groupedQuestions){
                    let newGroupedQuestions = [...questionResponse.groupedQuestions];
                    questionResponse.groupedQuestions.forEach((groupedQuestion: Failure) => {
                        if(groupedQuestion.component && itemsRemoved.includes(groupedQuestion.component)){
                            newGroupedQuestions = newGroupedQuestions.filter(e => e !== groupedQuestion)   
                        }
                    })
                    formik.setFieldValue(`${formikString}.groupedQuestions`, newGroupedQuestions)

                }
            }
        }

        return (
            <div style={{paddingTop: "0.001rem"}}>
                <QuestionTitle
                    title={`Which ${questionContent.relatedComponent?.label} failed?`}
                    required
                />
                <Autocomplete
                    multiple
                    id="failedItems"
                    options={questionContent.relatedComponent?.children ?? []}
                    onChange={(_, value) => handleFailedItemsChange(value)}
                    value={questionResponse.failedItems}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            size="small"
                            label={`Failed ${questionContent.relatedComponent?.label}`}
                        />
                    )}
                />
                {questionResponse.failedItems && questionResponse.failedItems.map((childComponent, index) => (
                    <Stack key={childComponent.id}>
                        <GroupedIssueResolvedQuestion 
                            componentLabel={childComponent.label}
                            groupedQuestion={questionResponse.groupedQuestions ? questionResponse.groupedQuestions[index] : {component: childComponent}}
                            groupedQuestionIndex={index}
                        />
                        {index < (questionResponse.failedItems?.length ? questionResponse.failedItems.length-1: 1) &&
                            <Divider variant="middle" sx={{padding:"0.5rem"}}/>
                        }
                    </Stack>
                    ))
                }
                
            </div>
        )
    }

    function GroupedIssueResolvedQuestion({componentLabel, groupedQuestion, groupedQuestionIndex} : {componentLabel: string, groupedQuestion: Failure, groupedQuestionIndex: number}){
        const [failureCause, setFailureCause] = useState<string>(groupedQuestion.failureCause ?? "");
        const [remidialAction, setRemidialAction] = useState<string>(groupedQuestion.remidialAction ?? "");
        const [actionRequired, setActionRequired] = useState<string>(groupedQuestion.actionRequired ?? "")

        const failureAnswerString = `${formikString}.groupedQuestions[${groupedQuestionIndex}]`
        const failureAnswer: Failure = questionResponse.groupedQuestions ? questionResponse.groupedQuestions[groupedQuestionIndex] : {}

        const setIssueResolvedAnswer = (answer: boolean | null) => {
            formik.setFieldValue(`${failureAnswerString}.issueResolved`, answer)
            setAndValidateFailureCause();
        }

        const groupedQuestionErrorObj = formikErrors?.groupedQuestions?.[groupedQuestionIndex] as Failure | undefined;

        const failureCauseError = groupedQuestionErrorObj?.failureCause;
        const failureCauseHasValidAnswer = failureCause.length >= 10;
        
        const remidialActionError = groupedQuestionErrorObj?.remidialAction;
        const remidialActionHasValidAnswer = remidialAction.length >= 10;

        const actionRequiredError = groupedQuestionErrorObj?.actionRequired;
        const actionRequiredHasValidAnswer = actionRequired.length >= 10;

        const setAndValidateFailureCause = () => {
            formik.setFieldValue(`${failureAnswerString}.failureCause`, failureCause)
            //wait until field is set before validating
            setTimeout(() => {
                formik.validateField(`${failureAnswerString}.failureCause`)
                formik.setFieldTouched(`${failureAnswerString}.failureCause`)
            }, 200)
        }

        const failureCauseHandleBlur = () => {
            setAndValidateFailureCause();
            if(failureCauseError){
                setIssueResolvedAnswer(null)
            }
        }

        const handleResolvedYesClick = () => {
            if(failureCauseHasValidAnswer){
                failureAnswer.issueResolved === true ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(true);
                //if it equals true that means that this click is actually unanswering the question
            } else {
                formik.setFieldTouched(`${failureAnswerString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }
        }

        const handleResolvedNoClick = () => {
            
            if(failureCauseHasValidAnswer){ 
                failureAnswer.issueResolved === false ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(false);

            } else {
                formik.setFieldTouched(`${failureAnswerString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }   
        }

        const remidialActionHandleBlur = () => {
            formik.setFieldValue(`${failureAnswerString}.remidialAction`, remidialAction)
            //TODO: Temporary fix to allow progression when groupedQuestion used.
            formik.setFieldValue(`${formikString}.status`, Status.Passed)
            if(remidialActionHasValidAnswer){

                setTimeout(() => {
                    formik.validateField(`${failureAnswerString}.remidialAction`);
                }, 100)
                
            } else {

                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.remidialAction`, "Remidial action is required and must be at least 10 characters")
                }, 100)
            }
            
        }   
        
        const actionRequiredHandleBlur = () => {
            formik.setFieldValue(`${failureAnswerString}.actionRequired`, actionRequired)
            if(actionRequiredHasValidAnswer){

                setTimeout(() => {
                    formik.validateField(`${failureAnswerString}.actionRequired`);
                }, 100)
                
            } else {

                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.actionRequired`, "Action required is required and must be at least 10 characters")
                }, 100)
            }
            
        }
        const yesVariant = failureAnswer.issueResolved === true ? "contained" : "outlined";
        const noVariant = failureAnswer.issueResolved === false ? "contained" : "outlined";
        return (
            <>
            <QuestionTitle
                title={`What caused ${componentLabel} to fail?`}
                required
            />
            <TextField
                id={`${failureAnswerString}.failureCause`}
                name={`${failureAnswerString}.failureCause`}
                size="small"
                sx={{width: "100%"}}
                multiline
                maxRows={3}
                value={failureCause}
                onChange={(e) => {setFailureCause(e.target.value)}}
                onBlur={failureCauseHandleBlur}
                error={ formikTouched && Boolean(failureCauseError)}
                helperText={ formikTouched && failureCauseError}
            />
            <QuestionTitle
                title="Has this issue now been resolved?"
                required
            />

            <Stack direction="row">
                <Button variant={yesVariant} color='success' onMouseDown={() => handleResolvedYesClick()}>Yes</Button>
                <Button variant={noVariant} color='error' onMouseDown={() => handleResolvedNoClick()}>No</Button>
            </Stack>

            {failureAnswer.issueResolved === true ? (
                <>
                    <QuestionTitle
                        title="What remidial action was taken to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={remidialAction}
                        onChange={(e) => {setRemidialAction(e.target.value)}}
                        onBlur={remidialActionHandleBlur}
                        error={formikTouched && Boolean(remidialActionError)}
                        helperText={Boolean(remidialActionError) && remidialActionError}
                    />
                </>     
            ) : failureAnswer.issueResolved === false && (
                <>
                    <QuestionTitle
                        title="What action is required to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={actionRequired}
                        onChange={(e) => {setActionRequired(e.target.value)}}
                        onBlur={actionRequiredHandleBlur}
                        error={formikTouched && Boolean(actionRequiredError)}
                        helperText={Boolean(actionRequiredError) && actionRequiredError}

                    />
                </>
            )}
            
            </>
            
        )
    }

    const initialYesVariant = initialYes ? "contained" : "outlined";
    const initialNoVariant = initialNo ? "contained" : "outlined";
    return (
        <>
            <Paper>
            <span style={{ display: 'flex' }}>
            
            <div className='question-body'>
                <Stack sx={{marginTop: "1rem"}}>
                    <Grid container>
                        <Grid item xs={10}>
                            {questionContent.relatedComponent && (
                                <div style={{width: "fit-content", padding: "2px 10px", borderRadius: "12px",   backgroundColor: "#f2f2f2"}}>
                                    <Typography variant="body2" sx={{color: "#333"}}>{questionContent.relatedComponent?.label}</Typography>
                                </div>
                            )}
                            {/* <Typography>{questionResponse.timeAnswered?.toLocaleString()}</Typography> */}
                        </Grid>
                        <Grid item xs={2} sx={{display:"flex", justifyContent:"right"}}>
                            <>
                            {updateQuestionMutation.isSuccess ? (
                                <CloudDoneOutlinedIcon fontSize='small' color="success" sx={{marginTop:"0.2rem"}}/>
                            ): updateQuestionMutation.isError ? (
                                <CloudOffOutlinedIcon fontSize='small' color="error" sx={{marginTop:"0.2rem"}}/>
                            ): updateQuestionMutation.isPending ? (
                                <CircularProgress size="1rem" color="info" sx={{marginTop:"0.2rem"}} />
                            ): (
                                <CloudQueueIcon fontSize='small' sx={{color: "#cfcfcf"}} />
                            )}  
                            </>

                        </Grid>

                    </Grid>
                    
                    <QuestionTitle
                        title={`${questionContent.number}. ${questionContent.label}`}
                        required={questionContent.required}
                        infoText={questionContent.additionalInformation}
                        marginTop='0.2rem'
                        fontSize={16}
                    />  
                        
                    
                </Stack>
                
                {questionResponse.status === Status.Unanswered && taggedOutComponents && taggedOutComponents.findIndex(ce => ce.id === questionContent.relatedComponent?.id) !== -1 ? (
                    <Chip
                        label="Component tagged out."
                        color="warning"
                    />
                ) : (
                    <>
                        <Grid container>

                        <Grid item xs={8}>
                            <Stack direction="row">
                                <Button variant={initialYesVariant} color='success' onClick={handleInitialYesClick}>Yes</Button>
                                <Button variant={initialNoVariant} color='error' onClick={handleInitialNoClick}>No</Button>
                            </Stack>
                            
                        </Grid>
                        <Grid item xs={4} sx={{display: "flex", justifyContent:"right"}}>
                            <Stack direction="row">
                                <IconButton onClick={handlePhotoClick}>
                                    {photoGalleryImages.length > 0 ? (
                                        <CollectionsOutlinedIcon color='primary'/>
                                    ) : (
                                        <AddAPhotoOutlinedIcon/>
                                    )}
                                    
                                </IconButton>
                                <IconButton onClick={handleCommentClick}>
                                    <AddCommentOutlinedIcon/>
                                </IconButton>
                            </Stack>
                        </Grid>
                        </Grid>
                        {commentOpen && initialNo !== true && (
                            <>
                                <QuestionTitle title='Provide any additional commentary'/>
                                <TextField
                                    id={`${formikString}.comment`}
                                    name={`${formikString}.comment`}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    onBlur={() => formik.setFieldValue(`${formikString}.comment`, comment)}
                                    sx={{width: "100%"}}
                                    multiline
                                    minRows={2}
                                    maxRows={3}
                                    size="small"
                                />
                            </>
                        )}
                        {initialNo === true && questionContent.relatedComponent && questionContent.relatedComponent.children && questionContent.relatedComponent.children.length > 1 ? (
                            <GroupedQuestion/>
                        ) : initialNo === true && (
                            <div style={{paddingTop: "0.001rem"}}>
                                <IssueResolvedQuestion componentLabel='this question'/>
                            </div>
                        )}
                    
                    </>
                )}
                
                <PhotoGalleryWithCam
                    photosData={photoGalleryImages}
                    photoGalleryOpen={photoGalleryOpen}
                    setPhotoGalleryOpen={setPhotoGalleryOpen}
                    setCameraOpen={setCameraOpen}
                    deletePhoto={deleteImage}
                />

                <WebcamCapture 
                    onApprove={handleApproveImage} 
                    cameraOpen={cameraOpen} 
                    setCameraOpen={setCameraOpen}
                    onReject={handleRejectImage}
                /> 
            </div>
               
            </span>
            </Paper>
            {/* Individual tag out if question failed and question related component requires tagging */}
            { questionResponse.status === Status.Failed && questionContent.relatedComponent?.requiresTagging === true && (
                <Paper style={{display:"flex", padding: "1rem"}}> 
                    <Stack spacing={2} sx={{width:"100%"}}>
                        <Stack direction="row" spacing={2} sx={{display:"flex", alignItems: "center"}}>
                            <WarningAmberIcon fontSize='large' color='error'/>
                            <Typography>{`${questionContent.relatedComponent?.label} has failed its inspection and now must be tagged out.`}</Typography>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography sx={{textAlign:"center"}}>{`Please take a photo of ${questionContent.relatedComponent?.label} tagged out`}</Typography>
                            <span style={{display:"flex", justifyContent: "center"}}>
                                <IconButton onClick={() => {
                                    if(taggedOutImg){
                                        setTaggedOutImg("");
                                        untagComponent();
                                    } else {
                                        setTaggedOutImg("Tagged out");
                                        if(setTaggedOutComponents && taggedOutComponents && questionContent.relatedComponent){
                                            setTaggedOutComponents([...taggedOutComponents, questionContent.relatedComponent])
                                        } else {
                                            console.log("Unable to set tagged out component")
                                        }
                                    }
                                    
                                }}>
                                    {taggedOutImg ? (
                                        <PhotoCameraBackOutlinedIcon fontSize='inherit' color='primary'/>
                                    ) : (
                                        <AddAPhotoOutlinedIcon fontSize='inherit'/>
                                    )
                                    }
                                </IconButton>
                            </span>

                        </Stack>
                    </Stack>
                </Paper>
            )}
        </>
        
        
    )
}

//const YesOrNoQuestion = memo<Props>(YesOrNoQuestionUnmemoized, areEqual)

const YesOrNoQuestion = YesOrNoQuestionUnmemoized

export default YesOrNoQuestion