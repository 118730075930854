import { DeleteForever } from '@mui/icons-material'
import { Typography, TextField, Button } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { showDialog } from 'src/components/AlertDialog/AlertDialog'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import { guidRegex } from 'src/Utils/helperFunc'

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function DeleteResponse({setMessageItem}: Props) {
    const [id, setId] = useState("")

    const deleteMutation = useMutation({
        mutationFn: (responseId: string) => {
            return InspectionChecklistResponseService.DeleteResponse(responseId)
        },
        onSuccess: () => {
            setMessageItem({successMessage:"Attraction Inspection Response Deleted"})
            console.log(`Attraction Inspection Response with ID: '${id}' deleted`)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleDeletePress = async () => {

        if(!guidRegex.test(id)){
            setMessageItem({errorMessage: "Attraction Inspection ResponseID must be a guid"})
            return
        }
        const deleteResponse = await showDialog({
            title: `Delete Attraction Inspection Response`,
            contentText: `Are you sure you want to delete the attraction inspection response with id '${id}', this action is irreversible.`,
            acceptText: "Delete Permanently",
            declineText: "Cancel"
        })

        if(deleteResponse){
            deleteMutation.mutate(id)
        }
    }
    
    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            <Typography variant='h6'>Delete Attraction Inspection Response</Typography>
            <Typography>Attraction Inspection Response ID</Typography>
            <TextField
                id="AttractionInspectionResponseId"
                name="AttractionInspectionResponseId"
                value={id}
                onChange={(e) => setId(e.target.value)}
                size='small'
            />
            <div style={{paddingTop:"1rem"}}>
                <Button onClick={handleDeletePress} color='error' variant='contained'>
                    Delete<DeleteForever/>
                </Button>
            </div>
        </div>
    )
}

export default DeleteResponse