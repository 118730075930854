
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { NameDto } from 'src/dtos/NameDto.dto';
import React from 'react';
import { useState } from 'react';
import CustomList from './Components/CustomList';

function not(a: readonly NameDto[], b: readonly NameDto[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly NameDto[], b: readonly NameDto[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly NameDto[], b: readonly NameDto[]) {
  return [...a, ...not(b, a)];
}

type Props = {
    choicesTitle: string
    left: readonly NameDto[]
    setLeft: React.Dispatch<React.SetStateAction<readonly NameDto[]>>
    right: readonly NameDto[]
    setRight: React.Dispatch<React.SetStateAction<readonly NameDto[]>>  
}

export default function SearchableTransferList({left, setLeft, right, setRight, choicesTitle}: Props) {
  const [checked, setChecked] = useState<readonly NameDto[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: NameDto) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly NameDto[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly NameDto[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  
  

return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
            <CustomList
                handleToggle={handleToggle}
                handleToggleAll={handleToggleAll}
                checked={checked}
                numberOfChecked={numberOfChecked}
                title={choicesTitle}
                items={left}
            />
        </Grid>
        <Grid item>
            <Grid container direction="column" alignItems="center">
            <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
            >
                &gt;
            </Button>
            <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
            >
                &lt;
            </Button>
            </Grid>
        </Grid>
        <Grid item>
            <CustomList
                title={`Chosen ${choicesTitle}`}
                items={right}
                handleToggle={handleToggle}
                handleToggleAll={handleToggleAll}
                checked={checked}
                numberOfChecked={numberOfChecked}
            />
        </Grid>
    </Grid>
);
}