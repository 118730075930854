import { ListItem, Grid, Stack, TextField, Tooltip, IconButton } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react'
import { Draggable } from 'react-beautiful-dnd';
import { LearningDocumentSubpoint, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import { FocusElement } from 'src/Utils/helperFunc';

type Props = {
  formik: FormikProps<LearningDocumentVersion>
  formikString: string;
  formikSubpointValue: LearningDocumentSubpoint;
  pointSortOrder: number;
  DeleteSubPoint: (subpointIndex: number) => void;
  handleAddSubpointBelow: () => void;
  HandleAddPointBelow: () => void; //used to add point below all subpoints
  index: number;
}

function SubpointListItem({formik, formikString, formikSubpointValue, pointSortOrder, DeleteSubPoint, index, handleAddSubpointBelow, HandleAddPointBelow}: Props) {
    
      return (
        <Draggable draggableId={`p_${pointSortOrder}_sp_${index}`} index={index}>
          {(provided, snapshot) => (
            <ListItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Stack direction={"row"}>
                    <div 
                      {...provided.dragHandleProps} 
                      style={{ display: "flex", alignItems: "center", marginRight: '8px' }}
                      >
                      <DragIndicatorIcon/>
                    </div>
                    <TextField 
                      id={`${formikString}.label`}
                      name={`${formikString}.label`}
                      label={"Label"}
                      value={formikSubpointValue.label}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={e => {
                        if(e.key === "Enter"){
                            e.preventDefault();
                            if(formikSubpointValue.label.length > 0){
                              handleAddSubpointBelow()
                            } else {
                              HandleAddPointBelow()
                              setTimeout(() => {
                                FocusElement(1)
                                DeleteSubPoint(index)
                              }, 10)
                            }
                        }
                      }}
                      sx={{width:"100%"}}
                      size="small"
                      multiline
                      maxRows={2}
                    />
                    <Tooltip title="Delete Subpoint">
                      <div>
                        <IconButton tabIndex={-1} onClick={() => DeleteSubPoint(index)}>
                          <DeleteIcon/>
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </Draggable>
    )
}

export default SubpointListItem