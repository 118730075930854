import { Stack, Button, Modal, Box, Typography, IconButton } from '@mui/material';
import React, { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas';
import CloseIcon from '@mui/icons-material/Close';

function CustomSignaturePad({person, setSignature, locked}: {person: {signature?: string | undefined, name: string}, setSignature: (signature: string | undefined) => void, locked?: boolean}) {
    const [open, SetOpen] = useState(false)

    const handleClose = () => {
        SetOpen(false)
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'max-content',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        paddingBottom: 2,
        paddingTop: 2,
    };

    let sigPad : ReactSignatureCanvas | null = null;

    const handleClear = () => {
        if(sigPad){
            sigPad.clear();
            setSignature(undefined)
        }
    }

    const handleSave = () => {
        if(sigPad && !sigPad.isEmpty()){
            const trimmedData = sigPad.getTrimmedCanvas().toDataURL('image/png');
            setSignature(trimmedData);
        }
        handleClose();
    }

    const handleOpen = () => {
        if(!locked)
            SetOpen(true)
    }

    return (
        <>
            {person.signature ? (
                <Stack direction="row">
                    <img style={{cursor:"pointer", width: "5rem"}} onClick={handleOpen} src={person.signature} alt="Signature"/>
                </Stack>
            ) : (
                <Button onClick={handleOpen}>
                    Sign
                </Button>
            )}
            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <span style={{display:"flex", justifyContent:"space-between", alignItems: "center", flexDirection:"row", paddingBottom:"0.5rem"}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {`${person.name} Signature`}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </span>
                    <div className='SigningPad'>
                        <SignatureCanvas ref={(ref) => {sigPad = ref}} />
                    </div>
                    <Stack direction="row" spacing={2} style={{display:"flex", justifyContent: "flex-end"}} >
                        <Button onClick={handleClear}>Clear</Button>
                        <Button variant='contained' onClick={handleSave}>Save</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default CustomSignaturePad