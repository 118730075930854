import { TabContext, TabPanel } from '@mui/lab'
import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import LearningItemService from 'src/Services/Training/LearningItemService'
import { LearningItemType } from 'src/dtos/Training/LearningItem.dto'
import { Action, ActionType, ActionTypeStrings } from '../BulkActions'

type Props = {
    handleNext: () => void
    selectedAction: Action
    setSelectedAction: React.Dispatch<React.SetStateAction<Action>>
}

function SelectAction({handleNext, selectedAction, setSelectedAction}: Props) {
    
    //#region queries
    const onlineLearningCourseNamesQuery = useQuery({
        queryKey: ["onlineLearningNames"],
        queryFn: async () => {
            return await LearningItemService.GetNames([LearningItemType.talentLMS])
        },
        enabled: selectedAction.type === ActionType.OnlineLearning
    })
    //#endregion

    const stepCompleted = selectedAction.type === ActionType.OnlineLearning && selectedAction.onlineLearningCourse != null
    
    const handleNextClick = () => {
        if(stepCompleted){
            handleNext()
        }
    }

    return (
    <>
        <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
            <Grid item>
                <FormControl size='small'>
                    <InputLabel>Action</InputLabel>
                    <Select
                        id="action"
                        name="action"
                        label="Action"
                        value={selectedAction.type}
                        onChange={(e) => {
                            if(typeof e.target.value === "string" || e.target.value == null) {
                                return
                            } 
                            //reset to new action
                            setSelectedAction({type: e.target.value})
                        }}
                        sx={{width:"20rem"}}
                    >
                        <MenuItem value={ActionType.OnlineLearning}>{ActionTypeStrings[ActionType.OnlineLearning]}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TabContext value={selectedAction.type.toString()}>
                    <TabPanel value={ActionType.OnlineLearning.toString()}>
                        <Autocomplete
                            id="onlineLearningCourse"
                            options={onlineLearningCourseNamesQuery.data ?? []}
                            value={selectedAction.onlineLearningCourse}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label ? option.label: ""}
                            onChange={(e, value) => {
                                setSelectedAction((prevValue) => ({...prevValue, onlineLearningCourse: value})) 
                            }}
                            size="small"
                            sx={{ width: "20rem"}}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    label="Online Learning Course"
                                />
                            }
                        />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
        <span style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"1rem"}}>
            <Button disabled>
                Back
            </Button>
            <Button onClick={() => handleNextClick()} disabled={!stepCompleted}>
                Next
            </Button>
        </span> 
    </>
  )
}

export default SelectAction