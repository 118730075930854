import { Box, TableCell } from '@mui/material'
import React, { useState } from 'react'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import { ListParameters, SortDirection } from 'src/Services/ListParameters'
import Datatable from 'src/components/Datatable/Datatable'
import { StyledTableRow } from 'src/components/Datatable/StyledTableRow'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import colorConfigs from 'src/config/colorConfigs'
import { dateTimeOptions } from 'src/config/constants'
import { CompletedInspectionDto } from 'src/dtos/Checklists/AttractionInspections/CompletedInspection.dto'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { Status, StatusString } from 'src/dtos/Statuses'

function CompletedInspections() {
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})

    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "inspectionLabel", width: "13rem", sortable: false},
        {label: "Status", field: "status", width: "4rem", sortable: false},
        {label: "Started", field: "startedTime", width: "6rem", sortable: false},
        {label: "Completed", field: "completedTime", width: "6rem", sortable: false},
        {label: "Maintainer", field: "completedBy", width: "8rem", sortable: false}
    ]
    const initialListParams: ListParameters = {
        sortBy: "completedTime",
        sortDirection: SortDirection.Descending,
        page: 1,
        pageSize: 30   
    }

    const fetchCompletedInspections = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await InspectionChecklistResponseService.GetCompletedInspections(listParams, searchTerm)
        return data;
    }   

    function renderTableRow ({data} : {data: CompletedInspectionDto}) {
        const handleClick = (e: any, id: string) => {
            e.stopPropagation();
        }

        const completedByParts = data.completedBy?.split('@')[0].split('.') ?? [""];
        const firstNameLastName = completedByParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

        return (
            <React.Fragment>
              <StyledTableRow
              hover
              onClick={(e:any)=> handleClick(e, data.id ?? "")}
              key={data.id}
              sx={{cursor: 'pointer'}}
              >
                <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor:data.status === Status.Passed ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.inspectionLabel}</TableCell>
                <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{StatusString[data.status]}</TableCell>
                <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.startedTime ? new Date(data.startedTime).toLocaleDateString(undefined, dateTimeOptions): "Unknown"}</TableCell>
                <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{data.completedTime ? new Date(data.completedTime).toLocaleDateString(undefined, dateTimeOptions): "Unknown"}</TableCell>
                <TableCell sx={{minWidth: `${tableHeaders[5].width} !important`, maxWidth: `${tableHeaders[5].width} !important`}}>{firstNameLastName}</TableCell>


      
            </StyledTableRow>
            </React.Fragment>
            
          )
    }


    return (
        <>
            <Box sx={{display: "Flex", justifyContent:"center", paddingTop:"2rem"}}>
                <Datatable
                    tableHeaders={tableHeaders}
                    initialListParams={initialListParams}
                    tableTitle='Completed Inspections'
                    showSearch={true}
                    renderRow={renderTableRow}
                    callService={fetchCompletedInspections}
                    setMessageItem={(caughtError: MessageItem) => setMessageItem(caughtError)}
                />
            </Box>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default CompletedInspections