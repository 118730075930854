import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, CircularProgressProps, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AlertQuestion from 'src/pages/User Site/Inspections/Inspection/InspectionComponents/questions/AlertQuestion'
import YesOrNoQuestion from 'src/pages/User Site/Inspections/Inspection/InspectionComponents/questions/YesOrNoQuestion'
import { QuestionType, InspectionChecklistSection, QuestionDto } from 'src/dtos/Checklists/MaintenanceChecklist.dto'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { Status, StatusString } from 'src/dtos/Statuses'
import { ChildEntity } from 'src/dtos/EntityChild.dto'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { useMutation } from '@tanstack/react-query'
import { FormikErrors, FormikProps, FormikTouched } from 'formik'

type Props = {
    demoMode: Boolean; //If the answers are saved.
    formik: FormikProps<InspectionChecklistResponseDto>
    formikErrors?: FormikErrors<InspectionChecklistSectionResponseDto>
    formikTouched?: FormikTouched<InspectionChecklistSectionResponseDto>
    formikString: string
    sectionContent: InspectionChecklistSection;
    sectionResponse: InspectionChecklistSectionResponseDto
    handleSectionStatusChange: (newSectionResponse: InspectionChecklistSectionResponseDto) => void;
    questionsEditable: () => true | string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;

    //TODO: replace with the inspected components from formik.inspectedComponents
    taggedOutComponents?: ChildEntity[]; 
    setTaggedOutComponents?: React.Dispatch<React.SetStateAction<ChildEntity[]>>
}


function FormSection({formik, formikErrors, formikTouched, formikString, sectionContent, sectionResponse, handleSectionStatusChange, questionsEditable, taggedOutComponents, setTaggedOutComponents, demoMode, setMessageItem}: Props) {
    const accordionRef = useRef<HTMLDivElement | null>(null);
    const [sectionOpen, setSectionOpen] = useState(false)

    const questionVisible = (question: QuestionDto) : boolean => {
        if(question.repeatOnComponentSetUserSelected === true && formik.values.selectedComponents && question.relatedComponent && question.relatedComponent.children){
            return formik.values.selectedComponents.some(e => e.id === question.relatedComponent?.id);
        } else {
            return true;
        }
    }

    const [questionVisibility, setQuestionVisibility] = useState<boolean[]>(() => sectionContent.questions.map(question => questionVisible(question)))


    useEffect(() => {
        const newQuestionVisibility = sectionContent.questions.map(question => questionVisible(question));
        setQuestionVisibility(newQuestionVisibility)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionContent.questions, formik.values.selectedComponents])
    
    const numberOfQuestions = sectionResponse ? sectionResponse.questionResponses.filter((answer) => answer.status !== Status.NA).length : 0
    const passedQuestions = sectionResponse ? sectionResponse.questionResponses.filter((answer) => answer.status === Status.Passed).length : 0
    const failedQuestions = sectionResponse ? sectionResponse.questionResponses.filter((answer) => answer.status === Status.Failed).length : 0
    const sectionFailed = Boolean(failedQuestions > 0)


    //#region queries

    const updateSectionMutation = useMutation({
        mutationKey: ["UpdateSection", sectionResponse.id],
        mutationFn: async (values: InspectionChecklistSectionResponseDto) => {
            return await InspectionChecklistResponseService.UpdateSection(sectionResponse.id, values)
        },
    })


    //#endregion

    const updateSectionStatus = (newQuestionResponse: InspectionChecklistQuestionResponseDto) => {

        const isSectionFailed = sectionResponse.questionResponses.some((questionResponse) => {
            const response = questionResponse.id === newQuestionResponse.id ? newQuestionResponse : questionResponse
            return response.status === Status.Failed;
        })

        //passed where no Unasnwered questions and is not failed
        const isSectionPassed = isSectionFailed === false && sectionResponse.questionResponses.every((questionResponse) => {
            const response = questionResponse.id === newQuestionResponse.id ? newQuestionResponse : questionResponse
            return response.status !== Status.Unanswered
        })
        
        const isUnanswered = sectionResponse.questionResponses.every((questionResponse) => {
            const response = questionResponse.id === newQuestionResponse.id ? newQuestionResponse : questionResponse
            return response.status !== Status.Passed && response.status !== Status.Failed
        })

        let sectionStatus = Status.Inprogress
        if(isSectionFailed){
            if(sectionContent.repeatingComponentSet?.requiredToPass === false){
                sectionStatus = Status.ComponentFailed                
            } else {
                sectionStatus = Status.Failed
            }
        } else if(isSectionPassed){
            sectionStatus = Status.Passed

        } else if (isUnanswered){
            sectionStatus = Status.Unanswered
        }    

        if(sectionResponse.status !== sectionStatus){

            formik.setFieldValue(`${formikString}.status`, sectionStatus)
            const newValues: InspectionChecklistSectionResponseDto = {...sectionResponse, status: sectionStatus};
            
            updateSectionMutation.mutateAsync(newValues)
            
            handleSectionStatusChange(newValues)
        }

    }

    

    function QuestionProgress(
        props: CircularProgressProps & { value: number }
    ) {
        const progressColour = passedQuestions === numberOfQuestions ? "success" : sectionFailed ? "error" : "primary";
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress variant="determinate" {...props} color={progressColour} size="3rem"/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${passedQuestions}/${numberOfQuestions}`}</Typography>
                </Box>
            </Box>
        );
    }

    const handleSectionOpen = () => {
        setSectionOpen(!sectionOpen)
    }

    
    return (
        <div>
            <Accordion ref={accordionRef} expanded={sectionOpen} onChange={handleSectionOpen}>
                <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`${sectionContent.label}-content`}
                id={`${sectionContent.label}-header`}
                >
                <Stack direction="row" sx={{ width: "95%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography variant='h5'>{sectionContent.label}</Typography>
                    <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center"}}>
                        <Typography>{StatusString[sectionResponse.status]}</Typography>

                        {failedQuestions > 0 && (
                            <ErrorOutlineOutlinedIcon sx={{color:"red"}} fontSize='large'/>
                        )}
                        {passedQuestions > 0 &&
                        <QuestionProgress 
                            value={(passedQuestions/numberOfQuestions)*100}  
                        />
                        }          
                    </Stack>
                    
                </Stack>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                <Typography variant='body1' sx={{paddingLeft:"1rem"}}>{sectionContent.sublabel}</Typography>
                <Stack spacing={2}>
                {sectionContent.questions.map((question, questionIndex) => {

                    if (!sectionResponse || !sectionResponse.questionResponses) {
                        return null;
                    }
                    const questionError =
                    formikErrors?.questionResponses && typeof formikErrors.questionResponses[questionIndex] === 'object'
                        ? (formikErrors.questionResponses[questionIndex] as FormikErrors<InspectionChecklistQuestionResponseDto>)
                        : undefined;
                    const questionTouched =
                    formikTouched?.questionResponses && typeof formikTouched.questionResponses[questionIndex] === 'object'
                        ? (formikTouched.questionResponses[questionIndex] as FormikTouched<InspectionChecklistQuestionResponseDto>)
                        : undefined;

                    return(
                        <React.Fragment key={questionIndex}>
                            {questionVisibility[questionIndex] && (
                                (question.type === QuestionType.YesOrNo && sectionResponse.questionResponses[questionIndex] && (
                                    <YesOrNoQuestion
                                        key={questionIndex}
                                        demoMode={demoMode}
                                        formik={formik}
                                        formikErrors={questionError}
                                        formikTouched={questionTouched}
                                        formikString={`${formikString}.questionResponses[${questionIndex}]`}
                                        questionContent={question}
                                        questionResponse={sectionResponse.questionResponses[questionIndex]}
                                        taggedOutComponents={taggedOutComponents}
                                        setTaggedOutComponents={setTaggedOutComponents}
                                        updateSectionStatus={updateSectionStatus}
                                        questionEditable={questionsEditable}
                                        setMessageItem={setMessageItem}
                                    />
                                )) ||
                                (question.type === QuestionType.Alert && (
                                    <AlertQuestion
                                        key={questionIndex}
                                        questionTitle={question.label}
                                        />
                                ))
                            )}
                            
                        </React.Fragment>
                    )})
                }

                </Stack>
                </AccordionDetails>
            </Accordion>
        </div>
        
    )
    
}

export default FormSection