import { Alert, AlertColor, Snackbar, SxProps, Theme, Typography } from '@mui/material';
import { AxiosError, isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react'

export type MessageItem = {error?: AxiosError | Error, successMessage?: string, errorMessage?: string}
type Props = {messageItem: MessageItem, alertSx?:SxProps<Theme>}

function ErrorHandlingSnackbar({messageItem, alertSx}: Props) {
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState<AlertColor>("error")
  const errorMessageOpen = Boolean(message);

  const handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage("");
  };
  
  const handleAxiosError = (error: AxiosError) => {
    if (error.response?.status === 401) {
      setMessage('Unauthorised');
    }else if(error.code === "ERR_NETWORK"){
      setMessage("Unable to reach the database");
    }else if (error.response?.status === 409){
      setMessage(`Saving failed: ${error.response.data}`)
    } else if (error.response?.status === 403){
      setMessage(`Access Denied`)
    } else if (error.code){
      //unhandled error
      setMessage(`Error: ${error.response?.data}`);
    }

    setSeverity("error");

  }

  const handleError = (error: Error) => {
    if(error.message.length > 100){
      setMessage("Unknown Error: View console for more detail")
      console.log(error)
    } else {
      setMessage(`${error.name}: ${error.message}`)
    }
    setSeverity("error");
  }

  useEffect(() => {
    function DetermineMessage(){

      
      //success code
      if(messageItem.successMessage){
        setMessage(messageItem.successMessage);
        setSeverity("success");
      }else if(messageItem.errorMessage) {
          setMessage(messageItem.errorMessage);
          setSeverity("error");
      }else if(messageItem.error){
        if(isAxiosError(messageItem.error)){
          handleAxiosError(messageItem.error)
        } else {
          handleError(messageItem.error)
        }
      }
    }
    DetermineMessage()

  },[messageItem])
    
  return (
    <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={errorMessageOpen}
        autoHideDuration={6000}
        onClose={handleClose}
    >
        <Alert sx={alertSx} severity={severity} onClose={() => setMessage("")}><Typography>{message}</Typography></Alert>
    </Snackbar>
  )
}

export default ErrorHandlingSnackbar