import { AttractionDto } from "src/dtos/AwTypes";
import { BaseModel } from "src/dtos/BaseModel.dto";
import { NameDto } from "src/dtos/NameDto.dto";

export interface JournalDto {
    attractionId?: string;
    attraction: AttractionDto;
    outstandingEntriesQty: number;
    unreadEntriesQty: number;
    lastOpenedTime?: Date;
}

export interface JournalEntryQueryOptions {
    attractionId: string;
    statuses?: JournalEntryStatus[];
    types?: JournalEntryType[];
    from?: Date;
    to?: Date;
    reportedBySearch?: string;
}

export interface JournalEntryDto extends BaseModel {
    id?: string;
    entryId: string;
    attraction: NameDto;
    label: string;
    type: JournalEntryType;
    status: JournalEntryStatus;
    linkedEntry?: NameDto;
    description?: string; //also used to describe action required
    resolutionDescription?: string;
    resolvedOn?: Date;
    resolvedBy?: string;
    entryUnread?: boolean;
}

export enum JournalEntryType {
    serviceTask,
    issueFaultRecord,
    outOfService,
    duplicateEntry,
    ThirdPartyAuditFinding
}
export const JournalEntryTypeString: string[] = [
    "Service or Repair",
    "Issue/Fault Record",
    "Out of Service",
    "Duplicate Entry",
    "Third Party Corrective Action / Audit Finding"
]

export enum JournalEntryStatus {
    NA,
    outstanding,
    completed
}
export const JournalEntryStatusString: string[] = [
    "N/A",
    "Outstanding",
    "Completed"
]

export interface CommentDto extends BaseModel {
    id: string;
    description?: string;
}