import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { ListParameters, SortDirection } from "../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { DietaryRequirement } from "src/dtos/HumanResources/DietaryRequirement.dto";
import { NameDto } from "src/dtos/NameDto.dto";


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/DietaryRequirement";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class DietaryRequirementService {
    static GetDefaultValues(){
        const defaultValues: DietaryRequirement = {
            id:"",
            label:"",
            enabled: true
        }
        return defaultValues;
    }

    static async Get(id: string): Promise<DietaryRequirement> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<DietaryRequirement> = await axios.get<DietaryRequirement>(`${apiBaseUrl}/get/${id}`, {headers: {Authorization: bearer}});
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, includeDisabled?: Boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    includeDisabled: includeDisabled  
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetNames(): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: DietaryRequirement): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            debugger
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, data: DietaryRequirement): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}