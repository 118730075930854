import { Tooltip, IconButton, List, ListItem, ListItemText, Paper, Stack, Typography, Button, ListItemIcon } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import { LearningDocument } from 'src/dtos/Training/LearningDocument.dto'
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import AddDocumentForm from './AddDocumentForm'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import CircleIcon from '@mui/icons-material/Circle';

type Props = {
    formik: FormikProps<TrainingSession>
    formikString: string;
    formikTrainingDocuments: LearningDocument[];
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    editable: boolean;
}
function TrainingDocumentsSelector({formik, formikString, formikTrainingDocuments, setMessageItem, editable} : Props) {
    const [formOpen, setFormOpen] = useState(false)

    function TrainingDocumentListItem({index, document}:{index: number, document: LearningDocument}) {
        return (
            <ListItem
                sx={{width:"100%"}}
                secondaryAction={
                    <>
                        {editable && (
                            <Tooltip title="Remove Document">
                                <IconButton edge="end" aria-label='delete' onClick={() => handleRemoveDocument(index)}>
                                    <DeleteOutlineOutlinedIcon color='error'/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                }
            >
                <ListItemIcon sx={{marginRight:"-20px"}}>
                    <CircleIcon fontSize="inherit" sx={{fontSize: "0.6rem", color:"black"}}/>
                </ListItemIcon>
                <ListItemText
                    sx={{textWrap:"wrap"}}
                    primary={document.label}
                />
            </ListItem>
        )
    }

    const handleFormClose = () => {
        setFormOpen(false)
    }

    const handleAddDocuments = (learningDocs: LearningDocument[]) => {

        const nonExistingDocs = learningDocs.filter(learningDoc => formikTrainingDocuments.findIndex(doc => doc.id === learningDoc.id) === -1)
        
        formik.setFieldValue(formikString, [...formikTrainingDocuments, ...nonExistingDocs])
    }

    const handleRemoveDocument = (index: number) => {
        const newDocs = formikTrainingDocuments;
        newDocs.splice(index, 1)
        formik.setFieldValue(formikString, newDocs)
    }   

    
    return (
        <>
            <Paper sx={{padding:"1rem", width:"fit-Content"}}>
                <Stack direction="row">
                    <Typography variant='h5'>Training Documents</Typography>
                    <QuestionTitle title="" infoText='Training Documents of type "Training Document NTM" & "Training Document RTM" automatically include an attendance sheet. A Training Document of type "Orientation" does not have an included attendance sheet'/>
                    {editable && (
                        <Button
                            variant='contained'
                            onClick={() => setFormOpen(true)}
                            sx={{marginLeft:"auto"}}
                        >
                            Add Documents
                        </Button>
                    )}
                </Stack>
                <List sx={{width:"40rem", minWidth:"30rem"}}>
                        {formikTrainingDocuments.map((document, index) => (
                            <TrainingDocumentListItem 
                                key={index} 
                                document={document}
                                index={index}
                            />
                        ))}
                </List>
            </Paper>
            <AddDocumentForm
                open={formOpen}
                OnClose={handleFormClose}
                setMessageItem={setMessageItem}
                handleAddDocuments={handleAddDocuments}
            />
        </>
    )
}

export default TrainingDocumentsSelector