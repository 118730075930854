import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useEffect } from 'react'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto'
import TrainingDocumentsSelector from './TrainingDocumentsSelector/TrainingDocumentsSelector'
import AttendanceSheetsSelector from './AttendanceSheetsSelector/AttendanceSheetsSelector'
import OnlineLearningSelector from './OnlineLearningSelector/OnlineLearningSelector'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import dayjs from 'dayjs'
import { ExpandMore } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import { dateOptions } from 'src/config/constants'
import { useQuery } from '@tanstack/react-query'
import TrainingGroupService from 'src/Services/Training/TrainingGroupService'
import DeleteSessionButton from './DeleteSessionButton'

type Props = {
    formik: FormikProps<TrainingSession>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    loading: boolean;
}
function SessionSettings({formik, setMessageItem, loading}: Props) {
    const {id} = useParams();
    const settingsEditable = formik.values.sessionResponses.length === 0;
    
    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit();
          } else {
            formik.validateForm();
          }
    }

    const trainingGroupsQuery = useQuery({
        queryKey: ["trainingGroupNames"],
        queryFn: async () => {
            return await TrainingGroupService.GetNames();
        }
    })

    //handle query errors
    useEffect(() => {
        if(trainingGroupsQuery.isError){
            setMessageItem({error: trainingGroupsQuery.error})
        }
    }, [setMessageItem, trainingGroupsQuery.isError, trainingGroupsQuery.error])
    return (
    <>
        <Stack spacing={1}>
            {settingsEditable && (
            <>
                <div>
                    <QuestionTitle title="Session Name" required/>
                    <TextField
                        id="label"
                        name="label"
                        value={formik.values.label}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        sx={{width:"20rem"}}
                        error={formik.touched.label && Boolean(formik.errors.label)}
                        helperText={formik.touched.label && formik.errors.label}
                    />
                </div>
                <div>
                    <QuestionTitle title="Session Date" required/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            name="date"
                            format='DD MMMM YYYY'
                            value={dayjs(formik.values.date)}
                            onChange={(value) => {
                                if(value){
                                    formik.setFieldValue("date", value.format("YYYY-MM-DD"))
                                } else {
                                    formik.setFieldValue("date", null)
                                }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: formik.touched.date && Boolean(formik.errors.date),
                                    helperText: formik.touched.date && formik.errors.date
                                }
                            }}
                            sx={{width:"20rem"}}
                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <QuestionTitle title="Max Trainees" infoText='The maximum number of trainees allowed in the session'/>
                    <TextField
                        id="maxTrainees"
                        name="maxTrainees"
                        type="number"
                        value={formik.values.maxTrainees}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        sx={{width:"20rem"}}
                        error={formik.touched.maxTrainees && Boolean(formik.errors.maxTrainees)}
                        helperText={formik.touched.maxTrainees && formik.errors.maxTrainees}
                    />
                </div>
                <div>
                    <QuestionTitle title="Training Groups" infoText='Only team members who are assigned the selected Training Groups are able to be added to this session.'/>
                    <Autocomplete
                        id="trainingGroups"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => (option.label ? option.label : "")}
                        options={trainingGroupsQuery.data ?? []}
                        multiple
                        value={formik.values.trainingGroups}
                        onChange={(e, value) => {
                            formik.setFieldValue("trainingGroups", value)
                        }}
                        size={"small"}
                        sx={{width:"20rem"}}
                        renderInput={(params) => 
                            <TextField {...params}/>
                        }
                    />
                </div>
                <div>
                    <QuestionTitle title="Session Lock from Date" infoText='From this date (inclusive) Department Managers will no longer be able to add or remove Trainees. They will be able to add Trainers but cannot remove them'/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            name="sessionLockDate"
                            format='DD MMMM YYYY'
                            disablePast
                            value={dayjs(formik.values.sessionLockDate)}
                            maxDate={dayjs(formik.values.date)}
                            onChange={(value) => {
                                if(value){
                                    formik.setFieldValue("sessionLockDate", value.format("YYYY-MM-DD"))
                                } else {
                                    formik.setFieldValue("sessionLockDate", null)
                                }
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                }
                            }}
                            sx={{width:"20rem"}}
                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <QuestionTitle title="Session Lock Override" infoText='If Toggled ON department managers will have the same permissions as before the Session Lock Date'/>
                    <FormControlLabel
                        control={
                            <Switch
                                name={`sessionLockOverride`}
                                checked={
                                    formik.values.sessionLockOverride
                                }
                                onChange={() =>
                                    formik.setFieldValue(
                                        `sessionLockOverride`,
                                        !formik.values.sessionLockOverride
                                    )
                                }
                            />
                        }
                        label={formik.values.sessionLockOverride ? "On" : "Off"}
                    />
                </div>
                
                <div>
                    <DeleteSessionButton 
                        setMessageItem={setMessageItem} 
                        id={id}
                        trainingSessionName={formik.values.label}    
                    />
                </div>
            </>
            )}

            <TrainingDocumentsSelector
                formik={formik}
                formikTrainingDocuments={formik.values.sessionTrainingDocuments}
                formikString='sessionTrainingDocuments'
                setMessageItem={setMessageItem}
                editable={settingsEditable}
            />

            <AttendanceSheetsSelector 
                formik={formik}
                formikString='sessionAttendanceSheets'
                formikAttendanceSheets={formik.values.sessionAttendanceSheets}
                setMessageItem={setMessageItem}
                editable={settingsEditable}
            />

            <OnlineLearningSelector 
                formik={formik}
                formikString='sessionOnlineLearnings'
                formikOnlineLearnings={formik.values.sessionOnlineLearnings}
                setMessageItem={setMessageItem}
                editable={settingsEditable}
            />

            <Stack direction="row" sx={{display: "flex", justifyContent: "space-between", paddingTop:"1rem"}}>
              {id !== "new" && (
                <Accordion sx={{width: "42rem"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="additional-info"
                    >
                    <Typography>Modification Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {formik.values.createdBy && (
                        <Typography>
                            Created By: {formik.values.createdBy}
                        </Typography>
                        )}
                        {formik.values.createdOn && (
                        <Typography>
                            Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                        {formik.values.modifiedBy && (
                        <Typography>
                            Modified By: {formik.values.modifiedBy}
                        </Typography>
                        )}
                        {formik.values.modifiedOn && (
                        <Typography>
                            Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                    </AccordionDetails>              
                </Accordion>
              )}
                
              <div style={{flexGrow: 1, textAlign:"right"}}>
                  <LoadingButton  variant="contained" color='primary' size="large" loading={loading} onClick={handleSaveClick}><span>Save</span></LoadingButton>
              </div>
            </Stack>

        </Stack>

    
    </>
  )
}

export default SessionSettings