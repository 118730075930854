import { EmojiPeople } from "@mui/icons-material"
import React from "react"
import colorConfigs from "src/config/colorConfigs"
import { LearningDocumentPointType } from "src/dtos/Training/LearningDocument.dto"
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MovieIcon from '@mui/icons-material/Movie';
import HealingIcon from '@mui/icons-material/Healing';
import ImageIcon from '@mui/icons-material/Image';
import GroupsIcon from '@mui/icons-material/Groups';

export const PointIcon = ({pointType} : {pointType: LearningDocumentPointType}) => {
    const iconStyle: React.CSSProperties = {width:"4.25rem", display:"flex", alignContent:"center", justifyContent: "center"}
    switch(pointType) {
        case LearningDocumentPointType.question:
            return (
                <div style={iconStyle}>
                    <QuestionMarkIcon fontSize='large' sx={{color: colorConfigs.learningDocumentIcons.question}}/>
                </div>
            )
        case LearningDocumentPointType.trainerAction:
            return (
                <div style={iconStyle}>
                    <EmojiPeople fontSize='large' sx={{color: colorConfigs.learningDocumentIcons.trainerAction}}/>
                </div>
            )
        case LearningDocumentPointType.traineeDemo:
            return (
                <div style={iconStyle}>
                    <GroupsIcon fontSize='large' sx={{color: colorConfigs.learningDocumentIcons.traineeDemo}}/>
                </div>
            )
        case LearningDocumentPointType.previousIncident:
            return (
                <div style={iconStyle}>
                    <HealingIcon fontSize='large' sx={{color: colorConfigs.learningDocumentIcons.previousIncident}}/>
                </div>
            )
        case LearningDocumentPointType.video:
            return (
                <div style={iconStyle}>
                    <MovieIcon fontSize='large' sx={{color: colorConfigs.learningDocumentIcons.video}}/>
                </div>
            )
        case LearningDocumentPointType.image:
            return (
                <div style={iconStyle}>
                    <ImageIcon fontSize='large' sx={{color: colorConfigs.learningDocumentIcons.image}}/>
                </div>
            )
        default:
            return <div style={iconStyle}/>
    }
}