import { Checkbox, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { TMTrainingGroupParent, TeamMemberTrainingGroup } from 'src/dtos/HumanResources/TeamMemberTrainingGroup.dto';
import { TrainingGroupTypeString } from 'src/dtos/Training/TrainingGroup.dto';

type Props = {
    teamMember: TeamMemberTrainingGroup, 
    setTeamMembers: React.Dispatch<React.SetStateAction<TeamMemberTrainingGroup[]>>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    trainingGroupParent: TMTrainingGroupParent;
}
function TrainingGroupCheckbox({teamMember, setTeamMembers, setMessageItem, trainingGroupParent}:Props) {

    const assignedTrainingGroups = trainingGroupParent.trainingGroups.filter(trainingGroup => teamMember.trainingGroupIds.includes(trainingGroup.id))
    const checked = assignedTrainingGroups.length > 0

    const addTrainingGroupMutation = useMutation({
        mutationFn: (addParentTrainingGroupId: string) => {
            return TeamMemberService.AddTraineeTrainingGroup(teamMember.teamMemberId, addParentTrainingGroupId)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: `Training Group added to ${teamMember.teamMemberName} successfully`})
            setTeamMembers(prevMembers =>
                prevMembers.map(member => (member.teamMemberId === teamMember.teamMemberId ? data : member))
            );
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const removeTrainingGroupMutation = useMutation({
        mutationFn: (removeParentTrainingGroupId: string) => {
            return TeamMemberService.RemoveTraineeTrainingGroup(teamMember.teamMemberId, removeParentTrainingGroupId)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: `Training Group removed from ${teamMember.teamMemberName} successfully`})
            setTeamMembers(prevMembers =>
                prevMembers.map(member => (member.teamMemberId === teamMember.teamMemberId ? data : member))
            );
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleCheckBoxClick = () => {
        if(checked){
            removeTrainingGroupMutation.mutate(trainingGroupParent.id)
        } else {
            addTrainingGroupMutation.mutate(trainingGroupParent.id)
        }
    }

    const pending = addTrainingGroupMutation.isPending || removeTrainingGroupMutation.isPending;

    return (
    <>
        {!pending ?
            <>
                <Checkbox
                    checked={checked}
                    onClick={() => handleCheckBoxClick()}
                />
                <Typography variant='caption'>
                    {assignedTrainingGroups.map((assignedTrainingGroup, index) => (
                        <React.Fragment key={index}>
                            {TrainingGroupTypeString[assignedTrainingGroup.type]}
                            {index !== assignedTrainingGroups.length-1 && " | "}
                        </React.Fragment>
                    ))}
                </Typography>
            </>
            :
            <div style={{display:"flex", justifyContent:"center"}}>
                <div className='loader'/>
            </div>
        } 
    </>
  )
}

export default TrainingGroupCheckbox