import { BaseModel } from "../BaseModel.dto";
import { Expiry } from "../Checklists/Expiry.dto";

export enum LearningItemType {
    attendanceSheet,
    talentLMS
}

export const LearningItemTypeString: string[] = [
    "Attendance Sheet",
    "TalentLMS Course"
]

export interface LearningItem extends BaseModel { 
    id: string;
    label: string;
    type: LearningItemType;
    talentLMSCourseId?: string;
    expiry: Expiry
}