import React from 'react'
import InfoButton from './InfoButton/InfoButton'

type Props = {
    title: string;
    required?: boolean;
    infoText?: string;
    width?: string;
    marginTop?: string;
    marginBottom?: string;
    fontSize?: number;
}

function QuestionTitle({title, required, infoText, width, marginTop, marginBottom, fontSize}: Props) {
  return (
    <>
        <p style={{width: width, marginTop: marginTop, marginBottom: marginBottom, fontSize: fontSize}}>
            {title}
            {required && <span style={{color: 'red'}}>*</span>}
            {infoText && <InfoButton infoText={infoText}/>} 
        </p>
    </>
    
  )
}

export default QuestionTitle