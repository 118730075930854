import { Stack, Autocomplete, TextField, IconButton, Pagination } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import { JournalEntryTypeString, JournalEntryStatusString, JournalEntryStatus, JournalEntryType, JournalEntryQueryOptions } from 'src/dtos/Checklists/AttractionInspections/Journal.dto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ListParameters } from 'src/Services/ListParameters';
import { useQuery } from '@tanstack/react-query';
import JournalEntryService from 'src/Services/Checklists/Inspections/AttractionJournalEntryService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import EntriesTable from './Components/EntriesTable';

const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30,
}
function JournalSearch() {
    const{attractionId} = useParams() 
    const [searchParams, setSearchParams] = useSearchParams()

    const initialQueryOptions: JournalEntryQueryOptions = {
        attractionId: attractionId??"",
        statuses: searchParams.get("statuses") ? JSON.parse(decodeURIComponent(searchParams.get("statuses") as string)) : [],
        types: searchParams.get("types") ? JSON.parse(decodeURIComponent(searchParams.get("types") as string)) : [],
        from: searchParams.get("from") ? new Date(searchParams.get("from") as string) : undefined,
        to: searchParams.get("to") ? new Date(searchParams.get("to") as string) : undefined,
        reportedBySearch: searchParams.get("reportedBySearch") ?? "",
    }
    const [listParams, setListParams] = useState<ListParameters>(initialListParams);
    const [queryOptions, setQueryOptions] = useState<JournalEntryQueryOptions>(initialQueryOptions)
    const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm") ?? "")
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const location = useLocation()

    const typeOptions = [
        JournalEntryType.serviceTask,
        JournalEntryType.issueFaultRecord,
        JournalEntryType.outOfService,
        JournalEntryType.duplicateEntry
    ]
    const statusOptions = [
        JournalEntryStatus.completed,
        JournalEntryStatus.outstanding
    ]

     //#region queries
     const entriesQuery = useQuery({
        queryKey: ["journalEntries", listParams, queryOptions, searchTerm],
        queryFn: async () => {
            return await JournalEntryService.GetList(queryOptions, listParams, searchTerm)
        },
        placeholderData: (prev) => prev //used to keep previous data when fetching with new query params
    })

    useEffect(() => {
        if(entriesQuery.isError){
            setMessageItem({error: entriesQuery.error})
        }
    }, [entriesQuery.error, entriesQuery.isError])
    //#endregion
    
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setListParams({...listParams, page:value}); 
    };

    const totalPages = Math.ceil((entriesQuery.data?.totalRecords??0) / listParams.pageSize)

    return (
    <>
        <div style={{padding:"1rem"}}>
            <Stack spacing={1}>
                <Stack direction={"row"} spacing={1}>
                    <div>
                        <Link to={location.pathname.replace("/search", "")}>
                            <IconButton>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Link>
                    </div>
                    <TextField
                        id="searchInput"
                        name="searchInput"
                        label="Search"
                        size="small"
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoFocus
                    />
                </Stack>
                <div style={{overflowX: "scroll", padding:"12px"}}>
                    <Stack direction={"row"} spacing={1}>
                        <Autocomplete
                            disablePortal
                            id="type"
                            multiple
                            disableCloseOnSelect
                            disableClearable
                            options={typeOptions}
                            value={queryOptions.types}
                            onChange={(e, value) => {
                                setQueryOptions({...queryOptions, types: value})
                                if(value.length === 0)
                                    searchParams.delete("types")
                                else 
                                    searchParams.set("types", encodeURIComponent(JSON.stringify(value)))
                                setSearchParams(searchParams)
                            }}
                            getOptionLabel={(option) => JournalEntryTypeString[option]}
                            limitTags={1}
                            size="small"
                            sx={{width: "12rem"}}
                            renderInput={(params) => 
                                <TextField {...params} label="Type"/>
                            }
                        />
                        <Autocomplete
                            disablePortal
                            id="status"
                            multiple
                            disableCloseOnSelect
                            disableClearable
                            options={statusOptions}
                            value={queryOptions.statuses}
                            onChange={(e, value) => {
                                setQueryOptions({...queryOptions, statuses: value})
                                if(value.length === 0)
                                    searchParams.delete("statuses")
                                else 
                                    searchParams.set("statuses", encodeURIComponent(JSON.stringify(value)))
                                setSearchParams(searchParams)
                            }}
                            getOptionLabel={(option) => JournalEntryStatusString[option]}
                            limitTags={1}
                            size="small"
                            sx={{width: "12rem"}}
                            renderInput={(params) => 
                                <TextField {...params} label="Status"/>
                            }
                        />
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                    name="From"
                                    format='DD/MM/YYYY'
                                    value={queryOptions.from ? dayjs(queryOptions.from) : null}
                                    onChange={(value) => {
                                        if (value) {
                                            // Set time to midnight
                                            const startOfDay = value.startOf('day').toDate();
                                            setQueryOptions({...queryOptions, from: startOfDay});
                                            searchParams.set("from", startOfDay.toString())
                                        } else {
                                            setQueryOptions({...queryOptions, from: undefined});
                                            searchParams.delete("from")
                                        }
                                        setSearchParams(searchParams)
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            label: "From"
                                        }
                                    }}
                                    sx={{width:"10rem"}}
                                />
                            </LocalizationProvider>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                                name="To"
                                format='DD/MM/YYYY'
                                value={queryOptions.to ? dayjs(queryOptions.to) : null}
                                onChange={(value) => {
                                    if (value) {
                                        // Set time to 11:59 PM
                                        const endOfDay = value.endOf('day').toDate();
                                        setQueryOptions({...queryOptions, to: endOfDay});
                                        searchParams.set("to", endOfDay.toString())

                                    } else {
                                        setQueryOptions({...queryOptions, to: undefined});
                                        searchParams.delete("to")
                                    }
                                    setSearchParams(searchParams)
                                }}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        label: "To"
                                    }
                                }}
                                sx={{width:"10rem"}}
                            />
                        </LocalizationProvider>
                        <TextField
                            id="reportedBySearch"
                            name="reportedBySearch"
                            label="Reported By"
                            size="small"
                            placeholder='Search'
                            value={queryOptions.reportedBySearch}
                            onChange={(e) => {
                                if(e.target.value){
                                    setQueryOptions({...queryOptions, reportedBySearch: e.target.value});
                                    searchParams.set("reportedBySearch", e.target.value)
                                } else {
                                    setQueryOptions({...queryOptions, reportedBySearch: e.target.value});
                                    searchParams.delete("reportedBySearch")
                                }
                                setSearchParams(searchParams)
                            }}
                        />
                    </Stack>
                </div>
            </Stack>
            <EntriesTable 
                entriesQuery={entriesQuery} 
                pageSize={listParams.pageSize}
            />
            {totalPages > 1 && (
                <Pagination
                    count={totalPages}
                    page={listParams.page}
                    onChange={handlePageChange}
                    sx={{marginTop:"0.5rem"}}
                />
            )}
        </div>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
  )
}

export default JournalSearch