import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { ListParameters, SortDirection } from "src/Services/ListParameters";
import { GetBearer } from "src/Utils/authUtils";
import { LearningDocument, LearningDocumentType } from "src/dtos/Training/LearningDocument.dto";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { NameDto } from "src/dtos/NameDto.dto";
import qs from "qs";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/LearningDocument";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class LearningDocumentService {
    static GetDefaultValues(){
        const defaultLearningDocumentValues: LearningDocument = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            type: LearningDocumentType.trainingDocumentNTM,
            description: "",
            expiry: {
                type: "",
                afterValue: null,
                afterUnits: "",
                everyUnit: "",
                everyWeekdayValue: "",
                everyMonthValue: "",

                everyTimeValue: dayjs().startOf("day").format("HH:mm"),
            },
            departmentId: undefined, 
            department: null,
            enabled: true,
        }
        return defaultLearningDocumentValues
    }

    static async Get(id: string): Promise<LearningDocument> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocument> = await axios.get<LearningDocument>(`${apiBaseUrl}/get/${id}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, departmentId?: string, type?: LearningDocumentType, includeUnpublished?: boolean, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    type: type,
                    departmentId: departmentId,
                    includeUnpublished: includeUnpublished,
                    includeDisabled: includeDisabled                
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }
    
    static async GetNames(documentTypes?: LearningDocumentType[]): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    documentTypes: documentTypes
                },
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(learningDocumentData: LearningDocument): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,
                learningDocumentData, 
                {
                    headers: {
                        Authorization: bearer
                    },
                }
            );
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, learningDocumentData: LearningDocument): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, learningDocumentData, {
                headers: {
                    Authorization: bearer
                },
            }
            );
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}