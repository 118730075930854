import { BaseModel } from "../BaseModel.dto";

export interface AWUser extends BaseModel {
    id: string;
    name: string;
    email: string;
    assignedRoles: AssignedRole[];
}

export enum RoleGroupType {
    System,
    Departments,
    AttractionInspections,
    Training
} 

export interface Role {
    id: string;
    label: string;
    description: string;
    group: RoleGroupType;
}

export interface AssignedRole {
    teamMemberId: string;
    roleId: string;
}