import React from 'react'
import Section from '../Section/Section'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import './styles.scss'
import { Status } from 'src/dtos/Statuses';

type Props = {
    checklistName: string;
    checklistStatus: Status;
    submitInspection?: () => void;
}

function FinalSignOffSection({checklistName, checklistStatus, submitInspection}: Props) {
    
    const handleSubmitClick = () => {
        if(submitInspection){
            submitInspection();
        }
    }

    return (
    <>
        <Section
            sectionTitle='Final Sign Off'
            checkPrevSectionPassed={() => true} //Can always be opened
        >
            <QuestionTitle
                title={`I certify that this checklist, ${checklistName}, has been answered to the best of my knowledge and abilities.`}
                required
            />
            <FormControlLabel control={<Checkbox />} label="I agree" />
            
            {checklistStatus === Status.Passed ? (
                <>
                    <Typography>{`${checklistName} has passed.`}</Typography>
                </>
            ) : checklistStatus === Status.Inprogress ? (
                <>
                    <Typography>{`${checklistName} is incomplete and therefore can only be submitted as failed`}</Typography>
                </>
            ) : (
                <>
                    <Typography>{`${checklistName} has failed.`}</Typography>
                </>
            )}
            <Button onClick={handleSubmitClick} variant='contained' color={checklistStatus === Status.Passed ? "success" : "error"}>{`Submit as ${checklistStatus === Status.Passed ? "passed" : "failed"}`}</Button>
        </Section>
    </>
    
  )
}

export default FinalSignOffSection