import { Autocomplete, CircularProgress, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import { useQuery } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { JournalEntryDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import JournalEntryService from 'src/Services/Checklists/Inspections/AttractionJournalEntryService'

type Props = {
    formik: FormikProps<JournalEntryDto>
}
function DuplicateEntry({formik}: Props) {

    const {attractionId} = useParams()

    const outstandingEntiresQuery = useQuery({
        queryKey: ["GetOutstandingEntries", attractionId],
        queryFn: async () => {
            if(attractionId){
                return await JournalEntryService.GetOutstandingEntries(attractionId)
            }
            return []
        }
    })
    const loading = outstandingEntiresQuery.isLoading
    return (
        <>
            <Stack>

                <Typography variant='h6'>Linked Entry</Typography>   
                <Autocomplete
                    id="linkedEntry"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label}
                    options={outstandingEntiresQuery.data ?? []}
                    value={formik.values.linkedEntry}
                    loading={loading}
                    onChange={(_, value) => {
                        formik.setFieldValue('linkedEntry', value)
                    }}
                    sx={{ width: "100%" }}
                    size='small'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                    {loading && <CircularProgress size={20}/>}
                                </>
                                ),
                            }}
                            helperText={formik.touched.linkedEntry && formik.errors.linkedEntry}
                            error={formik.touched.linkedEntry && Boolean(formik.errors.linkedEntry)}
                        />
                    )}
                    />
                <Typography variant='h6'>Description</Typography>
                <EditableLabel
                    value={formik.values.description ?? ""}
                    setValue={(newValue: string) => formik.setFieldValue("description", newValue)}
                    placeholder='Describe any additional useful information'
                    onBlur={() => formik.setFieldTouched('description', true, false)}
                    helperText={formik.touched.description && formik.errors.description}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                /> 
            </Stack>
        </>
  )
}

export default DuplicateEntry