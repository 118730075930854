import axios, { AxiosResponse } from "axios";
import { AttractionDto, AttractionNameDto, AttractionStringToIndex, AttractionType, AttractionTypeIndexToString, ChildEntity, CreateAttractionDto, ListResponseDto } from "../../dtos/AwTypes";
import { ListParameters, SortDirection } from "../ListParameters";
import { GetBearer } from "src/Utils/authUtils";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/attraction";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class AttractionService {
    static GetDefaultValues(){
        const defaultAttractionValues: AttractionDto = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            description: "",
            residingArea: null,
            childEntities: [],
            enabled: true,
            createdBy: "Unknown",
            createdOn: new Date('1/1/1900'),
            type: undefined,
            deviceCapacityLabel: "",
            deviceMinimumCapacity: "",
            deviceMaximumCapacity: ""
        }
        return defaultAttractionValues
    }

    static async GetNames(): Promise<AttractionNameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<AttractionNameDto[]> = await axios.get<AttractionNameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, type?: AttractionType, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/List`, {
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    type: type,
                    includeDisabled: includeDisabled                
                },
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static attractionDtoToCreateAttractionDto(data: AttractionDto) {
        const createAttractionData: CreateAttractionDto = {
            id: data.id !== "00000000-0000-0000-0000-000000000000" ? data.id : undefined,
            label: data.label,
            description: data.description, 
            childEntity: {
                label: data.label,
                requiredToPass: true,
                requiresTagging: true,
                children: data.childEntities ?? []
            },
            type: data.type && AttractionStringToIndex[data.type],
            location:{
                label: data.label,
                description: data.description,
                parentLocationId: data.residingArea?.id,
            },
            deviceMaximumCapacity: data.deviceMaximumCapacity === "" ? undefined : data.deviceMaximumCapacity,
            deviceMinimumCapacity: data.deviceMinimumCapacity === "" ? undefined : data.deviceMinimumCapacity,
            deviceCapacityLabel: data.deviceCapacityLabel,
            enabled: true
        }
        return createAttractionData
    }

    static async Create(attractionsData: AttractionDto): Promise<AxiosResponse> {
        const createAttractionData: CreateAttractionDto = this.attractionDtoToCreateAttractionDto(attractionsData);
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(
                `${apiBaseUrl}/Create`,
                createAttractionData, {
                    headers: {
                        Authorization: bearer
                    }
                }
            );
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, attractionsData: AttractionDto): Promise<AxiosResponse> {
        const updateAttractionData: CreateAttractionDto = this.attractionDtoToCreateAttractionDto(attractionsData);
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(
                `${apiBaseUrl}/Update`,
                updateAttractionData,
                {
                    params: {id: id},
                    headers: {
                        Authorization: bearer
                    }
                }
            );
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Get(id: string): Promise<AttractionDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<CreateAttractionDto> = await axios.get<CreateAttractionDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            const attractionData: AttractionDto = {
                id: response.data.id ?? "00000000-0000-0000-0000-000000000000",
                label: response.data.label, 
                description: response.data.description,
                childEntities: response.data.childEntity?.children,
                type: response.data.type !== undefined ? AttractionTypeIndexToString[response.data.type]: undefined,
                residingArea: response.data.location && {id: response.data.location.parentLocationId ?? "00000000-0000-0000-0000-000000000000", label: response.data.location.parentLocation?.label ?? ""},
                deviceMinimumCapacity: response.data.deviceMinimumCapacity === null ? "" : response.data.deviceMinimumCapacity,
                deviceMaximumCapacity: response.data.deviceMaximumCapacity === null ? "" : response.data.deviceMaximumCapacity,
                deviceCapacityLabel: response.data.deviceCapacityLabel,
                enabled: response.data.enabled,
                createdBy: response.data.createdBy,
                createdOn: response.data.createdOn,
                modifiedOn: response.data.modifiedOn,
                modifiedBy: response.data.modifiedBy
            }
            return attractionData;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetComponents(id: string): Promise<ChildEntity> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ChildEntity> = await axios.get<ChildEntity>(`${apiBaseUrl}/Components/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            const data = response.data;
            return data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}