import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, Typography } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { FormikProps } from 'formik';
import { TeamMember } from 'src/dtos/HumanResources/TeamMember.dto';

type Props = {
    formik: FormikProps<TeamMember>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    loading: boolean;
}
function TmTraining({formik, setMessageItem, loading}: Props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [openTab, setOpenTab] = React.useState(searchParams.get("nestedTab") ?? 'CompletedTraining');
    

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        searchParams.set("nestedTab", tabValue)
        setSearchParams(searchParams)
    };

    return (
        <>
            <TabContext value={openTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Completed Training" value="CompletedTraining"></Tab>
                        <Tab label="Upcoming Sessions" value="Sessions"></Tab>
                    </TabList>
                </Box>
                    <TabPanel value="CompletedTraining">
                        <Typography variant='h5'>Completed Training</Typography>
                        Table of all completed training including online, attendance sheet, training sessions, assessments.
                    </TabPanel>
                    <TabPanel value="Sessions">
                        Table of all the upcoming training sessions this team member is a trainee or trainer in. 
                    </TabPanel>
            </TabContext>
        </>
    )
}

export default TmTraining