import { DeleteForever } from '@mui/icons-material'
import { Typography, TextField, Button } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import LearningItemResponseService from 'src/Services/Training/LearningItemResponseService'
import { guidRegex } from 'src/Utils/helperFunc'
import { showDialog } from 'src/components/AlertDialog/AlertDialog'
import { MessageItem } from 'src/components/errorHandlingSnackbar'

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function DeleteLIResponse({setMessageItem}: Props) {
    const [id, setId] = useState("")

    const deleteMutation = useMutation({
        mutationFn: (responseId: string) => {
            return LearningItemResponseService.DeleteResponse(responseId)
        },
        onSuccess: () => {
            setMessageItem({successMessage:"Learning Item Response Deleted"})
            console.log(`Learning Item Response with ID: '${id}' deleted`)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    const handleDeletePress = async () => {

        if(!guidRegex.test(id)){
            setMessageItem({errorMessage: "Learning Item ResponseID must be a guid"})
            return
        }
        const deleteResponse = await showDialog({
            title: `Delete Learning Item Response`,
            contentText: `Are you sure you want to delete the learning item response with id '${id}', this action is irreversible.`,
            acceptText: "Delete Permanently",
            declineText: "Cancel"
        })

        if(deleteResponse){
            deleteMutation.mutate(id)
        }
    }

    return (
    <div style={{display:"flex", flexDirection:"column"}}>
        <Typography variant='h6'>Delete Learning Item Response</Typography>
        <Typography>Learning Item Response ID</Typography>
        <TextField
            id="LearningItemResponseId"
            name="LearningItemResponseId"
            value={id}
            onChange={(e) => setId(e.target.value)}
            size='small'
        />
        <div style={{paddingTop:"1rem"}}>
            <Button onClick={handleDeletePress} color='error' variant='contained'>
                Delete<DeleteForever/>
            </Button>
        </div>
    </div>
    )
}

export default DeleteLIResponse