import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { ListParameters } from "src/Services/ListParameters";
import { GetBearer } from "src/Utils/authUtils";
import { CommentDto, JournalEntryDto, JournalEntryQueryOptions, JournalEntryStatus, JournalEntryType } from "src/dtos/Checklists/AttractionInspections/Journal.dto";
import { FileMetadata } from "src/dtos/FileMetadata.dto";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { NameDto } from "src/dtos/NameDto.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/AttractionJournalEntry";
const debug = process.env.REACT_APP_DEBUG;

export const journalEntryTypesWithStatus: JournalEntryType[] = [
    JournalEntryType.serviceTask,
    JournalEntryType.outOfService,
    JournalEntryType.ThirdPartyAuditFinding
]
export default class JournalEntryService {

    static GetDefaultValues(attraction?: NameDto, type?: JournalEntryType) {
        const defaultVals: JournalEntryDto = {
            id: "00000000-0000-0000-0000-000000000000",
            entryId: "",
            attraction: attraction ? attraction : {id: "", label: ""},
            label: "",
            type: type ?? JournalEntryType.serviceTask,
            status: (type && !journalEntryTypesWithStatus.includes(type)) ? JournalEntryStatus.NA : JournalEntryStatus.outstanding,
            description: "",
            resolutionDescription: "",
            enabled: true
        }

        return defaultVals
    }

    static GetDefaultCommentValues() {
        const defaultVals: CommentDto = {
            id: "00000000-0000-0000-0000-000000000000",
            description: "",
            enabled: true,
        }
        return defaultVals
    }

    static async Get(id: string): Promise<JournalEntryDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<JournalEntryDto> = await axios.get<JournalEntryDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(queryOptions: JournalEntryQueryOptions, listParam?: ListParameters, searchTerm?: string) : Promise<ListResponseDto<JournalEntryDto>> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto<JournalEntryDto>> = await axios.get<ListResponseDto<JournalEntryDto>>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    ...listParam,
                    ...queryOptions,
                    searchTerm: searchTerm
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
        
    }

    static async GetOutstandingEntries(attractionId: string) : Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetOutstandingEntries/${attractionId}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
        
    }

    static async GetListAttachments(journalEntryId: string, page: number, pageSize: number) : Promise<ListResponseDto<FileMetadata>> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto<FileMetadata>> = await axios.get<ListResponseDto<FileMetadata>>(`${apiBaseUrl}/GetListAttachments/${journalEntryId}`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: page, 
                    pageSize: pageSize
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetListComments(journalEntryId: string, listParam?: ListParameters) : Promise<ListResponseDto<CommentDto>> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto<CommentDto>> = await axios.get<ListResponseDto<CommentDto>>(`${apiBaseUrl}/GetListComments/${journalEntryId}`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    ...listParam,
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: JournalEntryDto): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, data: JournalEntryDto): Promise<AxiosResponse<JournalEntryDto>> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddComment(journalEntryId: string, data: CommentDto): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddComment/${journalEntryId}`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateComment(commentId: string, data: CommentDto): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateComment/${commentId}`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddFile(journalEntryId: string, file: File): Promise<AxiosResponse<FileMetadata>> {
        try {
            const bearer = await GetBearer();

            const formData = new FormData();
            formData.append("file", file);

            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/AddFile/${journalEntryId}`,formData,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DeleteFile(journalEntryId: string, fileId: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();

            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeleteFile`,{
                headers: {
                    Authorization: bearer
                }, 
                params: {
                    journalEntryId: journalEntryId,
                    fileId: fileId
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}