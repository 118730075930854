import { Stack, Button } from "@mui/material"
import { FormikProps } from "formik"
import React from "react"
import { AttendanceSheetAttendee, AttendanceSheetResponse } from "src/dtos/Training/AttendanceSheetResponse.dto"
import { LearningDocumentType } from "src/dtos/Training/LearningDocument.dto"

type Props = {
    formik: FormikProps<AttendanceSheetResponse>
    trainee: AttendanceSheetAttendee
    type: LearningDocumentType
}
function ReadyForAssessment({formik, trainee, type}:Props){

    const traineeIndex = formik.values.attendees.findIndex(attendee => attendee === trainee)

    const handleYesClick = () => {
        if(trainee.readyForAssessment === true){
            formik.setFieldValue(`attendees[${traineeIndex}].readyForAssessment`, null)
        } else {
            formik.setFieldValue(`attendees[${traineeIndex}].readyForAssessment`, true)
        }
    }

    const handleNoClick = () => {
        if(trainee.readyForAssessment === false){
            formik.setFieldValue(`attendees[${traineeIndex}].readyForAssessment`, null)
        } else {
            formik.setFieldValue(`attendees[${traineeIndex}].readyForAssessment`, false)
        }
    }
    
    const yesText = type === LearningDocumentType.trainingDocumentRTM ? "C" : "Yes"
    const noText = type === LearningDocumentType.trainingDocumentRTM ? "NYC" : "No"

    return (
        <Stack direction="row" spacing={1} sx={{display:"flex", justifyContent: "center"}}>
            <Stack direction="row" spacing={1}>
                <Button 
                    style={{maxWidth: '3rem', maxHeight: '2.5rem', minWidth: '3rem', minHeight: '2.5rem'}}
                    size="small"
                    variant={trainee.readyForAssessment === true ? "contained" : "outlined"}
                    color="success" 
                    onClick={handleYesClick}
                >
                    {yesText}
                </Button>
                <Button 
                    style={{maxWidth: '3rem', maxHeight: '2.5rem', minWidth: '3rem', minHeight: '2.5rem'}}
                    size="small"
                    variant={trainee.readyForAssessment === false ? "contained" : "outlined"} 
                    color="warning" 
                    onClick={handleNoClick}
                >
                    {noText}
                </Button>
            </Stack>
        </Stack>
    )
}

export default ReadyForAssessment