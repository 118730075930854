import { List, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material";
import React from "react";
import { NameDto } from "src/dtos/NameDto.dto";

type Props = {
    searchTerm: string;
    items: readonly NameDto[];
    handleToggle: (value: NameDto) => () => void
    checked: readonly NameDto[]
}
export default function ItemsList({searchTerm, items, handleToggle, checked}: Props) {
    return (
        <List
            sx={{
                width: 400,
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
        >
            {items.filter(item => item.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())).map((value: NameDto) => {
                const labelId = `transfer-list-all-item-${value.id}-label`;
        
                return (
                    <ListItemButton
                    key={value.id}
                    role="listitem"
                    onClick={handleToggle(value)}
                    >
                    <ListItemIcon>
                        <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.label}`} />
                    </ListItemButton>
                );
            })}
        </List>
        )
}