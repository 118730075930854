import React, { useRef } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import JournalEntryService from 'src/Services/Checklists/Inspections/AttractionJournalEntryService';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { CommentDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto';
import { MessageItem } from 'src/components/errorHandlingSnackbar';

const validationSchema = yup.object({
    description: yup.string()
        .required("Comment is a required field")
        .max(500, "Comment cannot be more than 500 characters")
})

type Props = {
    journalEntryId: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    refetchJournalComments: () => void;
}
function NewComment({journalEntryId, setMessageItem, refetchJournalComments}:Props) {
    const descriptionInputRef = useRef<HTMLDivElement>(null); 

    const formik = useFormik({
        validationSchema: validationSchema, 
        initialValues: JournalEntryService.GetDefaultCommentValues(),
        onSubmit: (values) => {
            addCommentMutation.mutate(values)
        },
        validateOnChange: false,
        validateOnBlur: false
    })

    const addCommentMutation = useMutation({
        mutationFn: (values:CommentDto) => {
            return JournalEntryService.AddComment(journalEntryId, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Comment added successfully!"})
            formik.resetForm()
            refetchJournalComments()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleAdd = () => {
        formik.handleSubmit()
    }

    const handlekeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.key === "Enter"){
            event.preventDefault();
            handleAdd();
            descriptionInputRef.current && descriptionInputRef.current.blur();
        }
    }

    return (
        <Stack direction={"row"} spacing={1}>
            <TextField
                ref={descriptionInputRef}
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyDown={(e) => handlekeyPress(e)}
                variant='outlined'
                size="small"
                multiline
                maxRows={4}
                sx={{width:"100%"}}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}

            />
            <div>
                <LoadingButton 
                    variant='contained' 
                    disabled={!formik.dirty}
                    onClick={handleAdd} 
                    loading={addCommentMutation.isPending}
                >
                    Add
                </LoadingButton>
            </div>
        </Stack>
    )
}

export default NewComment