import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Stack, Tab, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SessionSettings from './Components/SessionSettings/SessionSettings';
import TeamMembers from './Components/TeamMembers';
import { useFormik } from 'formik';
import * as yup from "yup";
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { TrainingSession } from 'src/dtos/Training/TrainingSession.dto';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dateOptions } from 'src/config/constants';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { useMutation, useQuery } from '@tanstack/react-query';
import { guidRegex } from 'src/Utils/helperFunc';
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto';
import TrainingSessionDocumentResponses from './Components/TrainingSessionDocumentResponses';

const validationSchema = yup.object({
    label: yup.string().required("Session Name is required"),
    date: yup.date().required("Session Date is required"),
    maxTrainees: yup.number().integer().min(1, "Max Trainees must be greater than or equal to 1").notRequired(),
})

function TrainingSessionCreator() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [openTab, setOpenTab] = React.useState(searchParams.get("openTab") ?? 'SessionSettings');
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const {id} = useParams();
    const urlIdRegex = new RegExp(`/${id}$`)
    const navigate = useNavigate()

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        searchParams.set("openTab", tabValue)
        setSearchParams(searchParams)
    };

    //#region queries
    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id)){
                return await TrainingSessionService.Get(id);
            }
            return null
        }
    })

    //HandleQuery Errors
    useEffect(() => {
        if(trainingSessionQuery.isError){
            setMessageItem({error: trainingSessionQuery.error})
        }
    }, [trainingSessionQuery.error, trainingSessionQuery.isError])

    const createMutation = useMutation({
        mutationFn: (values: TrainingSession) => {
            return TrainingSessionService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Session created successfully!"})

            //update URL
            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: TrainingSession}) => {
            return TrainingSessionService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Session updated successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    //#endregion

    const save = async (values: TrainingSession) => {
        if(id === "new"){
            //Create
            createMutation.mutate(values)
        } else if(id !== undefined && guidRegex.test(id)){
            //update
            updateMutation.mutate({id, values})
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: trainingSessionQuery.data ?? TrainingSessionService.GetDefaultValues(),
        onSubmit: (values) => {
            save(values)
        }
    })

    const sessionLocked = () => {
        let now = Math.floor(new Date().getTime() / (1000*60*60*24))
        let sessionDate = formik.values.date && Math.floor(new Date(formik.values.date).getTime() / (1000*60*60*24))
        let sessionLockDate = formik.values.sessionLockDate && Math.floor(new Date(formik.values.sessionLockDate).getTime() / (1000*60*60*24))
        if(!sessionDate){
            return true
        }
        if (formik.values.date && sessionDate < now){
            return true
        }
        if(formik.values.sessionLockOverride === true){
            return false
        }
        if(sessionLockDate === null){
            return false
        }
        if (sessionLockDate < now){
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <Stack spacing={2}>
                <Paper sx={{padding:"1rem"}}>
                    <Typography variant="h5">{formik.values.label === "" ? "New Session" : formik.values.label}</Typography>
                    {formik.values.date && (
                        <Typography>Session Date: {new Date(formik.values.date).toLocaleDateString(undefined, dateOptions)}</Typography>
                    )}
                    {formik.values.maxTrainees && (
                        <Typography>Session Capacity: {`${(formik.values.sessionAttendees && formik.values.sessionAttendees.filter(tm => tm.type === AttendeeType.Trainee).length) ?? 0}/${formik.values.maxTrainees}`}</Typography>
                    )}
                    <Typography>Session {sessionLocked() ? "Locked" : "Unlocked"}</Typography>
                </Paper>
                <Paper>
                    <TabContext value={openTab}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabChange}>
                                <Tab label="Session Settings" value="SessionSettings"/>
                                <Tab label="Team Members" value="TeamMembers"/>
                                <Tab label="Completed & Outstanding Documents" value="CompletedDocuments"/>
                            </TabList>
                        </Box>
                        <TabPanel value="SessionSettings">
                            <SessionSettings 
                                formik={formik}
                                setMessageItem={setMessageItem}
                                loading={updateMutation.isPending || createMutation.isPending}
                            />
                        </TabPanel>
                        <TabPanel value="TeamMembers">
                            <TeamMembers
                                formik={formik}
                                setMessageItem={setMessageItem}
                                sessionLocked={sessionLocked()}
                            />
                        </TabPanel>
                        <TabPanel value="CompletedDocuments">
                            <TrainingSessionDocumentResponses
                                trainingSessionId={formik.values.id}
                                sessionResponses={formik.values.sessionResponses}
                            />
                        </TabPanel>

                    </TabContext>
                </Paper>
            </Stack>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingSessionCreator