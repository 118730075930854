import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { FormikErrors, FormikProps } from 'formik'
import React, { useState } from 'react'
import { LearningDocumentTopic, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto'
import DeleteIcon from '@mui/icons-material/Delete';
import SubTopicCreator from '../SubTopicCreator/SubTopicCreator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import { FocusElement } from 'src/Utils/helperFunc';
import ReorderIcon from '@mui/icons-material/Reorder';
import { DropResult } from 'react-beautiful-dnd';
import DraggableList, { DraggableListItem } from 'src/components/DraggableList/DraggableList';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { reorder } from '../PointsCreator/helper';
import CloseIcon from '@mui/icons-material/Close';


type Props = {
    formik: FormikProps<LearningDocumentVersion>
    topicIndex: number;
    published: boolean;
}

function TopicCreator({formik, topicIndex, published}: Props) {
    const [editMode, setEditMode] = useState(published ? false : (formik.values.topics[topicIndex].label === "" ? true : false))
    const [expanded, setExpanded] = useState(formik.values.topics[topicIndex].label === "" ? true : false)
    const [reorderSubtopicsOpen, setReorderSubtopicsOpen] = useState(false)

    const handleAddSubtopic = () => {
        const newSubtopics = [...formik.values.topics[topicIndex].subtopics, {...LearningDocumentVersionService.GetDefaultSubTopicValues(), sortOrder: formik.values.topics[topicIndex].subtopics.length}]
        formik.setFieldValue(`topics[${topicIndex}].subtopics`, newSubtopics)

        //move focus to new subtopic
        setTimeout(() => {
            FocusElement(-3);
          }, 100);
    }

    const handleDeleteSubtopic = (index: number) => {
        let newSubtopics = [...formik.values.topics[topicIndex].subtopics]
        //remove subtopic
        newSubtopics.splice(index, 1)
        //reorder suborders
        newSubtopics = newSubtopics.map((subtopic, subtopicIndex) => {
            return {...subtopic, sortOrder: subtopicIndex}
        })
        //insert blank subtopic if all deleted
        if(newSubtopics.length === 0){
            newSubtopics = [LearningDocumentVersionService.GetDefaultSubTopicValues()]
        }
        formik.setFieldValue(`topics[${topicIndex}].subtopics`, newSubtopics)
    }

    const handleDeleteTopicClick = (e: any) => {
        e.stopPropagation()
        let newTopics = [...formik.values.topics]
        //remove topic
        newTopics.splice(topicIndex, 1)
        //reorder topics
        newTopics = newTopics.map((topic, newTopicIndex) => {
            return {...topic, sortOrder: newTopicIndex}
        })
        //insert blank topic if all deleted
        if(newTopics.length === 0) {
            newTopics = [LearningDocumentVersionService.GetDefaultTopicValues()]
        }
        formik.setFieldValue(`topics`, newTopics)

    }

    const handleReorderSubtopicsClick = (e: any) => {
        e.stopPropagation();
        setReorderSubtopicsOpen(true);
    }

    const ReorderSubtopicsModal = () => {
        const style = {
            margin: "auto",
            marginTop: "10rem",
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: "1rem",
            boxShadow: 24,
            p: 4,
        };

        const handleClose = () => {
            setReorderSubtopicsOpen(false);
        }

        const onDragEnd = (result: DropResult) => {
            const { destination, source } = result;
            // dropped outside the list
            if (!destination) return;

            //reorderSubtopics
            let newSubtopics = reorder(formik.values.topics[topicIndex].subtopics, source.index, destination.index)
            //set new sortorder
            newSubtopics = newSubtopics.map((subtopic, index) => {
                return {...subtopic, sortOrder: index}
            })
            
            
            formik.setFieldValue(`topics[${topicIndex}].subtopics`, newSubtopics);
        }

        return (
            <>
                <Modal
                    open={reorderSubtopicsOpen}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Stack direction="row" spacing={1} sx={{display:"flex", justifyContent:"space-between"}}>
                            <Typography variant='h6'>Reorder Subtopics</Typography>
                            <div>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                        </Stack>
                        <DraggableList
                            onDragEnd={onDragEnd}
                            droppableId='droppable-list-subtopics'
                        >
                            {formik.values.topics[topicIndex].subtopics && formik.values.topics[topicIndex].subtopics.map((subtopic, index) => (
                                <DraggableListItem
                                    draggableId={`subtopic_${index}`}
                                    index={index}
                                    key={index}
                                >
                                    {(provided) => (
                                        <Stack direction={"row"}>
                                            <div 
                                                {...provided.dragHandleProps} 
                                                style={{ display: "flex", alignItems: "center", marginRight: '8px' }}
                                                >
                                                <DragIndicatorIcon/>
                                            </div>
                                            <Typography>{subtopic.label === "" ? `Subtopic ${index + 1}` : subtopic.label}</Typography>
                                        </Stack>
                                    )}
                                </DraggableListItem>
                            ))}
                        </DraggableList>
                    </Box>
                    
                </Modal>
            </>
        )
    }

    const DeleteTopicDialog = () => {
        const [open, setOpen] = useState(false)

        const handleClick = (e:any) => {
            e.stopPropagation();
            setOpen(true)
        }

        return (
            <>
                <Tooltip title="Delete Topic">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton tabIndex={-1} onClick={handleClick}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>
                        Delete Topic
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete the topic?
                            This action is not reversible!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleDeleteTopicClick}>Delete</Button>
                    </DialogActions>

                </Dialog>
            </>
        )
    }

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="topic"
                id={`${topicIndex}topic_header`}
            >
                <div style={{display:"flex", justifyContent: "space-between", width: "100%"}}>
                    {(published ? false : editMode) ? (
                        <Stack direction="row" spacing={1} style={{width:"100%"}}>
                            <TextField
                                id={`topics[${topicIndex}].label`}
                                name={`topics[${topicIndex}].label`}
                                label="Topic Title"
                                value={formik.values.topics[topicIndex].label}
                                onChange={formik.handleChange}
                                onClick={(e) => e.stopPropagation()}
                                onBlur={formik.handleChange}
                                variant='standard'
                                size="medium"
                                sx={{width:"70%"}}
                            />
                            <TextField
                                id={`topics[${topicIndex}].estimateCompletionMinutes`}
                                name={`topics[${topicIndex}].estimateCompletionMinutes`}
                                label="Estimate Completion Time (Minutes)"
                                value={formik.values.topics?.[topicIndex]?.estimateCompletionMinutes}
                                variant="standard"
                                onChange={(e) => {
                                    if(e.target.value === ""){
                                        formik.setFieldValue(`topics[${topicIndex}].estimateCompletionMinutes`, undefined)
                                    } else {
                                        formik.setFieldValue(`topics[${topicIndex}].estimateCompletionMinutes`, e.target.value)
                                    }
                                }}
                                onClick={(e) => e.stopPropagation()}
                                onBlur={formik.handleBlur}
                                sx={{width:"30%"}}
                                type="number"
                                InputProps={{ inputProps: { min: 1 } }}
                                error={formik.touched.topics && formik.touched.topics[topicIndex]?.estimateCompletionMinutes && Boolean(formik.errors.topics && (formik.errors.topics?.[topicIndex] as  FormikErrors<LearningDocumentTopic>)?.estimateCompletionMinutes)}
                                helperText={formik.touched.topics && formik.touched.topics[topicIndex]?.estimateCompletionMinutes && formik.errors.topics && (formik.errors.topics?.[topicIndex] as  FormikErrors<LearningDocumentTopic>)?.estimateCompletionMinutes}
                            />
                        </Stack>
                    ) : (
                        <Stack direction="row" sx={{width:"100%", marginRight:"1rem", display:"flex", justifyContent:"space-between"}}>
                            <Typography variant='h5'>{formik.values.topics[topicIndex].label.length > 0 ? formik.values.topics[topicIndex].label : "New Topic"}</Typography>
                            <Typography variant='h5'>{formik.values.topics[topicIndex].estimateCompletionMinutes !== null && formik.values.topics[topicIndex].estimateCompletionMinutes !== undefined && `${formik.values.topics[topicIndex].estimateCompletionMinutes}m`}</Typography>
                        </Stack>

                    )}

                    {!published && (
                        <Stack direction="row" spacing={1}>
                            <Tooltip title={editMode ? "Stop Editing Topic" : "Edit Topic"}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <IconButton tabIndex={-1} onClick={(e) => {e.stopPropagation(); setEditMode(!editMode)}}>
                                        {editMode ? (
                                            <EditOffIcon/>
                                        ) : (
                                            <EditIcon/>
                                        )}
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title="Reorder Subtopics">
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <IconButton tabIndex={-1} onClick={handleReorderSubtopicsClick}>
                                        <ReorderIcon/>
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <DeleteTopicDialog/>
                        </Stack>
                    )}

                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    {formik.values.topics[topicIndex].subtopics && formik.values.topics[topicIndex].subtopics.map((subTopic, subTopicIndex) => (
                        <SubTopicCreator
                            key={subTopicIndex}
                            index={subTopicIndex}
                            formik={formik}
                            formikString={`topics[${topicIndex}].subtopics[${subTopicIndex}]`}
                            formikValue={formik.values.topics[topicIndex].subtopics[subTopicIndex]}
                            editMode={published ? false : editMode}
                            DeleteSubpoint={() => handleDeleteSubtopic(subTopicIndex)}
                        />
                    ))}
                    {(published ? false : editMode) && (
                        <div style={{display:"flex", justifyContent: "center"}}>
                            <Button onClick={handleAddSubtopic}>Add Subtopic</Button>
                        </div>
                    )}
                </Stack>
            </AccordionDetails>

            <ReorderSubtopicsModal/>
        </Accordion>
    )
}


export default TopicCreator