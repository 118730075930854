import { Button, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { ChipColor } from 'src/config/constants'
import { LearningDocumentResponseStatus, LearningDocumentResponseStatusString } from 'src/dtos/Training/LearningDocumentResponse.dto'
import { SessionResponseDto } from 'src/dtos/Training/TrainingSession.dto'

type Props = {
    trainingSessionId: string
    sessionResponses: SessionResponseDto[]
}
function TrainingSessionDocumentResponses({trainingSessionId, sessionResponses}:Props) {
    //based on status
    const chipColour: ChipColor[] = [
        "success",
        "primary",
        "default",
        "success",
        "success",
        "secondary",
        "secondary"
    ]

    return (
    <div>
        <Typography variant='h6'>Completed Documents</Typography>
        {sessionResponses.filter(sr => sr.status === LearningDocumentResponseStatus.Completed).map((sessionResponse, index) => (
            <React.Fragment key={index}>
                {sessionResponse.learningDocumentResponseId && (
                        <Stack direction="row" spacing={2} sx={{display:"flex", justifyContent:"Left", alignItems:"center"}}>
                        <Typography>{sessionResponse.learningDocumentLabel}</Typography>
                        <Chip
                            label={LearningDocumentResponseStatusString[sessionResponse.status]}
                            color={chipColour[sessionResponse.status]}
                        />
                        <Link to={`/completed/training/trainingSessions/${trainingSessionId}/${sessionResponse.learningDocumentResponseId}`}>
                            <Button>Open</Button>
                        </Link>
                    </Stack>
                )}
                {sessionResponse.learningItemResponseId && (
                    <Stack direction="row" spacing={2} sx={{display:"flex", justifyContent:"Left", alignItems:"center"}}>
                        <Typography>{sessionResponse.learningItemLabel}</Typography>
                        <Chip
                            label={LearningDocumentResponseStatusString[sessionResponse.status]}
                            color={chipColour[sessionResponse.status]}
                        />
                        <Link to={`/completed/training/trainingSessions/${trainingSessionId}/Attendance/${sessionResponse.learningItemResponseId}`}>
                            <Button>Open</Button>
                        </Link>
                    </Stack>
                )}
            </React.Fragment>
        ))}
        <Stack direction={"row"}>
            <Typography variant='h6'>Outstanding Documents</Typography>
            <QuestionTitle title={''} infoText='Outstanding documents will only show here once the session has been started'/>
        </Stack>
        {sessionResponses.length === 0 && (
            <Typography>Session hasn't been started!</Typography>
        )}
        {sessionResponses.filter(sr => sr.status !== LearningDocumentResponseStatus.Completed).map((sessionResponse, index) => (
            <React.Fragment key={index}>
                {sessionResponse.learningDocumentResponseId && (
                        <Stack direction="row" spacing={2} sx={{display:"flex", justifyContent:"Left", alignItems:"center"}}>
                        <Typography>{sessionResponse.learningDocumentLabel}</Typography>
                        <Chip
                            label={LearningDocumentResponseStatusString[sessionResponse.status]}
                            color={chipColour[sessionResponse.status]}
                        />
                        <Link to={`/user/training/sessions/${trainingSessionId}/${sessionResponse.learningDocumentResponseId}`}>
                            <Button>Open</Button>
                        </Link>
                    </Stack>
                )}
                {sessionResponse.learningItemResponseId && (
                    <Stack direction="row" spacing={2} sx={{display:"flex", justifyContent:"Left", alignItems:"center"}}>
                        <Typography>{sessionResponse.learningItemLabel}</Typography>
                        <Chip
                            label={LearningDocumentResponseStatusString[sessionResponse.status]}
                            color={chipColour[sessionResponse.status]}
                        />
                        <Link to={`/user/training/sessions/${trainingSessionId}/Attendance/${sessionResponse.learningItemResponseId}`}>
                            <Button>Open</Button>
                        </Link>
                    </Stack>
                )}
            </React.Fragment>
        ))}
    </div>
  )
}

export default TrainingSessionDocumentResponses