import { BaseModel } from "../BaseModel.dto";
import { TrainingGroupParent } from "./TrainingGroupParent.dto";

export enum TrainingGroupType {
    NTM,
    RTM,
    ATM,
    Required
}
export const TrainingGroupTypeString : string[] = [
    "NTM",
    "RTM",
    "ATM",
    "Required"
]

export interface TrainingGroup extends BaseModel {
    id: string;
    type: TrainingGroupType;
    trainingGroupParent: TrainingGroupParent;
    learningAreas: LearningArea[];
    materialAllAreas: LearningMaterialItem[]; //material required in all areas
}

export interface LearningArea { //Attraction/outlet/area
    id?: number;
    areaNameId?: string;
    areaName?: LearningAreaName;
    learningMaterials: LearningMaterialItem[];
}

export interface LearningAreaName extends BaseModel {
    id: string;
    label: string;
}

export enum LearningMaterialType {
    learningItem, 
    learningDocument
}
export const LearningMaterialTypeString: string[] = [
    "Learning Item",
    "Learning Document"
]
export interface LearningMaterialItem {
    id?: number;
    type: LearningMaterialType;
    learningItemId?: string;
    learningDocumentId?: string;
}


