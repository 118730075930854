import { Box, IconButton, Stack, Typography, TypographyProps } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import colorConfigs from 'src/config/colorConfigs'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    value: string
    placeholder: string
    setValue?: (newValue: string) => void
    onBlur?: () => void
    typographyProps?: TypographyProps
    autoFocus?: boolean; //If true, the element is focused during the first mount.
    helperText?: React.ReactNode;
    error?: boolean;
}
function EditableLabel({value, placeholder, typographyProps, onBlur, setValue, autoFocus, helperText, error}: Props) {
    const [isEditing, setIsEditing] = useState(false);
    const [isCloseClicked, setIsCloseClicked] = useState(false);
    const editableRef = useRef<HTMLDivElement>(null); 
    
    const handleFocus = () => {
        setIsEditing(true);
    }

    const handleSave = () => {
        setIsEditing(false); // Stop editing mode
        const currentValue = editableRef.current?.textContent ?? "";
        setValue && setValue(currentValue); //Update parent

    }

    const handleBlur = () => {

        if (isCloseClicked) {
            setIsCloseClicked(false); // Reset state
            return;
        }

        handleSave();
        
        onBlur && onBlur();
    }

    const handleCloseClick = (event: React.MouseEvent) => {

        setIsEditing(false); // Stop editing mode
        // Ensure the editableRef content is also reverted
        if (editableRef.current) {
            editableRef.current.textContent = value;
            editableRef.current.blur();
        }
    }

    const handleDoneClick = () => {
        handleSave()
    }

    const handlekeyPress = (e: KeyboardEvent) => {
        if(e.key === "Enter"){
            e.preventDefault();
            handleSave();
            editableRef.current && editableRef.current.blur();
        }
    }

    useEffect(() => {
        if (autoFocus && editableRef.current) {
            editableRef.current.focus(); // Focus on the editable element when the component mounts
        }
    }, [autoFocus]);

    return (
        <Stack direction={"row"} spacing={1}>
            <Stack sx={{width:"100%"}}>
                <Typography 
                    ref={editableRef}
                    suppressContentEditableWarning={true} 
                    contentEditable={true}
                    component={"div"}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    {...typographyProps}
                    onKeyDown={handlekeyPress}
                    sx={{
                        backgroundColor: 'transparent',
                        transition: 'background-color 0.3s ease',
                        ...(!isEditing && {
                            '&:hover': {
                                backgroundColor: colorConfigs.utils.hoverBg,
                            }
                        }),
                        width:"100%",
                        padding:"4px",
                        paddingLeft: "6px",
                        borderRadius:"5px",
                        '&:empty:before': {
                            content: `"${placeholder}"`,
                            opacity: 0.9,
                            fontStyle: 'italic',
                            color: '#999',
                            pointerEvents: 'none',
                            display: 'block',
                        },
                    }}
                >
                    {value}
                </Typography>
                <Typography variant='caption' sx={{paddingLeft: "6px"}} color={error ? "error" : "default"}>
                    {helperText}
                </Typography>

            </Stack>
            <Box>
                {isEditing && (
                    <Stack direction={"row"} sx={{paddingTop:"5px", display:"flex", justifyContent:"end"}} spacing={1}>
                        <IconButton size="small" onMouseDown={handleDoneClick}>
                            <DoneIcon/>
                        </IconButton>
                        <IconButton size="small" onMouseDown={handleCloseClick}>
                            <CloseIcon/>
                        </IconButton>
                        
                    </Stack>
                )}
            </Box>
        </Stack>
    )
}

export default EditableLabel