import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React from 'react'
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService'
import { guidRegex } from 'src/Utils/helperFunc'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { LearningDocumentResponseDto } from 'src/dtos/Training/LearningDocumentResponse.dto'
import { useParams } from 'react-router-dom'
import TrainerRow from './TrainerRow'
import { LoadingButton } from '@mui/lab'

type Props = {
    formik: FormikProps<LearningDocumentResponseDto>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    departmentName?: string
    currentUser: {id: string, name: string}
}
function OrientationDocumentSignOff({formik, setMessageItem, departmentName, currentUser}: Props) {
    
    const {responseId} = useParams();

    const TrainerSignOff = () => {
        const trainers = formik.values.attendanceSheetResponse.attendees.filter(asr => asr.type === AttendeeType.Trainer)        

        const addAssessorMutation = useMutation({
            mutationFn: ({id, microsoftId}: {id: string, microsoftId: string}) => {
                return LearningDocumentResponseService.AddAssessor(id, microsoftId)
            },
            onSuccess: (data) => {
                formik.setFieldValue("attendanceSheetResponse", data.data.attendanceSheetResponse)
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })
        const handleAddTrainer = () => {
            if(responseId && guidRegex.test(responseId)){
                addAssessorMutation.mutate({id: responseId, microsoftId: currentUser.id})
            }
        }
        return (
            <>
            
            <TableContainer component={Paper} sx={{ minWidth: "45rem", width: "52rem" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Signature</TableCell>
                            <TableCell>Time Signed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trainers.map((trainer) => (
                            <TrainerRow
                                key={trainer.id}
                                formik={formik}
                                trainer={trainer}
                            />  
                        ))}
                        {trainers.findIndex(assessor => assessor.teamMember.microsoftAccountId === currentUser.id) === -1 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <LoadingButton onClick={handleAddTrainer} loading={addAssessorMutation.isPending}>Add Myself as a Trainer</LoadingButton>

                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
        )
    }

    return (
        <>
            <Typography variant='h6' sx={{marginTop:"1rem"}}>Trainers</Typography>
            <Typography>
                As the Trainer, I acknowledge I have completed this document myself and I am responsible for the results shown by signing the below section.
            </Typography>
            {formik.values.attendanceSheetResponse && (
                <TrainerSignOff/>
            )}
        </>
    )
}

export default OrientationDocumentSignOff