import { Avatar, Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import assets from 'src/assets';

const LandingPage = () => {

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height:"100vh"}}>
            <Paper sx={{padding: "2rem", blockSize: "fit-content",}} >
                <Stack sx={{display: "flex", alignItems: "center"}} spacing={2}>
                    <Avatar src ={assets.images.logo} sx={{ width: "8rem", height: "8rem", marginBottom:"1rem" }}/>
                    <Typography variant='h3'>AW Hub</Typography>
                    <Typography>Please choose a site to continue</Typography>
                    <Stack direction="row" spacing={10}>
                        <Button variant='contained' size="large" href='/admin'>
                            Admin Site
                        </Button>
                        <Button variant='contained' size="large" href='/user'>
                            User Site
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </div>
    )
}

export default LandingPage