import { ChildEntity } from "../AwTypes";
import { Status } from "../Statuses";
import { BaseModel } from "../BaseModel.dto";
import { DepartmentNameDto } from "../Resources/Department.dto";
import { Expiry } from "./Expiry.dto";

export enum QuestionType {
    YesOrNo = 0,
    Alert = 1,
}


  
export interface ChecklistDto extends BaseModel {
    id: string;
    label: string;
    description?: string;
    expiry: Expiry;
    departmentId?: string;
    department?: DepartmentNameDto | null;
}

export interface InspectionChecklist extends ChecklistDto {
    publishedVersionId?: string;
    checklistVersions?: InspectionChecklistVersion[];
    attractionId?: string;
    attraction?: {id: string; label: string} | null;
    locationId?: string;
    location?: {id: string; label: string} | null;
}

export interface ChecklistVersion extends BaseModel {
    id: string;
    versionLabel: string;
    versionDescription: string;
    status: Status;
    datePublished?: Date;
    publishedBy?: string;
    forceWorkflow: Boolean; //Allow sections to be completed out of order
}

export interface ChecklistVersionName {
    id: string;
    versionLabel: string;
}

export interface InspectionChecklistVersion extends ChecklistVersion{
    repeatingComponentSetId?: number;
    repeatingComponentSet?: ChildEntity | null;
    userSelectedComponents?: boolean;
    sections: InspectionChecklistSection[];
    inspectionChecklistId: string;

    userSelectedComponent?: ChildEntity | null; //Component that is user selected within this inspection
}

export interface ChecklistSection {
    id: number;
    label: string;
    sublabel?: string;
    percentageToPass: number; //Number from 0-1
    sortOrder?: number;
}
export interface InspectionChecklistSection extends ChecklistSection {
    repeatingComponentSetId?: number | null;
    repeatingComponentSet?: ChildEntity | null;
    userSelectedComponents?: boolean;
    previousSectionId?: number;
    previousSection?: InspectionChecklistSection;
    questions: QuestionDto[];

    inspectionChecklistVersionId?: string;
}

export interface ChecklistQuestion {
    id: number;
    label: string;
    number?: number;
    required: boolean;
    additionalInformation?: string;
    type: QuestionType;
    allowPhoto?: boolean;
    allowComment?: boolean;
    sortOrder?: number;
}

export interface QuestionDto extends ChecklistQuestion {
    relatedComponentId?: number;
    relatedComponent?: ChildEntity | null;
    repeatOnComponentSet?: boolean;
    repeatOnComponentSetUserSelected?: boolean;
    previousQuestionId?: number;
    previousQuestion?: QuestionDto;

    inspectionChecklistSectionId?: number;
}
  

export interface VersionDto {
    id?: number;
    label: string;
    description?: string;
    status: Status.Published | Status.Draft | Status.Outdated;
    publishedDate?: Date;
}