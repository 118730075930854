import { Box, Button, FormControlLabel, IconButton, Modal, Switch, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ListParameters } from 'src/Services/ListParameters';
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService';
import { StyledTableRow } from 'src/components/Datatable/StyledTableRow';
import { TableHeader } from 'src/components/Datatable/TableHeader';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import colorConfigs from 'src/config/colorConfigs';
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import { TrainingGroupParent } from 'src/dtos/Training/TrainingGroupParent.dto';
import EditIcon from '@mui/icons-material/Edit';
import Datatable from 'src/components/Datatable/Datatable';
import TrainingGroupParentForm from './TrainingGroupParentForm';

type Props = {
    open: boolean;
    handleClose: () => void;
}
function TrainingGroupParents({open, handleClose}: Props) {
    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "label", width: "25rem", sortable: true},
        {label: '', field: "edit", width: "3rem", sortable: false},
    ]

    type filterOptionsType = {
        includeDisabled?: boolean;
    }

    const [filterOptions, setFilterOptions] = useState<filterOptionsType>({includeDisabled: false})
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [toggleRefresh, setToggleRefresh] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()


    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30    
    }

    const fetchTrainingGroupParents = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await TrainingGroupParentService.GetList(listParams, searchTerm, filterOptions.includeDisabled);
        return data
    }

    const setParentId = (id: string | null) => { 
        if(id){
            searchParams.set("parentId", id);
            setSearchParams(searchParams)
        } else {
            searchParams.delete("parentId")
            setSearchParams(searchParams)
        }  
    }

    const renderTableRow = ({data, index}: {data: TrainingGroupParent, index: number}) => {
        const handleEdit = (e: any, id: string) => {
            e.stopPropagation();
            setParentId(id);
            setDialogOpen(true);
        }

        return (
            <>
                <StyledTableRow
                    hover
                    onClick={(e) => handleEdit(e, data.id)}
                    key={`UsersRow_${index}`}
                    sx={{cursor: "pointer"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`, paddingLeft: "0rem"}}><IconButton onClick={(e)=> handleEdit(e, data.id)}><EditIcon fontSize='small'/></IconButton></TableCell>
                </StyledTableRow>
            </>
        )
    };

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
        setParentId(null);
        setToggleRefresh((prevState) => !prevState)
    }

    function FilterMenu(props:{handleClose: () => void}){
        const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);

        const handleIncludeDisabledChange = () => {
            setIncludeDisabled((prevVal) => !prevVal)
        }

        const handleOkClick = () => {
            props.handleClose()
            setFilterOptions({includeDisabled: includeDisabled})
        }

        return (
            <Box style={{padding:"1rem", display:"block"}}>
                <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
                
                <FormControlLabel 
                    control={
                        <Switch 
                            checked={includeDisabled}
                            onChange={handleIncludeDisabledChange}  
                        />
                    }
                    label={"Include Inactive?"}
                    sx={{minWidth: '100%'}}
                />
                <Button onClick={handleOkClick}>Filter</Button>
            </Box>
        )

    }

    const style = {
        position: "absolute",
        top: "50%",
        left:"50%",
        transform: 'translate(-50%, -50%)',
        width: "34rem",
        bgcolor: 'background.paper',
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Datatable
                        tableHeaders={tableHeaders}
                        initialListParams={initialListParams}
                        tableTitle='Training Group Names'
                        buttonLabel='Add New Name'
                        buttonOnClick={handleDialogOpen}
                        showSearch={true}
                        renderRow={renderTableRow}
                        callService={fetchTrainingGroupParents}
                        setMessageItem={setMessageItem}
                        FilterMenu={FilterMenu}
                        toggleRefreshFlag={toggleRefresh}
                        height='25rem'
                    />
                </Box>
            </Modal>
            <TrainingGroupParentForm
                open={dialogOpen}
                handleClose={handleDialogClose}
                setMessageItem={setMessageItem}
            />
            <ErrorHandlingSnackbar messageItem={messageItem}/>            
        </>
  )
}

export default TrainingGroupParents