import { Autocomplete, IconButton, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MaterialCreator from '../LearningMaterialCreator/MaterialCreator'
import { FormikProps } from 'formik'
import { LearningArea, TrainingGroup } from 'src/dtos/Training/TrainingGroup.dto'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { NameDto } from 'src/dtos/NameDto.dto'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import LearningAreaNames from './LearningAreaNames/LearningAreaNames'
import { useQuery } from '@tanstack/react-query'
import LearningAreaNameService from 'src/Services/Training/LearningAreaNameService'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import EditIcon from '@mui/icons-material/Edit';


type Props = {
  formik: FormikProps<TrainingGroup>;
  formikString: string;
  formikAreaValue: LearningArea;
  handleDelete: () => void;
  learningDocuments: NameDto[];
  learningItems: NameDto[];
  setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>

}

function LearningAreaItem({formik, formikString, formikAreaValue, handleDelete, learningDocuments, learningItems, setMessageItem}: Props) {
  const [editNamesOpen, setEditNamesOpen] = useState(false)

  const learningAreaNamesQuery = useQuery({
    queryKey: ["learningAreaNames"],
    queryFn: async () => {
      return await LearningAreaNameService.GetNames();
    } 
  })

  //Handle query errors
  useEffect(() => {
    if(learningAreaNamesQuery.isError){
      setMessageItem({error: learningAreaNamesQuery.error})
    }
  }, [learningAreaNamesQuery.error, learningAreaNamesQuery.isError, setMessageItem])

  const handleEditNamesClose = () => {
    setEditNamesOpen(false)
    learningAreaNamesQuery.refetch();
  }

  return (
    <Paper sx={{padding:"1rem"}}>
      <Stack direction="row" sx={{display:"flex", justifyContent: "space-between"}}>
        <Stack direction="row" spacing={1}>
          <Autocomplete
            id={`${formikString}.areaName`}
            options={learningAreaNamesQuery.data ?? []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option ? option.label : ""}
            getOptionKey={option => option.id}
            value={formikAreaValue.areaName ?? null}
            onChange={(e, value) => {
              if(value) {
                formik.setFieldValue(`${formikString}.areaName`, value)
              } else {
                formik.setFieldValue(`${formikString}.areaName`, null)
              }
            }}
            size="small"
            sx={{width:"20rem"}}
            //disableClearable
            renderInput={(params) => 
              <TextField {...params} label="Area Name"/>
            }
          />
          <Tooltip title="Edit Area Names">
            <>
              <IconButton onClick={() => setEditNamesOpen(true)}>
                  <EditIcon/>
              </IconButton>
              <LearningAreaNames
                open={editNamesOpen}
                handleClose={handleEditNamesClose}
              />
            </>
          </Tooltip>
        </Stack>
        <Tooltip title="Delete Area">
          <IconButton onClick={() => handleDelete()}>
            <DeleteOutlineOutlinedIcon sx={{color: "crimson"}} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row">
        <Typography variant='h6'>Training Resources</Typography>
        <QuestionTitle title='' infoText='Training Resources refers to all of the documents/items that must be completed and valid to be competent/trained in that area. Note additional items may need to be completed from "Training Resources All Areas"'/>
      </Stack>
      <MaterialCreator
        formik={formik}
        formikLearningMaterialItems={formikAreaValue.learningMaterials}
        formikString={`${formikString}.learningMaterials`}
        learningDocuments={learningDocuments}
        learningItems={learningItems}
      />
    </Paper>
  )
}

export default LearningAreaItem