import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { Role } from "src/dtos/HumanResources/Role.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/Role";
const debug = process.env.REACT_APP_DEBUG;

export default class RoleService {
    static async GetNames(): Promise<Role[]>{
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<Role[]> = await axios.get<Role[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

}