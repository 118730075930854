import React, { ReactNode, useEffect } from 'react'
import { useDispatch} from "react-redux";
import { setAppState, setCurrentGuideLink, setCurrentPageName } from '../../redux/features/appStateSlice';

type Props = {
    state?: string,
    pageName?: string,
    guideLink?: string,
    children: ReactNode;
};

const PageWrapper = (props: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if(props.state) {
      dispatch(setAppState(props.state));
    }

    if(props.pageName) {
      dispatch(setCurrentPageName(props.pageName));
    }

    if(props.guideLink) {
      dispatch(setCurrentGuideLink(props.guideLink));
    } else if(props.pageName !== undefined) {
      dispatch(setCurrentGuideLink(""));
    }
  }, [dispatch, props])
  
  return (
    <>{props.children}</>
  )
}

export default PageWrapper;