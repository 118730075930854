import { Button, IconButton, Modal, Stack } from '@mui/material';
import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './styles.scss'
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';

type Props = {
    onApprove: (image: string, setCapturedImage: React.Dispatch<React.SetStateAction<string | null>>) => void;
    onReject?: (image: string) =>  void;
    cameraOpen: boolean;
    setCameraOpen: React.Dispatch<React.SetStateAction<boolean>>;
    existingImage?: string;
};

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

function WebcamCapture({onApprove, cameraOpen, setCameraOpen, existingImage, onReject}: Props) {
    const webcamRef = useRef<Webcam>(null);
    const [capturedImage, setCapturedImage] = useState<string | null>(existingImage ?? null);
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);


    const capture = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if(imageSrc) {
            setCapturedImage(imageSrc);
        }
    }, [])

    const handleCameraClose = () => {
        setCameraOpen(false)
    }

    const handleFlipCameraClick = React.useCallback(() => {
        setFacingMode((prevState) =>
          prevState === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER
        );
      }, []);

    const handleApproveClick = () => {
        handleCameraClose()
        capturedImage && onApprove(capturedImage, setCapturedImage);
    }

    const handleRejectClick = () => {
        setCapturedImage(null)
        onReject && capturedImage && onReject(capturedImage);
    }

    return (
        <Modal
        open={cameraOpen}
        onClose={handleCameraClose}
    >
        <div className='modalBox'>
            <Stack>
            {capturedImage ? (
                <>
                    {/* Display the captured image */}
                    <img src={capturedImage} alt="Captured" className='capturedImage'/>
                    <Stack style={{display: "flex", justifyContent: "center"}} direction="row">
                        <IconButton color='success' onClick={handleApproveClick} size="large"><CheckCircleIcon fontSize='large'/></IconButton>
                        <IconButton color='error' onClick={handleRejectClick} size="large"><CancelIcon fontSize='large'/></IconButton>
                    </Stack>
                </>
            ) : (
                <>
                <div className='webcam-container'>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{facingMode:facingMode}}
                        mirrored={true}
                        className="webcam"
                        onClick={capture}
                    />
                </div>
                    
                    <Stack style={{display: "flex", justifyContent: "center", margin: "0.5rem", marginTop: "0.4rem"}} direction="row" spacing={2} >
                        <IconButton onClick={handleFlipCameraClick}>
                            <FlipCameraIosIcon/>
                        </IconButton>
                        <Button onClick={capture} variant='contained'>Capture</Button>
                        <Button onClick={handleCameraClose}>Cancel</Button>
                    </Stack> 
                </>
            )}
            </Stack>
        </div>
    </Modal>
    )
}

export default WebcamCapture