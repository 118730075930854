import { Autocomplete, Box, Button, FormControlLabel, IconButton, Switch, TableCell, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService'
import { ListParameters } from 'src/Services/ListParameters'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import colorConfigs from 'src/config/colorConfigs'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import EditIcon from '@mui/icons-material/Edit'
import { DepartmentNameDto } from 'src/dtos/Resources/Department.dto'
import DepartmentService from 'src/Services/Resources/DepartmentService'
import { LearningDocument, LearningDocumentType, LearningDocumentTypeString } from 'src/dtos/Training/LearningDocument.dto'
import Datatable from 'src/components/Datatable/Datatable'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'


function LearningDocuments() {
  const tableHeaders: TableHeader[] = [
    {label: '', field: "statusIndicator", width: "10px", sortable: false},
    {label: "Name", field: "label", width: "15rem", sortable: true},
    {label: "Description", field: "description", width: "18rem", sortable: false},
    {label: "Department", field: "department", width: "15rem", sortable: true},
    {label: "Type", field: "type", width: "12rem", sortable: true},
    {label: '', field: "edit", width: "3rem", sortable: false}
  ]
  
  type filterOptionsType = {
    department?: {id: string, label: string},
    type?: LearningDocumentType,
    includeUnpublished?: boolean,
    includeDisabled?: boolean
  }

  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
  const [filterOptions, setFilterOptions] = useState<filterOptionsType>({department: undefined, includeUnpublished: true, includeDisabled: false})
  const navigate = useNavigate();

  const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30    
  }

  const fetchLearningDocuments = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
    const data = await LearningDocumentService.GetList(listParams, searchTerm, filterOptions.department?.id, filterOptions.type, filterOptions.includeUnpublished, filterOptions.includeDisabled);
    return data;
  }

  const renderTableRow = ({data, index}: {data: LearningDocument, index: number}) => {  
    let statusIndicatorColour = data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive;
    if(data.publishedVersionId == null && data.enabled){
      statusIndicatorColour = colorConfigs.statuses.outdated
    }
    return (
      <>
        <ClickableTableRow
          hover
          href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
          key={`LearningDocumentsRow_${index}`}
          sx={{cursor: "pointer", textDecoration: "none"}}
        >
          <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: statusIndicatorColour}}></TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.description}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.department?.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{LearningDocumentTypeString[data.type]}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[5].width} !important`, maxWidth: `${tableHeaders[5].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
        </ClickableTableRow>
      </>
    )
  };

  const handleFormOpen = () => {
    navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
  }

  function FilterMenu(props: {handleClose: () => void}){
    const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);
    const [includeUnpublished, setIncludeUnpublished] = useState(filterOptions.includeUnpublished);
    const [department, setDepartment] = useState(filterOptions.department);
    const [departmentNames, setDepartmentNames] = useState<DepartmentNameDto[]>([]);
    const [type, setType] = useState<LearningDocumentType | undefined>(filterOptions.type);
    const typeNames: LearningDocumentType[] = [
      LearningDocumentType.trainingDocumentNTM,
      LearningDocumentType.trainingDocumentRTM,
      LearningDocumentType.competencyDocument,
      LearningDocumentType.orientation
    ]
    

    useEffect(() => {
      const fetchDepartmentNames = async () => {
        try {
          const data = await DepartmentService.GetNames()
          setDepartmentNames(data)
        } catch (error: any) {
          setMessageItem({error: error})
        }
      }

      fetchDepartmentNames();
    }, [])

    const handleIncludeDisabledChange = () => {
      setIncludeDisabled((prevVal) => !prevVal)
    }

    const handleIncludeUnpublishedChange = () => {
      setIncludeUnpublished((prevVal) => !prevVal)
    }

    const handleOkClick = () => {
      props.handleClose()
      setFilterOptions({department: department, type: type, includeUnpublished: includeUnpublished, includeDisabled: includeDisabled})
    }

    return (
      <Box style={{padding: "1rem", display: "block"}}>
        <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
        <Autocomplete
          disablePortal={false}
          id="department"
          options={departmentNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={department || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setDepartment(value)
            } else {
              setDepartment(undefined)
            }
          }}
          renderInput={(params) => 
            <TextField {...params} label="Department"/>
          }
        />
        <Autocomplete
          disablePortal={false}
          id="type"
          options={typeNames}
          size="small"
          sx={{width:"15rem", paddingBottom: "0.5rem"}}
          value={type ?? null}
          getOptionLabel={(option) => option !== undefined ? LearningDocumentTypeString[option] : ""}
          onChange={(e, value) => {
            if(value !== undefined && value !== null) {
              setType(value)
            } else {
              setType(undefined)
            }
          }}
          renderInput={(params) => 
            <TextField {...params} label="Type"/>
          }
        />
        
        <FormControlLabel 
          control={
            <Switch 
              checked={includeDisabled}
              onChange={handleIncludeDisabledChange}  
            />
          }
          label={"Include Inactive?"}
          sx={{minWidth: '100%'}}
        />
        <FormControlLabel 
          control={
            <Switch 
              checked={includeUnpublished}
              onChange={handleIncludeUnpublishedChange}  
            />
          }
          label={"Include Unpublished?"}
          sx={{minWidth: '100%'}}
        />
        <Button onClick={handleOkClick}>Filter</Button>
      </Box>
    )

  }
  
  return (
    <>
      <Datatable
        tableHeaders={tableHeaders}
        initialListParams={initialListParams}
        tableTitle='Learning Documents'
        buttonLabel='Create New Document'
        buttonOnClick={handleFormOpen}
        showSearch={true}
        renderRow={renderTableRow}
        callService={fetchLearningDocuments}
        setMessageItem={setMessageItem}
        FilterMenu={FilterMenu}
      />
      <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
  )
}

export default LearningDocuments