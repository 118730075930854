import { Dayjs } from "dayjs";

export enum ExpiryType {
    After,
    Every,
    Never
}

export interface Expiry {
    type?: ExpiryType | "";
    afterValue: number | null;
    afterUnits?: "Days" | "Weeks" | "Months" | "Years" | "";
    everyUnit?: "Day" | "Week" | "Month" | "Year"| "";
    everyTimeValue?: Dayjs | string;
    everyWeekdayValue?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday" | "";
    everyMonthValue?: "January" | "February" | "March" | "April" | "May" | "June" | "July" | "August" | "September" | "October" | "November" | "December" | "";
}