import { Table, TableHead, TableRow, TableCell, TableBody, Skeleton, LinearProgress } from "@mui/material"
import { UseQueryResult } from "@tanstack/react-query"
import React from "react"
import ClickableTableRow from "src/components/Datatable/ClickableTableRow"
import { dateOptions } from "src/config/constants"
import { JournalEntryDto, JournalEntryTypeString, JournalEntryStatusString } from "src/dtos/Checklists/AttractionInspections/Journal.dto"
import { ListResponseDto } from "src/dtos/ListResponse.dto"
import { EmailToNameString } from "src/Utils/helperFunc"

type Props = {
    entriesQuery: UseQueryResult<ListResponseDto<JournalEntryDto>, Error>
    pageSize: number
}
function EntriesTable({entriesQuery, pageSize}: Props) {
    return (
        <>
            {entriesQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer so table doesnt move
            )}
            
            <Table>
                <TableHead>
                    <TableRow>  
                        <TableCell>ID</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Summary</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Reported On</TableCell>
                        <TableCell>Reported By</TableCell>
                        <TableCell>Last Modified</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(entriesQuery.data == null || entriesQuery.data.data.length === 0) && entriesQuery.isLoading ? (
                        <>
                            {Array.from({length: pageSize}).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                    <TableCell sx={{padding:"0.62rem"}}><Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/></TableCell>
                                </TableRow>
                            ))}
                        
                        </>
                        
                    ) : entriesQuery.data && entriesQuery.data.data.map((journalEntry) => (
                        <ClickableTableRow
                            key={journalEntry.id}
                            hover
                            href={`${window.location.pathname.replace("/search", "")}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${journalEntry.id}`}
                            sx={{cursor: "pointer", textDecoration: "none"}}
                        >
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal", borderLeft: journalEntry.entryUnread ? "solid blue 5px" : "5px"}}>
                                {journalEntry.entryId}
                            </TableCell>
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal"}}>
                                {JournalEntryTypeString[journalEntry.type]}
                            </TableCell>
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal"}}>
                                {journalEntry.label}
                            </TableCell>
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal"}}>
                                {JournalEntryStatusString[journalEntry.status]}
                            </TableCell>
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal"}}>
                                {journalEntry.createdOn && new Date(journalEntry.createdOn).toLocaleTimeString(undefined, dateOptions)}
                            </TableCell>
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal"}}>
                                {EmailToNameString(journalEntry.createdBy??"")}
                            </TableCell>
                            <TableCell sx={{fontWeight: journalEntry.entryUnread ? "bold" : "normal"}}>
                                {journalEntry.modifiedOn && new Date(journalEntry.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                            </TableCell>
                        </ClickableTableRow>
                    ))}

                </TableBody>
            </Table>
        </>
    )
}

export default EntriesTable