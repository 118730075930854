import { Button, IconButton, Modal, Stack } from '@mui/material';
import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';

type Props = {
    type: "Photo" | "Video"
    handleSave: (file: File) => void;
    handleClose: () =>  void;
    cameraOpen: boolean;
    filePrefix?: string;
    currentFileNames: string[]
};

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

function CameraCapture({type, handleSave, handleClose, cameraOpen, filePrefix, currentFileNames}: Props) {
    const webcamRef = useRef<Webcam>(null);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [recording, setRecording] = useState(false)
    const [recordedVideoURL, setRecordedVideoURL] = useState<string | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [videoChunks, setVideoChunks] = useState<Blob[]>([]);

    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        
        if(imageSrc) {
            setCapturedImage(imageSrc);
        }
    }, [])

    //is also triggered at the end of a recording
    const handleDataAvailable = useCallback(({ data} : {data: Blob}) => {
        if (data.size > 0) {
            
            setVideoChunks((prev) => prev.concat(data));
            const newVideoChunks = [...videoChunks, data]
            //If no longer recording then recording just stopped
            if(!recording){
                const videoBlob = new Blob(newVideoChunks, { type: 'video/webm' });
                setRecordedVideoURL(URL.createObjectURL(videoBlob));
            }
            
        }
    }, [recording, videoChunks]);

    const startRecording = useCallback(() => {
        
        setRecording(true)
        const stream = webcamRef.current?.stream as MediaStream 
        mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
          );
        mediaRecorderRef.current.start();
        
    }, [webcamRef, setRecording, mediaRecorderRef, handleDataAvailable])
    
    const stopRecording = useCallback(() => {
        mediaRecorderRef.current?.stop();
        setRecording(false);
    }, []);

    const handleSaveVideo = () => {
        if(videoChunks.length > 0){
            const videoBlob = new Blob(videoChunks, { type: 'video/webm' });
            const currentDate = new Date().toISOString().split('T')[0];
            let newFileName = `${filePrefix || 'video'} ${currentDate}.webm`;
            while (currentFileNames.includes(newFileName)) {
                const match = /\((\d+)\)/.exec(newFileName);
                const number = match ? parseInt(match[1]) + 1 : 1;
                newFileName = `${filePrefix || 'video'} ${currentDate} (${number}).webm`;
            }
            const videoFile = new File([videoBlob], newFileName, { type: 'video/webm' });
            handleSave(videoFile);
            setVideoChunks([]); 
            setRecordedVideoURL(null);
        }
    }

    const handleFlipCameraClick = React.useCallback(() => {
        setFacingMode((prevState) =>
          prevState === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER
        );
      }, []);

    const handleSavePhoto = () => {
        if(capturedImage){
            const byteString = atob(capturedImage.split(',')[1]); // Remove header
            const mimeType = 'image/webp';
            const byteArray = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                byteArray[i] = byteString.charCodeAt(i);
            }
            
            const currentDate = new Date().toISOString().split('T')[0]
            let newFileName = `${filePrefix} ${currentDate}.webp`
            while(currentFileNames.includes(newFileName)){
                const match = /\((\d+)\)/.exec(newFileName)
                const number = match ? parseInt(match[1]) + 1 : 1
                newFileName = `${filePrefix} ${currentDate} (${number}).webp`
            }
            
            const file = new File([byteArray], newFileName, { type: mimeType });
            handleSave(file);
            setCapturedImage(null)
        }
        
    }

    const handleRejectClick = () => {
        setCapturedImage(null)
        setRecordedVideoURL(null);
        //handleClose()
    }

    return (
        <Modal
        open={cameraOpen}
        onClose={handleClose}
    >
        <div style={{ position: "relative", width: "100%", height: "100svh", overflow: "hidden", background:"#fff" }}>
            <Stack sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            {capturedImage || recordedVideoURL ? (
                <div style={{ display: "flex", flexDirection: "column", height: "100svh" }}>
                    <div style={{ flex: 1, overflow: "hidden" }}>
                        {capturedImage ? (
                            <img 
                                src={capturedImage} 
                                alt="Captured" 
                                style={{ 
                                    height: "100%", 
                                    width: "100%", 
                                    objectFit: "cover",
                                    borderRadius: "12px"
                                }}
                            />

                        ) : (
                            <video
                                src={recordedVideoURL || ""}
                                controls
                                style={{ 
                                    height: "100%", 
                                    width: "100%", 
                                    objectFit: "cover",
                                    borderRadius: "12px"
                                }}
                            />
                        )

                        }
                    </div>
                    <Stack style={{ padding:"0.7rem", backgroundColor:"#fff", zIndex: 1, justifyContent:"center" }} direction="row">
                        <IconButton color='success' onClick={capturedImage ? handleSavePhoto : handleSaveVideo} size="large"><CheckCircleIcon fontSize='large'/></IconButton>
                        <IconButton color='error' onClick={handleRejectClick} size="large"><CancelIcon fontSize='large'/></IconButton>
                    </Stack>
                </div>
            ) : (
                <div style={{ display: "flex", flexDirection: "column", height: "100svh" }}>
                    <div style={{ flex: 1, overflow: "hidden" }}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/webp"
                            videoConstraints={{
                                facingMode:facingMode,
                                aspectRatio: 16/9
                            }}
                            mirrored={facingMode === FACING_MODE_USER}
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "12px",
                            }}
                        />
                    </div>
                        
                    <Stack style={{ padding:"1rem", backgroundColor:"#fff", zIndex: 1, justifyContent:"center" }} direction="row" spacing={2} >
                        <div style={{display:"flex", alignContent:"center", justifyContent:"center"}}>
                            <IconButton onClick={handleFlipCameraClick}>
                                <FlipCameraIosIcon fontSize='large'/>
                            </IconButton>
                        </div>
                        {type === "Photo" ? (
                            <IconButton onClick={capturePhoto}>
                                <RadioButtonCheckedIcon fontSize="inherit" sx={{fontSize:"4rem"}} />
                            </IconButton>
                        ) : (
                            <IconButton onClick={recording ? stopRecording : startRecording}>
                                {recording ? 
                                    <StopCircleOutlinedIcon fontSize="inherit" sx={{fontSize:"4rem"}} color="error" /> : 
                                    <RadioButtonCheckedIcon fontSize="inherit" sx={{fontSize:"4rem"}}/>}
                            </IconButton>
                        )}

                        <Button onClick={handleClose}>Cancel</Button>
                    </Stack> 
                </div>
            )}
            </Stack>
        </div>
    </Modal>
    )
}

export default CameraCapture