import { ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { FormikProps } from "formik"
import React, { useEffect, useRef, useState } from "react"
import LearningDocumentResponseService from "src/Services/Training/LearningDocuments/LearningDocumentResponseService"
import { SaveStatusIcons } from "src/config/constants"
import { LearningDocumentResponseDto, LearningDocumentSubpointResponse } from "src/dtos/Training/LearningDocumentResponse.dto"


type BaseProps = {
    label:string
    subpointResponse?: LearningDocumentSubpointResponse;
    demoMode?: boolean;
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    subpointResponse: LearningDocumentSubpointResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    subpointClick: (changedSubpointAnswer: boolean, changedSubpointId: number) => void;
}




type Props = DisplayProps | ActiveProps;
function Subpoint(props:Props){
    const {label} = props
    const [saveStatusIcon, setSaveStatusIcon] = useState<React.ReactNode>(SaveStatusIcons.idle)
    
    const handleSubpointClick = () => {
        if(props.type === "Active"){
            const {subpointResponse, formikString, formik, subpointClick} = props
            
            formik.setFieldValue(`${formikString}.checked`, !subpointResponse.checked);
            formik.setFieldValue(`${formikString}.timeAnswered`, new Date());
            subpointClick(!subpointResponse.checked, subpointResponse.id);
           
        }
    }

    //Handle Update subpoint
    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: LearningDocumentSubpointResponse }) => {
            return LearningDocumentResponseService.UpdateSubpoint(id, values)
        },
        onSuccess: (data) => {
            setSaveStatusIcon(SaveStatusIcons.success)
        },
        onError: (error) => {
            setSaveStatusIcon(SaveStatusIcons.failed)
        }
    })
    
    useEffect(() => {
        if(updateMutation.isPending && saveStatusIcon !== SaveStatusIcons.loading){
            setSaveStatusIcon(SaveStatusIcons.loading)
        }
    }, [updateMutation.isPending, saveStatusIcon])

    const hasMounted = useRef(false);
    useEffect(() => {
        function updateSubpoint(){
            if (hasMounted.current && props.demoMode === false) {
                //Subpoint has changed
                if(props.subpointResponse && !updateMutation.isPending){
                    updateMutation.mutate({id: props.subpointResponse.id, values: props.subpointResponse})
                }
            } else {
                hasMounted.current = true;
            }
        }
        updateSubpoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subpointResponse?.checked])

    return(
        <ListItemButton onClick={() => handleSubpointClick()}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={props.type === "Active" ? props.subpointResponse.checked : false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': "fdsfsd" }}
                />
            </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{variant: "body1"}}/>
            {saveStatusIcon}
        </ListItemButton>
    )
}

export default Subpoint