import { Box, Button, FormControlLabel, IconButton, Switch, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ListParameters } from 'src/Services/ListParameters';
import TrainingGroupService from 'src/Services/Training/TrainingGroupService';
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import colorConfigs from 'src/config/colorConfigs';
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import { TrainingGroup, TrainingGroupTypeString } from 'src/dtos/Training/TrainingGroup.dto';
import EditIcon from '@mui/icons-material/Edit'
import Datatable from 'src/components/Datatable/Datatable';
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow';

function TrainingGroups() {
    const tableHeaders: TableHeader[] = [
        {label: '', field: "statusIndicator", width: "10px", sortable: false},
        {label: "Name", field: "label", width: "25rem", sortable: true},
        {label: '', field: "edit", width: "3rem", sortable: false},
    ]

    type filterOptionsType = {
        includeDisabled?: boolean;
    }

    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [filterOptions, setFilterOptions] = useState<filterOptionsType>({includeDisabled: false})
    const navigate = useNavigate();

    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 30    
    }

    const fetchTrainingGroups = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
        const data = await TrainingGroupService.GetList(listParams, searchTerm, filterOptions.includeDisabled);
        return data;
    }

    const renderTableRow = ({data, index}: {data: TrainingGroup, index: number}) => {

        return (
            <>
                <ClickableTableRow
                    hover
                    href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
                    key={`UsersRow_${index}`}
                    sx={{cursor: "pointer", textDecoration:"none"}}
                >
                    <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor: data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{`${data.trainingGroupParent?.label} [${TrainingGroupTypeString[data.type]}]`}</TableCell>
                    <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
                </ClickableTableRow>
            </>
        )
    };

    const handleFormOpen = () => {
        navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
    }

    function FilterMenu(props:{handleClose: () => void}){
        const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);

        const handleIncludeDisabledChange = () => {
            setIncludeDisabled((prevVal) => !prevVal)
        }

        const handleOkClick = () => {
            props.handleClose()
            setFilterOptions({includeDisabled: includeDisabled})
        }

        return (
            <Box style={{padding:"1rem", display:"block"}}>
                <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
                <FormControlLabel 
                    control={
                        <Switch 
                        checked={includeDisabled}
                        onChange={handleIncludeDisabledChange}  
                        />
                    }
                    label={"Include Inactive?"}
                    sx={{minWidth: '100%'}}
                />
                <Button onClick={handleOkClick}>Filter</Button>
            </Box>
        )

    }

    return (
        <>
            <Datatable
                tableHeaders={tableHeaders}
                initialListParams={initialListParams}
                tableTitle='Training Groups'
                buttonLabel='Create New Training Group'
                buttonOnClick={handleFormOpen}
                showSearch={true}
                renderRow={renderTableRow}
                callService={fetchTrainingGroups}
                setMessageItem={setMessageItem}
                FilterMenu={FilterMenu}
            />
            <ErrorHandlingSnackbar messageItem={messageItem} />
        </>
    )
}

export default TrainingGroups