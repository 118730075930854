import { Paper, Stack } from '@mui/material'
import React, { useState } from 'react'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import DeleteResponse from './Components/DeleteResponse'

function AttractionInspectionsAdminPage() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    return (
        <Paper>
            <Stack sx={{padding:"1rem"}}>
                <DeleteResponse
                    setMessageItem={setMessageItem}
                />
            </Stack>
            <ErrorHandlingSnackbar messageItem={messageItem}/>

        </Paper>
    )
}

export default AttractionInspectionsAdminPage