import { Paper, TextField, Button, Stack, Tooltip, IconButton } from '@mui/material'
import React, { memo } from 'react'
import PointsCreator from 'src/pages/Checklists/LearningContentChecklists/LearningContentCreator/Components/PointsCreator/PointsCreator';
import { LearningDocumentPoint, LearningDocumentSubtopic, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto';
import { FormikProps } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';

import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import { FocusElement } from 'src/Utils/helperFunc';
import Subtopic from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/Subtopic/Subtopic';


type Props = {
  formik: FormikProps<LearningDocumentVersion>
  formikString: string;
  formikValue: LearningDocumentSubtopic;
  editMode: boolean;
  DeleteSubpoint: () => void;
  index: number;
  preparationChecklist?: boolean;
}

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    prevProps.formikValue === nextProps.formikValue &&
    prevProps.editMode === nextProps.editMode
  );
}

function SubTopicCreatorUnmemoized({formik, formikString, formikValue, editMode, DeleteSubpoint, index, preparationChecklist}: Props) {   

  const setPoints = (newPoints: LearningDocumentPoint[]) => {
    formik.setFieldValue(`${formikString}.points`, newPoints)
  }
  
  const HandleAddPoint = () => {
    const newPoints = [...formikValue.points, {...LearningDocumentVersionService.GetDefaultPointValue(), sortOrder: formikValue.points.length}]
    setPoints(newPoints)

    //move focus to new points label
    setTimeout(() => {
      FocusElement(-1);
    }, 100);    
  }

  return (
    <Paper>
      {editMode ? (
        <Stack sx={{padding: "1rem"}} spacing={2}>
        <Stack direction="row">
          <TextField
            id={`${formikString}.label`}
            name={`${formikString}.label`}
            label={preparationChecklist ? "Checklist Title" : "Subtopic Title"}
            value={formikValue.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={e => {
              if(e.key === "Enter"){
                e.preventDefault();
              }
            }}
            variant='standard'
            size="medium"
            sx={{width: "100%"}}
          />
          <div style={{display: "flex", alignItems: "center"}}>
            <Tooltip title="Delete Subtopic">
              <IconButton tabIndex={-1} onClick={DeleteSubpoint}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </div>
        </Stack>
        <PointsCreator
          formik={formik}
          formikString={`${formikString}.points`} 
          formikPointsValue={formikValue.points}
          preparationChecklist={preparationChecklist}
        />
        <div style={{display:"flex", justifyContent:"center"}}>
          <Button onClick={HandleAddPoint}>Add Point</Button>
        </div>
      </Stack>
      ) : (
        <Subtopic
          index={index}
          subtopic={formikValue}
          type="DisplayOnly"
        />
        
      )}
      
    </Paper>
  );
  

}

const SubTopicCreator = memo<Props>(SubTopicCreatorUnmemoized, areEqual)


export default SubTopicCreator