/* eslint-disable import/no-anonymous-default-export */
import { Paper, Typography } from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react'
import React from 'react'

export default () => {
  return (
    <NodeViewWrapper>
      <Paper sx={{width:"max-content", padding: "0.5rem"}} elevation={3}>
        <Typography variant='h6'>Checklist</Typography>
        <Typography>Where the above created checklist will be displayed</Typography>

      </Paper>
      

    </NodeViewWrapper>
  )
}