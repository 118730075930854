import React from 'react'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Paper, Stack, Typography } from '@mui/material';

type Props = {
    questionTitle: string;
}

function AlertQuestion({questionTitle}: Props) {
  return (
    <Paper sx={{paddingLeft: "1rem", paddingRight: "1rem"}}>
        <Stack direction="row" className='alert-stack'>
            <p style={{paddingRight: "1rem", color: "blue"}} ><WarningAmberOutlinedIcon fontSize='large'/></p>
            <Typography fontSize={17}>{questionTitle}</Typography>
        </Stack>
        
    </Paper>
  )
}

export default AlertQuestion