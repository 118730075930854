import { loginRequest } from "src/config/authConfig";
import {msalInstance} from "../index";

export const GetBearer = async () => {
    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest, 
        account: account
    });

    const bearer = `Bearer ${response.accessToken}`;
    return bearer;
};

export const GetCurrentName = () => {
    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    return account.name ?? account.username;
}

export const GetCurrentUsername = () => {
    const account = msalInstance.getActiveAccount();
    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    return account.username;
}

export const GetCurrentUser = () => {
    const account = msalInstance.getActiveAccount();

    if(!account){
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    return {name: account.name ?? account.username, id: account.localAccountId}
}

export const LogoutUser = async () => {
    try {
      // Attempt to log out the current user
      await msalInstance.logoutRedirect();
  
      // Log out successful
      console.log('User logged out successfully');
    } catch (error) {
      // Handle logout error
      console.error('Error logging out user:', error);
    }
  }
