import { FormikProps } from 'formik'
import React, { useState } from 'react'
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService'
import { LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto'
import SubTopicCreator from '../SubTopicCreator/SubTopicCreator'
import { Typography, Button, Accordion, AccordionSummary, AccordionDetails, Tooltip, IconButton } from '@mui/material'
import SimpleEditor from 'src/components/SimpleEditor/SimpleEditor'
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Status } from 'src/dtos/Statuses'
import { GeneratePreparationSectionContent } from 'src/pages/User Site/TrainingDocuments/TrainingDocumentComponents/PreparationSection/PreparationSection'


type Props = {
    formik: FormikProps<LearningDocumentVersion>
}

function PreparationSection({formik}: Props) {
    const published = formik.values.status === Status.Published;
    const [editorFocused, setEditorFocused] = useState(false)
    const [editMode, setEditMode] = useState(published ? false : true)


    const handleClick = () => {
        formik.setFieldValue('preparationChecklist', LearningDocumentVersionService.GetDefaultSubTopicValues())
    }

    

    

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="preparation"
                    id={`preparation_header`}
                >
                    <span style={{display: "inline-flex", justifyContent: "space-between", width: "100%"}}>
                        <Typography variant='h5'>Preparation</Typography>
                        <Tooltip title={editMode ? "Stop Editing Preparation Section" : "Edit Preparation Section"}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {!published && (
                                    <IconButton tabIndex={-1} onClick={(e) => {e.stopPropagation(); setEditMode(!editMode)}}>
                                        {editMode ? (
                                            <EditOffIcon/>
                                        ) : (
                                            <EditIcon/>
                                        )}
                                    </IconButton>
                                )}
                            </div>
                        </Tooltip>
                        
                    </span>
                </AccordionSummary>
                <AccordionDetails>
                    {editMode ? (
                        <>
                            {formik.values.preparationChecklist ? (
                                <SubTopicCreator
                                    formik={formik}
                                    formikValue={formik.values.preparationChecklist}
                                    formikString='preparationChecklist'
                                    editMode={true}
                                    DeleteSubpoint={() => {}}
                                    index={0}
                                    preparationChecklist={true}
                                />
        
                                ) : (
                                    <>
                                        <Button onClick={handleClick} variant="contained">Create Checklist</Button>
                                    </>
                            )}
                            <SimpleEditor
                                content={formik.values.initialSectionContent ?? ""}
                                onFocus={() => setEditorFocused(true)}
                                onBlurSaveContent={(content: string) => {setEditorFocused(false); formik.setFieldValue("initialSectionContent", content)}}
                            />
                            {editorFocused && (
                                <Typography variant='caption' sx={{paddingLeft:"8px", position: "relative", top:"-48px"}}>Unsaved</Typography>
                            )}
                        </>

                    ) : (
                        <div style={{padding:"1rem"}}>
                            {formik.values.initialSectionContent ? (
                                <GeneratePreparationSectionContent content={formik.values.initialSectionContent} checklist={formik.values.preparationChecklist}/>
                            ) : (
                                <Typography>{published ? "No content" : "No content yet! Press edit to add some content"}</Typography>
                            )}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default PreparationSection