import { Button, Stack } from '@mui/material'
import React from 'react'
import LearningAreaItem from './LearningAreaItem'
import { LearningArea, TrainingGroup } from 'src/dtos/Training/TrainingGroup.dto'
import { FormikProps } from 'formik'
import TrainingGroupService from 'src/Services/Training/TrainingGroupService'
import { NameDto } from 'src/dtos/NameDto.dto'
import { MessageItem } from 'src/components/errorHandlingSnackbar'

type Props = {
    formik: FormikProps<TrainingGroup>;
    formikString: string;
    formikAreasValue: LearningArea[];
    learningDocuments: NameDto[];
    learningItems: NameDto[];
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function LearningAreaCreator({formik, formikString, formikAreasValue, learningDocuments, learningItems, setMessageItem}: Props) {
    const handleAddArea = () => {
        formik.setFieldValue(formikString, [...formikAreasValue, TrainingGroupService.GetDefaultLearningAreaValues()])
    }

    const handleDeleteArea = (index: number) => {
        const newAreas = [...formikAreasValue]
        newAreas.splice(index, 1)
        formik.setFieldValue(formikString, newAreas)

        if(newAreas.length === 0){
            formik.setFieldValue(formikString, [TrainingGroupService.GetDefaultLearningAreaValues()])
        }
    }

    return (
        <Stack spacing={2}>
            {formikAreasValue.map((_, index) => (
                <LearningAreaItem
                    key={index}
                    formik={formik}
                    formikString={`${formikString}[${index}]`}
                    formikAreaValue={formikAreasValue[index]}
                    handleDelete={() => handleDeleteArea(index)}
                    learningDocuments={learningDocuments}
                    learningItems={learningItems}
                    setMessageItem={setMessageItem}
                />
            ))}
            <div style={{display:"flex", justifyContent:"center"}}>
                <Button size="large" onClick={handleAddArea}>
                    Add Area
                </Button>
            </div>
        </Stack>
    )
}

export default LearningAreaCreator