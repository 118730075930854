import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Stack, Switch, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocationDto, LocationNameDto, locationTypesList } from '../../../../dtos/AwTypes'
import Autocomplete from '@mui/material/Autocomplete';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import * as yup from 'yup'
import { useFormik } from 'formik';
import LocationService from 'src/Services/Resources/LocationService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { dateOptions } from 'src/config/constants';


type Props = {
    locationId?: string;
    initialFormValues: LocationDto;
    handleClose:() => void; //used to close modal
    setMessageItem: (messageItem: MessageItem) => void;
}

const validationSchema = yup.object({
  parentLocation: yup.object().shape({
    id: yup
      .string()
      .required(),
    label: yup
      .string()
      .required()
  }).required("Residing Location is required"),
  label: yup
    .string()
    .max(100, 'Location Name cannot be more than 100 characters')
    .required('Location Name is required'),
  description: yup
    .string()
    .max(255, "Location Description cannot be more than 255 characters")
    .notRequired(),
  type : yup
    .string()
    .required("Location Type is required"),
  sortOrder : yup
    .number()
    .integer("Sort Order must be an integer")
    .required("A Sort Order number is required")
})

function LocationForm (props: Props) {
  const [locationsData, setLocationsData] = useState<LocationNameDto[]>([])
  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const handleFormClose = () => {
    //TODO: reset form
    props.handleClose()
  }

  useEffect(() => {
    const fetchNames = async () => {
      try{
        const data = await LocationService.GetNames(props.locationId)
        setLocationsData(data)
      } catch (error: any) {
        props.setMessageItem({error: error})
      }
    }
    fetchNames()
  }, [props])

  

  const save = async (values: LocationDto) => {
    try {
      if(props.locationId){
        //Update
        await LocationService.Update(props.locationId, values)
        props.setMessageItem({successMessage: "Location Updated Successfully!"})
      } else{
        //create
        await LocationService.Create(values)
        props.setMessageItem({successMessage: "New Location Saved Successfully!"})
      }
      
      handleFormClose();
      
    } catch (error: any){
      props.setMessageItem({error: error})
    }
  }

  const formik = useFormik({
    initialValues: props.initialFormValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      save(values);
    }
  })

  const saveToggleEnable = async () => {
    try {
      if(props.locationId){
        if(!formik.values.enabled){
          const response = await LocationService.Enable(props.locationId)
          if (response.status >= 200 && response.status <= 299){
            props.setMessageItem({successMessage: "Location Activated Succesfully!"})
          }
        } else {
          const response = await LocationService.Disable(formik.values.id)
          if(response.status >= 200 && response.status <= 299){
            props.setMessageItem({successMessage: "Location Deactivated Succesfully!"})
          }
          
        }
      }
      
    } catch (error: any){
      props.setMessageItem({error: error});
    }
  }

  const handleStatusChange = () => {
    setOpenAlertDialog(true);
  }

  function AlertDialog() {
    const handleClick = () => {
      formik.setFieldValue("enabled", !formik.values.enabled);
      saveToggleEnable();
      setOpenAlertDialog(false);
    }
    return (
      <Dialog
        open={openAlertDialog}
        onClose={()=> setOpenAlertDialog(false)}
      >
        <DialogTitle>
        {formik.values.enabled ? 'Deactivate' : 'Activate'} Location?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {formik.values.enabled ? 'deactivate' : 'activate'} {formik.values.label}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDialog(false)}>Cancel</Button>
          <Button onClick={handleClick}>{formik.values.enabled ? 'Deactivate' : 'Activate'}</Button>
        </DialogActions>
      </Dialog>
    )
  }
  
  return (
    <div>
      <div className='header'>
          {props.locationId ? "Edit" : "New"} Location
          <IconButton onClick={handleFormClose}><CloseIcon fontSize='large'/></IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Box className='card'>
          <Stack sx={{padding:"1rem"}}> 
            <div>
              <QuestionTitle 
                title='Residing Location'
                infoText="The residing location is the location that the newly created location resides or belongs within. For example Abyss resides within Thrill and the Abyss Control Room resides within Abyss."
                required={true}
              />
              <Autocomplete
                  disablePortal
                  id="parentLocationLabel"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.label ? option.label: ""}
                  options={locationsData}
                  size="small"
                  sx={{ width: "20rem" }}
                  value={formik.values.parentLocation ? {id: formik.values.parentLocation.id, label: formik.values.parentLocation.label} : null}
                  onChange={(_, value:any) => 
                    formik.setFieldValue("parentLocation", value ? {id: value.id, label: value.label} : null)
                  }
                  onBlur={formik.handleBlur}
                  renderInput={(params) => 
                  <TextField {...params} 
                    name="parentLocationLabel"
                    error={formik.touched.parentLocation && Boolean(formik.errors.parentLocation)}
                    helperText={formik.touched.parentLocation && formik.errors.parentLocation}
                  />}
              />   
            </div>
            <div>
              <QuestionTitle title="Name" required={true}/>
              <TextField
                id="label" 
                name="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
                variant="outlined" 
                size="small" 
                sx={{width: "20rem"}}/>
            </div>
            <div>
              <QuestionTitle title="Description"/>
              <TextField 
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                multiline 
                maxRows={4} 
                variant="outlined" 
                size="small" 
                sx={{width: "20rem"}}/>
            </div>
            <div>
              <QuestionTitle title="Type" required={true}/>
              <Autocomplete
                  disablePortal
                  id="type"
                  options={locationTypesList}
                  size="small"
                  sx={{ width: "20rem" }}
                  onChange={(e, value) => formik.setFieldValue("type", value)}
                  value={formik.values.type || null}
                  renderInput={(params) => 
                  <TextField {...params} 
                    name="type"
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />}
              />
            </div>
            {props.locationId && (
            <div>
              <QuestionTitle title="Location Status"/>
              <FormControlLabel 
                control={
                  <Switch 
                    checked={formik.values.enabled}
                    onChange={handleStatusChange}  
                  />
                }
                label={formik.values.enabled ? "Active" : "Inactive"}
              />
            </div> 
            )}
          </Stack>
          
      </Box>
      <div className='footer'>
          <Button type="submit" variant='contained' color='primary' size="large">Save</Button>
      </div>
      {props.locationId && (
      <div className='info-row'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            id="additional-info"
          >
            <Typography>Modification Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {formik.values.createdBy && (
              <Typography>
                Created By: {formik.values.createdBy}
              </Typography>
            )}
            {formik.values.createdOn && (
              <Typography>
                Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
              </Typography>
            )}
            {formik.values.modifiedBy && (
              <Typography>
                Modified By: {formik.values.modifiedBy}
              </Typography>
            )}
            {formik.values.modifiedOn && (
              <Typography>
                Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
              </Typography>
            )}
          </AccordionDetails>
          
        </Accordion>
        
      </div>)}
      </form>
      <AlertDialog/>
    </div>
    
  )
}

export default LocationForm