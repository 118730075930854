import { TrainingGroupType } from "../Training/TrainingGroup.dto";

export interface TeamMemberTrainingGroup {
    teamMemberId: string;
    teamMemberName: string;
    trainingGroupIds: string[]
}

export interface TMTrainingGroupParent {
    id: string;
    label: string;
    trainingGroups: TMTrainingGroup[];
}

export interface TMTrainingGroup {
    id: string;
    type: TrainingGroupType;
    assigned?: boolean;
}

export enum IntakeTypeDto {
    Permanent, 
    RTM,
    IntakeOne,
    IntakeTwo,
    IntakeThree,
    IntakeFour,
    IntakeFive
}

export const IntakeTypeDtoString: string[] = [
    "Permanent",
    "Return Team Member",
    "Intake One",
    "Intake Two",
    "Intake Three"
]
