import React from 'react';

const PageNotFound = () => {
    return (
        <article style={{padding: "100px"}}>
            <h1>Oops!</h1>
            <p>Page Not Found</p>
        </article>
    )
}

export default PageNotFound