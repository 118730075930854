import { List, ListItem } from '@mui/material'
import React from 'react'
import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable } from 'react-beautiful-dnd'

type Props = {
    onDragEnd: (result: DropResult) => void;
    droppableId: string;
    children: React.ReactNode;
}

export function DraggableList({onDragEnd, droppableId, children}: Props) {
  return (
    <DragDropContext onDragEnd={onDragEnd} >
        <Droppable droppableId={droppableId}>
            {(provided) => (
                <List ref={provided.innerRef} {...provided.droppableProps}>

                    {children}
                    {provided.placeholder}
                </List>
                
            )}                         
        </Droppable>
    </DragDropContext>
  )
}

type ListItemProps = {
    draggableId: string;
    index: number
    children: (provided: DraggableProvided) => React.ReactElement;
}

export function DraggableListItem({draggableId, index, children} : ListItemProps) {
    return (
        <Draggable
            draggableId={draggableId}
            index={index}
        >   
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={snapshot.isDragging ? {background: 'rgb(235,235,235)' } : {}}
                >
                    {children(provided)}
                </ListItem>
            )}

        </Draggable>
    )
}

export default DraggableList