import { alpha, Autocomplete, Button, IconButton, Menu, MenuItem, MenuProps, Stack, styled, TextField, } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import AttractionJournalService from 'src/Services/Checklists/Inspections/AttractionJournalService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { JournalDto, JournalEntryType, JournalEntryTypeString } from 'src/dtos/Checklists/AttractionInspections/Journal.dto';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import JournalEntries from './Components/JournalEntries';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Journal() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const navigate = useNavigate()
    const {attractionId} = useParams() 

    //#region queries

    const journalsQuery = useQuery({
        queryKey: ["journals"],
        queryFn: async () => {
            return await AttractionJournalService.GetJournals()
        }, 
    })


    useEffect(() => {
        if(journalsQuery.isError){
            setMessageItem({error: journalsQuery.error})
        }
    }, [setMessageItem, journalsQuery.error, journalsQuery.isError ])

    //#endregion

    const journalReadId = useRef<string | undefined>(undefined)
    useEffect(() => {
        //on mount only read after 1 second
        if(journalReadId.current !== attractionId){
            setTimeout( async () => {
                if(attractionId){
                    try {
                        await AttractionJournalService.UpdateReadTime(attractionId)
                    } catch (error: any) {
                        setMessageItem({error: error})
                    }
                }
            }, 1000);
            journalReadId.current = attractionId
        }
    }, [attractionId])

    const handleAttractionChange = (value: JournalDto | null) => {
        if(value == null || value.attractionId == null){
            return
        }
        navigate({ pathname: window.location.pathname.replace(new RegExp(`${attractionId}`), value.attractionId) });
    }

    const handleBackClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${attractionId}`), '') });
    }

    const handleSearchClick =() => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${attractionId}`), `/${attractionId}/search`) });

    }
    const handleAddClick = (type: JournalEntryType) => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${attractionId}`), `/${attractionId}/new`), search: createSearchParams({type: type.toString()}).toString() });
    }

    const AddEntryMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const StyledMenu = styled((props: MenuProps) => (
            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              {...props}
            />
          ))(({ theme }) => ({
            '& .MuiPaper-root': {
              borderRadius: 6,
              marginTop: theme.spacing(1),
              minWidth: 180,
              color: 'rgb(55, 65, 81)',
              boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              '& .MuiMenu-list': {
                padding: '4px 0',
              },
              '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: theme.palette.text.secondary,
                  marginRight: theme.spacing(1.5),
                },
                '&:active': {
                  backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                  ),
                },
              },
           
            },
          }));

        return (
            <div>
                <Button
                    id="add-entry-button"
                    aria-controls={open ? 'add-entry-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    onClick={handleClick}
                    size="large"
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    <AddIcon/>
                </Button>
                <StyledMenu
                    id="add-entry-menu"
                    MenuListProps={{
                    'aria-labelledby': 'add-entry-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleAddClick(JournalEntryType.serviceTask)} disableRipple>
                        {JournalEntryTypeString[JournalEntryType.serviceTask]}
                    </MenuItem>
                    <MenuItem onClick={() => handleAddClick(JournalEntryType.issueFaultRecord)} disableRipple>
                        {JournalEntryTypeString[JournalEntryType.issueFaultRecord]}
                    </MenuItem>
                    <MenuItem onClick={() => handleAddClick(JournalEntryType.outOfService)} disableRipple>
                        {JournalEntryTypeString[JournalEntryType.outOfService]}
                    </MenuItem>
                    <MenuItem onClick={() => handleAddClick(JournalEntryType.duplicateEntry)} disableRipple>
                        {JournalEntryTypeString[JournalEntryType.duplicateEntry]}
                    </MenuItem>
                    <MenuItem onClick={() => handleAddClick(JournalEntryType.ThirdPartyAuditFinding)} disableRipple>
                        {JournalEntryTypeString[JournalEntryType.ThirdPartyAuditFinding]}
                    </MenuItem>
                </StyledMenu>
            </div>
          );

    }

    

    return (
    <div style={{padding:"1rem"}}>
        <Stack direction={"row"} spacing={1}>
            <div>
                <IconButton onClick={handleBackClick}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <Autocomplete
                disablePortal
                id="attraction"
                isOptionEqualToValue={(option, value) => option.attractionId === value.attractionId}
                getOptionLabel={(option) => option.attraction.label}
                options={journalsQuery.data ?? []}
                value={(journalsQuery.data && attractionId) ? journalsQuery.data.find(je => je.attractionId === attractionId) ?? null : null}
                onChange={(e, value) => {
                    handleAttractionChange(value)
                }}
                size="small"
                sx={{width:"25rem"}}
                renderInput={(params) => 
                    <TextField {...params}/>
                }
            />
            <Button variant='contained' onClick={handleSearchClick}>
                <SearchIcon/>
            </Button>
            <AddEntryMenu/>
        </Stack>

        <JournalEntries/>

        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </div>
    
  )
}

export default Journal