import React, { useState } from 'react'
import { IconButton, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { useMutation, useQuery } from '@tanstack/react-query';
import { guidRegex } from 'src/Utils/helperFunc';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto';
import { useFormik } from 'formik';
import TraineeRow from './Components/TraineeRow';
import { LoadingButton } from '@mui/lab';
import TrainerRow from './Components/TrainerRow';
import LearningItemResponseService from 'src/Services/Training/LearningItemResponseService';
import LearningItemService from 'src/Services/Training/LearningItemService';
import { LearningDocumentResponseStatus } from 'src/dtos/Training/LearningDocumentResponse.dto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function IndividualAttendanceSheet() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const {id, responseId} = useParams(); //id is trainingSessionId

    const navigate = useNavigate()
    //#region queries
    const itemResponseQuery = useQuery({
        queryKey: ["itemResponse", responseId],
        queryFn: async () => {
            if(responseId && guidRegex.test(responseId)){
                return await LearningItemResponseService.Get(responseId)
            }
            return null
        }
    })

    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", id],
        queryFn: async () => {
            if(id && guidRegex.test(id)){
                return await TrainingSessionService.Get(id)
            }
            return null
        }
    })

    const learningItemQuery = useQuery({
        queryKey: ["learningItem", itemResponseQuery.data?.learningItemId],
        queryFn: async () => {
            if(itemResponseQuery.data){
                return await LearningItemService.Get(itemResponseQuery.data.learningItemId)
            }
            return null
        }
    })

    const initialLoading = trainingSessionQuery.isLoading || itemResponseQuery.isLoading || learningItemQuery.isLoading

    const formik = useFormik({
        enableReinitialize: true, 
        validateOnChange: false,
        initialValues: itemResponseQuery.data?.attendanceSheetResponse ?? LearningDocumentResponseService.GetDefaultValues().attendanceSheetResponse,
        onSubmit: () => {
            
        }
    })

    const submitMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningItemResponseService.SubmitAttendanceSheet(id)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Training Outcome Submitted Successfully"})
            itemResponseQuery.refetch()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    
    //#endregion
    const editingLocked = itemResponseQuery.data?.status === LearningDocumentResponseStatus.Completed
    
    const handleBackClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/Attendance/${responseId}`), '') });
    }
    const handleSubmitClick = () => {
        if(itemResponseQuery.data && !editingLocked)
            submitMutation.mutate(itemResponseQuery.data.id)
    }
    
    const trainees = formik.values.attendees.filter(a => a.type === AttendeeType.Trainee)
    const trainers = formik.values.attendees.filter(a => a.type === AttendeeType.Trainer)

    if(initialLoading) {
        return (
            <Stack>
                {/* For title */}
                <div style={{width:"100%", display:"flex", justifyContent:"center", height:"5rem"}}>
                    <Skeleton animation="wave" variant="text" sx={{width:"80%", display:"flex"}}/>

                </div>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </Stack>
        )
    }
    return (
    <>
        <Stack spacing={2} sx={{marginBottom:"4rem"}}>
            <Paper sx={{padding: "1rem"}}>
                <Stack direction="row">
                    <Typography variant='h5' sx={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>{`Training Outcome - ${learningItemQuery.data?.label}`}</Typography>
                    <div>
                        <IconButton onClick={handleBackClick}>
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                    </div>
                </Stack>
            </Paper>

            <Paper sx={{padding: "1rem"}}>
                <Typography variant='h6'>Trainees</Typography>
                <Typography>
                    {`By signing this document, I confirm that I attended the ${learningItemQuery.data?.label}. `}
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                                <TableCell>Notes/Feedback</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainees.map((trainee, index) => (
                                <TraineeRow 
                                    key={index} 
                                    formik={formik} 
                                    trainee={trainee}
                                    setMessageItem={setMessageItem}
                                    readyForAssesmentColumnDisabled
                                    editingLocked={editingLocked}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='h6' sx={{marginTop: "1rem"}}>Trainers</Typography>
                <Typography>As the Trainer, I sign this to acknowledge the attendance of all the above marked Trainee's. </Typography>
                <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainers.map((trainer, index) => (
                                <TrainerRow
                                    key={index}
                                    trainer={trainer}
                                    formik={formik}
                                    setMessageItem={setMessageItem}
                                    editingLocked={editingLocked}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <LoadingButton sx={{marginTop:"1rem"}} variant='contained' color='success' loading={submitMutation.isPending} onClick={handleSubmitClick}>{editingLocked ? "Already Submitted" : "Submit"}</LoadingButton>

            </Paper>
                        
        </Stack>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>

  )

}

export default IndividualAttendanceSheet