import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, {  } from 'react'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { JournalEntryDto, JournalEntryStatus, JournalEntryStatusString, JournalEntryType, JournalEntryTypeString } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import ServiceTaskEntry from './ServiceTaskEntry'
import { LoadingButton } from '@mui/lab'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import IssueOrFaultRecordEntry from './IssueOrFaultRecordEntry'
import OutOfServiceEntry from './OutOfServiceEntry'
import DuplicateEntry from './DuplicateEntry'
import * as yup from 'yup'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import JournalEntryService, { journalEntryTypesWithStatus } from 'src/Services/Checklists/Inspections/AttractionJournalEntryService'
import { useMutation } from '@tanstack/react-query'
import { NameDto } from 'src/dtos/NameDto.dto'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



type Props = {
    validationSchema: yup.AnySchema
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    attractionName: NameDto
}

function JournalEntryNew({validationSchema, setMessageItem, attractionName}: Props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const typeString =  searchParams.get("type")
    const defaultType = typeString !== null ? Number.parseInt(typeString) as JournalEntryType : undefined
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: JournalEntryService.GetDefaultValues(attractionName, defaultType),
        onSubmit: (values) => {
            createMutation.mutate(values)
        }
    })


    const handleCancelClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/new`), '') });
    }

    const handleTypeChange = (event: SelectChangeEvent<JournalEntryType>) => {
        const newType: JournalEntryType = event.target.value as JournalEntryType
        
        if(journalEntryTypesWithStatus.includes(newType)){
            formik.setFieldValue("status", JournalEntryStatus.outstanding)
        } else {
            formik.setFieldValue("status", JournalEntryStatus.NA)
        }
        formik.handleChange(event)
    }

    const createMutation = useMutation({
        mutationFn: (values:JournalEntryDto) => {
            return JournalEntryService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Journal Entry created successfully!"})
            
            //Update Url
            navigate({pathname: window.location.pathname.replace(new RegExp('/new'), `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleCreateClick = () => {
        formik.handleSubmit()
    }
    return (
        <>
            <div style={{paddingTop:'1rem', paddingLeft:"1rem", paddingRight:"4rem", display:"flex", flexDirection:"row", width:"100%"}}>
            <div>
                    <Link to={location.pathname.replace(`/new`, "")}>
                        <IconButton>
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                    </Link>
            </div>
            <div style={{width:'100%', textAlign:"center"}}>
                {attractionName.label !== "" ? (
                    <Typography sx={{textAlign:"center"}} variant='h5'>   
                        {attractionName.label}
                    </Typography>
                ) : (
                    <Skeleton variant='text' sx={{textAlign:"center", fontSize:"2.83rem"}}/>
                )}
            </div>
        </div>    
            <Stack sx={{padding:"1rem"}} spacing={1}>
                <div style={{width:"100%"}}>
                    <EditableLabel 
                        value={formik.values.label}
                        setValue={(newValue: string) => formik.setFieldValue("label", newValue)}
                        onBlur={() => {formik.setFieldTouched('label', true, false)}}
                        placeholder={"Entry Summary"}
                        typographyProps={{
                            variant:"h5"
                        }}
                        autoFocus
                        helperText={formik.touched.label && formik.errors.label}
                        error={formik.touched.label && Boolean(formik.errors.label)}                
                    />
                </div>
                <Stack direction={"row"} spacing={1}>
                    <FormControl fullWidth>
                        <InputLabel id="entry-type-label">Type</InputLabel>
                        <Select
                            id="type"
                            name="type"
                            labelId="entry-type-label"
                            label="Type"
                            value={formik.values.type}
                            onChange={handleTypeChange}
                            onBlur={formik.handleBlur}
                            size={"small"}
                        >
                            <MenuItem value={JournalEntryType.serviceTask}>{JournalEntryTypeString[JournalEntryType.serviceTask]}</MenuItem>
                            <MenuItem value={JournalEntryType.issueFaultRecord}>{JournalEntryTypeString[JournalEntryType.issueFaultRecord]}</MenuItem>
                            <MenuItem value={JournalEntryType.outOfService}>{JournalEntryTypeString[JournalEntryType.outOfService]}</MenuItem>
                            <MenuItem value={JournalEntryType.duplicateEntry}>{JournalEntryTypeString[JournalEntryType.duplicateEntry]}</MenuItem>
                            <MenuItem value={JournalEntryType.ThirdPartyAuditFinding}>{JournalEntryTypeString[JournalEntryType.ThirdPartyAuditFinding]}</MenuItem>

                        </Select>
                    </FormControl>
                    {journalEntryTypesWithStatus.includes(formik.values.type) && (
                        <FormControl fullWidth>
                            <InputLabel id="entry-status-label">Status</InputLabel>
                            <Select
                                id="status"
                                name="status"
                                labelId="entry-status-label"
                                label="Status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                size={"small"}
                            >
                                <MenuItem value={JournalEntryStatus.outstanding}>{JournalEntryStatusString[JournalEntryStatus.outstanding]}</MenuItem>
                                <MenuItem value={JournalEntryStatus.completed}>{JournalEntryStatusString[JournalEntryStatus.completed]}</MenuItem>
                            </Select>
                        </FormControl>        
                    )}
                </Stack>
                
                {formik.values.type === JournalEntryType.serviceTask && (
                    <ServiceTaskEntry
                        formik={formik}
                    />
                )}
                {formik.values.type === JournalEntryType.issueFaultRecord && (
                    <IssueOrFaultRecordEntry
                        formik={formik}
                    />
                )}
                {formik.values.type === JournalEntryType.outOfService && (
                    <OutOfServiceEntry
                        formik={formik}
                    />
                )}
                {formik.values.type === JournalEntryType.duplicateEntry && (
                    <DuplicateEntry
                        formik={formik}
                    />
                )}
                {formik.values.type === JournalEntryType.ThirdPartyAuditFinding && (
                    <ServiceTaskEntry
                        formik={formik}
                    />
                )}

                <span style={{display:"flex", flexDirection: "row", gap:"1rem", justifyContent: "space-between", padding:"1rem", paddingTop:"0"}}>
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <LoadingButton 
                        variant='contained' 
                        onClick={handleCreateClick}
                        loading={createMutation.isPending}    
                    >
                        Create
                    </LoadingButton>
                </span>

            </Stack>
        </>
  )
}

export default JournalEntryNew