import { ChildEntity } from "../AwTypes";

export enum AttractionType {
    Mechanical = "Mechanical",
    Aquatic = "Aquatic",
    Hybrid = "Hybrid",
    Other = "Other"
}

export const AttractionTypeIndexToString: {[key: number]: AttractionType} = {
    0: AttractionType.Mechanical,
    1: AttractionType.Aquatic,
    2: AttractionType.Hybrid,
    3: AttractionType.Other
}

export const AttractionStringToIndex: {[key: string]: number} = {
    "Mechanical": 0,
    "Aquatic": 1,
    "Hybrid": 2,
    "Other": 3
}

export const AttractionTypeList: string[] =[
    AttractionType.Mechanical,
    AttractionType.Aquatic,
    AttractionType.Hybrid,
    AttractionType.Other
]

export interface AttractionDto {
    id: string;
    label: string;
    description?: string;
    childEntities?: ChildEntity[];
    type: AttractionType | undefined;
    residingArea?: {id: string, label: string} | null;
    residingAreaLabel?: string;
    deviceMinimumCapacity?: number | "";
    deviceMaximumCapacity?: number | "";
    deviceCapacityLabel?: string;
    enabled: boolean;
    createdBy?: string;
    createdOn?: Date;
    modifiedBy?: string;
    modifiedOn?: Date;
}

export interface CreateAttractionDto {
    id?: string;
    label: string;
    description?: string;
    childEntity?: ChildEntity;
    type: number | undefined;
    location: 
        {
            id?: string;
            label: string;
            description?: string;
            parentLocationId?: string;
            parentLocation?: {
                id: string,
                label: string
            };         
        } | null;
    deviceMinimumCapacity?: number;
    deviceMaximumCapacity?: number;
    deviceCapacityLabel?: string;
    enabled: boolean;
    createdBy?: string;
    createdOn?: Date;
    modifiedBy?: string;
    modifiedOn?: Date;
}

export interface AttractionNameDto {
    id: string;
    label: string;
}