import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import ChecklistIcon from '@mui/icons-material/Checklist';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import React from 'react'

function MaintenanceBottomNavigation() {
  return (
    <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
      <BottomNavigation
          showLabels
      >
        <BottomNavigationAction label="Journal" icon={<FeedOutlinedIcon/>}/>
        <BottomNavigationAction label="Checklist" icon={<ChecklistIcon/>}/>
      </BottomNavigation>
    </Paper>
  )
}

export default MaintenanceBottomNavigation