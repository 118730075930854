import React, { useEffect, useState } from 'react'
import JournalComment from './Components/JournalComment'
import { Avatar, LinearProgress, List, ListItem, ListItemAvatar, Pagination, Skeleton } from '@mui/material'
import NewComment from './Components/NewComment'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { useQuery } from '@tanstack/react-query'
import { ListParameters } from 'src/Services/ListParameters'
import JournalEntryService from 'src/Services/Checklists/Inspections/AttractionJournalEntryService'
import { GetCurrentUsername } from 'src/Utils/authUtils'

type Props = {
    journalEntryId: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function JournalComments({journalEntryId, setMessageItem}:Props) {
    const initialListParams: ListParameters = {
        page: 1,
        pageSize: 10,
    }
    const [listParams, setListParams] = useState<ListParameters>(initialListParams);
    const currentUsername = GetCurrentUsername()
    //#region queries
    const journalCommentsQuery = useQuery({
        queryKey: ["journalComments", journalEntryId, listParams],
        queryFn: async () => {
            return await JournalEntryService.GetListComments(journalEntryId, listParams)
        },
        placeholderData: (prev) => prev //used to keep previous data when fetching
    })
    
    useEffect(() => {
        if(journalCommentsQuery.isError){
            setMessageItem({error: journalCommentsQuery.error})
        }
    }, [setMessageItem, journalCommentsQuery.error, journalCommentsQuery.isError])
    //#endregion

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setListParams({...listParams, page:value}); 
    };

    const totalPages = Math.ceil((journalCommentsQuery.data?.totalRecords??0) / listParams.pageSize)
    return (
        <>
            <NewComment
                journalEntryId={journalEntryId}
                setMessageItem={setMessageItem}
                refetchJournalComments={journalCommentsQuery.refetch}
            />
            {journalCommentsQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer so list doesnt move
            )}
            <List>
                {(journalCommentsQuery.data == null || journalCommentsQuery.data.data.length === 0) && journalCommentsQuery.isLoading ? (
                    <>
                        {Array.from({length: listParams.pageSize}).map((_, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Skeleton variant='circular'>
                                        <Avatar>
                                            
                                        </Avatar>
                                    </Skeleton>
                                </ListItemAvatar>
                                <Skeleton variant='text' width={"10rem"}/>
                            </ListItem>
                        ))}
                    </>
                ) : journalCommentsQuery.data && journalCommentsQuery.data.data.map((journalComment) => (
                    <JournalComment
                        key={journalComment.id}
                        comment={journalComment}
                        setMessageItem={setMessageItem}
                        refetchJournalComments={journalCommentsQuery.refetch}
                        currentUserName={currentUsername}
                    />
                ))}
            </List>
            {totalPages > 1 && (
                <Pagination
                    count={totalPages}
                    page={listParams.page}
                    onChange={handlePageChange}
                    sx={{marginTop:"0.5rem"}}
                />
            )}
        </>
    );
}

export default JournalComments