import { MenuItem, Select, Tooltip } from '@mui/material'
import { useEditor, Editor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useCallback, useEffect } from 'react'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import "./styles.scss";
import classNames from 'classnames';
import Underline from "@tiptap/extension-underline";
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PreparationChecklistComponent from './PreparationChecklistExtension'
import ChecklistIcon from '@mui/icons-material/Checklist';

import Link from '@tiptap/extension-link';
import { flushSync } from 'react-dom';


//https://tiptap.dev/docs
const SimpleEditor = ({onBlurSaveContent, content, onFocus}: {onBlurSaveContent: (content: string) => void, onFocus?: () => void, content: string}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      PreparationChecklistComponent,
      Link
    ],
    content
  }) as Editor;
  
  //#region menu effects
  const toggleBold = useCallback((e: any) => {
    editor.chain().focus().toggleBold().run();
    e.preventDefault()
  }, [editor]);

  const toggleItalic = useCallback((e: any) => {
    editor.chain().focus().toggleItalic().run();
    e.preventDefault()
  }, [editor]);

  const toggleUnderline = useCallback((e: any) => {
    editor.chain().focus().toggleUnderline().run();
    e.preventDefault()
  }, [editor]);

  const setParagraph = useCallback((e: any) => {
    editor.chain().focus().setParagraph().run();
    e.preventDefault()
  }, [editor])

  const setHeading = useCallback((e: any, level: any) => {
    editor.commands.setHeading({ level: level})
    e.preventDefault()
  }, [editor])

  const toggleBulletList = useCallback((e: any) => {
    editor.chain().focus().toggleBulletList().run();
    e.preventDefault()
  }, [editor])

  const toggleOrderedList = useCallback((e: any) => {
    editor.chain().focus().toggleOrderedList().run();
    e.preventDefault()
  }, [editor])

  const insertChecklistChecklist = useCallback((e:any) => {
    editor.commands.insertContent(`<PreparationChecklist></PreparationChecklist><p></p>`, {
      updateSelection: false,
      parseOptions: {
        preserveWhitespace: false
      }
    })

    e.preventDefault()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const setContent = useCallback((newContent: string) => {
    editor.commands.setContent(newContent)
  }, [editor])

  useEffect(() => {
    if(editor){
        setContent(content)
    }
  }, [content, editor, setContent])

  //#endregion

  useEffect(() => {
    if(editor){
      flushSync(() => {
        editor.commands.setContent(content);
      });
    }
  }, [content, editor])

  if(!editor){
    return null
  }

  

  enum TextStyleTest {
    paragraph, 
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
  }
  
  const selectionsStyle: TextStyleTest = editor.isActive('heading', {level: 1}) ? TextStyleTest.h1 :
    editor.isActive('heading', {level: 2}) ? TextStyleTest.h2 :
    editor.isActive('heading', {level: 3}) ? TextStyleTest.h3 :
    editor.isActive('heading', {level: 4}) ? TextStyleTest.h4 :
    editor.isActive('heading', {level: 5}) ? TextStyleTest.h5 :
    editor.isActive('heading', {level: 6}) ? TextStyleTest.h6 :
    TextStyleTest.paragraph

  const handleStyleChange = (e: any) => {
    const newStyle = e.target.value;
    if(newStyle === TextStyleTest.paragraph){
      setParagraph(e)
    } else {
      setHeading(e, newStyle)
    }
  }
  
  return (
    <div className="editor">
      <div className='menu'  tabIndex={-1}>
        <button onClick={toggleBold} className={classNames("menu-button", {
          "is-active": editor.isActive("bold")
        })}>
          <FormatBoldIcon/>
        </button>
        <button onClick={toggleItalic} className={classNames("menu-button", {
          "is-active": editor.isActive("italic")
        })}>
          <FormatItalicIcon/>
        </button>
        <button onClick={toggleUnderline} className={classNames("menu-button", {
          "is-active": editor.isActive("underline")
        })}>
          <FormatUnderlinedIcon/>
        </button>
        <Select
          id="style"
          size="small"
          value={selectionsStyle}
          onChange={handleStyleChange}
        >
          <MenuItem value={TextStyleTest.paragraph}>Paragraph</MenuItem>
          <MenuItem value={TextStyleTest.h1}>Heading 1</MenuItem>
          <MenuItem value={TextStyleTest.h2}>Heading 2</MenuItem>
          <MenuItem value={TextStyleTest.h3}>Heading 3</MenuItem>
        </Select>
        <button onClick={toggleBulletList} className={classNames("menu-button", {
          "is-active": editor.isActive("bulletList")
        })}>
          <FormatListBulletedIcon/>
        </button>
        <button onClick={toggleOrderedList} className={classNames("menu-button", {
          "is-active": editor.isActive("orderedList")
        })}>
          <FormatListNumberedIcon/>
        </button>
        
        <Tooltip title={"Insert Checklist"}>
          <button onClick={insertChecklistChecklist} className='menu-button'>
            <ChecklistIcon/>
          </button>
        </Tooltip>
      
        
      </div>
      <EditorContent editor={editor} onBlur={() => onBlurSaveContent(editor.getHTML())} tabIndex={-1} onFocus={onFocus}/>
    </div>
    
  )
}

export default SimpleEditor