import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, TextField, Stack, IconButton, Grid, Tooltip, FormControl, Select, MenuItem, SelectChangeEvent, InputLabel } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { LearningDocumentPoint, LearningDocumentPointType, LearningDocumentVersion, PointTypeString } from 'src/dtos/Training/LearningDocument.dto';
import ULeditor from '../ULEditor/ULeditor';
import { FormikProps } from 'formik';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import SubpointsCreator from '../SubpointsCreator/SubpointsCreator';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { FocusElement, sanitizeHtmlToIFrame } from 'src/Utils/helperFunc';
  

export type Props = {
  formik: FormikProps<LearningDocumentVersion>;
  formikString: string;
  formikPointValue: LearningDocumentPoint;
  index: number;
  DeletePoint: () => void;
  DeleteSubPoint: (subpointIndex: number) => void;
  HandleAddPointBelow: () => void;
  preparationChecklist?: boolean;
};

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    prevProps.formikPointValue === nextProps.formikPointValue &&
    prevProps.index === nextProps.index
  );
}

function PointsListItemUnmemoized({ formik, formikString, formikPointValue, index: pointIndex, DeletePoint, DeleteSubPoint, HandleAddPointBelow, preparationChecklist }: Props) {

  const handleTypeChange = (e: SelectChangeEvent<LearningDocumentPointType>) => {
    const selectedType : LearningDocumentPointType = typeof e.target.value === 'string' ? LearningDocumentPointType.standard : e.target.value
    formik.setFieldValue(`${formikString}.type`, selectedType)

    if(selectedType === LearningDocumentPointType.troubleshooting){
      if(!formikPointValue.unorderedList){
        formik.setFieldValue(`${formikString}.unorderedList`, " ")
      }
    } else if (formikPointValue.unorderedList === " "){
      formik.setFieldValue(`${formikString}.unorderedList`, null)
    }
  }

  const handleAddExamplesClick = () => {
    if(formikPointValue.unorderedList) {
      formik.setFieldValue(`${formikString}.unorderedList`, null)
    } else {
      formik.setFieldValue(`${formikString}.unorderedList`, " ")
    }
  }
  

  const handleAddSubPointsClick = () => {
    const newSubpoint = {...LearningDocumentVersionService.GetDefaultSubpointValue(), sortOrder: formikPointValue.subpoints.length}
    const newSubpoints = [...formikPointValue.subpoints, newSubpoint]
    formik.setFieldValue(`${formikString}.subpoints`, newSubpoints)
  }

  return (
    <Draggable 
        draggableId={`point_${formikPointValue.sortOrder}`} 
        index={pointIndex}          
    >
      {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
          >
            <Grid container>
              <Grid container columnSpacing={1}>
                <Stack direction={"row"} sx={{width: "100%"}}>
                  <div 
                    {...provided.dragHandleProps} 
                    style={{ display: "flex", alignItems: "center", marginRight: '8px' }}
                    >
                    <DragIndicatorIcon/>
                  </div>
                  <Grid item xs={preparationChecklist ? 0 : 3}>
                    {!preparationChecklist && (
                      <FormControl sx={{width:"100%", paddingRight:"0.5rem"}}>
                        <InputLabel>Type</InputLabel>
                        <Select 
                          id={`${formikString}.type`}
                          name={`${formikString}.type`}
                          value={formikPointValue.type}
                          onChange={handleTypeChange}
                          size='small' 
                          sx={{width: "100%"}}
                          label="Type"
                        >
                          <MenuItem value={LearningDocumentPointType.standard}>{PointTypeString[LearningDocumentPointType.standard]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.question}>{PointTypeString[LearningDocumentPointType.question]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.trainerAction}>{PointTypeString[LearningDocumentPointType.trainerAction]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.traineeDemo}>{PointTypeString[LearningDocumentPointType.traineeDemo]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.previousIncident}>{PointTypeString[LearningDocumentPointType.previousIncident]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.tallyCounter}>{PointTypeString[LearningDocumentPointType.tallyCounter]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.video}>{PointTypeString[LearningDocumentPointType.video]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.image}>{PointTypeString[LearningDocumentPointType.image]}</MenuItem>
                          <MenuItem value={LearningDocumentPointType.troubleshooting}>{PointTypeString[LearningDocumentPointType.troubleshooting]}</MenuItem>

                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={preparationChecklist ? 12 : 9}>
                    <Stack direction={"row"} spacing={0.5}>
                      <TextField
                        id={`${formikString}.label`} 
                        name={`${formikString}.label`}
                        label={"Label"}
                        value={formikPointValue.label}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyDown={e => {
                          if(e.key === "Enter" && e.shiftKey && e.ctrlKey) {
                            e.preventDefault();
                            if(!formikPointValue.unorderedList){
                              handleAddExamplesClick();
                            }
                          }
                          else if(e.key === "Enter" && e.shiftKey){
                            e.preventDefault();
                            handleAddSubPointsClick();
                            setTimeout(() => {
                              FocusElement(1 + formikPointValue.subpoints.length)

                            }, 1)
                          }
                          else if(e.key === "Enter"){
                            e.preventDefault();
                            if(formikPointValue.label.length > 0){
                              HandleAddPointBelow()
                              let moveAmount = 1
                              moveAmount += formikPointValue.subpoints.length;
                              //Move focus to new subpoints label
                              setTimeout(() => {
                                FocusElement(moveAmount);
                              }, 1); 
                            }
                          }
                        }}
                        size="small"
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                      />
                      {formikPointValue.type !== LearningDocumentPointType.troubleshooting && (
                        <>
                          <Tooltip title={`${formikPointValue.unorderedList ? "Delete Examples" : "Add Examples"}`}>
                            <div style={{display: "flex", alignItems: "center"}}>
                              <IconButton  tabIndex={-1} size='small' onClick={handleAddExamplesClick}>
                                {formikPointValue.unorderedList ? (
                                    <PlaylistRemoveIcon/>
                                  ) : (
                                    <ListIcon fontSize='small'/>
                                )}
                              </IconButton>
                            </div>
                          </Tooltip>
                          <Tooltip title="Add Subpoint">
                            <div style={{display: "flex", alignItems: "center"}}>
                              <IconButton tabIndex={-1} size='small' onClick={handleAddSubPointsClick}>
                                <ChecklistIcon fontSize='small'/>
                              </IconButton>
                            </div>
                          </Tooltip>
                        </>
                      )

                      }
                      <Tooltip title="Delete Point and Subpoints">
                        <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton tabIndex={-1} onClick={DeletePoint}>
                          <DeleteIcon/>
                        </IconButton>
                        </div>
                      </Tooltip>
                    </Stack>
                    {formikPointValue.type === LearningDocumentPointType.video && (
                      <>
                        <QuestionTitle title='Embedded Video' infoText="Only accepts iframes"/>
                        <TextField
                          id={`${formikString}.embeddedIFrame`}
                          name={`${formikString}.embeddedIFrame`}
                          value={formikPointValue.embeddedIFrame}
                          onChange={(e) => {e.target.value = sanitizeHtmlToIFrame(e.target.value); formik.handleChange(e); }}
                          onBlur={formik.handleBlur}
                          size="small"
                          sx={{width:"100%"}}
                        />
                      </>
                    )}
                    {formikPointValue.type === LearningDocumentPointType.image && (
                      <>
                        <QuestionTitle title='Embedded Image' infoText="Only accepts iframes"/>
                        <TextField
                          id={`${formikString}.embeddedIFrame`}
                          name={`${formikString}.embeddedIFrame`}
                          value={formikPointValue.embeddedIFrame}
                          onChange={(e) => {e.target.value = sanitizeHtmlToIFrame(e.target.value); formik.handleChange(e); }}
                          onBlur={formik.handleBlur}
                          size="small"
                          sx={{width:"100%"}}
                        />
                      </>
                    )}
                    {formikPointValue.type === LearningDocumentPointType.tallyCounter && (
                      <TextField
                        id={`${formikString}.tallyNumber`}
                        name={`${formikString}.tallyNumber`}
                        value={formikPointValue.tallyNumber}
                        onChange={(e: any) => {
                          if(e.target.value === ""){
                            formik.setFieldValue(`${formikString}.tallyNumber`, null)
                          } else {
                            formik.handleChange(e)
                          }
                        }}
                        onBlur={formik.handleBlur}
                        type='number'
                        size="small"
                        sx={{marginTop:"0.5rem", width:"9rem"}}
                        label="Minimum Tally"      
                      />
                    )}
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={9.25} sx={{marginLeft: "3rem"}}>
                {formikPointValue.unorderedList && (
                  <ULeditor
                    label={formikPointValue.type === LearningDocumentPointType.troubleshooting ? "Solutions" : "Examples"} 
                    content={formikPointValue.unorderedList}
                    onBlurSaveContent={(content) => formik.setFieldValue(`${formikString}.unorderedList`, content)}             
                  />
                )}
                
              </Grid>
              <Grid item xs={12}>
                {formikPointValue.subpoints && (
                  <SubpointsCreator
                    formik={formik}
                    formikString={`${formikString}.subpoints`}
                    formikSubpointsValue={formikPointValue.subpoints}
                    pointSortOrder={formikPointValue.sortOrder}
                    DeleteSubPoint={DeleteSubPoint}
                    HandleAddPointBelow={HandleAddPointBelow}
                  />
                )}
              </Grid>
              
            </Grid>
            
          </ListItem>
          
      )}
    </Draggable>
  );
};

const PointsListItem = memo<Props>(PointsListItemUnmemoized, areEqual)

export default PointsListItem;
