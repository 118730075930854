import axios, {AxiosResponse} from 'axios'
import { LocationDto, LocationNameDto, LocationType, CreateLocationDto, LocationStringToIndex } from 'src/dtos/Resources/Location.dto';
import {ListParameters, SortDirection} from '../ListParameters';
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import { GetBearer } from 'src/Utils/authUtils';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/location";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class LocationService {
    static GetDefaultValues(){
        const defaultLocationValues: LocationDto = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            parentLocation: null,
            description: "",
            sortOrder: 100,
            type: undefined,
            enabled: true,
            createdBy: "unknown",
            createdOn: new Date()
          }
        return defaultLocationValues
    }
    static async GetList(listParam?: ListParameters, searchTerm?: string, type?: LocationType, parentLocationId?: string, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            
            const bearer = await GetBearer();
            
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/List`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    type: type,
                    parentLocationId: parentLocationId,
                    includeDisabled: includeDisabled                
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){ //not found error status code (404)
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async GetNames(excludeLocationId?: string): Promise<LocationNameDto[]> {
        try {
            const bearer = await GetBearer();

            const response: AxiosResponse<LocationNameDto[]> = await axios.get<LocationNameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    excludeLocationId: excludeLocationId
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){ //not found error status code (404)
                    return []
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(locationData: LocationDto): Promise<AxiosResponse> {
        const parentLocationId = locationData.parentLocation?.id
        const createLocationData: CreateLocationDto = {
            label: locationData.label,
            description: locationData.description,
            type: LocationStringToIndex[locationData.type ? locationData.type: 0],
            sortOrder: locationData.sortOrder
        }
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(
                `${apiBaseUrl}/Create`,
                createLocationData,
                {headers: {
                    Authorization: bearer
                },params: {parentLocationId: parentLocationId}}
            );
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, locationData: LocationDto): Promise<AxiosResponse> {
        const parentLocationId = locationData.parentLocation?.id
        const updateLocationData: CreateLocationDto = {
            id: id,
            label: locationData.label,
            description: locationData.description,
            type: LocationStringToIndex[locationData.type ? locationData.type: 0],
            sortOrder: locationData.sortOrder
        }
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(
                `${apiBaseUrl}/Update`,
                updateLocationData,
                {headers: {
                    Authorization: bearer
                },params: {id: id, parentLocationId: parentLocationId}}
            );
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Get(id: string): Promise<LocationDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LocationDto> = await axios.get<LocationDto>(`${apiBaseUrl}/get/${id}`, {headers: {Authorization: bearer}});
            if(response.data.parentLocationId){
                const parentLocation: AxiosResponse<LocationNameDto> = await axios.get<LocationNameDto>(`${apiBaseUrl}/get/${response.data.parentLocationId}`, {
                    headers: {
                        Authorization: bearer
                    }
                });
                response.data.parentLocation = {id: parentLocation.data.id, label: parentLocation.data.label};
            }
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAreas(): Promise<LocationNameDto[]>{
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LocationNameDto[]> = await axios.get<LocationNameDto[]>(`${apiBaseUrl}/GetNames`, {
                params: {
                    type: LocationStringToIndex[LocationType.Area]
                },
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try { 
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}   