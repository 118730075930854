import { Button, ImageList, Pagination, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FileMetadata } from 'src/dtos/FileMetadata.dto'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { MessageItem } from '../errorHandlingSnackbar';
import AttachmentListItem from './Components/AttachmentListItem';
import { ListResponseDto } from 'src/dtos/ListResponse.dto';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { GetCurrentUsername } from 'src/Utils/authUtils';
import CameraCapture from './Components/CameraCapture';

type Props = 
    {
        setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
        callService: (page: number, pageSize: number) => Promise<ListResponseDto<FileMetadata>>
        addFileService: (file: File) => Promise<AxiosResponse<FileMetadata>>
        deleteFileService: (fileId: string) => Promise<AxiosResponse>
        listIdentifier: string //used for query key and image prefix
    }
function Attachments({setMessageItem, callService, addFileService, deleteFileService, listIdentifier}: Props) {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [page, setPage] = useState(1)
    const [tempAttachments, setTempAttachments] = useState<FileMetadata[]>([]);
    const [cameraCaptureOpen, setCameraCaptureOpen] = useState(false)
    const [captureType, setCaptureType] = useState<"Photo" | "Video">("Photo")
    const currentUsername = GetCurrentUsername()

    const isXl = useMediaQuery('(min-width: 1920px)');
    const isLg = useMediaQuery('(min-width: 1280px)');
    const isMd = useMediaQuery('(min-width: 960px)');
    const isSm = useMediaQuery('(min-width: 600px)');

    
    const IMAGE_WIDTH = 164
    const SPACER_BETWEEN_IMAGES = 4

    const imageColumns = isXl ? 6 : isLg ? 5 : isMd ? 4 : isSm ? 3 : 2;
    const imageMaxRows = (isXl || isLg) ? 3 : 2 
    const pageSize = imageColumns * imageMaxRows
    

    //#region queries
    const attachmentsQuery = useQuery({
        queryKey: ["attachments", page, pageSize, listIdentifier], 
        queryFn: async () => {
            return await callService(page, pageSize)
        },
        placeholderData: (prev) => prev //keep previous data when fetching 
    })

    const addFileMutation = useMutation({
        mutationFn: async (values: File) => {
            return await addFileService(values)
        },
        onSuccess: (response) => {
            const fileMetadata = response.data
            setMessageItem({successMessage: "File added successfully!"})
            setTempAttachments((prev) => prev.filter((f) => f.fileName !== fileMetadata.fileName)); // Remove temp file
            attachmentsQuery.refetch()
        },
        onError: (error) => {
            setMessageItem({error: error})
            setTempAttachments([]);
        }
    })

    const deleteFileMutation = useMutation({
        mutationFn: async (values: string) => {
            return await deleteFileService(values)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "File deleted successfully!"})
            attachmentsQuery.refetch()
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    useEffect(() => {
        if(attachmentsQuery.isError){
            setMessageItem({error: attachmentsQuery.error})
        }
    }, [setMessageItem, attachmentsQuery.error, attachmentsQuery.isError])

    //#endregion

    const imageRows = (attachmentsQuery.data && attachmentsQuery.data.data) ? Math.ceil((attachmentsQuery.data.data.length + tempAttachments.length)/imageColumns) : tempAttachments.length
    const displayedRows = imageRows > imageMaxRows ? imageMaxRows : imageRows
    const imageListHeight = (IMAGE_WIDTH+SPACER_BETWEEN_IMAGES)*(displayedRows)+SPACER_BETWEEN_IMAGES*(imageColumns-1) //spacer added to width
    const imageListWidth = IMAGE_WIDTH*imageColumns+SPACER_BETWEEN_IMAGES*(imageColumns-1)

    const handleCameraCaptureOpen = (type: "Photo" | "Video") => {
        setCameraCaptureOpen(true)
        setCaptureType(type)
    }
    
    const handleChooseFileClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = Array.from(files);
            const newAttachments: FileMetadata[] = newFiles.map((file) => ({
                fileName: file.name,
                fileUrl: URL.createObjectURL(file), // URL for image preview
                fileSize: file.size,
                type: file.type,
            }));

            // Add temp files to state
            setTempAttachments((prev) => [...prev, ...newAttachments]);

            newFiles.forEach((file) => {
                addFileMutation.mutate(file);
            });
        }
    };

    const handleDeleteFile = (fileId: string) => {
        deleteFileMutation.mutate(fileId)
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); 
    };

    const handleSaveCaptureFile = (file: File) => {

        const newAttachment: FileMetadata = {
            fileName: file.name,
            fileUrl: URL.createObjectURL(file),
            fileSize: file.size,
            type: file.type
        }

        setTempAttachments((prev) => [...prev, newAttachment])
        addFileMutation.mutate(file)

        setCameraCaptureOpen(false)
    }

    const totalPages = Math.ceil((attachmentsQuery.data?.totalRecords??0) / pageSize)
    const attachmentQueryfileNames = attachmentsQuery.data && attachmentsQuery.data.data ? [...attachmentsQuery.data.data.map((attachment) => attachment.fileName)] : []
    const fileNames = [...attachmentQueryfileNames, ...tempAttachments.map((tempAttachment) => tempAttachment.fileName)]

    return (
    <>
        <Stack direction={"row"} spacing={1}>
            <Button onClick={() => handleCameraCaptureOpen("Photo")}>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                    <CameraAltIcon fontSize='large'/>
                    <Typography variant='caption'>Take Photo</Typography>
                </Stack>
            </Button>
            <Button onClick={() => handleCameraCaptureOpen("Video")}>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                    <RadioButtonCheckedIcon fontSize='large'/>
                    <Typography variant='caption'>Take Video</Typography>
                </Stack>
            </Button>
            <Button onClick={handleChooseFileClick}>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                    <AttachFileIcon fontSize='large'/>   
                    <Typography variant='caption'>Choose file</Typography>
                </Stack>
            </Button>
            <input
                type="file"
                ref={hiddenFileInput}
                style={{display:"none"}}
                multiple
                onChange={handleFileChange}   
            />
        </Stack>
        {attachmentsQuery.data && attachmentsQuery.data.totalRecords > 0 && (
            <>
                <ImageList sx={{ height: imageListHeight, width: imageListWidth}} cols={imageColumns} rowHeight={IMAGE_WIDTH}>
                    {attachmentsQuery.data.data.map((file, index) => (
                        <AttachmentListItem
                            key={`${page}_${index}`}
                            file={file}
                            imageWidth={IMAGE_WIDTH} 
                            handleDeleteFile={handleDeleteFile}                   
                            currentUserName={currentUsername}
                        />
                    ))}
                    {tempAttachments.map((file, index) => (
                        <AttachmentListItem
                            key={`temp-${index}`}
                            file={file}
                            imageWidth={IMAGE_WIDTH}
                            isLoading={true}
                            currentUserName={currentUsername}
                        />
                    ))

                    }
                </ImageList>
                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        sx={{marginTop:"0.5rem"}}
                    />
                )}
            </>
        )}
        <CameraCapture
            type={captureType}
            cameraOpen={cameraCaptureOpen}
            handleClose={() => setCameraCaptureOpen(false)}
            handleSave={handleSaveCaptureFile}
            filePrefix={listIdentifier}
            currentFileNames={fileNames}
        />
    </>
  )
}

export default Attachments