import React from 'react'
import '../styles.css'
import { Checkbox } from '@mui/material'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import { useMutation } from '@tanstack/react-query'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { TeamMemberTrainingGroup } from 'src/dtos/HumanResources/TeamMemberTrainingGroup.dto'

type Props = {
    teamMember: TeamMemberTrainingGroup, 
    setTeamMembers: React.Dispatch<React.SetStateAction<TeamMemberTrainingGroup[]>>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    trainingGroupId: string;
    loading: boolean;
}
function TrainingGroupCheckbox({teamMember, setTeamMembers, setMessageItem, trainingGroupId, loading}:Props) {
    const addTrainingGroupMutation = useMutation({
        mutationFn: (trainingGroupId: string) => {
            return TeamMemberService.AddTrainerTrainingGroup(teamMember.teamMemberId, [trainingGroupId])
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: `Training Group added to ${teamMember.teamMemberName} successfully`})
            setTeamMembers(prevMembers =>
                prevMembers.map(member => (member.teamMemberId === teamMember.teamMemberId ? data : member))
            );
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const removeTrainingGroupMutation = useMutation({
        mutationFn: (trainingGroupId: string) => {
            return TeamMemberService.RemoveTrainerTrainingGroup(teamMember.teamMemberId, [trainingGroupId])
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: `Training Group removed from ${teamMember.teamMemberName} successfully`})
            setTeamMembers(prevMembers =>
                prevMembers.map(member => (member.teamMemberId === teamMember.teamMemberId ? data : member))
            );
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleCheckBoxClick = (trainingGroupId: string) => {
        const checked = teamMember.trainingGroupIds.includes(trainingGroupId)
        if(checked){
            removeTrainingGroupMutation.mutate(trainingGroupId)
        } else {
            addTrainingGroupMutation.mutate(trainingGroupId)
        }
    }

    const pending = loading || removeTrainingGroupMutation.isPending || addTrainingGroupMutation.isPending

    return (
    <>
        {!pending ?
            <Checkbox
                checked={teamMember.trainingGroupIds.includes(trainingGroupId)}
                onClick={() => handleCheckBoxClick(trainingGroupId)}
            />
            :
            <div style={{display:"flex", justifyContent:"center"}}>
                <div className='loader'/>
            </div>
        } 
    </>
  )
}

export default TrainingGroupCheckbox