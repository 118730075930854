import { Stack, ImageListItem, ImageListItemBar, IconButton, LinearProgress } from '@mui/material'
import React from 'react'
import assets from 'src/assets'
import { FileMetadata } from 'src/dtos/FileMetadata.dto'
import DeleteIcon from '@mui/icons-material/Delete';
import { showDialog } from 'src/components/AlertDialog/AlertDialog';

type Props = {
    file: FileMetadata
    imageWidth: number
    isLoading?: boolean
    handleDeleteFile?: (fileId: string) => void;
    currentUserName: string;
}
function AttachmentListItem({file, imageWidth, isLoading, handleDeleteFile, currentUserName}: Props) {

    const handleDeleteFileClick = async () => {
        const deleteFile = await showDialog({
            title: `Delete File`,
            contentText: `Are you sure you want to delete the file "${file.fileName}". Once its gone its gone!`,
            acceptText: "Delete Permanently",
            declineText: "Cancel"
        })

        if(deleteFile && file.id && handleDeleteFile){
            handleDeleteFile(file.id)
        }
    }

    const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000);
    const deletable = (file.uploadedOn ? new Date(file.uploadedOn) > twelveHoursAgo : false) && currentUserName === file.uploadedBy
    
    
    return (
    <>
        <Stack>
            <ImageListItem >
                <img
                    src={file.fileUrl}
                    alt={file.fileName}
                    loading="lazy"
                    style={{
                        width: `${imageWidth}px`,
                        height: `${imageWidth}px`,
                        objectFit: 'cover',
                        cursor: "pointer"
                    }}
                    onError={(e) => (e.currentTarget.src = assets.images.noImagePlaceholder)} // Fallback for non-image files
                    onClick={() => window.open(file.fileUrl, '_blank')?.focus()}
                />
                <ImageListItemBar
                    subtitle={file.fileName}
                    actionIcon={
                        <> { deletable && (
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`Delete ${file.fileName}`}
                                size="small"
                                onClick={handleDeleteFileClick}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                        </>
                    }
                />
                
                
            </ImageListItem>
            {isLoading ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer
            )}
        </Stack>
    </>
  )
}

export default AttachmentListItem