import {createSlice, PayloadAction} from "@reduxjs/toolkit"

type appState = {
    appState: string;
    currentPageName: string;
    guideLink?: string;
}

const initialState: appState = {
    appState: "",
    currentPageName: "",
}

export const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setAppState: (state, action: PayloadAction<string>) => {
            state.appState = action.payload
        },
        setCurrentPageName: (state, action: PayloadAction<string>) => {
            state.currentPageName = action.payload
        },
        setCurrentGuideLink: (state, action: PayloadAction<string>) => {
            state.guideLink = action.payload
        }
    }
})

export const {
    setAppState,
    setCurrentPageName,
    setCurrentGuideLink,
} = appStateSlice.actions;

export default appStateSlice.reducer;