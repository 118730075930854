import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack, Tab, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import RoleService from 'src/Services/HumanResources/RoleService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { RoleGroupType } from 'src/dtos/Resources/User.dto';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormikProps } from 'formik';
import { TeamMember } from 'src/dtos/HumanResources/TeamMember.dto';
import { Role, TeamMemberRole } from 'src/dtos/HumanResources/Role.dto';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { dateTimeOptions } from 'src/config/constants';
import TrainingGroupService from 'src/Services/Training/TrainingGroupService';

type Props = {
    formik: FormikProps<TeamMember>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    loading: boolean
}
function TmRoles({formik, setMessageItem, loading}: Props) {
    
    const [searchParams, setSearchParams] = useSearchParams()
    const [openTab, setOpenTab] = React.useState(searchParams.get("nestedTab") ?? 'System');

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        searchParams.set("nestedTab", tabValue)
        setSearchParams(searchParams)
    };

    const rolesQuery = useQuery({
        queryKey: ["rolesNames"],
        queryFn: async () => {
            return await RoleService.GetNames()
        }
    })

    const trainingGroupsQuery = useQuery({
        queryKey: ["trainingGroupNames"],
        queryFn: async () => {
            return await TrainingGroupService.GetNames()
        }
    })

    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit();
          } else {
            formik.validateForm();
          }
    }

    useEffect(() => {
        if(rolesQuery.isError){
            setMessageItem({error: rolesQuery.error})
        }
        if(trainingGroupsQuery.isError){
            setMessageItem({error: trainingGroupsQuery.error})
        }
    }, [setMessageItem, rolesQuery.isError, rolesQuery.error, trainingGroupsQuery.isError, trainingGroupsQuery.error])

    const Roles = ({groupType}:{groupType: RoleGroupType}) => {
        
        const handleRoleChange = (roleId: string, newVal: boolean) => {

            const existingRoleIndex = formik.values.roles.findIndex(tmRole => tmRole.roleId === roleId)
            if (existingRoleIndex >= 0){
                let newTmRoles = formik.values.roles;
                newTmRoles[existingRoleIndex].assigned = newVal;
                if(newTmRoles[existingRoleIndex].createdBy == null && newVal === false){
                    //if the createdBy is null and newVal is false then no need to add to the newTmRoles list as theres nothing to record.
                    //It would have been added and then removed by the user on the front end 
                    newTmRoles.splice(existingRoleIndex, 1);
                }
                formik.setFieldValue("roles", newTmRoles)
                
            } else {

                const newTmRole: TeamMemberRole = {
                    teamMemberId: formik.values.id,
                    roleId: roleId,
                    assigned: true,
                    enabled: true
                }
                formik.setFieldValue("roles", [...formik.values.roles, newTmRole])
            }

        }

        const ModificationDetails = ({role}:{role: Role}) => {
            const [open, setOpen] = useState(false)

            const roleAssignment = formik.values.roles.find(tmRole => tmRole.roleId === role.id)

            const handleClick = () => {
                setOpen(true)
            }

            const handleClose = () => {
                setOpen(false)
            }
            
            if(roleAssignment == null || roleAssignment.createdBy == null){
                return null
            }

            return (
                <>
                    <div style={{display:"felx", alignSelf: "center", justifyContent:"center", cursor:"pointer"}}>
                        <Tooltip title="Modification Details">
                            <IconButton onClick={handleClick}>
                                <InfoOutlinedIcon color='primary'/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>
                            {`${role.label} Modification Details`}
                        </DialogTitle>
                        <DialogContent>
                            <Typography>{`Last Modified By: ${roleAssignment.modifiedBy ?? roleAssignment.createdBy}`}</Typography>
                            <Typography>{`Last Modified: ${new Date(roleAssignment.modifiedOn ?? roleAssignment.createdOn ?? "")?.toLocaleDateString(undefined, dateTimeOptions)}`}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )

        }

        const rolesData = (rolesQuery.data ?? []).filter(role => role.group === groupType)
        return (
            <>
                {rolesData.map((role, index) => (
                    <React.Fragment key={index}>
                        <div style={{display:"flex", flexWrap: "wrap", gap:"0.5rem", width:"100%"}}>
                            <Stack direction="row">
                                <FormControlLabel
                                    label={role.label}
                                    control={
                                        <Checkbox
                                            checked={formik.values.roles.find(tmRole => tmRole.roleId === role.id)?.assigned}
                                            onChange={(_, checked) => {
                                                handleRoleChange(role.id, checked)
                                            }}  
                                        />
                                    }
                                    />
                                    <div style={{display:"felx", alignSelf: "center", justifyContent:"center", cursor:"pointer"}}>
                                        <Tooltip title={role.description}>
                                            <HelpOutlineIcon color='primary'/>
                                        </Tooltip>
                                    </div>
                                    <ModificationDetails
                                        role={role}
                                    />
                            </Stack>
                        </div>
                    </React.Fragment>
                ))

                }
            </>
        )
    }

    return (
        <>
            <TabContext value={openTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="System Roles" value="System"></Tab>
                        <Tab label="Department Roles" value="Departments"></Tab>
                        <Tab label="Attraction Inspection Roles" value="AttractionInspections"></Tab>
                        <Tab label="Training Roles" value="Training"></Tab>

                    </TabList>
                </Box>
                    <TabPanel value="System">
                        <Typography variant='h5'>System Roles</Typography>
                        <Roles groupType={RoleGroupType.System}/>
                        
                    </TabPanel>
                    <TabPanel value="Departments">
                        <Typography variant='h5'>Department Roles</Typography>
                        <Roles groupType={RoleGroupType.Departments}/>
                    </TabPanel>
                    <TabPanel value="AttractionInspections">
                        <Typography variant='h5'>Attraction Inspections Roles</Typography>
                        <Roles groupType={RoleGroupType.AttractionInspections}/>
                    </TabPanel>
                    <TabPanel value="Training">
                        <Typography variant='h5'>Training Roles</Typography>
                        <Roles groupType={RoleGroupType.Training}/>
                    </TabPanel>
            </TabContext>
            <span style={{display:"flex", justifyContent:"end", padding: "1rem"}}>
                <LoadingButton
                    variant='contained'
                    loading={loading}
                    onClick={handleSaveClick}
                >
                    Save
                </LoadingButton>
            </span>
        </>
    )
}

export default TmRoles