const textContent = {
    learningDocument: {
        NTMtraineeSignOff: "By signing this document, I confirm that I received the above training and accept the results given regarding my readiness for assessment. I understand that additional training will be provided to me if requested by myself or the trainer before an assessment is completed.",
        RTMtraineeSignOff: "By signing this document, I confirm that I received the above training & assessment and accept the results as fair and accurate. I understand that additional training will be provided to me if requested by myself or the Trainer/Assessor, before I can undertake this role unsupervised. I understand that if I am marked 'C' I am competent to operate this ride unsupervised.",
        NTMtrainerSignOff: "As the Trainer, I sign this to acknowledge the completion of all tasks within this Trainer's Guide. I confirm that I have made the decision to deem each trainee as either 'Ready' or 'Not Yet Ready' as recorded above.",
        RTMtrainerSignOff: "As the Trainer, I sign this to acknowledge the completion of all tasks within this Trainer's Guide. I confirm that I have made the decision to deem each trainee as either 'Competent' or 'Not Yet Competent' as recorded above.",
        TrainersClause: (departmentLabel: string) => `Trainers have been appointed by the Training & Recruitment and ${departmentLabel} Departments, they have been assessed as having current industry skills and knowledge relevant to the training being delivered. Digital training and assessing documents are owned and monitored by the Training and Recruitment Team.`,
    }
}

export default textContent