import { Stack, TextField, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { JournalEntryDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'

type Props = {
    formik: FormikProps<JournalEntryDto>
}
function OutOfServiceEntry({formik}: Props) {
    return (
        <>
            <Stack>
                <Typography variant='h6'>Component</Typography>
                <TextField
                    size="small"
                    sx={{width:"20rem"}}
                />
                <Typography variant='h6'>Action Required</Typography>
                <EditableLabel
                    value={formik.values.description ?? ""}
                    setValue={(newValue: string) => formik.setFieldValue("description", newValue)}
                    placeholder='Describe the action required to put the item back in service'
                    onBlur={() => formik.setFieldTouched('description', true, false)}
                    helperText={formik.touched.description && formik.errors.description}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                />
            
                <Typography variant='h6'>Action Taken</Typography>
                <EditableLabel
                    value={formik.values.resolutionDescription ?? ""}
                    setValue={(newValue: string) => formik.setFieldValue("resolutionDescription", newValue)}
                    placeholder='Describe the action taken'
                    helperText={formik.errors.resolutionDescription}
                    error={Boolean(formik.errors.resolutionDescription)}
                />    
            </Stack>
        </>
  )
}

export default OutOfServiceEntry