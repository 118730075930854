import React, { useCallback, useEffect } from 'react'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import "./styles.scss";
import { Typography } from '@mui/material'

const CustomDocument = Document.extend({
    content: 'bulletList block*',
})

function ULeditor({content, onBlurSaveContent, label} : {content: string, onBlurSaveContent: (content: string) => void, label: string} ) {
    const editor = useEditor({
        extensions: [
            CustomDocument,
            StarterKit.configure({
                document: false,
            }),
            Placeholder.configure({
                placeholder: ({ node }) => {
                    if(node.type.name === 'bulletList') {
                        return "Example One"
                    }
                    return "";
                    
                }
            })
        ],
        content: content,
    }) as Editor;   

    const setContent = useCallback((newContent: string) => {
        editor.commands.setContent(newContent)
    }, [editor])
    
    useEffect(() => {
        if(editor){
            setContent(content)
        }
      }, [content, editor, setContent])

  return (
    <div className='UL-editor'>
        <Typography variant="caption" className='Label'>{label}</Typography>
        <EditorContent editor={editor} onBlur={() => onBlurSaveContent(editor.getHTML())}/>

    </div>  
  )
}

export default ULeditor;