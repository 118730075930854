import { Autocomplete, Box, IconButton, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import LearningItemService from 'src/Services/Training/LearningItemService';
import TrainingGroupService from 'src/Services/Training/TrainingGroupService';
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { NameDto } from 'src/dtos/NameDto.dto';
import { LearningItem, LearningItemType } from 'src/dtos/Training/LearningItem.dto'
import CloseIcon from '@mui/icons-material/Close';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { LoadingButton } from '@mui/lab';

type Props = {
    open: boolean;
    OnClose: () => void;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
    handleAddSheets: (attendanceSheet: LearningItem[]) => void;
}

function AddAttendanceSheetForm({open, OnClose, setMessageItem, handleAddSheets: hanldeAddSheets}: Props) {

    const enum AddType {
        trainingGroup,
        attendanceSheet
    }

    const [addType, setAddType] = useState<AddType | string>(AddType.trainingGroup)
    const [trainingGroup, setTrainingGroup] = useState<NameDto | null>(null)
    const [attendanceSheet, setAttendanceSheet] = useState<NameDto | null>(null)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'max-content',
        bgcolor: 'background.paper',
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        paddingBottom: 2,
        paddingTop: 2,
    };

    //#region queries

    const trainingGroupsQuery = useQuery({
        queryKey: ["trainingGroupNames"],
        queryFn: async () => {
            return await TrainingGroupService.GetNames();
        }
    }) 

    const attendanceSheetsQuery = useQuery({
        queryKey: ["attendanceSheetNames"],
        queryFn: async () => {
            return await LearningItemService.GetNames([LearningItemType.attendanceSheet])
        }
    })

    const trainingGroupAttendanceSheetsQuery = useQuery({
        queryKey: ["trainingGroupAttendanceSheets", trainingGroup?.id],
        queryFn: async () => {
            if(trainingGroup){
                return await TrainingGroupService.GetAttendanceSheets(trainingGroup.id)
            }
            return null
        }
    })

    const attendanceSheetQuery = useQuery({
        queryKey: ["learningItem", attendanceSheet?.id],
        queryFn: async () => {
            if(attendanceSheet){
                return await LearningItemService.Get(attendanceSheet.id)
            }
            return null
        }
    })

    //Handle query errors
    useEffect(() => {
        if(trainingGroupsQuery.isError){
            setMessageItem({error: trainingGroupsQuery.error})
        }
        if(attendanceSheetsQuery.isError){
            setMessageItem({error: attendanceSheetsQuery.error})
        }
        if(trainingGroupAttendanceSheetsQuery.isError){
            setMessageItem({error: trainingGroupAttendanceSheetsQuery.error})
        }
        if(attendanceSheetQuery.isError){
            setMessageItem({error: attendanceSheetQuery.error})
        }
    }, [attendanceSheetQuery.error, attendanceSheetQuery.isError, attendanceSheetsQuery.error, attendanceSheetsQuery.isError, setMessageItem, trainingGroupAttendanceSheetsQuery.error, trainingGroupAttendanceSheetsQuery.isError, trainingGroupsQuery.error, trainingGroupsQuery.isError])

    //#endregion

    const handleAddClick = () => {
        if(attendanceSheet){
            hanldeAddSheets(attendanceSheetQuery.data ? [attendanceSheetQuery.data] : [])
        }
        if(trainingGroup){
            hanldeAddSheets(trainingGroupAttendanceSheetsQuery.data ?? [])
        }
    }

    const handleClose = () => {
        OnClose()
        //clear form
        setTrainingGroup(null)
        setAttendanceSheet(null)
        setAddType(AddType.trainingGroup)
    }

    const loading = trainingGroupAttendanceSheetsQuery.isFetching || trainingGroupAttendanceSheetsQuery.isLoading || 
                    trainingGroupsQuery.isFetching || trainingGroupsQuery.isLoading ||
                    attendanceSheetsQuery.isFetching || attendanceSheetsQuery.isLoading ||
                    attendanceSheetQuery.isFetching || attendanceSheetQuery.isLoading

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Stack direction={"row"} sx={{display:"flex", justifyContent:"space-between"}}>
                    <Typography variant='h5'>Add Attendance Sheets</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Stack>
                <Stack spacing={1}>
                    <div>
                        <QuestionTitle title="Add From?" infoText='Training Group - Adds all Attendance Sheets from the selected Training Group. Individual - Choose an individual Attendance Sheet to Add'/>
                        <Select
                            id="addType"
                            name="addType"
                            value={addType}
                            onChange={(e) => {
                                setAddType(e.target.value)
                                setTrainingGroup(null)
                                setAttendanceSheet(null)
                            }}
                            size="small"
                            sx={{width:"25rem"}}
                        >
                            <MenuItem value={AddType.trainingGroup}>Training Group</MenuItem>
                            <MenuItem value={AddType.attendanceSheet}>Individual Attendance Sheet</MenuItem>
                        </Select>
                    </div>
                    {addType === AddType.trainingGroup && (
                        <div>
                            <QuestionTitle title="Training Group" infoText='Add all learning items of type Attendance Sheet from the selected training group. '/>
                            <Autocomplete
                                disablePortal
                                id="trainingGroup"
                                options={trainingGroupsQuery.data ?? []}
                                value={trainingGroup}
                                onChange={(e, value) => {
                                    setTrainingGroup(value)
                                    trainingGroupAttendanceSheetsQuery.refetch()
                                }}
                                size={"small"}
                                sx={{width:"25rem"}}
                                renderInput={(params) => 
                                    <TextField {...params}/>
                                }
                            />
                        </div>
                    )}
                    {addType === AddType.attendanceSheet && (
                        <div>
                            <QuestionTitle title="Attendance Sheet" infoText="Add an individual Attendance Sheet"/>
                            <Autocomplete
                                disablePortal
                                id="attendanceSheet"
                                options={attendanceSheetsQuery.data ?? []}
                                value={attendanceSheet}
                                onChange={(e, value) => {
                                    setAttendanceSheet(value)
                                }}
                                size={"small"}
                                sx={{width:"25rem"}}
                                renderInput={(params) => 
                                    <TextField {...params}/>
                                }
                            />
                        </div>
                    )}
                    <span style={{display:"flex", justifyContent:"end"}}>
                        <LoadingButton loading={loading} variant="contained" onClick={handleAddClick}>
                            Add
                        </LoadingButton>
                    </span>
                </Stack>
            </Box>

        </Modal>
    
    )
}

export default AddAttendanceSheetForm